module.exports = {
    public: {
        confirm: '确认',
        cancel: '取消',
        search: '搜索',
        RefreshSucceeded: '刷新成功',
        noMoreData: '没有更多数据了',
        pullingText: '下拉即可刷新…',
        ReleaseText: '释放即可刷新…',
        loadingText: '加载中…',
        noData: '没有记录',
        copySuccess: '复制成功',
        parent: '推荐人',
        swap: '永续',
        amount: '金额',
        buy: '购买',
        orderNo: '订单号',
        Paypassword: '交易密码',
        PaypasswordMsg: '请输入6位交易密码',
        noPayPasswordMsg: '还未设置交易密码，去设置？',
        noAuthisg: '还未实名认证，去认证？',
        broker: '经纪人',
    },
    tabBar: {
        home: '首页',
        chat: '家聊',
        trustship: '资产托管',
        property: '资产'
    },
    register: {
        title: '账号注册',
        send: '发送验证码',
        Resend: '重新发送',
        sendSuccess: '发送成功',
        PhoneNumber: '手机号码',
        Captcha: '验证码',
        Password: '密码',
        ConfirmPassword: '确认密码',
        ParentId: '推荐人ID',
        btn: '立即注册',
        placeholder0: '请输入手机号码',
        placeholder1: '请输入密码',
        placeholder2: '请输入确认密码',
        placeholder3: '请输入推荐人ID',
        placeholder4: '请输入验证码',
        success: '注册成功',
        message: '密码至少6位',
        message1: '确认密码至少6位',
        message2: '两次密码不匹配',
        searchCountryMsg: '请输入国家、区号搜索',
    },
    login: {
        title: '家盛',
        Account: '登录账号',
        Password: '密码',
        btn: '登录',
        placeholder: '请输入手机号/ID号',
        placeholder1: '请输入密码',
        ForgotPassword: '忘记密码？',
        Registration: '注册',
        server: '联系客服',
        success: '登录成功',
    },
    forgotPass: {
        title: '找回密码',
        qrzh: '确认',
        Password: '设置登录密码',
        placeholder: '请输入新密码',
        success: '设置成功',
    },
    home: {
        title: '家盛',
        toRecharge: '我要充币',
        lineServer: '在线客服',
        newPrice: '实时价格',
        news: '新闻资讯',
        seeAll: '查看全部',
        auth: '实名认证',
        bill: '账单',
        safeCenter: '安全中心',
        message: '消息通知',
        withAddress: '提币地址',
        about: '关于我们',
        whitePaper: 'JiaSheng Token白皮书',
        setting: '设置',
        downApp: '下载App',
        addChat: '加入群聊',
    },
    auth: {
        title: '实名认证',
        gj: '国籍',
        tipsgj: '请选择国籍',
        userName: '真实姓名',
        tips1: '请输入真实姓名',
        IDNumber: '证件号码',
        tips2: '请输入证件号码',
        high: '高级认证',
        zmz: '证件正面照片',
        bmz: '证件背面照片',
        scz: '手持证件照片',
        tips3: '请上传证件正面照片',
        tips4: '请上传证件背面照片',
        tips5: '请上传手持证件照片',
        btn: '提交',
        upbtn: '修改证件',
        subMsg: '提交成功，请等待审核',
        upMsg: '修改后将重新审核，确定修改证件？',
        wait: '审核中',
        fail: '认证失败',
        success: '认证成功',
    },
    safeCenter: {
        title: '安全中心',
        editLoginPwd: '修改登录密码',
        editPayPwd: '修改交易密码',
        setPayPwd: '设置交易密码',
        oldPassword: '原密码',
        oldPasswordMsg: '请输入原密码',
        newPassword: '新密码',
        newPasswordMsg: '请输入新密码',
        twoPassword: '确认密码',
        twoPasswordMsg: '请输入确认密码',
        twoinputMsg: '两次输入密码不同',
        submsg: '修改成功',
        btn: '确认修改',
        setPayedMsg: '已设置过交易密码',
        payPassMsg: '请设置6位数字支付密码',
        payPassMsg2: '支付密码用于提币等其他交易操作，请不要泄露给他人',
        setBtn: '确认',
        setSuccess: '设置成功',
    },
    search: {
        title: '搜索',
        inputSearchTxt: '请输入关键词',
        logs: '搜索历史',
        maxMsg: '最多显示十条',
        news: '新闻资讯',
        trustship: '资产托管',
    },
    news: {
        title: '新闻资讯',
        Newspaper: '报刊文章',
        Investment: '家盛公告',
        MarketJournal: '操作指南',
        InvestSchool: '投资学堂',
        FundNotice: '基金通告',
    },
    chat: {
        title: '家聊',
        noOpen: '暂未开放',
    },
    bill: {
        title: '账单',
        tradeLog: '交易记录',
        etfTrade: 'ETF交易',
        tsTrade: '托管交易',
        orderNo: '订单号',
        rechargeLog: '充币记录',
        withLog: '提币记录',
        rechargeAmount: '充币金额',
        withAmount: '提币金额',
        TsOrder: '托管下单',
        TsSettle: '托管收益',
        TsCommission: '托管提成收益',
        TsStop: '托管中止',
        TsEndReturn: '托管到期返还',
        EtfOrder: 'ETF下单',
        EtfSettle: 'ETF订单结算',
        EtfCancel: 'ETF取消订单',
        Wait: '待审核',
        Success: '审核成功',
        Fail: '审核不通过',
    },
    message: {
        title: '消息通知',
        Account: '账户变动通知',
        ETF: 'ETF交易通知',
        TrustShip: '托管订单通知',
        AccountMsg1: '尊敬的用户，您充币',
        AccountMsg2: '尊敬的用户，您提币',
        AccountMsg3: '尊敬的用户，您币币兑换',
        AccountMsg4: '已成功到账，请注意查收',
        AccountMsg5: '已被拒绝',
        AccountMsg6: '尊敬的用户，您邀请奖励',
        AccountMsg7: '尊敬的用户，您购买项目奖励',
        AccountMsg8: '尊敬的用户，您认证奖励',
        TsMsg1: '尊敬的用户，您的资产托管项目',
        TsMsg2: '托管金额',
        TsMsg3: '已托管成功，并开始计算收益',
        TsMsg4: '已到期，托管资金和收益',
        TsMsg5: '已返还账户余额，请注意查收',
        TsMsg6: '已提前中止',
        User: '账号动态通知',
        UserMsg1: '尊敬的用户，您修改了登录密码',
        UserMsg2: '尊敬的用户，您修改了交易密码',
        UserMsg3: '尊敬的用户，您修改了钱包地址',
        UserMsg4: '尊敬的用户，您的实名认证已审核通过',
        UserMsg5: '尊敬的用户，您的实名认证审核未通过，请修改后重新提交',
    },
    setting: {
        title: '设置',
        heard: '头像',
        lang: '语言',
        nickname: '昵称',
        nicknamemsg: '请输入昵称',
        outlogin: '退出登录',
        outloginmsg: '确认退出登录？',
        editNicknmae: '修改昵称',
    },
    withAddress: {
        title: '提币地址',
        addressMsg: '请输入地址',
        succss: '修改成功',
        edit: '修改',
        copy: '复制地址',
        tips: '请仔细检查提币的钱包地址',
        setTips: '请绑定提币地址',
    },
    recharge: {
        title: '充币',
        copy: '复制地址',
        amount: '充币数量',
        amountMsg: '请输入充币数量',
        Voucher: '转账凭证',
        VoucherMsg: '请上传转账凭证',
        VoucherTips: '请上传转账成功的凭证截图',
        submit: '提交',
        subMsg: '提交成功，请等待审核',
        tips1: '该地址只能接收',
        tips2: '的资产，如果充值其他币种，将无法找回',
    },
    with: {
        title: '提币',
        Balance: '可提余额',
        BalanceNot: '可提余额不足',
        amount: '提币数量',
        amountMsg: '请输入提币数量',
        subMsg: '提交成功，请等待审核',
        selectLin: '选择链',
        coin: '提现币种',
        mixAmount: '最小提币金额10USDT',
        ratetips: '提币手续费0.2%，不满1USDT按1USDT计算',
        ratetipsNo: 'V2及以上免手续费',
        tips1: '请仔细检查提币的钱包地址。',
        tips2: '发送不对应的数字货币到钱包地址会造成永久性的损失。',
        tips3: '提币手续费将从提币数量中扣除。',
        resdAmount: '到账金额',
        ratefee: '手续费',
        rateMsg: '将从余额扣除',
        submit: '提交',
        address: '钱包地址',
        edit: '修改',
    },
    about: {
        title: '关于我们',
    },
    etf: {
        smallMin: '分钟',
        hour: '小时',
        dayMin: '天',
        Trend: '分时',
        Present: '市价委托',
        Limit: '限价委托',
        Balance: '用户权益',
        canUse: '未实现盈亏',
        riskRate: '风险率',
        markPriceMsg: '以当前最新价交易',
        limitMsg: '限价',
        price: '价格',
        num: '数量',
        numTip: '张',
        zyzsSet: '止盈止损设置（选填）',
        zyPrice: '止盈触发价',
        zsPrice: '止损触发价',
        Openmore: '开多（看涨）',
        Openempty: '开空（看跌）',
        canNum: '可开',
        EstimatedMargin: '预估保证金',
        tradeList: '交易记录',
        haveTrade: '持有仓位',
        nowTrade: '当前委托',
        time: '时间',
        direction: '方向',
        buy: '买',
        sell: '卖',
        product: '产品',
        newProce: '最新价',
        Fluctuation: '涨跌幅',
        high: '24H最高',
        low: '24H最低',
        vol: '24H量',
        markTrades: '买卖盘',
        newTrades: '最新成交',
        coinInfo: '币种信息',
        buyPrice: '买价',
        sellPrice: '卖价',
        IssueTotal: '发行总量',
        CirculateTotal: '流通总量',
        IssuePrice: '发行价格',
        IssueTime: '发行时间',
        WhitePaperAddress: '白皮书地址',
        OfficialWebsiteAddress: '官网地址',
        Infos: '简介',
        buyBtn: '买入',
        sellBtn: '卖出',
        cloedMsg: '所在区域不支持此操作',
    },
    trustShip: {
        title: '资产托管',
        buynum: '数量',
        buynumMsg: '请输入购买数量',
        selectDays: '选择期限',
        rate: '收益率',
        useBanlace: '可用余额',
        noBanlace: '余额不足',
        goRecharge: '去充值',
        tradeFail: '交易失败',
        Repurchase: '重新购买',
        tradeSuccess: '交易成功',
        toConTract: '查看合同',
        selectDaysMsg: '请选择期限',
        contractTitle: '加密资产托管合同',
        contractInfo1: '甲方将自有合法资金全权委托给乙方帮助管理， 以实现保值增值目的。本着自愿、真诚、共同受益的原则，根据资产管理方面的法律、法规， 在充分协商的基础上， 就委托资产管理事宜订立本合同。',
        contractInfo2: '一、合同的定立',
        contractInfo3: '1、甲方为获取投资收益，与乙方就甲方资产的投资事宜提供经营及管理服务达成协议。',
        contractInfo4: '2、甲方委托给乙方的投资资金为',
        contractInfo5: '托管期限为',
        contractInfo6: '日',
        contractInfo7: '甲方委托乙方全权负责甲方资产的投资管理和经营，乙方可以自行指定乙方工作人员或外聘专家进行具体操作。',
        contractInfo8: '二、合同的履行',
        contractInfo9: '1、乙方将实现封闭式投资管理，在合同有效期内及合同终止后，甲方不得泄露乙方操作策略和财务状况等商业秘密，不得损害乙方利益。',
        contractInfo10: '2、合同期内，甲方如提前中止合同，只退返本金',
        contractInfo11: '3、因乙方对甲方资金采取分散式投资，部分投资品种可能会有一定的风险，全部由乙方承担，甲方不承担任何风险。',
        contractInfo12: '4、乙方不得恶意转移甲方资金，如因此造成损失甲方将追究乙方刑事责任，并追讨损失。',
        contractInfo13: '三、资金回报率分配',
        contractInfo14: '1、甲方投资资金的收益，甲方获得保底日收益率%(如遇国家政策法规调整，自然灾害等不可抗力或意外事件，双方互不承担责任，合同立即终止。)',
        contractInfo15: '2、托管到期后，乙方及时返还甲方资金。',
        contractInfo16: '四、合同的解除和终止',
        contractInfo17: '1、本合同经甲、乙双方协商一致，甲方可随时解除本合同。',
        contractInfo18: '2、合同的权利义务终止后，甲、乙双方应遵循诚实信用原则，根据约定履行通知、协助、保密等义务。',
        contractInfo19: '五、合同的生效',
        contractInfo20: '1、本合同一式2份，双方各持1份。',
        contractInfo21: '2、本协议自甲方在指定理财账户上确定入金后，签订合同，各方签字，盖章之日起生效。',
        contractInfo22: '甲方',
        contractInfo23: '签约日期',
        contractInfo24: '乙方：（签章）',
        contractInfo25: '地址：香港湾仔道165-171号幸运中心15楼',
        contractInfo26: '联系方式：12356-8656-555',
        contractInfo27: '合同联系方式：jiashengcapita@gmail.com',
        downLoad: '下载到手机',
    },
    exchange: {
        title: '币币兑换',
        follow: '从',
        fundAccount: '资金账户',
        curreny: '币种',
        buyAmount: '划转数量',
        buyAmountMsg: '请输入划转数量',
        canAmount: '可用',
        needAmount: '预计需要',
        noAmount: '余额不足',
        subMsg: '兑换成功'
    },
    property: {
        title: '资产',
        walletAddress: '钱包地址',
        AllAmount: '总资产',
        CanAmount: '可用余额',
        funtAccount: '资金账户',
        Balance: '余额',
        convert: '折合',
        contractposition: '合约持仓',
        tsProject: '托管项目',
        tsAmount: '托管总额',
        tsName: '项目名称',
        buyTime: '购买时间',
        endTime: '到期时间',
        operate: '操作',
        stopTs: '中止托管',
        toSee: '查看',
        myTeam: '我的团队',
        toTeam: '成为经纪人',
        teamNum: '团队人数',
        tsAmountTotal: '资产托管共计',
        ProfitTotal: '预计收益共计',
        AlreadyProfit: '已收益共计',
        tsToName: '资产托管',
        profit: '预计收益',
        alePorfit: '已收益',
        apply: '申请',
        applyMsg: '是否申请成为经纪人，当成为经纪人后，个人等级权益将被经纪人权益覆盖。',
        tips: '提示',
        stopMsg: '提前终止投资合约将无法如约获取收益仅退回投资本金，请确认是否终止！',
        gradeMsg1: '等级机制奖励',
        gradeMsg2: '累计投资，只升不降',
        gradeMsg3: '下级项目收益',
        gradeMsg4: '二层',
        gradeMsg5: '三层',
        gradeMsg6: '可申请成为经纪人',
        gradeMsg7: '拿团队成员项目收益的',
        projectInfo: '项目明细',
        rate: '利率',
        endProfit: '到期收益',
        applySuccess: '申请成功，请等待审核',
        projectorder: '项目订单',
        orderNo: '订单号',
        allRate: '总收益率',
        tsammount: '托管金额',
        allProject: '预期收益',
        allEnd: '期满总额',
        strattime: '生效时间',
        enddtime: '结束时间',
        teCantr: '托管合同',
        btnSee: '点击查看',
        tostopTs: '提前解约',
        completed: '已完成',
        canceled: '已解约',
        stopSuccess: '解约成功',
        tvApplying: '经纪人审核中',
        waiting: '进行中',
        depth: '层级',
        depthMsg: '输入层级筛选',
        Difference: '充提差',
    },
    share: {
        title: '分享下载',
        MyShareCode: '推荐ID',
        CopyShareCode: '复制分享链接',
    }
}