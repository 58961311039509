module.exports = {
    pcregister: {
        p1: 'Существующий счет?',
        dl: 'Войти в систему',
        zc: 'Регистрация на сайте',
        p4: 'Счет в банке',
        p5: 'Пожалуйста, введите номер счета',
        p6: 'Пожалуйста, введите пароль',
        p7: 'Пожалуйста, введите пароль подтверждения',
        p8: 'Пожалуйста, введите код приглашения',
        p9: '- я согласен.',
        p10: 'Пользовательское соглашение и политика конфиденциальности',
        p11: 'Почтовый ящик',
        p12: 'Пожалуйста, введите ваш адрес электронной почты',
        p13: 'Пожалуйста, введите код проверки электронной почты',
        p14: 'В чем дело?',
        p15: 'Год выпуска 2007',
        p16: 'В течение месяца',
        p17: 'День и ночь',
        p18: 'Время в пути',
        p19: 'В течение нескольких минут',
        p20: 'В секундах',
    },
    pclogin: {
        l1: 'Ни одного счета?',
        l2: 'Регистрация на сайте',
        l3: 'Войти в систему',
        l4: 'Введите имя пользователя',
        l5: 'Пожалуйста, введите пароль',
        l6: 'Забыли пароль',
        l7: 'Войти в систему',
    },
    pccontract: {
        ss: 'Рыночная цена',
        xj: 'Фиксированная цена',
        gg: 'рычаг управления.',
    },
    pcrapidly: {
        zf: 'Увеличение объема ресурсов',
        dd: 'По запросу',
        bdsj: 'Местное время анталия',
        ky: 'В наличии:',
    },
    pchome: {
        global1: 'Мировой лидер в области торговли цифровыми активами',
        global2: 'Она обеспечивает безопасную и надежную торговлю цифровыми активами и услуги по управлению активами для миллионов пользователей в более чем 100 странах.',
        global3: 'Безопасность и надежность',
        global4: 'Пятилетний опыт работы в области финансовых услуг, связанных с цифровыми активами. Сосредоточьтесь на распределенной архитектуре и антиddos атакующей системе.',
        global5: 'Глобальная экологическая структура',
        global6: 'Во многих странах были созданы локализованные центры торговых услуг для создания экосистемы блокчейна, объединяющей различные форматы.',
        global7: 'Пользователь в первую очередь',
        global8: 'Создание механизма предварительной компенсации и фонда защиты инвесторов.',
        whever1: 'Терминальная транзакция на нескольких платформах в любое время и в любом месте',
        whever2: 'Она охватывает несколько платформ, включая iOS Android и Windows, и поддерживает все бизнес-функции.',
        register1: 'Регистрация в системе a',
        register3: 'Глобальный счет начинает торговаться',
        register2: 'Зарегистрируйтесь сейчас же',
        commodity: 'По сырьевым товарам',
        news: 'Новости компании',
        ttop1: 'Откройте для себя тайну Pinnacle',
        ttop2: 'Pinnacle является международной биржей, которая торгует фьючерсами и опционами контракты сельскохозяйственных энергетических валют металлов индексы и многое другое. С момента своего создания биржа постоянно совершенствует свои правила торговли и увеличивает объем различных видов продукции. Компания успешно распространилась на розничных и институциональных клиентов более чем в 30 странах и продолжает расти. Мы стремимся поддерживать торговые издержки на как можно более низком уровне и предоставлять услуги в духе сотрудничества, что помогает нашим клиентам стать прибыльными трейдерами, а не просто прибыльными клиентами. В конце концов, если ты делаешь хорошо, мы делаем хорошо.',
        ttop3: 'Начать торговлю сейчас',
        product1: 'Быстрое и надежное выполнение заказа',
        product2: 'Передовые аналитические инструменты',
        product3: 'Котировки в реальном времени',
        product4: 'Быстрый и безопасный вывод средств',
        trade1: 'я умнее.',
        trade2: 'Практиковать свою стратегию',
        trade3: 'Попробуйте наш бесплатный демо-счет, прежде чем открыть реальный торговый счет, чтобы изучить нашу интуитивную торговую платформу и повысить свои навыки.',
        trade4: 'Расширить свои знания',
        trade5: 'Узнайте о торговле CFD с Pinnacle, используя наши комплексные учебные материалы.',
        trade6: 'Управление своим риском',
        trade7: 'Используйте наши передовые инструменты управления рисками, чтобы ограничить ваши потери и закрепить прибыль.',
        allselect1: 'Почему вы все выбираете Pinnacle?',
        allselect2: 'Защита и безопасность',
        allselect3: 'Ваши данные являются безопасными, и ваши средства хранятся на отдельных банковских счетах в соответствии с нормативными требованиями.',
        allselect4: 'Профессиональная поддержка',
        allselect5: 'Получите круглосуточное обслуживание клиентов на нескольких языках.',
        allselect6: 'Регулируемые вопросы',
        allselect7: 'Лицензирование и регулирование осуществляется рядом ведущих мировых регулирующих органов.',
        allselect8: 'Надежность и надежность',
        allselect9: 'Pinnacle Ltd — компания FTSE 250, акции которой котируются на канадском фондовом рынке публичных компаний.',
        down1: 'Любая модель мобильного телефона на мобильном терминале может поддерживать работу и использование',
        down2: 'Приложение для платформы',
    },
    pcbottom: {
        box1: 'Глобальный лидер',
        box2: 'Одна из лучших команд',
        box3: 'Более подробная информация',
        box4: 'О нас',
        box5: 'белая книга',
        box6: 'Услуга за услугу',
        box7: 'скачать',
        box8: 'Общая проблема',
        box9: 'Онлайн-сервис',
        box10: 'Правила и положения',
        box11: 'Соглашение с пользователем',
        box12: 'Политика конфиденциальности',
        box13: 'Заявление по правовым вопросам',
        box14: 'контакты',
    },
    pcAbout_us: {
        t1: 'да, конечно.',
        t2: '7 лет в дороге',
        t3: 'С момента своего рождения в 2015 году мы посвятили себя блокчейн-сервисам и выделенным технологиям и услугам канала строительства.',
        t4: 'С 2014 по 2017 год он пережил повышательный период устойчивого и возрастающего 2017-2018 годов первой половины денег купить, чтобы разбогатеть сумасшедшим периодом со второй половины 2019 года до холодного зимнего периода сегодня я развил реальное мастерство. Бесчисленное множество успешных партнеров, так что low-key мы имеем высокую репутацию в отрасли. В 2022 году мы будем иметь совершенно новую платформу V3.0 и создадим новое акционерное общество BitGoldEX для ведения бизнеса в более чем 100 странах мира.',
        t5: 'Кого мы ищем',
        t6: 'С момента своего создания и по сегодняшний день',
        t7: 'Я искал поставщиков услуг, которые имеют трудности и имеют тот же вкус со мной. В течение нескольких лет я успешно решал проблемы десятков поставщиков услуг',
        t8: 'В современном рациональном мире, если вы ищете хорошего партнера, который мог бы двигаться вперед с вами... возможно, поговорите с нами.',
        t9: 'Будьте уверены, что человек, которого вы ищете, искал вас.',
        t10: 'Услуга за услугу',
        t11: 'контакты',
        t12: 'Когда друг знакомит тебя с бизнесом, ты хороший человек. Когда я знакомлю тебя с бизнесом, думаю, у тебя хорошая репутация. Когда клиент знакомит вас с бизнесом, это доказывает, что вы заслуживаете доверия.',
        t13: 'В духе святого духа',
        t14: 'Мы будем там, где нам нужно',
        t15: 'У разных клиентов разные требования. Разные продукты имеют разные характеристики. Цель различна в разное время. Смысл нашего существования заключается в адаптации к концептуальной системе и требованиям различных поставщиков услуг для решения различных проблем.',
        t16: 'Мы хотели бы быть кирпичом с идеями и функциями, мы будем там, где мы нужны.',
    },
    pcproblem: {
        p1: 'Вопросы и ответы',
        p2: 'Почему меняется преобразованная сумма активов?',
        p3: 'Эквивалентный расчет в активе — это стоимость текущей цифровой валюты, конвертированной в доллары США, которая меняется в связи с колебаниями цен на цифровой актив. Количество ваших цифровых активов не изменилось.',
        p4: 'Забыли о методе обработки паролей фондов для операций платформы',
        p5: 'Если вы забыли пароль торгового фонда на платформе, перейдите в раздел "My-Settings-Click Set fund password "или обратитесь в службу поддержки клиентов для перезагрузки',
        p6: 'Где происходит движение капитала после покупки сделки?',
        p7: 'После того как заказ-наряд будет выполнен, средства будут введены на счет во французской валюте в активе. Если необходимо осуществлять торговлю контрактами или фьючерсами на приобретенные активы, пожалуйста, перейдите в модуль перевода средств фонда для передачи.',
        p8: 'Зачем ограничивать количество изменений в именах',
        p9: 'По соображениям безопасности вашей транзакции мы ограничиваем настоящее имя, которое может быть изменено только Один раз.',
        p10: 'Что ты имеешь в виду, когда переводишься? Почему ты хочешь перевестись',
        p11: 'Для обеспечения независимости средств между вашими счетами и облегчения разумного управления рисками мы настоящим делим счета каждого крупного операционного модуля. Под переводом понимается процесс перевода активов между торговыми счетами.',
        p12: 'Как рассчитать собственный капитал учетной записи в пользовательском договоре?',
        p13: 'Собственный капитал контрактного счета = маржа позиции + плавающая прибыль и убыток позиции + доступная сумма текущего счета',
        p14: 'Каково значение параметра stop loss and profit при открытии позиции? Как это должно быть установлено?',
        p15: 'Возьмите прибыль и остановить потерю как верхний предел прибыли и потери, установленный вами. При достижении установленной суммы stop profit и stop loss система автоматически закрывает позицию. Его можно использовать для управления рисками при покупке контракта. Половина набора прибыль-получение суммы: сумма увеличения x количество x рычаги мультиset stop loss. Мы рекомендуем вам установить его в соответствии с вашей фактической ситуации с активами и разумно контролировать риск.',
        p16: 'Как уменьшить контрактный риск?',
        p17: 'Вы можете перевести имеющиеся активы остальных счетов на контрактный счет путем перевода средств или уменьшить риск путем уменьшения открытых позиций.',
        p18: 'Что означает количество на открытии контракта?',
        p19: 'Количество на открытой позиции представляет собой количество валют, которые вы ожидаете купить. Например: выберите на первой странице BTC/USD торговой пары купить long введите цену 1000USD и введите сумму 10BTC это означает: вы ожидаете купить 10BTC с удельной ценой 1000USD.',
        p20: 'Как рассчитывается плата за обработку в договоре?',
        p21: 'Сбор за обработку = цена открытия * количество открытия * ставка сбора за обработку',
        p22: 'Записки о принудительной ликвидации',
        p23: 'Степень риска является индикатором для измерения риска ваших активов. Когда степень риска равна 100%, ваша позиция рассматривается как ликвидация степень риска = (холдинговая маржа/собственный капитал счета договора)*100% для того, чтобы предотвратить пользователей от ношения позиции система устанавливает поправочный коэффициент степени риска. Когда степень риска достигает значения риска, установленного системой, система будет вынуждена закрыть позицию. Например: коэффициент коррекции, установленный системой, составляет 10%, тогда, когда степень риска выше или равна 90%, все ваши позиции будут вынуждены быть закрыты системой.',
        p24: 'Каковы правила торговли контрактами?',
        p25: 'Тип сделки',
        p26: 'Виды торгов делятся на два направления: длинные позиции (покупка) и короткие позиции (продажа):',
        p27: 'Купить long (бычий) означает, что вы думаете, что текущий индекс, вероятно, возрастет, и вы хотите купить определенное количество определенных контрактов по установленной вами цене или текущей рыночной цене.',
        p28: 'Продать короткий (медвежий) означает, что вы думаете, что текущий индекс, вероятно, упадет, и вы хотите продать определенное количество новых контрактов по установленной вами цене или текущей рыночной цене.',
        p29: 'Метод заказа',
        p30: 'Лимитированная цена заказа: необходимо указать цену и количество размещенного заказа',
        p31: 'Рыночный заказ: вам нужно только установить количество заказа цена является текущей рыночной ценой',
        p32: '1. Должности',
        p33: 'Когда заказ на открытие позиции выполнен, она называется позицией',
        p34: 'Вопросы поставки по контрактам',
        p35: 'Договор платформы является бессрочным договором без установленного срока поставки. Если система не отвечает условиям для ликвидации или вы не закрываете позицию вручную, вы можете держать позицию постоянно.',
        p36: '1: система автоматически закроет позицию при достижении установленного значения Take Profit и Stop Loss',
        p37: '2: риск слишком высок, система вынуждена закрыть позицию',
        p38: 'Какова маржа в договорных сделках?',
        p39: 'В договорных сделках пользователи могут участвовать в продаже и покупке контрактов на основе цены и количества контракта и соответствующего увеличения левереджа. Пользователь будет занимать маржу при открытии позиции. Чем больше стартовая маржа, тем выше будет риск по счету.',
        p40: 'Что такое лимитный и рыночный порядок?',
        p41: 'Лимит-ордер относится к цене, которую вы ожидаете доверить платформе для торговли и рыночный заказ относится к доверить платформе для торговли по текущей цене непосредственно. В правилах открытия позиций рыночным заявкам отдается приоритет перед лимитированными заявками. Если вы выбираете лимит-ордер, пожалуйста, откройте позицию разумно в соответствии с текущей рыночной ценой валюты, чтобы избежать потерь из-за необоснованных цен открытия.',
        p42: 'Каков уровень риска, связанного с контрактными сделками?',
        p43: 'Степень риска является индикатором риска в вашем счете контракта. Степень риска, равная 100%, рассматривается как ликвидация. Мы считаем, что если риск превышает 50%, вы должны тщательно открыть позицию, чтобы избежать потерь в результате ликвидации. Вы можете уменьшить свой риск, пополнив имеющиеся средства контрактных активов или сократив свои позиции.',
        p44: 'Почему необходим обмен валюты?',
        p45: 'Целью биржи является обеспечение беспрепятственного обращения средств в различных валютах в ваших активах, и QCC, полученный на фьючерсном счете, может быть свободно конвертирован в доллары США для торговли. USDT на других счетах также может быть свободно конвертирован в QCC для торговли.',
        p46: 'Почему другая сторона не получила перевод вовремя после передачи сделки?',
        p47: 'Прежде всего, убедитесь, что способ оплаты, который вы заполняете при переводе денег, точно такой же, как показано на странице реквизитов заказа. Затем, пожалуйста, подтвердите, является ли ваш метод оплаты немедленным или отложенным. Наконец, пожалуйста, свяжитесь с вашим банком и платежным учреждением для обслуживания системы или по другим причинам.',
        p48: 'Как купить доллары через платформу?',
        p49: 'Способ 1: выберите заказ, который вы хотите купить через платформу купить и продать список, чтобы купить и продать.',
        p50: 'Способ 2: нажмите кнопку опубликовать, чтобы опубликовать заказ на покупку или продажу для быстрых сделок в соответствии с вашими потребностями. Система быстро сопоставит вас с покупателями и продавцами пользователей.',
        p51: 'Примечание: если заказ не оплачен через 15 минут, система автоматически аннулирует заказ, пожалуйста, оплатите вовремя. Если заказ аннулируется более 3 раз в Один и тот же день, сделка не может быть выполнена снова в тот же день и торговый орган будет восстановлен на следующий день.',
    },
    public: {
        zxkf: 'Онлайн обслуживание клиентов',
        sure: '3. Подтверждение:',
        cancel: 'Все в силе',
        RefreshSucceeded: '< < обновить > > успешно',
        noMoreData: 'Данных для чтения больше нет',
        pullingText: 'Тяните, чтобы обновить...',
        ReleaseText: 'Выпуск для обновления...',
        loadingText: 'погрузка...',
        noData: 'Никаких записей не найдено',
        xq: 'Информация о компании',
        submit: '1. Представление',
        kefu: 'Онлайн обслуживание клиентов',
        amount: 'Общая сумма',
        startInvestment: 'стартинвестмент',
        endInvestment: '< < эндинвестмент > >',
        profit: '1. Прибыль',
    },
    ChangeType: {
        Upper: 'Прибытие на депозит',
        Lower: 'Выход из договора',
        Win: '1. Прибыль',
        Bet: 'На что спорим?',
        BuyMining: 'Добыча полезных ископаемых',
        CoinWin: 'Новый маркер прибыли',
        SettleMining: 'Окупаемость капитала в горнодобывающей промышленности',
        GiveInterest: 'Интерес, связанный с добычей полезных ископаемых',
        BuyCoin: 'Покупка новых маркеров',
        BuyCoinBack: 'Возврат с новой покупки маркера',
        UsdtToBtc: 'Юсдобтк США',
        UsdtToEth: 'Соединенные Штаты америки',
        BtcToUsdt: 'да. - да',
        BtcToEth: 'BtcToEth (BtcToEth)',
        EthToUsdt: 'Организация < < эттусд > >',
        EthToBtc: 'Эттобтк (в долл. США)',
        CoinThaw: 'Оттепель новой валюты',
        CoinFrozen: 'Заморожена новая валюта',
        CoinCancel: 'Отмена заказа на валюту',
        CoinBuy: 'Покупка валюты',
        CoinBuyEntrust: 'Валюта купить trust',
        CoinSellEntrust: 'Валюта продать доверение',
        CoinSell: 'Продажа валюты',
        ContractBuy: 'Контрактный порядок вычета прибыли',
        ContractCharge: 'Заказы по контрактам за вычетом расходов на обработку',
        ContractCancelToBond: 'Возврат депозита после расторжения договора',
        ContractCancelToCharge: 'Возврат платы за обслуживание при расторжении договора',
        ContractCloseProfit: 'Поступления на конец срока действия контракта',
    },
    trade: {
        title: '1. Обмен валюты',
        Buy: 'купить',
        Sell: 'На продажу',
        pc: 'По адресу:',
        cb: 'Расходы по проекту',
        xj1: 'В настоящее время цена',
        yk: 'Прибыль и убытки',
        xianjia: 'Фиксированная цена',
        jiage: 'Цена за номер',
        shuliang: 'без изменений.',
        keyong: 'В наличии:',
        yonghuchicang: '1. Должности',
        fxlts: 'Ставка риска = номинальная маржа/собственный капитал клиента, умноженная на 100%, когда собственный капитал клиента равен имеющимся средствам счета + начальная операционная маржа + плавающая прибыль и убыток',
        fxl: 'Степень опасности',
        dqwt: 'Открытые заказы',
        name: 'Имя и фамилия',
        leixing: 'тип',
        all: 'Все продукты питания',
        shijian: 'В то время',
        long: 'В течение долгого времени',
        short: 'Краткое описание:',
        cc: 'Оставайтесь на местах.',
        zyjg: 'Текущая лучшая цена',
        caozuo: 'Операции по поддержанию мира',
        xj: 'Фиксированная цена',
        sj: 'Рыночная цена',
        jiaoyie: 'Общая сумма',
        orderNo: 'Организация < < ордерно > >',
        weituojia: 'Цена за номер',
        chengjiaojia: 'Цена за номер',
        chengjiaoe: 'Цена сделки',
        weituo: 'Режим работы с программой',
        qbwt: 'Все члены делегации',
        lswt: 'История заказа',
        tips: 'Пожалуйста, введите цену',
        tips1: 'Пожалуйста, введите нум',
        Create: '1. Доверенность',
        Wait: '3. Операции',
        Complete: '10 ч. 00 м. закрытое заседание',
        Cancel: 'Отменено в соответствии с законом',
        zsj: 'Стоп лосс цена',
        zyj: 'Остановить цены прибыли',
        cczsy: 'Общая сумма поступлений от должностей',
        ykbl: 'Соотношение прибыли и убытков',
        bili: 'Соотношение между мужчинами и женщинами',
        jyss: 'Число операций, проведенных',
        bzj: 'Разница в вознаграждении',
        sxf: 'Плата за обработку',
        gg: 'Множественные рычаги давления',
        yjpc: 'Закрыть все двери',
    },
    auth: {
        title: 'Удостоверение личности',
        title1: 'Удостоверение личности',
        low: 'Основная проверка подлинности',
        high: 'Дополнительная проверка подлинности',
        success: 'Успешно проверено на практике',
        tips: 'Пожалуйста, сначала удостоверьтесь',
        tips1: 'Пожалуйста, введите ваше настоящее имя',
        tips2: 'Пожалуйста, введите свой идентификационный номер',
        tips3: 'Пожалуйста, загрузите ваше удостоверение личности (лицевая сторона)',
        tips4: 'Пожалуйста, загрузите ваше удостоверение личности (назад)',
        tips5: 'Пожалуйста, загрузите фото, где вы держите удостоверение личности с фотографической страницы',
        tips6: 'Пожалуйста, выберите реальный тип аутентификации имени',
        qrz: 'Для проверки данных',
        shz: 'В процессе рассмотрения',
        rzsb: 'Проверка не проводилась',
        userName: 'настоящее имя.',
        IDNumber: 'Идентификационный номер телефона',
        zmz: 'Фото удостоверения личности (спереди)',
        bmz: 'Фото на удостоверении личности (Back)',
        scz: 'Фото, где вы держите удостоверение личности на фото странице',
        IDCard: 'Удостоверение личности личности',
        Passport: '3. Паспорт',
        AuthenticationType: 'Тип удостоверения подлинности',
        HighAuth: 'Углубленная сертификация',
        gj: '3. Гражданство',
        tipsgj: 'Пожалуйста, введите ваше гражданство',
    },
    share: {
        title: 'Мой рекомендательный код',
        cp: 'Адрес для копирования',
        success: 'Копия успешно выполнена.',
    },
    newRecharge: {
        Deposit: 'Вклад в депозит',
        Credit: '1. Кредитная карта',
        Wallet: 'Кошелек депозит выиграть бонус каждый раз',
        FAQ: 'Часто задаваемые вопросы',
        Currency: 'Выбрать валюту',
        placeholder: ' ',
        Main: 'Главная страница',
        DepositAds: 'Адрес депозита',
        Copy: 'Версия для печати',
        remark: '• не депонировать никаких активов, кроме',
        remark1: 'В долл. США',
        remark2: 'В противном случае активы возврату не подлежат;',
        remark3: '• после внесения депозита на указанный выше адрес вам необходимо дождаться подтверждения всего узла сети, и только после 6 подтверждений вы можете получить активы на вашем счете;',
        remark4: '• однократный депозит должен составлять не менее 15 USD.',
        remark5: '• перед каждым депозитом вы должны перейти на эту страницу и скопировать адрес, чтобы убедиться, что адрес правильный. Неправильный адрес приведет к неудаче депозита.',
        Pay: 'Оплата труда',
        Buy: 'купить',
        Merchant: 'По торговле и развитию',
        remark6: '* нажмите, чтобы проверить',
        remark7: 'Советы и рекомендации',
        remark8: 'Если у вас возникнут вопросы, пожалуйста, свяжитесь с нами',
        remark9: 'Обслуживание клиентов',
        ChipPay: 'Сделка по покупке монеты чиппей',
        Purchasebyamount: 'Покупка по стоимости',
        AccountBalance: 'Остаток средств на счете',
        Tips: 'Советы и рекомендации',
    },
    ChipPay: {
        copySUC: 'Распространение успешного опыта',
        copyERR: 'Копия не выполнена',
        Buy: 'купить',
        TOBECONFIRMED: 'в ожидании.',
        TransactionCompletion: 'Завершение работ по проекту',
        OrderingInformation: 'Заказ информации',
        oddNumbers: 'Нечетные числа:',
        TransferAmount: 'Сумма перевода:',
        ApproximatelyQuantity: 'Приблизительно в количестве:',
        UnitPrice: 'Цена за 1 украшение:',
        remark1: 'Если я не нажму на кнопку перевода, или расхождения в сумме перевода приведут к задержке получения. Переводы с неличных счетов и замечания/записи приведут к неудаче сделки и возврату средств',
        remark2: 'Советы и рекомендации',
        remark3: 'Платежная информация по каждому заказу действительна только для одного перевода. Пожалуйста, не сохраняйте информацию для второго транша.',
        remark4: 'После создания нескольких заказов без оплаты вы не сможете разместить другой заказ. Пожалуйста, будьте осторожны.',
        remark5: 'Пожалуйста, заполните перевод в течение указанного времени и убедитесь, что нажмите кнопку "я завершил перевод", чтобы обеспечить немедленное получение.',
        remark6: 'Если запрос на размещение торговой партии не удается и текущий объем сделки слишком велик, в результате чего торговая сторона занята, пожалуйста, подождите 5-10 минут, прежде чем повторно представить заказ.',
        remark7: 'Ваш перевод будет напрямую переведен на счет контрагента, а цифровые активы, проданные контрагентом во время сделки, будут управляться и защищаться платформой. Пожалуйста, будьте уверены в сделке.',
        cancellation: 'Аннулирование заказа',
        remittance: 'Передано в другое подразделение',
        remark8: 'Инструкции по операциям',
        remark9: 'Вы будете участвовать в цифровых валютных транзакциях с держателями USD на платформе ChipPay, и платформа будет гарантировать подлинность ваших активов USD на протяжении всей транзакции. Перед началом сделки ознакомьтесь со следующими условиями:',
        remark10: 'Сообщаем, что доллары США не выдаются платформой ChipPay и не имеют законной компенсации',
        remark11: 'При осуществлении сделок с долларовыми держателями на платформе необходимо соблюдать требования декларации о борьбе с отмыванием денег в процессе совершения сделки',
        remark12: 'Пожалуйста, ознакомьтесь с заявлением о рисках, прежде всего, быть в курсе рисков цифровой торговли валютой и согласиться с вышеуказанными вопросами',
        remark13: 'Я осознаю риски сделки и согласен с условиями предоставления услуг',
        remark14: 'Если я не нажму на кнопку перевода, или расхождения в сумме перевода приведут к задержке получения. Переводы с неличных счетов и замечания/записи приведут к неудаче сделки и возврату средств',
        remark15: '1. Работа. Пожалуйста, убедитесь, что сумма перевода соответствует сумме заказа. Если вы не выполните перевод, нажав на эту кнопку, система может оказаться в черном списке',
        ConfirmedRemittance: 'Подтвержденные переводы денежных средствc',
        ConfirmRemittance: 'Подтверждение перевода денежных средств',
        WrongPoint: 'Не та точка.',
        Ialreadyknow: 'Я уже знаю.',
        payattentionto: 'Обратите внимание на',
        agree: 'По общему мнению',
        Bank: 'Получающий банк:',
        ReceivingAccount: 'Счет получения:',
        Party: 'Торговая сторона:',
        Authenticated: 'Удостоверение подлинности',
        Margin: 'Разница в вознаграждении',
        tips1: 'Пожалуйста, проверьте, знаком ли я с транзакционными рисками',
    },
    newProperty: {
        title: 'Общая сумма активов (долл. США)',
        Spot: 'Свободные места в наличии',
        Contract: 'Наличие контракта',
        Bonus: 'В качестве бонуса',
        Purchase: '3. Зарядка',
        Deposit: 'Вклад в депозит',
        Withdraw: 'Выход из договора',
        Transfer: 'Передача функций',
        Spot1: 'Место на карте',
        Contract1: '1. Контракт',
        Commission1: 'Комиссия по наркотическим средствам',
        Financial1: 'По финансовым вопросам',
        Balance: 'Остаток на счетах',
        Hide: 'Скрыть активы 0',
    },
    withdrawal: {
        Wallet: 'Снятие средств с бумажника',
        Internal: 'Внутренняя передача функций',
        Currency: 'В иностранной валюте',
        Main: 'Главная страница',
        WithdrawAddress: 'Адрес для снятия средств',
        Frequently: 'Часто задаваемые вопросы',
        placeholder: 'Адрес для снятия средств в долларах США',
        note: 'Примечание к сделке',
        placeholder1: 'Введите текст сообщения',
        Amount: 'Сумма снятия средств',
        placeholder2: '10 минимум',
        All: 'Все продукты питания',
        Balance: 'Остаток средств на счете',
        Fee: 'Сбор за снятие средств',
        FundPassword: 'Пароль к фонду',
        placeholder3: 'Пожалуйста, введите пароль фонда',
        forget: 'Забыли пароль фонда?',
        OTP: 'Телефон/электронная почта OTP',
        placeholder4: 'Введите проверочный код',
        send: 'Отправить по электронной почте',
        Resend: '1. Повторное рассмотрение',
        Reminder: 'Напоминание о Том,',
        Reminder1: 'Период вывода с понедельника по воскресенье 9:00-22:00 (UTC+8)',
        Reminder2: 'Максимальная доступная сумма = сумма снятия включает комиссию за снятие средств.',
        Reminder3: 'Перед тем как подать заявление на снятие средств, пожалуйста, подтвердите правильность адреса снятия средств. После отправки транзакции в блокчейн-сеть она будет безотзывной',
        Confirm: '3. Подтверждение:',
        noPassword: 'Пароль фонда не установлен',
        Fees: 'Плата за обучение',
        Free: '- бесплатно;',
        Transfer: 'Сумма перевода средств',
        Limitation: 'Ограничение передачи прав',
        placeholder6: 'Количество перевода 0-100000',
        Available: 'Имеющаяся сумма',
        Receiving: 'Счет получения средств',
        placeholder5: 'Пожалуйста, введите псевдоним пользователя',
        TransferBtn: 'Передача функций',
        Reminder4: 'Напоминаем: при передаче данных обязательно подтвердите информацию пользователя',
        Reminder5: 'Время обработки транзакций 09:00-22:00 (UTC+8)',
        title: 'Ii. Заявление',
        title1: 'Место на карте',
        title2: '1. Контракт',
        title3: 'Фонд организации объединенных наций',
        title4: 'Покупка монет',
        title5: 'Продажа монет',
        Rate: 'Обменный курс',
        allRate: 'B. урегулирование споров',
        PlaceOrder: 'Заказ на место',
        Freeze: 'замороз',
        Thaw: 'размороз',
        backgroundProgram: 'Программа для детей',
        AuditReject: 'Продажа монет',
        OrderSettlement: 'Порядок урегулирования претензий',
        AccountBalance: 'Баланс: 1',
        CancelOrder: 'Заказ на отмену',
        Created: 'Создан (с)',
        Paid: 'За дополнительную плату',
        UnAuthed: 'Обзор не проводился',
        Accepted: 'Принята к исполнению',
        Canceled: 'Все в силе',
        Refuce: '3. Опровержение',
        placeholderMC: 'Успешно продано',
        read: 'прочита',
        unread: 'непрочита',
        chat: 'поболта',
    },
    property: {
        Contract: 'Место на карте',
        Exchange: 'Второй контракт',
        zzc: 'Общая сумма активов',
        mhyzh: 'Счет второго контракта',
        bbzh: 'Счет текущих операций',
        available: 'В наличии:',
        OnOrders: 'Ни с места!',
        Estimated: 'В эквиваленте',
        cw: 'Финансовая отчетность',
        number: '1. Число 1',
        record: 'В чем дело?',
        Transfer: 'Передача функций',
        time: 'В то время',
        dhsl: 'Количество маркеров для обмена',
        tips: 'Пожалуйста, введите сумму маркера для обмена',
        qbdh: 'Обмен на все',
        dhhl: 'Обменный курс',
        ky: 'В наличии:',
        kd: 'В наличии:',
        sxfbl: 'Сборы за обработку (ставка)',
        sxf: 'Сборы за обслуживание',
    },
    statusLang: {
        Review: 'Ii. Обзор',
        Success: '3. Успех',
        Fail: 'В случае неудачи',
    },
    with: {
        title: 'Выход из игры',
        history: 'История организации объединенных наций',
        ldz: 'Адрес блокчейна',
        tbdz: 'Адрес для снятия средств',
        tips: 'Пожалуйста, введите адрес для снятия денег',
        tips1: 'Пожалуйста, введите сумму снятия средств',
        tips2: 'Пожалуйста, введите пароль фонда',
        tbsl: 'Сумма снятых средств',
        ky: 'В наличии:',
        zjmm: 'Пароль к фонду',
        sxf: 'Плата за обработку',
        dz: 'Сумма, которая, как ожидается, будет получена',
    },
    tabBar: {
        home: 'Главная страница',
        Quotation: 'На рынках сбыта',
        Quotation2: 'Котировки на рынке',
        Trade: 'По торговле и развитию',
        Contract: '1. Контракт',
        newContract: 'По торговле и развитию',
        assets: 'Активы, находящиеся в собственности',
        qhyy: 'Перейти на другой язык',
        zyjb: 'Замок в шахтах',
        jsjy: 'Быстрая торговля',
        jryw: 'Финансовые операции',
        hyjy: 'Договорная сделка',
        Frequently: 'Часто задаваемые вопросы',
        mine: 'Для меня',
    },
    exchange: {
        title: '1. Обмен валюты',
        canChange: 'Количество в наличии',
        canChange1: 'Пожалуйста, введите сумму обмена',
        title1: 'Информация для обмена',
        Available: 'Имеющиеся квоты',
        Proposed: 'Предлагаемая цена',
        Redeem: 'Выкупить все',
        Confirm: '3. Подтверждение:',
        CurrencyBalance: 'Сальдо валютных курсов',
    },
    home: {
        gridcz: 'Наполненная французской валютой',
        gridsd: 'Снять наличные',
        gridxs: 'Часто задаваемые вопросы',
        gridbd: 'Список 1 2',
        Balance: 'Остаток на счетах',
        UserName: 'Имя пользователя',
        score: 'Оценка по рейтингу',
        kjmb: 'Быстрая покупка',
        zc: 'Поддержка BTC USD и т.д.',
        scwk: 'Добыча в изоляторе',
        sgxb: 'Новая расшифровка маркеров',
        aqkx: 'Безопасность и надежность',
        aqkx1: 'Ведущая в мире платформа для торговли цифровыми активами',
        rmcjb: 'горячая штучка.',
        xbb: 'Новые монеты',
        zfb: 'Рост на душу населения',
        dfb: 'на землю!',
        identify: 'Данные не проверены',
        identifyYes: 'Удостоверение подлинности',
        changeName: 'Пожалуйста, введите имя пользователя',
        changeName1: 'Имя пользователя может редактироваться только Один раз',
        confirmName: '3. Подтверждение:',
        closeName: 'Все в силе',
        mc: 'Имя и фамилия',
        zxj: 'Самая последняя цена',
        zdb: 'Чистое изменение',
        hy: 'Рычаги воздействия на окружающую среду',
        btyh: 'Обычный пользователь',
        sfyz: 'Удостоверение личности',
        skfs: 'Метод оплаты',
        share: 'Ссылка на мою долю',
        kjcb: '3. Зарядка',
        kjtb: 'Выход из договора',
        qbdz: 'Адрес кошелька',
        bps: '< < белая книга > >',
        xgmm: 'Изменение пароля',
        xgzfmm: 'Изменение пароля фонда',
        gywm: 'О нас',
        tcdl: 'Выход из системы',
        zjmx: 'Ведомость по фондам',
        cdsz: 'Настройка по умолчанию',
        cdaq: '3. Проверка подлинности',
        bzzx: 'Часто задаваемые вопросы',
        ysxy: 'Соглашение о конфиденциальности',
        xzyy: 'Выберите язык',
    },
    commonUse: {
        title: 'Услуга за услугу',
        Finance: 'Финансы',
        Notice: 'Уведомление о регистрации',
        customerService: 'Услуга за услугу',
        cc: ' Положение дел',
        zjmx: 'Ii. Заявление',
        cpdm: 'Имя и фамилия',
        mm: 'Купить/продать',
        wtj: 'Цена за номер',
        wtsl: 'Число 1',
        Buy: 'купить',
        Sell: 'На продажу',
        Buy1: 'купить',
        Sell1: 'На продажу',
        Buy2: 'Один клик монеты покупка',
        Exchangequantity: 'количество',
        Exchangeamount: 'Общая сумма',
        Currency: 'В иностранной валюте',
        Status: 'Статус',
        Type: 'тип',
        Service: 'Услуга за услугу',
        p1: 'Пожалуйста, выберите валюту',
        p2: 'Пожалуйста, введите сумму',
        czlb: 'В процессе эксплуатации',
    },
    product: {
        Buy: 'купить',
        Sell: 'На продажу',
        Gain: 'В этом случае',
        Trade: 'По торговле и развитию',
        Real: 'На самом деле',
        High: 'высокий уровень',
        ClosingPrice: 'Цена на закрытие',
        Low: 'Низкий уровень дохода',
        Volume: '24h объем',
        Limit: 'Порядок ограничения выбросов',
        Contract: 'Текущие заказы',
        News: 'Закрытие счетов',
        Liquidations: 'Ликвидация имущества',
        ContractType: 'Контракт/тип',
        OpeningPrice: 'Цена/начальная цена',
        Leverage: 'Рычаги воздействия на окружающую среду',
        LeverageP: 'Рычаг не может быть пустым',
        LimitPrice: 'Сумма сделки',
        LimitPlaceholder: 'Пожалуйста, введите предельную цену',
        Overnight: 'На всю ночь',
        Direction: '1. Направление деятельности',
        Operate: 'В процессе эксплуатации',
        Long: 'Купить /go Long',
        Short: 'Продажа/короткий',
        Balance: 'Остаток на счетах',
        Floating: 'Плавающий P/L',
        CloseAll: 'Закрыть все двери',
        MarketOrder: 'Порядок на рынке',
        LimitOrder: 'Порядок ограничения выбросов',
        Cross: 'Крест на кресте',
        Isolated: 'В условиях изоляции',
        placeholder: 'Лучшая цена исполнения',
        Margin: '1. Число 1',
        MarginBalance: 'Остаток разницы',
        TradingFee: 'Плата за торговлю',
        Position: '1. Положение дел',
        Success: 'Успешная эксплуатация оборудования',
        Commission: 'В случае необходимости',
        Transaction: '1. Интрансакция',
        Completed: 'Работа над проектом завершена',
        Cancellation: 'Аннулирование заказа',
        unitPrice: 'Цена за единицу',
        LeverageRatio: 'Коэффициент использования заемных средств',
        cancellation: 'Все в силе',
        ClosingPosition: '10 ч. 00 м. закрытое положение',
        productName: 'Название продукта',
        Margin1: 'Разница в вознаграждении',
        SettlementPrice: 'Конечная цена',
        EndTime: 'Конец света',
        CreateTime: 'Создать время,',
        WalletBalance: 'Остаток средств в бумажнике',
        AvailableFunds: 'Имеющиеся средства',
        Text1: 'Совет по торговле и развитию',
        Text2: 'Ты хочешь провести эту операцию',
        qr: '3. Подтверждение:',
        qx: 'Все в силе',
        ddbh: 'Номер артикула',
        wtsj: 'Время выдачи доверенности',
        szzyzs: 'Установленная прибыль и убыток',
    },
    notice: {
        title: 'Уведомление о регистрации',
        ckxq: 'Информация о компании',
    },
    helpCenter: {
        title: 'Руководство для начинающих'
    },
    pwdGroup: {
        title: 'Настройка паролей',
        pwdDL: 'Пароль для входа',
        pwdZj: 'Пароль к фонду',
    },
    quotation: {
        title: 'На рынках сбыта',
        mc: 'количество',
        zxj: 'Последняя цена',
        zdb: 'Чистое изменение',
        liang: 'В Том числе:',
    },
    contract: {
        kcslfw: 'Количество на начало:',
        gao: 'высокий уровень',
        di: 'Низкий уровень дохода',
        liang: '24 H объем',
        placeholder: 'Пожалуйста, введите сумму',
        SuccessfulPurchase: 'Покупка прошла успешно',
        smallMin: '* мин.',
        hour: 'Время в пути',
        dayMin: 'День и ночь',
        Trend: 'В то время',
        ccjl: 'Список для хранения',
        ddjl: 'Список заказов',
        sl: 'количество',
        gmj: 'Цена покупки',
        dqj: 'Текущая цена',
        yjyk: 'Расчетная прибыль и убытки',
        djs: 'Обратный отсчет времени',
        gbjg: 'Цена за забастовку',
        sc: 'Сборы за обслуживание',
        pal: 'Прибыль и убытки',
        mhy: 'Второй контракт',
        sj: 'В то время',
        yll: 'Ставка прибыли',
        mz: 'В течение долгого времени',
        md: 'Краткое описание:',
        kcsl: 'Количество в открытой позиции',
        kcsj: 'Время открытия позиции',
        zhye: 'Остаток средств на счете',
        qc: 'Все в силе',
    },
    editPassword: {
        title: 'Изменить пароль входа',
        jmm: 'Старый пароль',
        xmm: 'Новый пароль для входа',
        qrmm: 'Подтверждение пароля',
        placeholder: 'Старый пароль',
        placeholder1: 'Установить новый пароль',
        placeholder2: 'Пожалуйста, введите новый пароль снова',
        qrxf: 'Все в порядке.',
    },
    secure: {
        title: 'На всякий случай',
        title1: 'Двухфакторная аутентификация',
        title2: 'Чтобы обеспечить безопасность вашей учетной записи, пожалуйста, заполните KYC.',
        gridTxt: 'Настройка паролей',
        gridTxt1: 'Мобильная проверка',
        gridTxt2: 'Проверка электронной почты',
        gridTxt3: 'Удостоверение личности',
        listTxt: 'Банковская карта',
        listTxt1: 'Адрес для снятия средств',
    },
    bindBank: {
        title: 'Банковская карта',
        addCard: 'Добавить банковскую карту',
        editCard: 'Изменить пароль фонда проверки',
        ts1: 'Вы еще не прошли идентификацию',
        ts2: 'Пожалуйста, сначала выполните идентификацию личности',
        addTitle: 'Добавить банковскую карту'   ,
        userName: 'настоящее имя.',
        tips1: 'Пожалуйста, введите ваше настоящее имя',
        CollectionDetailArea: 'Область сбора данных',
        tips6: 'Пожалуйста, введите подробную информацию о коллекции',
        userPhone: 'Зарезервированный номер телефона',
        tips2: 'Пожалуйста, введите зарезервированный номер телефона',
        bankName: '1. Название банка',
        tips3: 'Пожалуйста, введите имя банка, открывающего счет',
        bankAds: 'Адрес банка:',
        tips5: 'Пожалуйста, введите адрес банка, открывающего счет',
        bankId: 'Номер банковского карточного счета',
        tips4: 'Пожалуйста, введите номер банковского счета',
        CountryCode: 'Код страны (код страны)',
        tips7: 'Пожалуйста, выберите код страны',
        tips8: 'Пожалуйста, введите SWIFT код',
        tips9: 'Пожалуйста, введите сумму',
        tips10: 'Сумма пополнения или вывода не может быть меньше 100',
        addTxt: 'За исключением:',
    },
    bindMail: {
        title: 'Установить адрес электронной почты',
        placeholder1: 'Адрес электронной почты',
        ptitle: 'Изменить номер телефона',
        placeholder2: 'Введите номер вашего мобильного телефона',
        next: 'Следующий проект',
        zjmm: 'Пароль к фонду',
        placeholder3: 'Пожалуйста, введите ваш пароль',
    },
    setting: {
        title: 'Настройка по умолчанию',
        setTxt: 'Изображение в профиле',
        setTxt1: 'Язык: английский',
        setTxt2: 'Обменный курс',
        setTxt3: 'О нас',
    },
    withPass: {
        title: 'Установите пароль фонда',
        title1: 'Пароль фонда не может быть таким же, как пароль входа',
        aqyz: 'Проверка безопасности полетов',
        aqyzm: 'Телефон/электронная почта OTP',
        jmm: 'Старый пароль',
        xmm: 'Новый пароль для входа',
        qrmm: 'Подтверждение пароля',
        placeholder: 'Старый пароль',
        placeholder1: 'Установить новый пароль',
        placeholder2: 'Пожалуйста, введите новый пароль снова',
        qrxf: 'Все в порядке.',
        tips: 'Пожалуйста, введите старый пароль не менее 6 цифр',
        tips1: 'Введите новый пароль не менее 6 цифр',
        tips2: 'Пожалуйста, введите ваш пароль подтверждения',
        tips3: 'Два новых ввода пароля непоследовательны',
        tips4: 'Пароль был установлен успешно',
        confirm: '3. Подтверждение:',
    },
    newBe: {
        title: 'Подписка на рассылку',
        title1: 'Моя подписка',
        scqx: 'Период содержания под стражей',
        day: 'День и ночь',
        sy: 'оставшаяся часть',
        sdsg: 'История организации объединенных наций',
        sysl: 'Оставшееся количество',
        gmsl: 'Количество в продаже',
        dj: 'Цена за 1 украшение',
        tips: 'Пожалуйста, введите количество покупки',
        gmzj: 'Общая закупочная цена',
        qbye: 'Остаток средств в бумажнике',
        fmzj: 'Общая закупочная цена',
        gm: 'купить',
        sgsj: 'Время оформления подписки',
        Currency: 'Тип маркера',
        Request: 'Испрашиваемая сумма',
        Passing: 'Сумма, прошедшая проверку',
        MarketTime: 'Время составления списка',
        sqz: 'В заявке на участие',
    },
    register: {
        title: 'Регистрация на сайте',
        lang: 'Язык: английский',
        send: 'Отправить по электронной почте',
        Resend: '1. Повторное рассмотрение',
        Account: 'Счет в банке',
        Password: 'Пароль для входа',
        ConfirmPassword: 'Подтверждение пароля',
        InvitationCode: 'Код приглашения',
        btn: 'Регистрация на сайте',
        mobile: 'Телефонный номер телефона',
        mailbox: 'Почтовый ящик',
        haveAccount: 'У вас уже есть аккаунт?',
        haveLogin: 'Вход в систему',
        Login: 'Вход в систему',
        placeholder: 'Пожалуйста, введите адрес электронной почты',
        placeholder0: 'Пожалуйста, введите номер телефона',
        placeholder1: 'Пожалуйста, введите пароль',
        placeholder2: 'Пожалуйста, введите пароль еще раз',
        placeholder3: 'Пожалуйста, введите код приглашения',
        placeholder4: 'Пожалуйста, введите проверочный код',
        placeholder5: 'Пожалуйста, введите правильный почтовый ящик',
        success: 'Регистрация прошла успешно',
        message: 'Пароль должен быть длиной 6-16 символов',
        message1: 'Подтвердить ввод пароля имеет не менее 6 символов',
        message2: 'Ввод пароля непоследователен',
        message3: 'Пожалуйста, прочтите и отметьте соглашение',
        agree: 'я согласен.',
        xy: 'Пользовательское соглашение и политика конфиденциальности',
        qr: 'Регистрация на сайте',
        ftitle: 'Поиск пароля',
        qrzh: 'Все в порядке.',
        placeholder7: 'Новый пароль для входа',
        placeholder6: 'Подтверждение нового пароля',
        placeholder8: 'Пожалуйста, введите графический код проверки',
        placeholderName: 'Пожалуйста, введите имя пользователя',
    },
    lockming: {
        title: 'Камера хранения маркеров',
        wkb: 'Сокровища из шахт',
        wkgjd: 'Добыча полезных ископаемых проще',
        qsljl: 'Легко заработать вознаграждение',
        yjzsr: 'Общая сумма сметных поступлений',
        ck: 'Базисная годовая норма прибыли за последние три дня',
        tips: 'Минимальный размер пенсии',
        rlx: 'Ежедневный интерес',
        day: 'День и ночь',
        scqx: 'Период содержания под стражей',
        buy: 'купить',
        zysl: 'Количество стаканов',
        qbye: 'Остаток средств в бумажнике',
        finished: 'Полный комплект документов',
        doing: 'В процессе осуществления',
        sl: 'количество',
        shouyilv: 'По состоянию на 31 декабря',
        ks: 'Время начала работ',
        js: 'Конец света',
    },
    login: {
        title: 'Вход в систему',
        lang: 'Язык: английский',
        mobile: 'Телефонный номер телефона',
        mailbox: 'Электронная почта',
        Account: 'Счет в банке',
        Password: 'Пароль для входа',
        btn: 'Вход в систему',
        placeholder1: 'Пожалуйста, введите пароль',
        placeholder: 'Пожалуйста, введите номер счета',
        fail: 'Пожалуйста, заполните форму',
        ForgotPassword: 'Забыли пароль?',
        NoAccount: 'Счета еще нет?',
        Registration: 'Зарегистрируйтесь сейчас же',
        OtherLogin: 'Другая учетная запись',
        server: 'Онлайн обслуживание клиентов',
        jzmm: 'Запомнить пароль',
        success: 'Успешно войти в систему',
        ageent: 'Читайте и соглашайтесь с',
        ageentTitle: 'Соглашение об обслуживании',
        ageentFail: 'Пожалуйста, ознакомьтесь с сервисным соглашением',
        Home: 'Главная страница',
        userbox: 'Имя пользователя',
    },
    moneyAddr: {
        title: 'Адрес кошелька',
        Currency: 'В иностранной валюте',
        Address: 'адрес',
        placeholder: 'Пожалуйста, выберите',
        DepositAddress: 'Адрес депозита',
        WalletAddress: 'Адрес кошелька',
        Date: 'Дата проведения выставки',
        add: 'Добавить адрес',
        addTxt: 'Добавьте не более 3 адресов в каждую блокчейн',
        remark: 'Замечания и рекомендации',
        Walletremark: 'Просьба добавить замечания по этому адресу',
        network: 'Главная страница',
        networkXy1: 'Сеть, которую вы выбрали',
        networkXy2: 'да. Если вы отправили свои активы на неправильный адрес по ошибке, вы потеряете свой актив.',
        ChainAddress: 'Цепной адрес:',
        qrcode: 'QR код кошелька',
        submit: '1. Представление',
        tips: 'Пожалуйста, выберите тип кошелька',
        tips1: 'Пожалуйста, выберите цепной адрес',
        tips2: 'Пожалуйста, введите адрес кошелька',
        tips3: 'Пожалуйста, загрузите QR код кошелька',
        success: '3. Успех',
    },
    recharge: {
        title: '3. Зарядка',
        title1: 'Детали подзарядки',
        ls: 'История организации объединенных наций',
        td: 'Пожалуйста, выберите следующий канал',
        bc: 'Сохранить QR',
        address: 'Адрес депозита',
        copy: 'Адрес для копирования',
        num: 'Количество вкладов в банке',
        Upload: 'Загрузить фото на сайт',
        Submit: '1. Представление',
        tip: 'Пожалуйста, введите сумму',
        tip1: 'Пожалуйста, загрузите QR код',
        copySuc: 'Успешно скопирована с сайта',
    }
}