module.exports = {
    pcregister: {
        p1: 'Cuenta existente?',
        dl: 'Iniciar sesión',
        zc: 'registro',
        p4: 'Cuenta cuenta',
        p5: 'Introduzca el número de cuenta',
        p6: 'Por favor introduzca una contraseña',
        p7: 'Introduzca la contraseña de confirmación',
        p8: 'Introduzca el código de invitación',
        p9: 'Estoy de acuerdo',
        p10: 'Acuerdo de usuario y política de privacidad',
        p11: 'Buzón de correo',
        p12: 'Por favor, introduzca su dirección de correo electrónico',
        p13: 'Por favor, introduzca el código de verificación del correo electrónico',
        p14: 'Enviar a',
        p15: 'año',
        p16: 'Mes mes',
        p17: 'día',
        p18: 'hora',
        p19: 'minutos',
        p20: 'segundos',
    },
    pclogin: {
        l1: '¿Ninguna cuenta?',
        l2: 'registro',
        l3: 'Iniciar sesión',
        l4: 'Introduzca un nombre de usuario',
        l5: 'Por favor introduzca una contraseña',
        l6: 'Ha olvidado la contraseña',
        l7: 'Iniciar sesión',
    },
    pccontract: {
        ss: 'Precio de mercado',
        xj: 'Precio fijo',
        gg: 'La palanca',
    },
    pcrapidly: {
        zf: 'aumento',
        dd: 'orden',
        bdsj: 'Hora Local',
        ky: 'disponible',
    },
    pchome: {
        global1: 'La plataforma de trading de activos digitales líder mundial',
        global2: 'Proporciona servicios de gestión y comercio de activos digitales seguros y fiables para millones de usuarios en más de 100 países.',
        global3: 'Seguro y fiable',
        global4: 'Cinco años de experiencia en servicios financieros de activos digitales. Se centra en la arquitectura distribuida y el sistema de ataque anti DDoS.',
        global5: 'Diseño ecológico Global',
        global6: 'Se han establecido centros de servicios comerciales localizados en muchos países para crear un ecosistema de cadena de bloques que integra múltiples formatos.',
        global7: 'Usuario primero',
        global8: 'Establecer un mecanismo de compensación anticipada y un fondo de protección al inversionista.',
        whever1: 'Transacción terminal multiplataforma en cualquier momento y lugar',
        whever2: 'Cubre múltiples plataformas incluyendo iOS Android y Windows y soporta funciones empresariales completas.',
        register1: 'registro',
        register3: 'La cuenta Global comienza a operar',
        register2: 'Registrarse ahora',
        commodity: 'Productos básicos',
        news: 'noticias',
        ttop1: 'Descubre el misterio de Pinnacle',
        ttop2: 'Pinnacle es una bolsa internacional que se ocupa de contratos de futuros y opciones de divisas de agricultura índices de metales y más. Desde su creación la bolsa ha mejorado continuamente sus reglas de comercio y el volumen de varios productos ha ido en aumento. La compañía se ha expandido con éxito para servir a clientes minoristas e institucionales en más de 30 países y sigue creciendo. Nos esforzamos por mantener los costos de negociación lo más bajo posible y proporcionar servicios en un espíritu de colaboración que ayuda a nuestros clientes a convertirse en comerciantes rentables no sólo clientes rentables. Después de todo si usted hace bien nosotros hacemos bien.',
        ttop3: 'Comience a operar ahora',
        product1: 'Ejecución de pedidos rápida y fiable',
        product2: 'Herramientas analíticas avanzadas',
        product3: 'Cotizaciones en tiempo real',
        product4: 'Retiros rápidos y seguros',
        trade1: 'Más inteligente',
        trade2: 'Practica tu estrategia',
        trade3: 'Pruebe nuestra cuenta demo gratuita antes de abrir una cuenta de trading real para explorar nuestra plataforma de trading intuitiva y mejorar sus habilidades.',
        trade4: 'Ampliar sus conocimientos',
        trade5: 'Aprenda sobre el comercio de CFD con Pinnacle utilizando nuestros materiales educativos completos.',
        trade6: 'Administrsu riesgo',
        trade7: 'Utilice nuestras avanzadas herramientas de gestión de riesgos para limitar sus pérdidas y asegurar sus ganancias.',
        allselect1: '¿Por qué eligen Pinnacle?',
        allselect2: 'Protegido y seguro',
        allselect3: 'Sus datos están seguros y sus fondos se mantienen en cuentas bancarias segregde acuerdo con los requisitos reglament.',
        allselect4: 'Apoyo profesional',
        allselect5: 'Obtenga un servicio de atención al cliente dedicado las 24 horas del día en varios idiomas.',
        allselect6: 'regulado',
        allselect7: 'Licenciado y regulado por una variedad de reguladores líderes mundiales.',
        allselect8: 'fiable',
        allselect9: 'Pinnacle Ltd es una compañía FTSE 250 que cotiza en el mercado principal de compañías públicas de la bolsa de valores de Canadá.',
        down1: 'Cualquier modelo de teléfono móvil en el terminal móvil puede apoyar la operación y el uso',
        down2: 'Aplicación de la plataforma',
    },
    pcbottom: {
        box1: 'Líder mundial',
        box2: 'Uno de los mejores equipos',
        box3: 'mas',
        box4: 'Sobre nosotros',
        box5: 'Libro blanco',
        box6: 'servicio',
        box7: 'descargar',
        box8: 'Problemas comunes',
        box9: 'Servicio en línea',
        box10: 'reglamentos',
        box11: 'Acuerdo de usuario',
        box12: 'Política de privacidad',
        box13: 'Declaración Legal',
        box14: 'Contacto contacto',
    },
    pcAbout_us: {
        t1: 'curso',
        t2: '7 años en el camino',
        t3: 'Desde su nacimiento en 2015 nos hemos dedicado a blockchain Services y tecnología dedicada y servicios de construcción de canales.',
        t4: 'De 2014 a 2017 experimentó un período ascendente de estable y aumentando2017-2018 primera mitad de la compra de dinero para hacerse rico período loco desde la segunda mitad de 2019 hasta el período de invierno frío hoy he desarrollado una habilidad real. Innumerables socios de éxitospara que de bajo perfil tenemos una reputación de alto perfil en la industria. En 2022 vamos a tener una nueva plataforma V3.0 y establecer una nueva sociedad anónima BitGoldEX para realizar negocios en más de 100 países de todo el mundo.',
        t5: '¿A quién buscamos',
        t6: 'Desde sus inicios hasta hoy',
        t7: 'He estado buscando los proveedores de servicios que tienen dificultades y tienen el mismo gusto conmigo. He resuelto con éxito los problemas de decenas de proveedores de servicios durante varios años',
        t8: 'En el mundo racional de hoy en día si estás buscando un buen compañero alguien que pueda seguir adelante contigo -- tal vez hable con nosotros.',
        t9: 'Tenga la seguridad de que la persona que estás buscando ha estado buscando para usted.',
        t10: 'servicio',
        t11: 'Contacto contacto',
        t12: 'Cuando un amigo te introduce en un negocio eres una buena persona. Cuando te presento a los negocios creo que tienes una buena reputación. Cuando un cliente le introduce a un negocio, demuestra que usted es confiable.',
        t13: 'espíritu',
        t14: 'Estaremos allí donde se nos necesite',
        t15: 'Diferentes clientes tienen diferentes necesidades. Diferentes productos tienen diferentes características. El objetivo es diferente en diferentes momentos. El significado de nuestra existencia es adaptarnos al sistema de conceptos y requisitos de los diferentes proveedores de servicios para resolver una variedad de problemas.',
        t16: 'Nos gustaría ser un ladrillo con ideas y funciones, vamos a estar allí donde se nos necesita.',
    },
    pcproblem: {
        p1: 'Cuestiones prejudiciales',
        p2: '¿Por qué cambia la cantidad de activos convertidos?',
        p3: 'El cálculo equivalente en el activo es el valor de la moneda digital actual convertida en USD que cambia debido a la fluctude precios del activo digital. El número de sus activos digitales no ha cambiado.',
        p4: 'Olvidó el método de procesamiento de contraseñas de fondos para las transacciones de la plataforma',
        p5: 'Si olvida la contraseña del fondo de operaciones en la plataforma, vaya a "mis ajustes - haga clic en establecer contraseña del fondo" o póngase en contacto con el servicio de atención al cliente para restablecer',
        p6: '¿Dónde está el flujo de capital después de la compra de la transacción?',
        p7: 'Una vez completada la orden de compra, los fondos ingresarán en la cuenta en moneda francesa del activo. Si es necesario llevar a cabo un contrato de negociación o negociación de futuros para activos comprados por favor vaya al módulo de transferencia de fondos para transferencia.',
        p8: '¿Por qué limitar el número de cambios a los apodos',
        p9: 'Por las razones de seguridad de su transacción actualmente limitamos el nombre de usuario a ser modificado una sola vez.',
        p10: '¿A qué te refieres cuando te transfieres? ¿Por qué necesita realizar una transferencia',
        p11: 'Con el fin de garantizar la independencia de los fondos entre sus cuentas y facilitar su control de riesgo razonable, dividimos las cuentas de cada módulo de transacción importante. La transferencia se refiere al proceso de conversión de activos entre cuentas de operaciones.',
        p12: '¿Cómo calcular el valor contable del contrato de usuario?',
        p13: 'Cuenta de contrato patrimonio neto = margen de posición + pérdidas y ganancias flotantes de posición + cantidad disponible en cuenta corriente',
        p14: '¿Cuál es el valor del ajuste de stop loss and profit en la apertura de una posición? ¿Cómo debe establecerse?',
        p15: 'Tome la ganancia y detenga la pérdida como el límite superior de ganancia y pérdida establecido por usted. Cuando se alcanza la cantidad establecida de stop profit y stop loss, el sistema cerrará automáticamente la posición. Puede ser utilizado para el control de riesgos cuando usted compra un contrato. La mitad de la cantidad de la toma de ganancia fijada es: cantidad de aumento x cantidad x apalancamiento múltiple fijado parada pérdida. Le recomendamos que lo ajuste de acuerdo a su situación real de activos y controle razonablemente el riesgo.',
        p16: '¿Cómo reducir el riesgo de contrato?',
        p17: 'Puede transferir los activos disponibles de las cuentas restantes a la cuenta del contrato mediante la transferencia de fondos o reducir el riesgo mediante la reducción de las posiciones abiertas.',
        p18: '¿Qué significa la cantidad en la apertura del contrato?',
        p19: 'La cantidad en la posición abierta representa el número de monedas que espera comprar. Por ejemplo: seleccione en la página de apertura de la pareja comercial BTC/USD buy Long introduzca el precio como 1000USD e introduzca la cantidad como 10BTC que significa: usted espera comprar 10BTC con un precio unitario de 1000USD.',
        p20: '¿Cómo se calcula la tasa de tramitación en el contrato?',
        p21: 'Comisión de gestión = precio de apertura * cantidad de apertura * tasa de Comisión de gestión',
        p22: 'Notas sobre la liquidación forzada',
        p23: 'El grado de riesgo es un indicador para medir el riesgo de sus activos. Cuando el grado de riesgo es igual al 100% su posición se considera como una liquidación el grado de riesgo = (margen de retención/equidad de la cuenta de contrato)*100% con el fin de evitar que los usuarios de la posición de desgaste el sistema establece la relación de ajuste del grado de riesgo. Cuando el grado de riesgo alcanza el valor de riesgo fijado por el sistema el sistema forzará la posición para cerrar. Por ejemplo: el ratio de ajuste establecido por el sistema es 10% entonces cuando su grado de riesgo es mayor o igual al 90% todas sus posiciones serán forzadas a ser cerradas por el sistema.',
        p24: '¿Cuáles son las reglas del contrato de comercio?',
        p25: 'Tipo de transacción',
        p26: 'Los tipos de operaciones se dividen en dos direcciones: posiciones largas (comprar) y posiciones cortas (vender):',
        p27: 'Comprar largo (alcista) significa que usted piensa que el índice actual es probable que aumente y quiere comprar un cierto número de ciertos contratos al precio que usted establece o el precio de mercado actual.',
        p28: 'Vender a corto (bajista) significa que usted piensa que es probable que el índice actual caiga y quiere vender un cierto número de nuevos contratos a un precio que usted establezca o al precio de mercado actual.',
        p29: 'Método de pedido',
        p30: 'Orden de precio limitado: es necesario especificar el precio y la cantidad de la orden realizada',
        p31: 'Orden de mercado: sólo necesita establecer la cantidad de orden el precio es el precio de mercado actual',
        p32: 'posiciones',
        p33: 'Cuando la orden que usted somete para abrir una posición se completa se llama una posición',
        p34: 'Cuestiones de entrega del contrato',
        p35: 'El contrato de plataforma es un contrato de duración indefinida. Siempre y cuando el sistema no cumple con las condiciones para la liquidación o no cerrar manualmente la posición puede mantener la posición de forma permanente.',
        p36: '1: el sistema cerrará automáticamente la posición si se alcanza el valor establecido de Take Profit y Stop Loss',
        p37: '2: el riesgo es demasiado alto el sistema se ve obligado a cerrar la posición',
        p38: '¿Cuál es el margen en las transacciones contractuales?',
        p39: 'En las transacciones contractuales los usuarios pueden participar en la venta y compra de contratos basados en el precio y la cantidad del contrato y los múltiplos de apalancamiento correspondientes. El usuario tomará el margen al abrir una posición. Cuanto mayor sea el margen de apertura, mayor será el riesgo de cuenta.',
        p40: '¿Qué son limit Order y market Order?',
        p41: 'El orden límite se refiere al precio que se espera confiar la plataforma al comercio y el orden de mercado se refiere a confiar directamente la plataforma al precio actual. En las reglas para abrir posiciones se da prioridad a las órdenes de mercado sobre las órdenes límite. Si elige una orden de límite por favor abra la posición razonablemente de acuerdo al precio de mercado actual de la moneda para evitar pérdidas debido a precios de apertura irrazonables.',
        p42: '¿Cuál es el nivel de riesgo de las transacciones contractuales?',
        p43: 'El grado de riesgo es un indicador de riesgo en su cuenta de contrato. Un grado de riesgo igual al 100% se considera una liquidación. Le sugerimos que cuando su riesgo exceda el 50% debe abrir su posición cuidadosamente para evitar pérdidas debido a la liquidación. Puede reducir su riesgo reponiendo los fondos disponibles de los activos del contrato o reduciendo sus posiciones.',
        p44: '¿Por qué es necesario el cambio de divisas?',
        p45: 'El propósito del intercambio es permitir la buena circulación de fondos en diferentes monedas en sus activos y el QCC obtenido en la cuenta de futuros puede ser convertido libremente en USD para el comercio. USDT en otras cuentas también se puede convertir libremente a QCC para el comercio.',
        p46: '¿Por qué la otra parte no recibió la transferencia a tiempo después de que se transfirió la transacción?',
        p47: 'En primer lugar, asegúrese de que el método de pago que rellena al transferir dinero es exactamente el mismo que aparece en la página de detalles del pedido. Entonces por favor confirme si su método de pago es inmediato o retrasado. Por último, póngase en contacto con su banco y entidad de pago para el mantenimiento del sistema u otras razones.',
        p48: '¿Cómo comprar USD a través de la plataforma?',
        p49: 'Método 1: seleccione el pedido que desea comprar a través de la lista de compra y venta de la plataforma para comprar y vender.',
        p50: 'Método 2: haga clic en el botón publicar para publicar una orden de compra o venta para transacciones rápidas de acuerdo a sus necesidades. El sistema se emparerápidamente con la compra y venta de usuarios.',
        p51: 'Nota: si la orden de compra no se paga después de 15 minutos el sistema cancelará automáticamente la orden por favor pagar a tiempo. Si la orden se cancela más de 3 veces en el mismo día, la transacción no se puede realizar de nuevo el mismo día y la autoridad comercial se restaurará al día siguiente.',
    },
    public: {
        zxkf: 'Servicio al cliente en línea',
        sure: 'Confirmar confirmación',
        cancel: 'cancelar',
        RefreshSucceeded: 'Ha tenido éxito',
        noMoreData: 'No hay más datos disponibles para leer',
        pullingText: 'Tire de...',
        ReleaseText: 'Lanzamiento para actualizar...',
        loadingText: 'Carga...',
        noData: 'No se ha encontrado ningún registro',
        xq: 'detalle',
        submit: 'enviar',
        kefu: 'Servicio al cliente en línea',
        amount: 'importe',
        startInvestment: 'Inversión inicial',
        endInvestment: 'Inversiones directas',
        profit: 'beneficio',
    },
    ChangeType: {
        Upper: 'Depósito de llegada',
        Lower: 'Retirada de la retirada',
        Win: 'beneficio',
        Bet: 'La apuesta',
        BuyMining: 'Minería minera',
        CoinWin: 'Nuevo beneficio simbólico',
        SettleMining: 'Rentabilidad del capital en minería',
        GiveInterest: 'Interés minero',
        BuyCoin: 'Compra de un nuevo token',
        BuyCoinBack: 'Retorno de la compra de nuevo token',
        UsdtToBtc: 'UsdoBtc',
        UsdtToEth: 'UsdoEth',
        BtcToUsdt: 'BtcToUsd',
        BtcToEth: 'BtcToEth',
        EthToUsdt: 'Productos de la partida',
        EthToBtc: 'ettobtc',
        CoinThaw: 'Nuevo deshielo de la moneda',
        CoinFrozen: 'Nueva moneda congelada',
        CoinCancel: 'Orden de divisa cancelar',
        CoinBuy: 'Comprar divisas',
        CoinBuyEntrust: 'Compra de moneda de confianza',
        CoinSellEntrust: 'Vender confianza de divisas',
        CoinSell: 'Venta de divisas',
        ContractBuy: 'Contratos — deducción del margen',
        ContractCharge: 'Pedidos por contrato menos gastos de gestión',
        ContractCancelToBond: 'Reembolso del depósito después de la cancelación del contrato',
        ContractCancelToCharge: 'Reembolso de los gastos de servicio por cancelación del contrato',
        ContractCloseProfit: 'Ingresos de cierre del contrato',
    },
    trade: {
        title: 'intercambio',
        Buy: 'comprar',
        Sell: 'vender',
        pc: 'cerca',
        cb: 'costo',
        xj1: 'Precio actual',
        yk: 'Pérdidas y ganancias',
        xianjia: 'Precio fijo',
        jiage: 'precio',
        shuliang: 'En num',
        keyong: 'disponible',
        yonghuchicang: 'posiciones',
        fxlts: 'Tasa de riesgo = margen de posición/patrimonio del cliente multiplicado por el 100% donde el patrimonio del cliente es igual a los fondos disponibles de la cuenta + margen de transacción inicial + beneficio y pérdida flotante',
        fxl: 'Tasa de riesgo',
        dqwt: 'Pedidos abiertos',
        name: 'nombre',
        leixing: 'tipo',
        all: 'todos',
        shijian: 'Tiempo tiempo',
        long: 'Largo largo',
        short: 'corto',
        cc: 'Mantener posiciones de apoyo',
        zyjg: 'Mejor precio actual',
        caozuo: 'funcionamiento',
        xj: 'Precio fijo',
        sj: 'Precio de mercado',
        jiaoyie: 'importe',
        orderNo: 'no',
        weituojia: 'precio',
        chengjiaojia: 'precio',
        chengjiaoe: 'Precio de oferta',
        weituo: 'modo',
        qbwt: 'Todos los delegados',
        lswt: 'Historia de la orden',
        tips: 'Entrar precio',
        tips1: 'Texto original',
        Create: 'entrega',
        Wait: 'transacción',
        Complete: 'cerrado',
        Cancel: 'revocrevocrevoc',
        zsj: 'Detener la pérdida de precio',
        zyj: 'Precio de la ganancia de la parada',
        cczsy: 'Ingresos totales procedentes de posiciones',
        ykbl: 'Relación de pérdidas y ganancias',
        bili: 'razón',
        jyss: 'Número de operaciones',
        bzj: 'Margen margen margen',
        sxf: 'Gastos de gestión',
        gg: 'Múltiples ventajas',
        yjpc: 'todo',
    },
    auth: {
        title: 'Autenticautenticautenticde identidad',
        title1: 'Autenticautenticautenticde identidad',
        low: 'autenticautenticautentic',
        high: 'Autenticación avanzada',
        success: 'Verificado con éxito',
        tips: 'Por favor autenticarse primero',
        tips1: 'Por favor, introduzca su nombre real',
        tips2: 'Por favor, introduzca su número de identificación',
        tips3: 'Por favor cargar su tarjeta de identificación (frente)',
        tips4: 'Por favor, cargue su tarjeta de identificación (volver)',
        tips5: 'Por favor, cargue una foto de usted sosteniendo la identificación con la página de fotos',
        tips6: 'Seleccione un tipo de autenticpor nombre real',
        qrz: 'Para verificar',
        shz: 'Bajo examen',
        rzsb: 'Fallo en la verificación',
        userName: 'Nombre Real',
        IDNumber: 'Número de identificación',
        zmz: 'Foto del documento de identificación (frente)',
        bmz: 'Foto en la tarjeta de identificación (atrás)',
        scz: 'Foto de usted mismo sosteniendo la identificación con la página de fotos',
        IDCard: 'IDCard',
        Passport: 'Pasaporte pasaporte pasaporte',
        AuthenticationType: 'Tipo de autentic',
        HighAuth: 'Certificación avanzada',
        gj: 'Nacionalidad nacionalidad',
        tipsgj: 'Por favor, introduzca su nacionalidad',
    },
    share: {
        title: 'Mi código de referencia',
        cp: 'Dirección de copia',
        success: 'Copia con éxito',
    },
    newRecharge: {
        Deposit: 'Depósito en depósito',
        Credit: 'Tarjeta de crédito',
        Wallet: 'Depósito en la billetera bono de ganancia cada vez',
        FAQ: 'Preguntas frecuentes',
        Currency: 'Elegir la moneda',
        placeholder: ' ',
        Main: 'principal',
        DepositAds: 'Dirección de depósito',
        Copy: 'copia',
        remark: '• no depositar ningún otro activo que no sea',
        remark1: 'USD USD USD USD USD USD USD USD USD USD USD USD USD USD',
        remark2: 'A la dirección anterior, de lo contrario los activos no se recuperarán;',
        remark3: '• después de que usted deposite a la dirección antarriba usted necesita esperar la confirmación del nodo entero de la red y solamente después de que 6 confirma usted puede recibir los activos en su cuenta;',
        remark4: '• un solo depósito no debe ser inferior a 15 USD esta cantidad causará el fracaso del depósito;',
        remark5: '• antes de cada depósito debe ir a esta página y copiar la dirección para asegurarse de que la dirección es correcta. La dirección equivocada dará lugar a la falta de depósito.',
        Pay: 'pagar',
        Buy: 'comprar',
        Merchant: 'comerciante',
        remark6: '* haga clic para comprobar',
        remark7: 'consejos',
        remark8: 'Si hay alguna pregunta por favor contác.',
        remark9: 'Servicio al cliente',
        ChipPay: 'Transacción de compra de moneda ChipPay',
        Purchasebyamount: 'Compra por importe',
        AccountBalance: 'Saldo de cuenta',
        Tips: 'consejos',
    },
    ChipPay: {
        copySUC: 'replicreplicéxito',
        copyERR: 'Fallo de la copia',
        Buy: 'comprar',
        TOBECONFIRMED: 'esperando',
        TransactionCompletion: 'La terminación',
        OrderingInformation: 'Solicitar información',
        oddNumbers: 'Números impares:',
        TransferAmount: 'Importe de transferencia:',
        ApproximatelyQuantity: 'Aproximadamente la cantidad:',
        UnitPrice: 'Precio unitario:',
        remark1: 'No hacer clic en el botón he completado el envío o discrepancias en el importe dela transferencia dará lugar a un retraso en la recepción. Las transferencias de cuentas no personales y los comentarios/anuncios resultarán en el fracaso de la transacción y un reembolso',
        remark2: 'consejos',
        remark3: 'La información de pago para cada pedido sólo es válida para una sola transferencia. Por favor no guarde la información para un segundo traslado.',
        remark4: 'Después de crear varios pedidos sin pago no podrá realizar otro pedido. Por favor, proceda con precaución.',
        remark5: 'Por favor complete el envío dentro del tiempo especificado y asegúrese de hacer clic en el botón "he completado el envío" para asegurar la recepción inmediata.',
        remark6: 'Si el aviso es la asignación de la parte comercial falló y el volumen actual de la transacción es demasiado grande haciendo que la parte comercial esté ocupada por favor espere 5-10 minutos antes de volver a enviar la orden.',
        remark7: 'Su envío será transferido directamente a la cuenta bancaria de la contraparte y los activos digitales vendidos por la contraparte durante la transacción serán administrados y protegidos por la plataforma. Por favor, tenga la seguridad de la transacción.',
        cancellation: 'cancelación',
        remittance: 'transferido',
        remark8: 'Instrucciones de transacción',
        remark9: 'Usted se involucrará en transacciones de moneda digital con los titulares de dólares estadounidenses en la plataforma ChipPay y la plataforma garantizará la autenticidad de sus activos en dólares estadounidenses durante toda la transacción. Por favor lea y acepte los siguientes términos antes de iniciar la transacción:',
        remark10: 'Por favor, tenga en cuenta que el USD no es emitido por la plataforma ChipPay y no tiene compensación legal',
        remark11: 'Al realizar transacciones con titulares de USD en la plataforma se requiere que usted cumpla con el contenido de la declaración contra el lavado de dinero durante el proceso de transacción',
        remark12: 'Por favor, lea la declaración de riesgos primero sea consciente de los riesgos del comercio digital de divisas y acepte las cuestiones anteriores',
        remark13: 'Soy consciente de los riesgos de transacción y estoy de acuerdo con las condiciones del servicio',
        remark14: 'No hacer clic en el botón he completado el envío o discrepancias en el importe dela transferencia dará lugar a un retraso en la recepción. Las transferencias de cuentas no personales y los comentarios/anuncios resultarán en el fracaso de la transacción y un reembolso',
        remark15: '1. Por favor, asegúrese de que el importe de su envío sea coherente con el importe del pedido. Si usted no remite haciendo clic en este botón puede resultar en la lista negra por el sistema',
        ConfirmedRemittance: 'Remisión confirmada',
        ConfirmRemittance: 'Confirmar envío',
        WrongPoint: 'Punto erróneo',
        Ialreadyknow: 'Ya sé que',
        payattentionto: 'Preste atención a',
        agree: 'De acuerdo',
        Bank: 'Banco receptor:',
        ReceivingAccount: 'Cuenta de recepción:',
        Party: 'Parte comercial:',
        Authenticated: 'autenticautenticautentic',
        Margin: 'Margen margen margen',
        tips1: 'Por favor, compruebe si estoy familiarizado con los riesgos de transacción',
    },
    newProperty: {
        title: 'Total de activos (USD)',
        Spot: 'Spot Spot',
        Contract: 'Contrato disponible',
        Bonus: 'Bono de bono',
        Purchase: 'recarga',
        Deposit: 'Depósito en depósito',
        Withdraw: 'Retirada de la retirada',
        Transfer: 'traslado',
        Spot1: 'punto',
        Contract1: 'contrato',
        Commission1: 'Comisión',
        Financial1: 'financiero',
        Balance: 'equilibrio',
        Hide: 'Ocultar activos de 0',
    },
    withdrawal: {
        Wallet: 'Retiro de la cartera',
        Internal: 'Transferencia interna',
        Currency: 'moneda',
        Main: 'principal',
        WithdrawAddress: 'Retirar la dirección',
        Frequently: 'Direcciones frecuentes',
        placeholder: 'Dirección de pago de USD',
        note: 'Nota de transacción',
        placeholder1: 'nota',
        Amount: 'Importe de retirada',
        placeholder2: '10 mínimo',
        All: 'todos',
        Balance: 'Saldo de cuenta',
        Fee: 'Retirar la tarifa',
        FundPassword: 'Contraseña del fondo',
        placeholder3: 'Por favor introduzca la contraseña del fondo',
        forget: '¿Contraseña del fondo olvidado?',
        OTP: 'Teléfono/correo electrónico OTP',
        placeholder4: 'Introduzca el código de verificación',
        send: 'enviar',
        Resend: 'Volver a enviar',
        Reminder: 'recordatorio',
        Reminder1: 'El periodo de desistimiento es de lunes a domingo 9:00-22:00 (UTC+8)',
        Reminder2: 'Saldo máximo disponible = saldo de la cuenta el importe del retiro incluye el cargo por retiro.',
        Reminder3: 'Antes de solicitar un retiro, por favor confirme si la dirección de retiro es correcta. Una vez que la transacción se envía a la red blockchain será irrevocable',
        Confirm: 'Confirmar confirmación',
        noPassword: 'No hay contraseña de fondo',
        Fees: 'tasas',
        Free: 'libre',
        Transfer: 'Importe de la transferencia',
        Limitation: 'Limitación de transferencia',
        placeholder6: 'La cantidad llevan la cantidad 0-100000',
        Available: 'Cantidad disponible',
        Receiving: 'Recibir la cuenta',
        placeholder5: 'Introduzca el nombre de usuario',
        TransferBtn: 'traslado',
        Reminder4: 'Recordatorio: por favor asegúrese de confirmar la información del usuario al transferir',
        Reminder5: 'Tiempo de procesamiento de transacciones 09:00-22:00 (UTC+8)',
        title: 'declaración',
        title1: 'punto',
        title2: 'contrato',
        title3: 'fondo',
        title4: 'Comprar monedas',
        title5: 'Venta de monedas',
        Rate: 'Tipo de cambio',
        allRate: 'El acuerdo de liquidación',
        PlaceOrder: 'Orden de pedido',
        Freeze: 'La congelación',
        Thaw: 'descongelar',
        backgroundProgram: 'programa',
        AuditReject: 'Venta de monedas',
        OrderSettlement: 'Resolución de un auto',
        AccountBalance: 'Equilibrio:',
        CancelOrder: 'Cancelar la orden',
        Created: 'creado',
        Paid: 'pagado',
        UnAuthed: 'No revisado',
        Accepted: 'aceptado',
        Canceled: 'Cancelado.',
        Refuce: 'refurefurefurefurefurefu',
        placeholderMC: 'Vendido con éxito',
        read: 'Ha leído',
        unread: 'Sin leer',
        chat: 'chat',
    },
    property: {
        Contract: 'punto',
        Exchange: 'Segundo contrato',
        zzc: 'Total de activos',
        mhyzh: 'Segunda cuenta de contrato',
        bbzh: 'Cuenta al contado',
        available: 'disponible',
        OnOrders: 'Congelar la congelación',
        Estimated: 'Equivalente equivalente equivalente equivalente equivalente equivalente equivalente equivalente equivalente equivalente equivalente equivalente equivalente equivalente equivalente equivalente',
        cw: 'Registro financiero',
        number: 'número',
        record: 'registro',
        Transfer: 'traslado',
        time: 'Tiempo tiempo',
        dhsl: 'Cantidad de token a intercambiar',
        tips: 'Por favor, introduzca la cantidad de token a swap',
        qbdh: 'Todos los',
        dhhl: 'Tipo de cambio',
        ky: 'disponible',
        kd: 'disponible',
        sxfbl: 'Gastos de gestión (tasa)',
        sxf: 'Gastos de gestión',
    },
    statusLang: {
        Review: 'revisión',
        Success: 'éxito',
        Fail: 'fracaso',
    },
    with: {
        title: 'Retirar la retirada',
        history: 'Historia historia historia',
        ldz: 'Dirección de la cadena de bloques',
        tbdz: 'Dirección de desistimiento',
        tips: 'Por favor, introduzca la dirección de retirada',
        tips1: 'Introduzca el importe de los retir.',
        tips2: 'Por favor introduzca la contraseña del fondo',
        tbsl: 'Importe de los retiros',
        ky: 'disponible',
        zjmm: 'Contraseña del fondo',
        sxf: 'Gastos de gestión',
        dz: 'Cantidad que se espera recibir',
    },
    tabBar: {
        home: 'Inicio',
        Quotation: 'Mercados mercados',
        Quotation2: 'Cotización de mercado',
        Trade: 'comercio',
        Contract: 'contrato',
        newContract: 'comercio',
        assets: 'activos',
        qhyy: 'Cambiar de idioma',
        zyjb: 'Bloqueo en la minería',
        jsjy: 'Comercio rápido',
        jryw: 'Negocios financieros',
        hyjy: 'La transacción Contractual',
        Frequently: 'frecuentemente',
        mine: 'Me',
    },
    exchange: {
        title: 'intercambio',
        canChange: 'Cantidad disponible',
        canChange1: 'Por favor, introduzca la cantidad de cambio',
        title1: 'Detalles de intercambio',
        Available: 'Cuota disponible',
        Proposed: 'Precio indicativo',
        Redeem: 'Reditodos todos',
        Confirm: 'Confirmar confirmación',
        CurrencyBalance: 'Balanza de divisas',
    },
    home: {
        gridcz: 'Recarga de fiat',
        gridsd: 'Retirada de fiat',
        gridxs: 'Preguntas frecuentes',
        gridbd: 'lista',
        Balance: 'equilibrio',
        UserName: 'Nombre de usuario',
        score: 'La puntuación',
        kjmb: 'Compra rápida',
        zc: 'Soporte BTC USD etc.',
        scwk: 'Minería de cierre',
        sgxb: 'Nuevo Token Subcription',
        aqkx: 'Seguro y fiable',
        aqkx1: 'La plataforma de trading de activos digitales líder en el mundo',
        rmcjb: 'En caliente',
        xbb: 'Nuevas monedas',
        zfb: 'crecimiento',
        dfb: 'caída',
        identify: 'No verificado',
        identifyYes: 'autenticautenticautentic',
        changeName: 'Por favor introduzca su nombre de usuario',
        changeName1: 'Nombre de usuario sólo puede editar una vez',
        confirmName: 'Confirmar confirmación',
        closeName: 'cancelar',
        mc: 'nombre',
        zxj: 'El último precio',
        zdb: 'Variación neta variación neta',
        hy: 'El efecto de palanca',
        btyh: 'Usuario Normal',
        sfyz: 'Autenticautenticautenticde identidad',
        skfs: 'Método de pago',
        share: 'Mi enlace a mi compartir',
        kjcb: 'recarga',
        kjtb: 'Retirada de la retirada',
        qbdz: 'Dirección de la cartera',
        bps: 'Libro blanco',
        xgmm: 'Cambiar la contraseña',
        xgzfmm: 'Cambiar la contraseña del fondo',
        gywm: 'sobre',
        tcdl: 'Cerrar la sesión',
        zjmx: 'Declaración del fondo',
        cdsz: 'configuración',
        cdaq: 'autenticautenticautenticautenticautenticautenticautenticautenticautenticautenticautenticautenticautentic',
        bzzx: 'Preguntas frecuentes',
        ysxy: 'Acuerdo de privacidad',
        xzyy: 'Seleccionar el idioma',
    },
    commonUse: {
        title: 'servicio',
        Finance: 'finanzas',
        Notice: 'notificación',
        customerService: 'servicio',
        cc: 'posición',
        zjmx: 'declaración',
        cpdm: 'nombre',
        mm: 'Comprar/vender',
        wtj: 'precio',
        wtsl: 'número',
        Buy: 'comprar',
        Sell: 'vender',
        Buy1: 'comprar',
        Sell1: 'vender',
        Buy2: 'Compra de monedas con un clic',
        Exchangequantity: 'La cantidad',
        Exchangeamount: 'importe',
        Currency: 'moneda',
        Status: 'Estado de la situación',
        Type: 'tipo',
        Service: 'servicio',
        p1: 'Por favor seleccione una moneda',
        p2: 'Por favor, introduzca la cantidad',
        czlb: 'operan',
    },
    product: {
        Buy: 'comprar',
        Sell: 'vender',
        Gain: 'ganancia',
        Trade: 'comercio',
        Real: 'Real',
        High: 'alto',
        ClosingPrice: 'Precio de cierre',
        Low: 'baja',
        Volume: 'Volumen 24h',
        Limit: 'Orden de límite',
        Contract: 'La celebración de órdenes',
        News: 'Órdenes de cierre',
        Liquidations: 'liquiliqui',
        ContractType: 'Contrato/tipo',
        OpeningPrice: 'Precio/precio de apertura',
        Leverage: 'El efecto de palanca',
        LeverageP: 'La palanca no puede estar vacía',
        LimitPrice: 'Importe de la transacción',
        LimitPlaceholder: 'Por favor, introduzca la cantidad límite de precio',
        Overnight: 'Durante la noche',
        Direction: 'dirección',
        Operate: 'operan',
        Long: 'Comprar/ir de largo',
        Short: 'Vender/corto',
        Balance: 'equilibrio',
        Floating: 'P/L variable',
        CloseAll: 'todo',
        MarketOrder: 'Orden del mercado',
        LimitOrder: 'Orden de límite',
        Cross: 'cruz',
        Isolated: 'aislado',
        placeholder: 'Mejor precio de ejecución',
        Margin: 'número',
        MarginBalance: 'Margen de variación',
        TradingFee: 'Tasa de comercio',
        Position: 'posición',
        Success: 'Éxito de la operación',
        Commission: 'Comisión',
        Transaction: 'operación',
        Completed: 'terminado',
        Cancellation: 'cancelación',
        unitPrice: 'Precio unitario',
        LeverageRatio: 'Tasa de apalancamiento',
        cancellation: 'cancelar',
        ClosingPosition: 'Posición cercana',
        productName: 'Nombre del producto',
        Margin1: 'Margen margen margen',
        SettlementPrice: 'Precio final',
        EndTime: 'Tiempo final',
        CreateTime: 'Crear tiempo',
        WalletBalance: 'Saldo de la cartera',
        AvailableFunds: 'Fondos disponibles',
        Text1: 'Consejo',
        Text2: '¿Desea realizar esta operación',
        qr: 'Confirmar confirmación',
        qx: 'cancelar',
        ddbh: 'Número de pedido',
        wtsj: 'Tiempo de espera',
        szzyzs: 'Ganancias y pérdidas por juego',
    },
    notice: {
        title: 'notificación',
        ckxq: 'detalle',
    },
    helpCenter: {
        title: 'Guía de principiantes'
    },
    pwdGroup: {
        title: 'Configuración de contraseña',
        pwdDL: 'Contraseña de acceso',
        pwdZj: 'Contraseña del fondo',
    },
    quotation: {
        title: 'Mercados mercados',
        mc: 'La cantidad',
        zxj: 'El último precio',
        zdb: 'Variación neta variación neta',
        liang: 'volumen',
    },
    contract: {
        kcslfw: 'Rango de cantidad de apertura:',
        gao: 'alto',
        di: 'baja',
        liang: 'Volumen de 24 horas',
        placeholder: 'Por favor, introduzca la cantidad',
        SuccessfulPurchase: 'Compra con éxito',
        smallMin: 'min',
        hour: 'hora',
        dayMin: 'día',
        Trend: 'Tiempo tiempo',
        ccjl: 'Lista de convocatoria',
        ddjl: 'Lista de pedidos',
        sl: 'La cantidad',
        gmj: 'Precio de compra',
        dqj: 'Precio corriente',
        yjyk: 'Estimación del resultado',
        djs: 'Cuenta atrás',
        gbjg: 'Precio de huelga',
        sc: 'Gastos de gestión',
        pal: 'Pérdidas y ganancias',
        mhy: 'Segundo contrato',
        sj: 'Tiempo tiempo',
        yll: 'Tasa de ganancia',
        mz: 'Largo largo',
        md: 'corto',
        kcsl: 'Cantidad de la posición abierta',
        kcsj: 'Tiempo de posición abierta',
        zhye: 'Saldo de cuenta',
        qc: 'cancelar',
    },
    editPassword: {
        title: 'Modificar la contraseña de acceso',
        jmm: 'Contraseña antigua',
        xmm: 'Nueva contraseña',
        qrmm: 'Confirmar la contraseña',
        placeholder: 'Contraseña antigua',
        placeholder1: 'Establecer una nueva contraseña',
        placeholder2: 'Por favor, introduzca una nueva contraseña de nuevo',
        qrxf: 'El OK',
    },
    secure: {
        title: 'seguro',
        title1: 'Autenticación de dos factores',
        title2: 'Para garantizar la seguridad de su cuenta por favor complete el KYC.',
        gridTxt: 'Configuración de contraseña',
        gridTxt1: 'Verificación móvil',
        gridTxt2: 'Verificación del correo electrónico',
        gridTxt3: 'Autenticautenticautenticde identidad',
        listTxt: 'Tarjeta bancaria',
        listTxt1: 'Dirección de desistimiento',
    },
    bindBank: {
        title: 'Tarjeta bancaria',
        addCard: 'Añadir tarjeta bancaria',
        editCard: 'Modificar la contraseña del fondo de verificación',
        ts1: 'Usted aún no ha pasado por la autenticde identidad',
        ts2: 'Realice primero la autenticde identidad',
        addTitle: 'Añadir tarjeta bancaria',
        userName: 'Nombre Real',
        tips1: 'Por favor, introduzca su nombre real',
        CollectionDetailArea: 'Área de detalle de la colección',
        tips6: 'Introduzca el área de detalle de la colección',
        userPhone: 'Número de teléfono reservado',
        tips2: 'Introduzca un número de teléfono reservado',
        bankName: 'Nombre del banco',
        tips3: 'Introduzca el nombre del banco que abre la cuenta',
        bankAds: 'Dirección bancaria',
        tips5: 'Introduzca la dirección bancaria de apertura de cuenta',
        bankId: 'Número de cuenta de tarjeta bancaria',
        tips4: 'Introduzca el número de cuenta de su tarjeta bancaria',
        CountryCode: 'Código del país',
        tips7: 'Seleccione un código de país',
        tips8: 'Introduzca el código SWIFT',
        tips9: 'Por favor, introduzca la cantidad',
        tips10: 'El monto de recarga o retiro no puede ser menor a 100',
        addTxt: 'ahorrar',
    },
    bindMail: {
        title: 'Establecer dirección de correo electrónico',
        placeholder1: 'Dirección de correo electrónico',
        ptitle: 'Cambiar el número de teléfono',
        placeholder2: 'Introduzca su número de teléfono móvil',
        next: 'siguiente',
        zjmm: 'Contraseña del fondo',
        placeholder3: 'Por favor, introduzca su contraseña del fondo',
    },
    setting: {
        title: 'configuración',
        setTxt: 'Imagen de perfil',
        setTxt1: 'idioma',
        setTxt2: 'Tipo de cambio',
        setTxt3: 'Sobre nosotros',
    },
    withPass: {
        title: 'Set Fund Passwordd (en inglés)',
        title1: 'La contraseña de fondo no puede ser la misma que la contraseña de inicio de sesión',
        aqyz: 'Verificación de seguridad',
        aqyzm: 'Teléfono/correo electrónico OTP',
        jmm: 'Contraseña antigua',
        xmm: 'Nueva contraseña',
        qrmm: 'Confirmar la contraseña',
        placeholder: 'Contraseña antigua',
        placeholder1: 'Establecer una nueva contraseña',
        placeholder2: 'Por favor, introduzca una nueva contraseña de nuevo',
        qrxf: 'El OK',
        tips: 'Por favor introduzca una contraseña antigua de al menos 6 dígitos',
        tips1: 'Introduzca una nueva contraseña de al menos 6 dígitos',
        tips2: 'Por favor, introduzca su contraseña de confirmación',
        tips3: 'Dos nuevas contraseñas de entrada son inconsistentes',
        tips4: 'La contraseña se ha establecido correctamente',
        confirm: 'Confirmar confirmación',
    },
    newBe: {
        title: 'suscripción',
        title1: 'Mi suscripción',
        scqx: 'Periodo de bloqueo',
        day: 'día',
        sy: 'restante',
        sdsg: 'Historia historia historia',
        sysl: 'Cantidad restante',
        gmsl: 'Cantidad de compra',
        dj: 'Precio unitario',
        tips: 'Introduzca la cantidad de compra',
        gmzj: 'Precio de compra Total',
        qbye: 'Saldo de la cartera',
        fmzj: 'Precio de compra Total',
        gm: 'comprar',
        sgsj: 'Tiempo de suscripción',
        Currency: 'Tipo Token',
        Request: 'Importe solicitado',
        Passing: 'La cantidad pasada',
        MarketTime: 'Tiempo de listado',
        sqz: 'En la solicitud',
    },
    register: {
        title: 'registro',
        lang: 'idioma',
        send: 'enviar',
        Resend: 'Volver a enviar',
        Account: 'Cuenta cuenta',
        Password: 'contraseña',
        ConfirmPassword: 'Confirmar la contraseña',
        InvitationCode: 'Código de invitación',
        btn: 'registro',
        mobile: 'Número de teléfono',
        mailbox: 'Buzón de correo',
        haveAccount: '¿Ya tienes una cuenta?',
        haveLogin: 'Iniciar sesión',
        Login: 'Iniciar sesión',
        placeholder: 'Por favor, introduzca el correo electrónico',
        placeholder0: 'Introduzca el número de teléfono',
        placeholder1: 'Por favor introduzca la contraseña',
        placeholder2: 'Por favor, introduzca la contraseña de nuevo',
        placeholder3: 'Introduzca el código de invitación',
        placeholder4: 'Introduzca el código de verificación',
        placeholder5: 'Por favor, introduzca el buzón de correo correcto',
        success: 'Éxito de la inscripción',
        message: 'La contraseña debe tener entre 6 y 16 caracteres',
        message1: 'Confirme que la entrada de contraseña tiene al menos 6 caracteres',
        message2: 'La entrada de contraseña es inconsistente',
        message3: 'Por favor lea y marque el acuerdo',
        agree: 'Estoy de acuerdo',
        xy: 'Acuerdo de usuario y política de privacidad',
        qr: 'registro',
        ftitle: 'Recuperar la contraseña',
        qrzh: 'El OK',
        placeholder7: 'Nueva contraseña',
        placeholder6: 'Confirmar la nueva contraseña',
        placeholder8: 'Por favor, introduzca el código de verificación gráfica',
        placeholderName: 'Introduzca un nombre de usuario',
    },
    lockming: {
        title: 'Bloqueo …',
        wkb: 'El tesoro minero',
        wkgjd: 'Minería es más fácil',
        qsljl: 'Ganar una recompensa con facilidad',
        yjzsr: 'Ingresos totales estimados',
        ck: 'Tasa de rendimiento anualizada de referencia de los últimos tres días',
        tips: 'Mínimo mínimo',
        rlx: 'Interés diario',
        day: 'día',
        scqx: 'Período de bloqueo',
        buy: 'comprar',
        zysl: 'Cantidad de apuestas',
        qbye: 'Saldo de la cartera',
        finished: 'completo',
        doing: 'En el progreso',
        sl: 'La cantidad',
        shouyilv: 'tarifa',
        ks: 'Hora de inicio',
        js: 'Tiempo final',
    },
    login: {
        title: 'Iniciar sesión',
        lang: 'idioma',
        mobile: 'Número de teléfono',
        mailbox: 'Correo electrónico',
        Account: 'Cuenta cuenta',
        Password: 'contraseña',
        btn: 'Iniciar sesión',
        placeholder1: 'Introduzca la contraseña',
        placeholder: 'Introduzca el número de cuenta',
        fail: 'Por favor complete el formulario',
        ForgotPassword: '¿Ha olvidado su contraseña?',
        NoAccount: '¿Todavía No tienes cuenta?',
        Registration: 'Registrarse ahora',
        OtherLogin: 'Otros login',
        server: 'Servicio al cliente en línea',
        jzmm: 'Recordar la contraseña',
        success: 'Éxito de la sesión',
        ageent: 'Leer y aceptar el',
        ageentTitle: 'Acuerdo de servicio',
        ageentFail: 'Por favor lea y verifique el contrato de servicio',
        Home: 'casa',
        userbox: 'Nombre de usuario',
    },
    moneyAddr: {
        title: 'Dirección de la cartera',
        Currency: 'moneda',
        Address: 'Dirección dirección',
        placeholder: 'Por favor seleccione',
        DepositAddress: 'Dirección de depósito',
        WalletAddress: 'Dirección de la cartera',
        Date: 'fecha',
        add: 'Añadir la dirección',
        addTxt: 'Añadir como máximo 3 direcciones a cada blockchain',
        remark: 'observaciones',
        Walletremark: 'Por favor agregue comentarios para esta dirección',
        network: 'principal',
        networkXy1: 'La red que eligió es',
        networkXy2: '. Si envió sus activos a una dirección incorrecta por error perderá su activo.',
        ChainAddress: 'Cadena de direcciones',
        qrcode: 'Código QR de la cartera',
        submit: 'enviar',
        tips: 'Por favor, seleccione el tipo de cartera',
        tips1: 'Por favor, seleccione la dirección de la cadena',
        tips2: 'Por favor, introduzca la dirección de la cartera',
        tips3: 'Por favor, cargue el código QR de la cartera',
        success: 'éxito',
    },
    recharge: {
        title: 'recarga',
        title1: 'Detalles de recarga',
        ls: 'Historia historia historia',
        td: 'Por favor seleccione el siguiente canal',
        bc: 'Ahorrar un QR',
        address: 'Dirección de depósito',
        copy: 'Dirección de copia',
        num: 'Número de depósitos',
        Upload: 'Subir una imagen',
        Submit: 'enviar',
        tip: 'Por favor, introduzca la cantidad',
        tip1: 'Por favor, cargar el código QR',
        copySuc: 'copicopicopiexitosamente',
    }
}