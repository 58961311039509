module.exports = {
    pcregister: {
        p1: "Conta existente?",
        dl: "Sign in",
        zc: "registo",
        p4: "conta",
        p5: "Introduza o número da conta",
        p6: "Introduza uma palavra-passe",
        p7: "Introduza a palavra-passe de confirmação",
        p8: "Introduza o código do convite",
        p9: "Concordo",
        p10: "Acordo do Utilizador e Política de Privacidade",
        p11: "mailbox",
        p12: "Introduza o seu endereço de e-mail",
        p13: "Introduza o código de verificação por e-mail",
        p14: "enviar",
        p15: "ano",
        p16: "mês",
        p17: "dia",
        p18: "hora",
        p19: "minutos",
        p20: "segundos"
    },
    pclogin: {
        l1: "Sem conta?",
        l2: "registo",
        l3: "Assinar",
        l4: "inserir um nome de utilizador",
        l5: "Introduza uma palavra-passe",
        l6: "Esqueci a senha",
        l7: "Assinar"
    },
    pccontract: {
        ss: "preço de mercado",
        xj: "preço fixo",
        gg: "alavanca"
    },
    pcrapidly: {
        zf: "Aumento",
        dd: "ordem",
        bdsj: "Hora local",
        ky: "disponível"
    },
    pchome: {
        global1: 'A plataforma de negociação de ativos digitais líder mundial',
        global2: 'Ele fornece serviços seguros e confiáveis de negociação de ativos digitais e gerenciamento de ativos para milhões de usuários em mais de 100 países.',
        global3: 'Seguro e fiável',
        global4: 'Cinco anos de experiência em serviços financeiros de ativos digitais. Concentre-se na arquitetura distribuída e no sistema de ataque anti DDoS.',
        global5: 'Disposição ecológica global',
        global6: 'Centros de serviços de negociação localizados foram criados em muitos países para criar um ecossistema blockchain integrando vários formatos.',
        global7: 'Utilizador Primeiro',
        global8: 'Estabelecer um mecanismo de compensação antecipada e um fundo de protecção dos investidores.',
        whever1: 'Transação de terminal multi plataforma a qualquer hora e em qualquer lugar',
        whever2: 'Ele cobre várias plataformas, incluindo iOS Android e Windows e suporta funções comerciais completas.',
        register1: 'Registar um',
        register3: 'Conta global começa a negociar',
        register2: 'Registe-se Agora',
        commodity: 'Mercadorias',
        news: 'Notícias',
        ttop1: 'Descubra o Mistério de Pinnacle',
        ttop2: 'Pinnacle é uma bolsa internacional que lida com contratos de futuros e opções agrícolas moedas de energia índices de metais e muito mais. Desde a sua criação, a bolsa tem melhorado continuamente as suas regras comerciais e o volume de vários produtos tem vindo a aumentar. A empresa expandiu-se com sucesso para atender clientes de varejo e instituições em mais de 30 países e continua crescendo. Nós nos esforçamos para manter os custos de negociação o mais baixos possível e fornecer serviços em um espírito colaborativo que ajuda nossos clientes a se tornarem comerciantes rentáveis e não apenas clientes rentáveis. Afinal, se você fizer bem, nós fazemos bem.',
        ttop3: 'Começar a Negociar Agora',
        product1: 'Execução rápida e fiável das ordens',
        product2: 'Ferramentas analíticas avançadas',
        product3: 'Citações em tempo real',
        product4: 'Retiradas rápidas e seguras',
        trade1: 'SMARTER',
        trade2: 'Pratique a sua estratégia',
        trade3: 'Experimente nossa conta demo gratuita antes de abrir uma conta de negociação real para explorar nossa plataforma de negociação intuitiva e melhorar suas habilidades.',
        trade4: 'Expanda os seus conhecimentos',
        trade5: 'Saiba mais sobre a negociação de CFDs com a Pinnacle usando nossos materiais educacionais abrangentes.',
        trade6: 'Gerir o seu risco',
        trade7: 'Use nossas ferramentas avançadas de gerenciamento de risco para limitar suas perdas e bloquear lucros.',
        allselect1: 'Porque é que escolhem o Pinnacle?',
        allselect2: 'Protegido & seguro',
        allselect3: 'Os seus dados estão seguros e os seus fundos são mantidos em contas bancárias segregadas de acordo com os requisitos regulamentares.',
        allselect4: 'Apoio profissional',
        allselect5: 'Obtenha atendimento ao cliente dedicado 24 horas por dia em vários idiomas.',
        allselect6: 'Regulamentado',
        allselect7: 'Licenciado e regulado por uma variedade de reguladores líderes globais.',
        allselect8: 'Confiável',
        allselect9: 'Pinnacle Ltd é uma empresa FTSE 250 listada no Mercado Principal de Empresas Públicas da Bolsa de Valores Canadense.',
        down1: 'Qualquer modelo de telefone móvel no terminal móvel pode suportar a operação e o uso',
        down2: 'aplicação de plataforma',
    },
    pcbottom: {
        box1: 'Líder global',
        box2: 'Uma das melhores equipas',
        box3: 'mais',
        box4: 'Sobre nós',
        box5: 'papel branco',
        box6: 'serviço',
        box7: 'download',
        box8: 'problema comum',
        box9: 'Serviço Online',
        box10: 'regulamentos',
        box11: 'Acordo do Utilizador',
        box12: 'Política de Privacidade',
        box13: 'Declaração jurídica',
        box14: 'contacto',
    },
    pcAbout_us: {
        t1: "Curso",
        t2: "7 anos na estrada",
        t3: "Desde seu nascimento em 2015, nos dedicamos a serviços blockchain e serviços dedicados de tecnologia e construção de canais.",
        t4: "De 2014 a 2017, experimentou um período ascendente de constante e crescente, 2017-2018 primeira metade do dinheiro de compra para ficar rico período louco, da segunda metade de 2019 para o período frio de inverno de hoje, eu desenvolvi uma habilidade real. Inúmeros parceiros bem sucedidos, de modo que low-key temos uma reputação de alto perfil na indústria. Em 2022, teremos uma nova plataforma V3.0 e montaremos uma nova empresa de ações BitGoldEX, para conduzir negócios em mais de 100 países ao redor do mundo.",
        t5: "Quem procuramos",
        t6: "Desde a sua criação até hoje",
        t7: "Tenho procurado os prestadores de serviços que têm dificuldades e têm o mesmo gosto comigo. Resolvi com sucesso os problemas de dezenas de prestadores de serviços ao longo de vários anos,",
        t8: "No mundo racional de hoje, se você está procurando um bom parceiro, alguém que possa seguir em frente com você -- talvez, fale conosco.",
        t9: "Tenha certeza de que a pessoa que você está procurando está procurando por você.",
        t10: "Serviço",
        t11: "Contacto",
        t12: "Quando um amigo te apresenta a um negócio, você é uma boa pessoa. Quando te apresento aos negócios, acho que tens uma boa reputação. Quando um cliente te apresenta a um negócio, isso prova que você é confiável.",
        t13: "Espírito",
        t14: "Estaremos lá onde formos necessários",
        t15: "Os clientes diferentes têm requisitos diferentes. Diferentes produtos têm características diferentes. O objetivo é diferente em momentos diferentes. O significado da nossa existência é adaptar-se ao conceito, sistema e requisitos de diferentes prestadores de serviços, para resolver uma variedade de problemas.",
        t16: "Gostaríamos de ser um tijolo com ideias e funções, estaremos lá onde somos necessários."
    },
    pcproblem: {
        p1: "Perguntas",
        p2: "Por que é que o montante convertido dos activos muda? ",
        p3: "O cálculo equivalente no ativo é o valor da moeda digital detentora atual convertido em USD, que muda devido à flutuação de preço do ativo digital. O número de seus ativos digitais não mudou.",
        p4: 'Esqueci o método de processamento de senha de fundos para transações de plataforma',
        p5: 'Se você esquecer a senha do fundo de negociação na plataforma, vá para "Minhas configurações-clique em Definir senha do fundo" ou entre em contato com o serviço ao cliente para redefinir',
        p6: "Onde está o fluxo de capital após a compra da transação?",
        p7: "Após a conclusão da ordem de compra, os fundos entrarão na conta da moeda francesa no ativo. Se for necessário realizar negociação contratual ou negociação de futuros para ativos comprados, acesse o módulo de transferência de fundos para transferência.",
        p8: 'Why limit the number of changes to nicknames',
        p9: 'Por razões de segurança da sua transação, atualmente limitamos o apelido a ser modificado apenas uma vez.',
        p10: 'O que você quer dizer quando você transfere? Por que você precisa transferir ',
        p11: "A fim de garantir a independência dos fundos entre suas contas e facilitar seu controle de risco razoável, dividimos as contas de cada módulo de transação principal. A transferência refere-se ao processo de conversão de activos entre contas de negociação.",
        p12: "Como calcular o capital próprio da conta do contrato de utilizador?",
        p13: "Capital próprio da conta contratual = margem da posição + lucro e perda flutuante da posição + montante disponível da conta corrente",
        p14: "Qual é o valor da definição stop loss e lucro na abertura de uma posição? Como deve ser definido?",
        p15: "Tome lucro e stop loss como o limite superior de lucro e perda definido por você. Quando a quantidade definida de stop profit e stop loss é alcançada, o sistema fechará automaticamente a posição. Ele pode ser usado para controle de risco quando você compra um contrato. Metade do valor de lucro definido é: quantidade de aumento x quantidade x alavancagem múltipla, set stop loss. Recomendamos que você defina de acordo com a situação real do seu ativo e controle razoavelmente o risco.",
        p16: "Como reduzir o risco contratual?",
        p17: "Você pode transferir os ativos disponíveis das contas restantes para a conta contratual transferindo fundos, ou reduzir o risco reduzindo as posições abertas.",
        p18: "O que significa a quantidade na abertura do contrato?",
        p19: "A quantidade na posição aberta representa o número de moedas que você espera comprar. Por exemplo: selecione na página de abertura do par de negociação BTC/USD, compre longo, insira o preço como 1000USD e insira o valor como 10BTC, isso significa: você espera comprar 10 BTC com um preço unitário de 1000USD.",
        p20: "Como é calculada a taxa de tratamento no contrato?",
        p21: "Taxa de manipulação=preço de abertura*quantidade de abertura*taxa de taxa de manipulação",
        p22: "Notas sobre liquidação forçada",
        p23: "O grau de risco é um indicador para medir o risco de seus ativos. Quando o grau de risco é igual a 100%, sua posição é considerada como uma liquidação, o grau de risco = (margem de detenção/patrimônio da conta contratual)*100%, a fim de evitar que os usuários usem Posição, o sistema define a razão de ajuste do grau de risco. Quando o grau de risco atinge o valor de risco definido pelo sistema, o sistema forçará a posição a fechar. Por exemplo: a taxa de ajuste definida pelo sistema é de 10%, então quando seu grau de risco for maior ou igual a 90%, todas as suas posições serão forçadas a ser fechadas pelo sistema.",
        p24: "Quais são as regras de negociação contratual?",
        p25: "Tipo de transacção",
        p26: "Os tipos de negociação são divididos em duas direções: posições longas (compra) e posições curtas (venda):",
        p27: "Comprar longo (alta) significa que você acha que o índice atual provavelmente aumentará, e você quer comprar um certo número de certos contratos ao preço que você definir ou ao preço de mercado atual.",
        p28: "Vender curto (baixo) significa que você acha que o índice atual provavelmente cairá, e você quer vender um certo número de novos contratos a um preço que você definir ou o preço de mercado atual.",
        p29: "Método de encomenda",
        p30: "Ordem de preço limitado: você precisa especificar o preço e a quantidade da ordem colocada",
        p31: "Ordem de mercado: você só precisa definir a quantidade da ordem, o preço é o preço de mercado atual",
        p32: "Posições",
        p33: "Quando a ordem que você enviar para abrir uma posição é concluída, ela é chamada de posição",
        p34: "Questões de entrega contratual",
        p35: "O contrato da plataforma é um contrato perpétuo sem prazo de entrega definido. Desde que o sistema não satisfaça as condições de liquidação ou você não feche manualmente a posição, você pode manter a posição permanentemente.",
        p36: "1: O sistema fechará automaticamente a posição se o valor definido de Take Profit e Stop Loss for atingido",
        p37: "2: O risco é muito alto, o sistema é forçado a fechar a posição",
        p38: "Qual é a margem nas transacções contratuais?",
        p39: "Nas transações contratuais, os usuários podem participar da venda e compra de contratos com base no preço e quantidade do contrato e nos múltiplos de alavancagem correspondentes. O usuário assumirá a margem ao abrir uma posição. Quanto maior for a margem de abertura, maior será o risco da conta.",
        p40: "O que são ordem limite e ordem de mercado?",
        p41: "Limit order refere-se ao preço que você espera confiar a plataforma para negociar, e market order refere-se a confiar a plataforma para negociar ao preço atual diretamente. Nas regras de abertura de posições, as ordens de mercado têm prioridade sobre as ordens de limite. Se você escolher uma ordem limite, abra a posição razoavelmente de acordo com o preço de mercado atual da moeda para evitar perdas devido a preços de abertura não razoáveis.",
        p42: "Qual é o nível de risco das transacções contratuais?",
        p43: "O grau de risco é um indicador de risco na sua conta contratual. Um grau de risco igual a 100% é considerado como liquidação. Sugerimos que, quando seu risco exceder 50%, você precisa abrir sua posição cuidadosamente para evitar perdas devido à liquidação. Você pode reduzir seu risco reabastecendo os fundos disponíveis dos ativos contratuais ou reduzindo suas posições.",
        p44: "Por que é necessária a troca de moeda?",
        p45: "O objetivo da troca é permitir a circulação suave de fundos em diferentes moedas em seus ativos, e o QCC obtido na conta de futuros pode ser livremente convertido em USD para negociação. USDT em outras contas também pode ser livremente convertido em QCC para negociação.",
        p46: "Por que razão a outra parte não recebeu a transferência a tempo após a transferência da transacção?",
        p47: "Em primeiro lugar, certifique-se de que o método de pagamento que você preenche quando você transfere dinheiro é exatamente o mesmo exibido na página de detalhes do pedido. Em seguida, confirme se o seu método de pagamento é imediato ou atrasado. Finalmente, entre em contato com seu banco e instituição de pagamento para manutenção do sistema ou outros motivos.",
        p48: 'Como comprar USD através da plataforma?',
        p49: 'Método 1: Selecione a ordem que você deseja comprar através da lista de compra e venda da plataforma para comprar e vender.',
        p50: 'Método 2: Clique no botão publicar para publicar uma ordem de compra ou venda para transações rápidas de acordo com suas necessidades. O sistema irá rapidamente combiná-lo com os usuários de compra e venda.',
        p51: 'Nota: Se a ordem de compra não for paga após 15 minutos, o sistema cancelará automaticamente a ordem, por favor pague a tempo. Se a ordem for cancelada mais de 3 vezes no mesmo dia, a transação não pode ser realizada novamente no mesmo dia e a autoridade de negociação será restaurada no dia seguinte.',
    },
    public: {
        zxkf: 'Atendimento ao cliente em linha',
        sure: 'Confirmar',
        cancel: 'Cancelar',
        RefreshSucceeded: 'A actualização foi bem sucedida',
        noMoreData: 'Não há mais dados disponíveis para ler',
        pullingText: 'Puxe para refrescar...',
        ReleaseText: 'Libertar para actualizar...',
        loadingText: 'Carregando ...',
        noData: 'Não foi encontrado nenhum registo',
        xq: 'Detalhe',
        submit: 'Enviar',
        kefu: 'Atendimento ao cliente em linha',
        amount: 'Montante',
        startInvestment: 'StartInvestment',
        endInvestment: 'EndInvestment',
        profit: 'Lucro',
    },
    ChangeType: {
        Upper: 'Chegada do depósito',
        Lower: 'Retirada',
        Win: 'Lucro',
        Bet: 'Aposta',
        BuyMining: 'Mineração',
        CoinWin: 'Novo lucro simbólico',
        SettleMining: 'Rendibilidade do capital mineiro',
        GiveInterest: 'Interesse mineiro',
        BuyCoin: 'Nova compra de tokens',
        BuyCoinBack: 'Retorno da nova compra de token',
        UsdtToBtc: 'UsdoBtc',
        UsdtToEth: 'UsdoEth',
        BtcToUsdt: 'BtcToUsd',
        BtcToEth: 'BtcToEth',
        EthToUsdt: 'EthToUsd',
        EthToBtc: 'EthToBtc',
        CoinThaw: 'Descongelamento da nova moeda',
        CoinFrozen: 'Nova moeda Congelada',
        CoinCancel: 'Cancelamento da ordem de moeda',
        CoinBuy: 'Moeda Comprar',
        CoinBuyEntrust: 'Moeda Confiança de compra',
        CoinSellEntrust: 'Moeda Confiança de venda',
        CoinSell: 'Moeda Venda',
        ContractBuy: 'Ordens contratuaisDedução da margem',
        ContractCharge: 'Ordens contratuais menos encargos de tratamento',
        ContractCancelToBond: 'Reembolso do depósito após cancelamento do contrato',
        ContractCancelToCharge: 'Reembolso da taxa de serviço por cancelamento de contrato',
        ContractCloseProfit: 'Rendimentos de encerramento de contratos',
    },
    trade: {
        title: 'Troca',
        Buy: 'Comprar',
        Sell: 'Vender',
        pc: 'fechar',
        cb: 'custo',
        xj1: 'Preço actual',
        yk: 'Lucros e perdas',
        xianjia: 'preço fixo',
        jiage: 'preço',
        shuliang: 'num',
        keyong: 'disponível',
        yonghuchicang: 'posições',
        fxlts: 'taxa de risco = margem de posição / capital do cliente multiplicado por 100%, quando o capital do cliente é igual aos fundos disponíveis da conta + margem inicial da transação + lucro e perda flutuantes',
        fxl: 'Taxa de Risco',
        dqwt: 'Encomendas abertas',
        name: 'nome',
        leixing: 'tipo',
        all: 'todos',
        shijian: 'tempo',
        long: 'longo',
        short: 'curto',
        cc: 'manter posições',
        zyjg: 'melhor preço actual',
        caozuo: 'operação',
        xj: 'preço fixo',
        sj: 'preço de mercado',
        jiaoyie: 'montante',
        orderNo: 'orderNo',
        weituojia: 'preço',
        chengjiaojia: 'preço',
        chengjiaoe: 'preço de venda',
        weituo: 'modo',
        qbwt: 'todos os delegados',
        lswt: 'Histórico de encomendas',
        tips: 'please enter price',
        tips1: 'please enter num',
        Create: 'atribuição',
        Wait: 'transacção',
        Complete: 'Fechado',
        Cancel: 'revogado',
        zsj: 'Preço stop loss',
        zyj: 'Preço stop profit',
        cczsy: 'Total dos proveitos das posições',
        ykbl: 'Rácio de lucros e perdas',
        bili: 'rácio',
        jyss: 'Número de operações',
        bzj: 'Margem',
        sxf: 'Taxa de tratamento',
        gg: 'Alavancagem múltipla',
        yjpc: 'fechar tudo',
    },
    auth: {
        title: 'Autenticação da Identidade',
        title1: 'Autenticação da Identidade',
        low: 'Autenticação básica',
        high: 'Autenticação avançada',
        success: 'Verificado com êxito',
        tips: 'Por favor, autentice-se primeiro.',
        tips1: 'Por favor, introduza o seu nome verdadeiro',
        tips2: 'Introduza o seu número de identificação',
        tips3: 'Envie o seu cartão de identificação (Frente)',
        tips4: 'Envie o seu cartão de identificação (Voltar)',
        tips5: 'Por favor, envie uma foto sua segurando o ID com a página da foto',
        tips6: 'Seleccione por favor um tipo de autenticação de nome real',
        qrz: 'Para verificar',
        shz: 'Em análise',
        rzsb: 'A verificação falhou',
        userName: 'Nome Real',
        IDNumber: 'Número de identificação',
        zmz: 'Foto do cartão de identificação (frente)',
        bmz: 'Foto no cartão de identificação (verso)',
        scz: 'Foto de você segurando o ID com a página da foto',
        IDCard: 'IDCard',
        Passport: 'Passaporte',
        AuthenticationType: 'Tipo de Autenticação',
        HighAuth: 'Certificação avançada',
        gj: 'nacionalidade',
        tipsgj: 'Indique a sua nacionalidade',
    },
    share: {
        title: 'O meu código de referência',
        cp: 'Copiar o endereço',
        success: 'Copiar com sucesso',
    },
    newRecharge: {
        Deposit: 'Depósito',
        Credit: 'Cartão de crédito',
        Wallet: 'Bónus de ganho de depósito de carteira todas as vezes',
        FAQ: 'FAQ',
        Currency: 'Escolher a Moeda',
        placeholder: ' ',
        Main: 'Principal',
        DepositAds: 'Endereço do depósito',
        Copy: 'Copiar',
        remark: '•Não deposite quaisquer outros ativos que não',
        remark1: 'USD',
        remark2: 'para o endereço acima, caso contrário, os ativos não serão recuperados;',
        remark3: '•Depois de depositar no endereço acima você precisa aguardar a confirmação de todo o nó da rede e somente após 6 confirmar você pode receber os ativos em sua conta;',
        remark4: '• O depósito único não deve ser inferior a 15 USD este valor causará falha no depósito;',
        remark5: '•Antes de cada depósito, você precisa ir para esta página e copiar o endereço para garantir que o endereço está correto. O endereço errado levará à falha do depósito.',
        Pay: 'Pagar',
        Buy: 'Comprar',
        Merchant: 'Comerciante',
        remark6: '* Carregue para verificar',
        remark7: 'dicas',
        remark8: 'Se houver alguma dúvida entre em contato',
        remark9: 'atendimento ao cliente',
        ChipPay: 'Transacção de compra de moedas ChipPay',
        Purchasebyamount: 'Compra por montante',
        AccountBalance: 'Saldo da Conta',
        Tips: 'Dicas',
    },
    ChipPay: {
        copySUC: 'Replicar o Sucesso',
        copyERR: 'A cópia falhou',
        Buy: 'Comprar',
        TOBECONFIRMED: 'À espera',
        TransactionCompletion: 'Conclusão',
        OrderingInformation: 'Informações sobre a encomenda',
        oddNumbers: 'Números ímpares:',
        TransferAmount: 'Montante da transferência:',
        ApproximatelyQuantity: 'Quantidade aproximada:',
        UnitPrice: 'Preço unitário:',
        remark1: 'Não clicar no botão Eu completei a remessa ou discrepâncias no valor da transferência resultarão em atraso no recebimento. Transferências de conta não pessoal e comentários / postagens resultarão em falha da transação e um reembolso',
        remark2: 'Dicas',
        remark3: 'As informações de pagamento para cada pedido são válidas apenas para uma única transferência. Por favor, não guarde as informações para uma segunda transferência.',
        remark4: 'Depois de criar vários pedidos sem pagamento, você não será capaz de fazer outro pedido. Por favor, proceda com cautela.',
        remark5: 'Por favor, complete a remessa dentro do tempo especificado e certifique-se de clicar no botão "Concluí a remessa" para garantir o recebimento imediato.',
        remark6: 'Se o prompt for a alocação da parte de negociação falhou e o volume atual da transação é muito grande fazendo com que a parte de negociação esteja ocupada, aguarde 5-10 minutos antes de reenviar a ordem.',
        remark7: 'Sua remessa será transferida diretamente para a conta bancária da contraparte e os ativos digitais vendidos pela contraparte durante a transação serão gerenciados e protegidos pela plataforma. Tenha a certeza da transacção.',
        cancellation: 'Cancelamento',
        remittance: 'Transferido',
        remark8: 'Instruções de Transacção',
        remark9: 'Você se envolverá em transações de moeda digital com detentores de USD na plataforma ChipPay e a plataforma garantirá a autenticidade de seus ativos em USD durante toda a transação. Por favor, leia e concorde com os seguintes termos antes de iniciar a transação:',
        remark10: 'Informamos que o USD não é emitido pela plataforma ChipPay e não tem compensação legal',
        remark11: 'Ao realizar transações com detentores de USD na plataforma, você é obrigado a cumprir o conteúdo da declaração antilavagem de dinheiro durante o processo de transação',
        remark12: 'Por favor, leia a declaração de risco primeiro, esteja ciente dos riscos da negociação de moeda digital e concorde com os assuntos acima',
        remark13: 'Estou ciente dos riscos da transação e concordo com os termos do serviço',
        remark14: 'Não clicar no botão Eu completei a remessa ou discrepâncias no valor da transferência resultarão em atraso no recebimento. Transferências de conta não pessoal e comentários / postagens resultarão em falha da transação e um reembolso',
        remark15: '1. Por favor, certifique-se de que o valor da remessa é consistente com o valor da ordem. Se você não enviar, clicar neste botão pode resultar na lista negra pelo sistema',
        ConfirmedRemittance: 'Remessa Confirmadac',
        ConfirmRemittance: 'Confirmar a Remessa',
        WrongPoint: 'Ponto Errado',
        Ialreadyknow: 'Eu já sei',
        payattentionto: 'Preste atenção a',
        agree: 'concordo',
        Bank: 'Banco receptor:',
        ReceivingAccount: 'Conta de recepção:',
        Party: 'Parte comercial:',
        Authenticated: 'Autenticado',
        Margin: 'Margem',
        tips1: 'Por favor, verifique se estou familiarizado com os riscos de transação',
    },
    newProperty: {
        title: 'Total dos activos (USD)',
        Spot: 'Spot disponível',
        Contract: 'Contrato disponível',
        Bonus: 'Bónus',
        Purchase: 'Recarregar',
        Deposit: 'Depósito',
        Withdraw: 'Retirada',
        Transfer: 'Transferência',
        Spot1: 'Mancha',
        Contract1: 'Contrato',
        Commission1: 'Comissão',
        Financial1: 'Financial',
        Balance: 'Saldo',
        Hide: 'Esconder Activos de 0',
    },
    withdrawal: {
        Wallet: 'Retirada da Carteira',
        Internal: 'Transferência interna',
        Currency: 'Moeda',
        Main: 'Principal',
        WithdrawAddress: 'Endereço de Retirada',
        Frequently: 'Endereços Frequentes',
        placeholder: 'Endereço de Retirada em USD',
        note: 'Nota de transacção',
        placeholder1: 'Indique a nota',
        Amount: 'Montante Retirado',
        placeholder2: '10 Mínimo',
        All: 'Todos',
        Balance: 'Saldo da Conta',
        Fee: 'Taxa de retirada',
        FundPassword: 'Senha do Fundo',
        placeholder3: 'Indique por favor a Senha do Fundo',
        forget: 'Esqueceste-te da senha do fundo?',
        OTP: 'Telefone/E-mail OTP',
        placeholder4: 'Indicar o Código de Verificação',
        send: 'Enviar',
        Resend: 'Reenviar',
        Reminder: 'Lembrete',
        Reminder1: 'O período de retirada é de segunda a domingo 9:00-22:00 (UTC+8)',
        Reminder2: 'Saldo máximo disponível = saldo da conta o valor da retirada inclui taxa de retirada.',
        Reminder3: 'Antes de solicitar a retirada, confirme se o endereço de retirada está correto. Uma vez que a transação é enviada para a rede blockchain, ela será irrevogável',
        Confirm: 'Confirmar',
        noPassword: 'Nenhuma senha do fundo definida',
        Fees: 'Taxas',
        Free: 'Livre',
        Transfer: 'Montante da transferência',
        Limitation: 'Limitação da transferência',
        placeholder6: 'Quantidade de transferência 0-100000',
        Available: 'Montante disponível',
        Receiving: 'Conta Receptora',
        placeholder5: 'Indique por favor o Nome do Utilizador',
        TransferBtn: 'Transferência',
        Reminder4: 'Lembrete: Certifique-se de confirmar as informações do usuário ao transferir',
        Reminder5: 'Tempo de processamento da transação 09:00-22:00 (UTC+8)',
        title: 'Declaração',
        title1: 'Mancha',
        title2: 'Contrato',
        title3: 'Fundo',
        title4: 'Compra de moedas',
        title5: 'Venda de moedas',
        Rate: 'Taxa de câmbio',
        allRate: 'liquidação',
        PlaceOrder: 'Colocar Ordem',
        Freeze: 'congelados',
        Thaw: 'descongelar',
        backgroundProgram: 'Programa',
        AuditReject: 'Venda de moedas Auditoria Rejeitar',
        OrderSettlement: 'Liquidação de Ordens',
        AccountBalance: 'Saldo:',
        CancelOrder: 'Cancelar Ordem',
        Created: 'Criado',
        Paid: 'Pago',
        UnAuthed: 'Sem Revisão',
        Accepted: 'Aceito',
        Canceled: 'Cancelado',
        Refuce: 'Recusar',
        placeholderMC: 'Vendido com sucesso',
        read: 'li',
        unread: 'Não- lidas',
        chat: 'conversar',
    },
    property: {
        Contract: 'Mancha',
        Exchange: 'Segundo contrato',
        zzc: 'total dos activos',
        mhyzh: 'Segunda conta contratual',
        bbzh: 'Conta à vista',
        available: 'Disponível',
        OnOrders: 'Congelar',
        Estimated: 'Equivalente',
        cw: 'Registo Financeiro',
        number: 'Número',
        record: 'Gravar',
        Transfer: 'Transferência',
        time: 'Tempo',
        dhsl: 'Quantidade de token a trocar',
        tips: 'Indique por favor a quantidade de token a trocar',
        qbdh: 'Trocar tudo',
        dhhl: 'Taxa de câmbio',
        ky: 'Disponível',
        kd: 'Disponível',
        sxfbl: 'Taxas de tratamento (taxa)',
        sxf: 'Taxas de tratamento',
    },
    statusLang: {
        Review: 'Revisão',
        Success: 'Sucesso',
        Fail: 'Falha',
    },
    with: {
        title: 'Retirar',
        history: 'História',
        ldz: 'Endereço do Blockchain',
        tbdz: 'Endereço de retirada',
        tips: 'Indique por favor o endereço de retirada',
        tips1: 'Indique o montante das retiradas',
        tips2: 'por favor indique a senha do Fundo',
        tbsl: 'Montante das retiradas',
        ky: 'Disponível',
        zjmm: 'Senha do fundo',
        sxf: 'Taxa de tratamento',
        dz: 'Montante previsto para receber',
    },
    tabBar: {
        home: 'Casa',
        Quotation: 'Mercados',
        Quotation2: 'Cotação de mercado',
        Trade: 'Comércio',
        Contract: 'Contrato',
        newContract: 'Comércio',
        assets: 'Activos',
        qhyy: 'mudar de idioma',
        zyjb: 'Bloquear a mineração',
        jsjy: 'Negociação rápida',
        jryw: 'Actividades financeiras',
        hyjy: 'Transacção contratual',
        Frequently: 'Frequentemente',
        mine: 'Eu',
    },
    exchange: {
        title: 'Troca',
        canChange: 'Quantidade disponível',
        canChange1: 'Indique o valor da troca',
        title1: 'Detalhes da Troca',
        Available: 'Quota disponível',
        Proposed: 'Preço proposto',
        Redeem: 'Resgatar tudo',
        Confirm: 'Confirmar',
        CurrencyBalance: 'Saldo monetário',
    },
    home: {
        gridcz: 'Recarga de moeda fiduciária',
        gridsd: 'Retirada de fiat',
        gridxs: 'FAQ',
        gridbd: 'LISTA',
        Balance: 'Saldo',
        UserName: 'Nome do Utilizador',
        score: 'Pontuação',
        kjmb: 'Compra rápida',
        zc: 'Suporte BTC USD etc.',
        scwk: 'Mineração de fechaduras',
        sgxb: 'Nova Subcrição de Fichas',
        aqkx: 'Seguro e fiável',
        aqkx1: 'A principal plataforma de negociação de ativos digitais do mundo',
        rmcjb: 'Quente',
        xbb: 'Moedas Novas',
        zfb: 'Crescimento',
        dfb: 'Largar',
        identify: 'Não verificado',
        identifyYes: 'autenticado',
        changeName: 'Por favor introduza o seu nome de utilizador',
        changeName1: 'O nome de utilizador só pode editar uma vez',
        confirmName: 'Confirmar',
        closeName: 'Cancelar',
        mc: 'Nome',
        zxj: 'Preço Último',
        zdb: 'Variação Líquida',
        hy: 'Alavancagem',
        btyh: 'Utilizador normal',
        sfyz: 'Autenticação da Identidade',
        skfs: 'Forma de pagamento',
        share: 'A minha ligação de partilha',
        kjcb: 'Recarregar',
        kjtb: 'Retirada',
        qbdz: 'Endereço da Carteira',
        bps: 'papel branco',
        xgmm: 'Mudar a Senha',
        xgzfmm: 'alterar a senha do fundo',
        gywm: 'Sobre',
        tcdl: 'Sair',
        zjmx: 'Declaração do Fundo',
        cdsz: 'Configuração',
        cdaq: 'Autenticação',
        bzzx: 'FAQ',
        ysxy: 'Acordo de Privacidade',
        xzyy: 'Seleccionar o Idioma',
    },
    commonUse: {
        title: 'Serviço',
        Finance: 'Finanças',
        Notice: 'Aviso',
        customerService: 'Serviço',
        cc: 'Posição',
        zjmx: 'Declaração',
        cpdm: 'Nome',
        mm: 'Comprar/Vender',
        wtj: 'Preço',
        wtsl: 'Número',
        Buy: 'Comprar',
        Sell: 'Vender',
        Buy1: 'Comprar',
        Sell1: 'Vender',
        Buy2: 'Compra de moeda com um clique',
        Exchangequantity: 'Quantidade',
        Exchangeamount: 'Montante',
        Currency: 'Moeda',
        Status: 'Estado',
        Type: 'Tipo',
        Service: 'Serviço',
        p1: 'Seleccione por favor uma moeda',
        p2: 'Indique o montante',
        czlb: 'operar',
    },
    product: {
        Buy: 'Comprar',
        Sell: 'Vender',
        Gain: 'Ganho',
        Trade: 'Comércio',
        Real: 'Real',
        High: 'Alto',
        ClosingPrice: 'Preço de encerramento',
        Low: 'Baixo',
        Volume: 'Volume 24h',
        Limit: 'Ordem Limitada',
        Contract: 'Ordens de depósito',
        News: 'Ordens de encerramento',
        Liquidations: 'Liquidações',
        ContractType: 'Contrato/Tipo',
        OpeningPrice: 'Preço/preço de abertura',
        Leverage: 'Alavancagem',
        LeverageP: 'A alavanca não pode estar vazia.',
        LimitPrice: 'Montante da transacção',
        LimitPlaceholder: 'Indique o valor limite de preço',
        Overnight: 'Overnight',
        Direction: 'Direcção',
        Operate: 'Operar',
        Long: 'Comprar/ir Longo',
        Short: 'Venda/venda curta',
        Balance: 'Saldo',
        Floating: 'P/L flutuante',
        CloseAll: 'Fechar Tudo',
        MarketOrder: 'Ordem de Mercado',
        LimitOrder: 'Ordem Limitada',
        Cross: 'Cruz',
        Isolated: 'Isolado',
        placeholder: 'Melhor Preço de Execução',
        Margin: 'Número',
        MarginBalance: 'Saldo da Margem',
        TradingFee: 'Taxa de negociação',
        Position: 'Posição',
        Success: 'Operação bem sucedida',
        Commission: 'InCommission',
        Transaction: 'InTransaction',
        Completed: 'Concluído',
        Cancellation: 'Cancelamento',
        unitPrice: 'preço unitário',
        LeverageRatio: 'Razão de alavancagem',
        cancellation: 'Cancelar',
        ClosingPosition: 'ClosePosition',
        productName: 'Nome do Produto',
        Margin1: 'Margem',
        SettlementPrice: 'Preço final',
        EndTime: 'Hora de Fim',
        CreateTime: 'Criar Tempo',
        WalletBalance: 'Saldo da Carteira',
        AvailableFunds: 'Fundos disponíveis',
        Text1: 'Dica',
        Text2: 'Deseja executar esta operação',
        qr: 'Confirmar',
        qx: 'Cancelar',
        ddbh: 'Número da Ordem',
        wtsj: 'Tempo de entrega',
        szzyzs: 'Definir os resultados',
    },
    notice: {
        title: 'Aviso',
        ckxq: 'Detalhe',
    },
    helpCenter: {
        title: 'Guia dos Noviços'
    },
    pwdGroup: {
        title: ' Password Setting',
        pwdDL: ' Login Password',
        pwdZj: ' Fund Password',
    },
    quotation: {
        title: 'Mercados',
        mc: 'Quantidade',
        zxj: 'Preço mais recente',
        zdb: 'Variação líquida',
        liang: 'Volume',
    },
    contract: {
        kcslfw: 'Intervalo de Quantidade de Abertura:',
        gao: 'alta',
        di: 'baixo',
        liang: 'Volume de 24 H',
        placeholder: 'Indique o montante',
        SuccessfulPurchase: 'Compra com Sucesso',
        smallMin: 'min',
        hour: 'hora',
        dayMin: 'dia',
        Trend: 'Tempo',
        ccjl: 'Lista de detenções',
        ddjl: 'Lista de ordens',
        sl: 'Quantidade',
        gmj: 'Preço de compra',
        dqj: 'Preço Actual',
        yjyk: 'Resultados estimados',
        djs: 'Contagem regressiva',
        gbjg: 'Preço de exercício',
        sc: 'Taxas de tratamento',
        pal: 'Lucros e perdas',
        mhy: 'Segundo contrato',
        sj: 'Tempo',
        yll: 'Taxa de lucro',
        mz: 'Longo',
        md: 'Curto',
        kcsl: 'Quantidade da posição aberta',
        kcsj: 'Hora da posição aberta',
        zhye: 'saldo da conta',
        qc: 'cancelar',
    },
    editPassword: {
        title: 'Modificar a Senha de Login',
        jmm: 'senha antiga',
        xmm: 'nova senha',
        qrmm: 'confirmar a senha',
        placeholder: 'senha antiga',
        placeholder1: 'definir uma nova senha',
        placeholder2: 'Introduza uma nova senha novamente',
        qrxf: 'OK',
    },
    secure: {
        title: 'Seguro',
        title1: 'Autenticação de Dois Factores',
        title2: 'Para garantir a segurança da sua conta, preencha o KYC.',
        gridTxt: 'Configuração da Senha',
        gridTxt1: 'Verificação Móvel',
        gridTxt2: 'Verificação por E- mail',
        gridTxt3: 'Autenticação da Identidade',
        listTxt: 'Cartão Bancário',
        listTxt1: 'Endereço de Retirada',
    },
    bindBank: {
        title: 'Cartão Bancário',
        addCard: 'Adicionar Cartão Bancário',
        editCard: 'Modificar a senha do fundo de verificação',
        ts1: 'Você ainda não foi submetido à autenticação de identidade',
        ts2: 'Execute por favor a autenticação de identidade primeiro',
        addTitle: 'Adicionar Cartão Bancário',
        userName: 'Nome Real',
        tips1: 'Por favor, introduza o seu nome verdadeiro',
        CollectionDetailArea: 'Área de Detalhes da Colecção',
        tips6: 'Por favor insira a Área de Detalhe da Colecção',
        userPhone: 'Número de telefone reservado',
        tips2: 'Indique um número de telefone reservado',
        bankName: 'Nome do banco',
        tips3: 'Introduza o nome do banco de abertura da conta',
        bankAds: 'Endereço Bancário',
        tips5: 'Indique o endereço do banco de abertura da conta',
        bankId: 'Número da conta do cartão bancário',
        tips4: 'Introduza o número da conta do seu cartão bancário',
        CountryCode: 'Código do país',
        tips7: 'Seleccione por favor um código de país',
        tips8: 'Introduza o código SWIFT',
        tips9: 'Indique o montante',
        tips10: 'O valor da recarga ou saque não pode ser inferior a 100',
        addTxt: 'Gravar',
    },
    bindMail: {
        title: 'Definir o Endereço de E- mail',
        placeholder1: 'Endereço de E- mail',
        ptitle: 'Alterar o Número de Telefone',
        placeholder2: 'Introduza o seu número de telemóvel',
        next: 'Próximo',
        zjmm: 'Senha do Fundo',
        placeholder3: 'Introduza a senha do seu fundo',
    },
    setting: {
        title: 'Configuração',
        setTxt: 'Imagem do perfil',
        setTxt1: 'Língua',
        setTxt2: 'Taxa de câmbio',
        setTxt3: 'Sobre nós',
    },
    withPass: {
        title: 'Definir a Senha do Fundodd',
        title1: 'A senha do fundo não pode ser a mesma que a senha de login',
        aqyz: 'Verificação de segurança',
        aqyzm: 'Telefone/E-mail OTP',
        jmm: 'Senha antiga',
        xmm: 'Nova senha',
        qrmm: 'Confirmar a senha',
        placeholder: 'senha antiga',
        placeholder1: 'definir uma nova senha',
        placeholder2: 'Introduza uma nova senha novamente',
        qrxf: 'OK',
        tips: 'Insira uma senha antiga de pelo menos 6 dígitos',
        tips1: 'Por favor, insira uma nova senha de pelo menos 6 dígitos',
        tips2: 'Por favor, introduza a sua senha de confirmação',
        tips3: 'Dois novos dados de senha são inconsistentes',
        tips4: 'A senha foi definida com sucesso',
        confirm: 'Confirmar',
    },
    newBe: {
        title: 'Assinatura',
        title1: 'A minha subscrição',
        scqx: 'Período de bloqueio',
        day: 'Dia',
        sy: 'Restantes',
        sdsg: 'História',
        sysl: 'Quantidade restante',
        gmsl: 'Quantidade de compra',
        dj: 'Preço unitário',
        tips: 'Indique por favor a quantidade de compra',
        gmzj: 'Preço total de compra',
        qbye: 'Saldo da carteira',
        fmzj: 'Preço total de compra',
        gm: 'comprar',
        sgsj: 'Tempo de Assinatura',
        Currency: 'Tipo de Ficha',
        Request: 'Montante solicitado',
        Passing: 'Montante aprovado',
        MarketTime: 'Tempo de Listagem',
        sqz: 'No pedido',
    },
    register: {
        title: 'Registo',
        lang: 'Língua',
        send: 'enviar',
        Resend: 'Reenviar',
        Account: 'Conta',
        Password: 'Senha',
        ConfirmPassword: 'Confirmar a senha',
        InvitationCode: 'Código do convite',
        btn: 'Registar',
        mobile: 'Número de telefone',
        mailbox: 'Caixa de Correio',
        haveAccount: 'Já tens uma conta?',
        haveLogin: 'Login',
        Login: 'Login',
        placeholder: 'Indique por favor o email',
        placeholder0: 'Indique por favor o número de telefone',
        placeholder1: 'Introduza a senha',
        placeholder2: 'Introduza a senha novamente',
        placeholder3: 'Indique por favor o código do convite',
        placeholder4: 'Introduza o código de verificação',
        placeholder5: 'Indique por favor a caixa de correio correcta',
        success: 'O registo foi bem sucedido',
        message: 'A senha deve ter 6-16 caracteres',
        message1: 'A entrada de senha tem pelo menos 6 caracteres',
        message2: 'A entrada da senha é inconsistente',
        message3: 'Por favor, leia e marque o acordo',
        agree: 'Concordo',
        xy: 'Acordo do utilizador e política de privacidade',
        qr: 'Registar',
        ftitle: 'Obter a Senha',
        qrzh: 'OK',
        placeholder7: 'Nova Senha',
        placeholder6: 'Confirmar a senha nova',
        placeholder8: 'Introduza o código de verificação gráfica',
        placeholderName: 'Indique por favor um nome de utilizador',
    },
    lockming: {
        title: 'Bloqueio de Fichas',
        wkb: 'Tesouro Mineiro',
        wkgjd: 'A mineração é mais fácil',
        qsljl: 'Ganhe recompensa facilmente',
        yjzsr: 'Rendimento total estimado',
        ck: 'Taxa de retorno anual de referência para os últimos três dias',
        tips: 'Mínimo',
        rlx: 'Juros diários',
        day: 'dia',
        scqx: 'Período de detenção',
        buy: 'comprar',
        zysl: 'Montante da participação',
        qbye: 'Saldo da Carteira',
        finished: 'Completo',
        doing: 'Em curso',
        sl: 'Quantidade',
        shouyilv: 'Taxa',
        ks: 'Hora de início',
        js: 'Hora do fim',
    },
    login: {
        title: 'Login',
        lang: 'Língua',
        mobile: 'Número de telefone',
        mailbox: 'E- mail',
        Account: 'Conta',
        Password: 'Senha',
        btn: 'Login',
        placeholder1: 'Introduza a senha',
        placeholder: 'Indique o número da conta',
        fail: 'Por favor preencha o formulário',
        ForgotPassword: 'Esqueceste-te da senha?',
        NoAccount: 'Ainda não tem conta?',
        Registration: 'Registe-se Agora',
        OtherLogin: 'Outro login',
        server: 'Serviço de Apoio ao Cliente Online',
        jzmm: 'Lembrar a senha',
        success: 'Login bem sucedido',
        ageent: 'Leia e concorde com o',
        ageentTitle: 'contrato de serviço',
        ageentFail: 'Por favor, leia e verifique o contrato de serviço',
        Home: 'Casa',
        userbox: 'Nome do Utilizador',
    },
    moneyAddr: {
        title: 'Endereço da carteira',
        Currency: 'Moeda',
        Address: 'Endereço',
        placeholder: 'Seleccione por favor',
        DepositAddress: 'Endereço do depósito',
        WalletAddress: 'Endereço da carteira',
        Date: 'Data',
        add: 'Adicionar Endereço',
        addTxt: 'Adicione no máximo 3 endereços a cada blockchain',
        remark: 'Observações',
        Walletremark: 'Por favor, adicione observações para este endereço',
        network: 'Principal',
        networkXy1: 'A rede que escolheu é',
        networkXy2: ' . Se você enviou seus ativos para um endereço incorreto por engano, você perderá seu ativo.',
        ChainAddress: 'endereço da cadeia',
        qrcode: 'Código QR da carteira',
        submit: 'submeter',
        tips: 'Seleccione por favor o tipo de carteira',
        tips1: 'Seleccione por favor o endereço da cadeia',
        tips2: 'Indique por favor o endereço da carteira',
        tips3: 'Envie por favor o código QR da carteira',
        success: 'sucesso',
    },
    recharge: {
        title: 'Recarregar',
        title1: 'Detalhes da Recarrega',
        ls: 'história',
        td: 'Seleccione por favor o seguinte canal',
        bc: 'Gravar o QR',
        address: 'Endereço do depósito',
        copy: 'Copiar o endereço',
        num: 'Número de depósitos',
        Upload: 'Enviar a imagem',
        Submit: 'Enviar',
        tip: 'Indique o montante',
        tip1: 'Envie por favor o código QR',
        copySuc: 'Copiado com Sucesso',
    }
}