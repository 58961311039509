import moment from 'moment'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'


// 手机验证
export function rightPhoneNumber(mobile) {
    return /^1\d{10}$/gi.test(mobile)
}
// 获取链接中的参数
export function getUrlParam(item) {
    var svalue = location.hash.match(
        new RegExp('[?&]' + item + '=([^&]*)(&?)', 'i')
    )
    return svalue ? svalue[1] : svalue
}

// 格式修改
export function splitData1(arr) {
    let categoryData = []

    arr.forEach((item) => {
        var obj = {
            time:
                (Date.parse(item.CreateTime.replace(/-/g, '/')) + 8 * 60 * 60 * 1000) /
                1000,
            // time: Date.parse(item.CreateTime.replace(/-/g, '/')) / 1000,
            open: item.Open,
            high: item.High,
            low: item.Low,
            close: item.Close
        }
        categoryData.push(obj)
    })
    return categoryData
}
export function IsreadSysNotice() {
    const flag = sessionStorage.getItem('props')
    if (!flag) {
        sessionStorage.setItem('props', true)
        return true
    } else {
        return false
    }
}
export function getLocalTime(value) {
    return moment(value).local().format('YYYY/MM/DD HH:mm:ss')
}

export function getHourTime(value) {
    return moment(value).local().format('HH:mm:ss')
}
export function returnLang(lang, setLang = false) {
    let localeLang = ''
    return localeLang
}

// 余额变更类型
export const balanceChangeTypeArr = [{
    Id: 'Upper',
    Name: '存款'
},
{
    Id: 'Lower',
    Name: '取款'
},
{
    Id: 'Bet',
    Name: '投注'
},
{
    Id: 'Win',
    Name: '中奖'
},
{
    Id: 'BalanceToFinance',
    Name: '余额转收益宝'
},
{
    Id: 'FinanceeToBalance',
    Name: '收益宝转余额'
},
{
    Id: 'CashProfit',
    Name: '下级提现收益'
}
]
// 状态类型
export const TransferStatusArr = [{
    Id: 'Review',
    Name: '审核中'
},
{
    Id: 'Success',
    Name: '成功'
},
{
    Id: 'Fail',
    Name: '失败'
}
]

//页面显示语言
const langArr = [{
    Id: 'zh',
    Name: '简体中文'
},
{
    Id: 'ft',
    Name: '繁體中文'
},
{
    Id: 'en',
    Name: 'English'
},
{
    Id: 'jp',
    Name: '日本語'
},
{
    Id: 'hy',
    Name: '한국인'
},
{
    Id: 'ty',
    Name: 'ภาษาไทย'
},
{
    Id: 'yn',
    Name: 'Indonesia'
},
{
    Id: 'yuenan',
    Name: 'Việt nam'
},
{
    Id: 'gmw',
    Name: 'កម្ពុជា'
},
{
    Id: 'fy',
    Name: 'Français'
},
{
    Id: 'xby',
    Name: 'Español'
},
{
    Id: 'ey',
    Name: 'Русский язык'
},
{
    Id: 'pty',
    Name: 'Português'
},
{
    Id: 'dy',
    Name: 'Deutsch'
},
]

/**
 * 根据lang获取名称
 * @param {String} string
 */
export function showName(Id) {
    if (Id === 'Error') {
        return '错误'
    } else {
        let name = ''
        langArr.some((item) => {
            if (item.Id === Id) {
                name = item.Name
                return true
            }
        })
        return name
    }
}
/**
 * 根据名称获取lang
 * @param {String} string
 */
export function showId(Name) {
    if (Name === 'Error') {
        return '错误'
    } else {
        let Id = ''
        langArr.some((item) => {
            if (item.Name === Name) {
                Id = item.Id
                return true
            }
        })
        return Id
    }
}
//获取调用api的语言头
export function getLang() {
    var lang = localStorage.getItem('language') || 'zh'
    if (lang == 'zh') {
        lang = 'zh-CN'
    }
    if (lang == 'en') {
        lang = 'en-US'
    }
    if (lang == 'jp') {
        lang = 'ja'
    }
    if (lang == 'hy') {
        lang = 'ko'
    }
    if (lang == 'ty') {
        lang = 'th'
    }
    if (lang == 'yn') {
        lang = 'id'
    }
    if (lang == 'yuenan') {
        lang = 'vi'
    }
    if (lang == 'gmw') {
        lang = 'km'
    }
    if (lang == 'fy') {
        lang = 'fr'
    }
    if (lang == 'xby') {
        lang = 'es'
    }
    if (lang == 'ey') {
        lang = 'ru'
    }
    if (lang == 'pty') {
        lang = 'pt'
    }
    if (lang == 'dy') {
        lang = 'de'
    }
    return lang
}

export function IsPC() {
    var userAgentInfo = navigator.userAgent
    var Agents = [
        'Android',
        'iPhone',
        'SymbianOS',
        'Windows Phone',
        'iPad',
        'iPod'
    ]
    var flag = true
    for (var i = 0; i < Agents.length; i++) {
        if (userAgentInfo.indexOf(Agents[i]) != -1) {
            flag = false
            break
        }
    }
    return flag
}

export function dataURLtoBlob(dataUrl) {
    const arr = dataUrl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
}

export function getRandomString(len) {
    len = len || 15
    var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
    var maxPos = $chars.length
    var pwd = ''
    for (var i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return pwd
}

export function rTime(date) {
    var json_date = new Date(date).toJSON()
    return new Date(new Date(json_date) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, ' ')
        .replace(/\.[\d]{3}Z/, '')
}

export function isEmptyObject(obj) {
    if (obj === null || obj === undefined) return true
    for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            return false
        }
    }
    return true
}

// PDF导出方法 
export function downloadPDF(ele, pdfName) {
    window.scrollTo(0, 0)
    const eleW = ele.offsetWidth // 获得该容器的宽
    const eleH = ele.offsetHeight // 获得该容器的高
    const eleOffsetTop = ele.offsetTop // 获得该容器到文档顶部的距离
    const eleOffsetLeft = ele.offsetLeft // 获得该容器到文档最左的距离
    var canvas = document.createElement('canvas')
    var abs = 0
    const win_in = document.documentElement.clientWidth || document.body.clientWidth
    // 获得当前可视窗口的宽度（不包含滚动条）
    const win_out = window.innerWidth // 获得当前窗口的宽度（包含滚动条）   
    if (win_out > win_in) {
        abs = (win_out - win_in) / 2 // 获得滚动条宽度的一半
    }
    canvas.width = eleW * 2 // 将画布宽&&高放大两倍
    canvas.height = eleH * 2
    var context = canvas.getContext('2d')
    context.scale(2, 2)
    context.translate(-eleOffsetLeft - abs, -eleOffsetTop)
    // 这里默认横向没有滚动条的情况，因为offset.left(),有无滚动条的时候存在差值，因此
    // translate 的时候，要把这个差值去掉
    html2canvas(ele, {
        dpi: 400,
        scale: 3,
        // allowTaint: true,  //允许 canvas 污染， allowTaint参数要去掉，否则是无法通过toDataURL导出canvas数据的
        useCORS: true // 允许canvas画布内 可以跨域请求外部链接图片, 允许跨域请求。
    }).then((canvas) => {
        var contentWidth = canvas.width
        var contentHeight = canvas.height
        // 一页pdf显示html页面生成的canvas高度;
        var pageHeight = contentWidth / 595.28 * 841.89
        // 未生成pdf的html页面高度
        var leftHeight = contentHeight
        // 页面偏移
        var position = 0
        // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        var imgWidth = 595.28
        var imgHeight = 595.28 / contentWidth * contentHeight
        var pageData = canvas.toDataURL('image/jpeg', 1.0)
        var pdf = new JsPDF('', 'pt', 'a4')
        if (leftHeight < pageHeight) {
            // 在pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示；
            pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        } else { // 分页
            while (leftHeight > 10) {
                // console.log(leftHeight)
                pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                leftHeight -= pageHeight
                position -= 871.89
                // 避免添加空白页
                if (leftHeight > 30) {
                    pdf.addPage()
                }
            }
        }
        // 可动态生成
        pdf.save(pdfName)
    })
}
