module.exports = {
    public: {
        confirm: 'Confirm',
        cancel: 'Cancel',
        search: 'Search',
        RefreshSucceeded: 'Refresh succeeded',
        noMoreData: 'No more data',
        pullingText: 'Pull down to refresh…',
        ReleaseText: 'Release to refresh…',
        loadingText: 'Loading…',
        noData: 'No records',
        copySuccess: 'Copy successful',
        parent: 'Referrer',
        swap: 'Perpetual',
        amount: 'Amount',
        buy: 'Buy',
        orderNo: 'OrderNo',
        Paypassword: 'Transaction password',
        PaypasswordMsg: 'Please enter a 6-digit transaction password',
        noPayPasswordMsg: 'Transaction password not set yet, go to set it?',
        noAuthisg: 'Not yet verified with real name, go verify?',
        broker: 'Broker',
    },
    tabBar: {
        home: 'Home',
        chat: 'Chat',
        trustship: 'Asset Custody',
        property: 'Asset'
    },
    register: {
        title: 'Account Registration',
        send: 'Send Verification Code',
        Resend: 'Resend',
        sendSuccess: 'Sent Successfully',
        PhoneNumber: 'Phone Number',
        Captcha: 'Verification Code',
        Password: 'Password',
        ConfirmPassword: 'Confirm Password',
        ParentId: 'Referrer ID',
        btn: 'Register Now',
        placeholder0: 'Please enter your phone number',
        placeholder1: 'Please enter your password',
        placeholder2: 'Please enter your confirm password',
        placeholder3: 'Please enter referrer ID',
        placeholder4: 'Please enter verification code',
        success: 'Registration Successful',
        message: 'Password must be at least 6 characters',
        message1: 'Confirm password must be at least 6 characters',
        message2: 'Passwords do not match',
        searchCountryMsg: 'Please enter country or area code to search',
    },
    login: {
        title: 'Jiasheng',
        Account: 'Login Account',
        Password: 'Password',
        btn: 'Login',
        placeholder: 'Please enter phone number/ID number',
        placeholder1: 'Please enter your password',
        ForgotPassword: 'Forgot password?',
        Registration: 'Register',
        server: 'Contact Customer Service',
        success: 'Login Successful',
    },
    forgotPass: {
        title: 'Retrieve Password',
        qrzh: 'Confirm',
        Password: 'Set Login Password',
        placeholder: 'Please enter new password',
        success: 'Password set successfully',
    },
    home: {
        title: 'Jiasheng',
        toRecharge: 'I want to recharge',
        lineServer: 'Online Customer Service',
        newPrice: 'Real-time Price',
        news: 'News',
        seeAll: 'View All',
        auth: 'Real-name Authentication',
        bill: 'Bill',
        safeCenter: 'Security Center',
        message: 'Notifications',
        withAddress: 'Withdrawal Address',
        about: 'About Us',
        whitePaper: 'JiaSheng Token White Paper',
        setting: 'Settings',
        downApp: 'Download App',
        addChat: 'Join Group Chat',
    },
    auth: {
        title: 'Real-name Authentication',
        gj: 'Nationality',
        tipsgj: 'Please select nationality',
        userName: 'Real Name',
        tips1: 'Please enter real name',
        IDNumber: 'ID Number',
        tips2: 'Please enter ID number',
        high: 'Advanced Authentication',
        zmz: 'Front Side of ID',
        bmz: 'Back Side of ID',
        scz: 'Photo with ID',
        tips3: 'Please upload the front side of your ID',
        tips4: 'Please upload the back side of your ID',
        tips5: 'Please upload a photo with your ID',
        btn: 'Submit',
        upbtn: 'Modify ID',
        subMsg: 'Submission successful, please wait for review',
        upMsg: 'Re-review required after modification, confirm ID modification?',
        wait: 'Under Review',
        fail: 'Authentication Failed',
        success: 'Authentication Successful',
    },
    safeCenter: {
        title: 'Security Center',
        editLoginPwd: 'Change Login Password',
        editPayPwd: 'Change Transaction Password',
        setPayPwd: 'Set Transaction Password',
        oldPassword: 'Old Password',
        oldPasswordMsg: 'Please enter the old password',
        newPassword: 'New Password',
        newPasswordMsg: 'Please enter the new password',
        twoPassword: 'Confirm Password',
        twoPasswordMsg: 'Please enter the confirm password',
        twoinputMsg: 'The two passwords do not match',
        submsg: 'Successfully changed',
        btn: 'Confirm Change',
        setPayedMsg: 'Transaction password already set',
        payPassMsg: 'Please set a 6-digit payment password',
        payPassMsg2: 'The payment password is used for withdrawals and other transactions, please do not disclose it to others',
        setBtn: 'Confirm',
        setSuccess: 'Successfully set',
    },
    search: {
        title: 'Search',
        inputSearchTxt: 'Please enter a keyword',
        logs: 'Search History',
        maxMsg: 'Up to ten records displayed',
        news: 'News',
        trustship: 'Asset Custody',
    },
    news: {
        title: 'News',
        Newspaper: 'Newspaper Articles',
        Investment: 'JiaSheng Notice',
        MarketJournal: 'How-to guide',
        InvestSchool: 'Investment School',
        FundNotice: 'Fund Notices',
    },
    chat: {
        title: 'JiaChat',
        noOpen: 'Not yet available',
    },
    bill: {
        title: 'Bill',
        tradeLog: 'Transaction Records',
        etfTrade: 'ETF Transactions',
        tsTrade: 'Custody Transactions',
        orderNo: 'Order No.',
        rechargeLog: 'Recharge Records',
        withLog: 'Withdrawal Records',
        rechargeAmount: 'Recharge Amount',
        withAmount: 'Withdrawal Amount',
        TsOrder: 'Custody Order',
        TsSettle: 'Custody Earnings',
        TsCommission: 'Custody Commission Earnings',
        TsStop: 'Custody Termination',
        TsEndReturn: 'Return upon expiration of custody',
        EtfOrder: 'ETF Order',
        EtfSettle: 'ETF Order Settlement',
        EtfCancel: 'ETF Order Cancellation',
        Wait: 'Pending Review',
        Success: 'Review Successful',
        Fail: 'Review Failed',
    },
    message: {
        title: 'Notifications',
        Account: 'Account Change Notifications',
        ETF: 'ETF Transaction Notifications',
        TrustShip: 'Custody Order Notifications',
        AccountMsg1: 'Dear user, your recharge',
        AccountMsg2: 'Dear user, your withdrawal',
        AccountMsg3: 'Dear user, your coin-to-coin exchange',
        AccountMsg4: 'has been successfully received, please check and confirm receipt',
        AccountMsg5: 'has been refuse',
        AccountMsg6: 'Dear user, you have invited rewards',
        AccountMsg7: 'Dear user, you will receive rewards for purchasing the project',
        AccountMsg8: 'Dear user, your authentication reward',
        TsMsg1: 'Dear user, your asset custody project',
        TsMsg2: 'Custody amount',
        TsMsg3: 'has been successfully deposited and earnings calculation has begun',
        TsMsg4: 'has matured, custody funds and earnings',
        TsMsg5: 'have been returned to your account balance, please check',
        TsMsg6: 'has been terminated prematurely',
        User: 'Account dynamic notification',
        UserMsg1: 'Dear user, you have changed your login password',
        UserMsg2: 'Dear user, you have changed your transaction password',
        UserMsg3: 'Dear user, you have modified your wallet address',
        UserMsg4: 'Dear user, your real name authentication has been approved',
        UserMsg5: 'Dear user, your real name authentication review did not pass. Please make the necessary changes and resubmit',
    },
    setting: {
        title: 'Settings',
        heard: 'Avatar',
        lang: 'Language',
        nickname: 'Nickname',
        nicknamemsg: 'Please enter a nickname',
        outlogin: 'Log Out',
        outloginmsg: 'Confirm log out?',
        editNicknmae: 'Change Nickname',
    },
    withAddress: {
        title: 'Withdrawal Address',
        addressMsg: 'Please enter the address',
        succss: 'Successfully modified',
        edit: 'Edit',
        copy: 'Copy Address',
        tips: 'Please carefully check the wallet address for withdrawal',
        setTips: 'Please bind the withdrawal address',
    },
    recharge: {
        title: 'Recharge',
        copy: 'Copy Address',
        amount: 'Recharge Amount',
        amountMsg: 'Please enter the recharge amount',
        Voucher: 'Transfer Voucher',
        VoucherMsg: 'Please upload the transfer voucher',
        VoucherTips: 'Please upload a screenshot of the successful transfer voucher',
        submit: 'Submit',
        subMsg: 'Submission successful, please wait for review',
        tips1: 'This address can only receive',
        tips2: 'assets. If you deposit other currencies, they cannot be recovered',
    },
    with: {
        title: 'Withdraw',
        Balance: 'Available Balance',
        BalanceNot: 'Insufficient available balance',
        amount: 'Withdrawal Amount',
        amountMsg: 'Please enter the withdrawal amount',
        subMsg: 'Submission successful, please wait for review',
        selectLin: 'Select Chain',
        coin: 'Withdrawal Currency',
        mixAmount: 'Minimum withdrawal amount 10 USDT',
        ratetips: 'Withdrawal fee is 0.2%, less than 1 USDT will be rounded up to 1 USDT',
        ratetipsNo: 'V2 and above are exempt from handling fees',
        tips1: 'Please carefully check the wallet address for withdrawal.',
        tips2: 'Sending the wrong cryptocurrency to the wallet address may result in permanent loss.',
        tips3: 'Withdrawal fees will be deducted from the withdrawal amount.',
        resdAmount: 'Amount Received',
        ratefee: 'Fee',
        rateMsg: 'Will be deducted from the balance',
        submit: 'Submit',
        address: 'Wallet Address',
        edit: 'Edit',
    },
    about: {
        title: 'About Us',
    },
    etf: {
        smallMin: 'Minutes',
        hour: 'Hour',
        dayMin: 'Day',
        Trend: 'Intraday',
        Present: 'Market Order',
        Limit: 'Limit Order',
        Balance: 'User Equity',
        canUse: 'Unrealized P/L',
        riskRate: 'Risk Rate',
        markPriceMsg: 'Trade at the latest price',
        limitMsg: 'Limit',
        price: 'Price',
        num: 'Quantity',
        numTip: 'Contracts',
        zyzsSet: 'Take Profit/Stop Loss Settings (Optional)',
        zyPrice: 'Take Profit Trigger Price',
        zsPrice: 'Stop Loss Trigger Price',
        Openmore: 'Open Long (Bullish)',
        Openempty: 'Open Short (Bearish)',
        canNum: 'Available to Open',
        EstimatedMargin: 'Estimated Margin',
        tradeList: 'Trade Records',
        haveTrade: 'Open Positions',
        nowTrade: 'Current Orders',
        time: 'Time',
        direction: 'Direction',
        buy: 'Buy',
        sell: 'Sell',
        product: 'Product',
        newProce: 'Latest Price',
        Fluctuation: 'Price Fluctuation',
        high: '24H High',
        low: '24H Low',
        vol: '24H Volume',
        markTrades: 'Order Book',
        newTrades: 'Latest Trades',
        coinInfo: 'Token Information',
        buyPrice: 'Bid Price',
        sellPrice: 'Ask Price',
        IssueTotal: 'Total Issuance',
        CirculateTotal: 'Circulating Supply',
        IssuePrice: 'Issuance Price',
        IssueTime: 'Issuance Time',
        WhitePaperAddress: 'Whitepaper URL',
        OfficialWebsiteAddress: 'Official Website URL',
        Infos: 'Introduction',
        buyBtn: 'Buy',
        sellBtn: 'Sell',
        cloedMsg: 'Operation not supported in your region',
    },
    trustShip: {
        title: 'Asset Custody',
        buynum: 'Quantity',
        buynumMsg: 'Please enter the quantity',
        selectDays: 'Select Term',
        rate: 'Interest Rate',
        useBanlace: 'Available Balance',
        noBanlace: 'Insufficient Balance',
        goRecharge: 'Recharge',
        tradeFail: 'Transaction Failed',
        Repurchase: 'Repurchase',
        tradeSuccess: 'Transaction Successful',
        toConTract: 'View Contract',
        selectDaysMsg: 'Please select the term',
        contractTitle: 'Cryptocurrency Asset Custody Contract',
        contractInfo1: 'Party A entrusts its legal funds to Party B for management, with the goal of preserving and increasing its value. Based on the principles of voluntariness, sincerity, and mutual benefit, and in accordance with relevant asset management laws and regulations, this contract is concluded after full consultation regarding entrusted asset management matters.',
        contractInfo2: 'I. Contract Formation',
        contractInfo3: '1. To obtain investment income, Party A reaches an agreement with Party B to provide management and operation services for Party A\'s assets.',
        contractInfo4: '2. The investment funds entrusted by Party A to Party B amount to',
        contractInfo5: 'The custody term is',
        contractInfo6: 'days',
        contractInfo7: 'Party A fully entrusts Party B with the investment management and operation of Party A\'s assets. Party B may designate its own personnel or hire external experts for specific operations.',
        contractInfo8: 'II. Contract Performance',
        contractInfo9: '1. Party B will implement closed-end investment management. During the contract term and after its termination, Party A shall not disclose Party B\'s operational strategies or financial status, nor harm Party B\'s interests.',
        contractInfo10: '2. During the contract period, if Party A terminates the contract early, only the principal will be refunded.',
        contractInfo11: '3. Due to Party B\'s diversified investment strategy, some investment products may carry certain risks, which are entirely borne by Party B. Party A assumes no risk.',
        contractInfo12: '4. Party B shall not maliciously transfer Party A\'s funds. If losses are caused by this, Party A will hold Party B criminally liable and seek compensation for the losses.',
        contractInfo13: 'III. Fund Return Rate Distribution',
        contractInfo14: '1. Party A\'s investment earnings entitle Party A to a guaranteed daily return rate of % (in the event of force majeure such as changes in national policies, natural disasters, etc., neither party shall be held responsible, and the contract will be immediately terminated).',
        contractInfo15: '2. Upon expiration of the custody term, Party B will promptly return Party A\'s funds.',
        contractInfo16: 'IV. Contract Termination',
        contractInfo17: '1. This contract may be terminated by Party A at any time upon mutual agreement between Party A and Party B.',
        contractInfo18: '2. After the termination of rights and obligations under this contract, both parties shall adhere to the principles of good faith, fulfilling notification, cooperation, confidentiality, and other obligations as agreed.',
        contractInfo19: 'V. Contract Effectiveness',
        contractInfo20: '1. This contract is made in two copies, one held by each party.',
        contractInfo21: '2. This agreement shall take effect upon the confirmation of the deposit in the designated investment account and the signing and stamping of the contract by both parties.',
        contractInfo22: 'Party A',
        contractInfo23: 'Signing Date',
        contractInfo24: 'Party B (Signature/Stamp)',
        contractInfo25: 'Address: 15/F, Luk Hoi Tong Centre, 165-171 Wan Chai Road, Hong Kong',
        contractInfo26: 'Contact: 12356-8656-555',
        contractInfo27: 'Contract Contact Email: jiashengcapita@gmail.com',
        downLoad: 'Download to Mobile',
    },
    exchange: {
        title: 'Coin Exchange',
        follow: 'From',
        fundAccount: 'Fund Account',
        curreny: 'Currency',
        buyAmount: 'Transfer Amount',
        buyAmountMsg: 'Please enter the transfer amount',
        canAmount: 'Available',
        needAmount: 'Estimated Needed',
        noAmount: 'Insufficient Balance',
        subMsg: 'Exchange Successful'
    },
    property: {
        title: 'Assets',
        walletAddress: 'Wallet Address',
        AllAmount: 'Total Assets',
        CanAmount: 'Available Balance',
        funtAccount: 'Account',
        Balance: 'Balance',
        convert: 'Equivalent',
        contractposition: 'ContractPosition',
        tsProject: 'Custody',
        tsAmount: 'Total Custody Amount',
        tsName: 'Project Name',
        buyTime: 'Purchase Time',
        endTime: 'Expiration Time',
        operate: 'Operation',
        stopTs: 'Terminate Custody',
        toSee: 'View',
        myTeam: 'MyTeam',
        toTeam: 'Become a Broker',
        teamNum: 'Team Size',
        tsAmountTotal: 'Total Asset Custody',
        ProfitTotal: 'Estimated Total Profit',
        AlreadyProfit: 'Total Profit Earned',
        tsToName: 'Asset Custody',
        profit: 'Estimated Profit',
        alePorfit: 'Profit Earned',
        apply: 'Apply',
        applyMsg: 'Do you want to apply to become a broker? Once you become a broker, your personal level benefits will be overridden by broker benefits.',
        tips: 'Tips',
        stopMsg: 'Terminating the investment contract early will result in only the return of the invested principal without earning the expected profit. Please confirm if you want to terminate!',
        gradeMsg1: 'Level Mechanism Rewards',
        gradeMsg2: 'Cumulative investment, non-decreasing',
        gradeMsg3: ' subordinate project profits',
        gradeMsg4: 'second level',
        gradeMsg5: 'third level',
        gradeMsg6: 'Eligible to apply to be a broker',
        gradeMsg7: 'project profits of team members',
        projectInfo: 'Project Details',
        rate: 'Interest Rate',
        endProfit: 'Maturity Profit',
        applySuccess: 'Application successful, please wait for review',
        projectorder: 'Project Order',
        orderNo: 'Order Number',
        allRate: 'Total Rate of Return',
        tsammount: 'Custody Amount',
        allProject: 'Expected Profit',
        allEnd: 'Total at Maturity',
        strattime: 'Effective Time',
        enddtime: 'End Time',
        teCantr: 'Custody Contract',
        btnSee: 'Click to View',
        tostopTs: 'Early Termination',
        completed: 'Completed',
        canceled: 'Terminated',
        stopSuccess: 'Termination Successful',
        tvApplying: 'Broker review in progress',
        waiting: 'In progress',
        depth: 'Hierarchy',
        depthMsg: 'Input level filtering',
        Difference: 'Difference',
    },
    share: {
        title: 'Share Download',
        MyShareCode: 'sharing link',
        CopyShareCode: 'Copy and share link',
    }
}