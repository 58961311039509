import userApi from '@/api/user'
import * as signalR from '@microsoft/signalr'
import { Token } from '@/utils/mm'
import { Notify } from 'vant'
import Router from '@/router'
import { EventBus } from '@/utils/event-bus'

async function getsignalROption() {
  let token = Token.get()
  if (!token) {
    let data = await userApi.getAccountRefresh({
      Account: 'signalrExclusive',
      Password: 'signalrExclusive',
    })
    let res = await userApi.getAccountToken(data.Data.Token)
    token = res.Data.Token
  }
  return token
}
export function getSignalR() {
  var options = {
    skipNegotiation: true,
    withCredentials: false,
    transport: signalR.HttpTransportType.WebSockets,
    accessTokenFactory: function () {
      return getsignalROption()
    }
  }
  let connection = new signalR.HubConnectionBuilder()
    // .withUrl(process.env.VUE_APP_BASE_API + '/hub/currency', options)
    .withUrl(window.baseURL + '/hubs', options)
    .withAutomaticReconnect([0, 500, 1000, 1500, 2000])
    .build()
  connection.start()
  connection.on('Login', function (res) {
    console.log('------')
    console.log(res)
  })
  connection.onclose(() => {
    console.log('onclose')
    connection.stop()
  })
  connection.onreconnected(() => {
    if (location.href.includes('product')) {
      connection.invoke('SendMsg', 'product')
    } else {
      connection.invoke('SendMsg', 'product')
    }
  })
  connection.on('Offline', function (type) {
    const message =
      type === 1 ? '您的账号已在其他地方登陆，您已被迫下线' : '您已被强制下线'
    // 账号登录
    Notify(message)
    Token.remove()
    Router.push({ name: 'Login' })
    EventBus.$emit('Logout')
  })
  return connection
}
