import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
let routes = [
    {
      path: '/',
      name: 'index',
      meta: { tx: 1 },
      redirect: '/Home'
    },
    {
        path: '/news',
        name: 'News',
        meta: { tx: 1 },
        component: () =>
            import('@/views/news')
    },
    {
        path: '/search',
        name: 'Search',
        meta: { tx: 1 },
        component: () =>
            import('@/views/search')
    },
    {
        path: '/setting',
        name: 'Setting',
        meta: { tx: 1 },
        component: () =>
            import('@/views/setting')
    },
    {
        path: '/home',
        name: 'Home',
        meta: { tx: 1 },
        component: () =>
            import('@/views/index')
    },
    {
        path: '/login',
        name: 'Login',
        meta: { tx: 1 },
        component: () =>
            import('@/views/login')
    },
    {
        path: '/detail',
        name: 'Detail',
        meta: { tx: 1 },
        component: () =>
            import('@/views/detail')
    },
    {
        path: '/register',
        name: 'Register',
        meta: { tx: 2 },
        component: () =>
            import('@/views/register')
    },
    {
        path: '/reg',
        name: 'Reg',
        meta: { tx: 2 },
        component: () =>
            import('@/views/register/reg')
    },
    {
        path: '/with',
        name: 'With',
        meta: { tx: 3 },
        component: () =>
            import('@/views/with')
    },
    {
        path: '/chat',
        name: 'Chat',
        meta: { tx: 3 },
        component: () =>
            import('@/views/chat')
    },
    {
        path: '/trustShip',
        name: 'TrustShip',
        meta: { tx: 3 },
        component: () =>
            import('@/views/trustShip')
    },
    {
        path: '/contract',
        name: 'Contract',
        meta: { tx: 3 },
        component: () =>
            import('@/views/trustShip/contract')
    },
    {
        path: '/etf',
        name: 'Etf',
        meta: { tx: 3 },
        component: () =>
            import('@/views/etf')
    },
    {
        path: '/etfInfo',
        name: 'EtfInfo',
        meta: { tx: 3 },
        component: () =>
            import('@/views/etf/etfInfo')
    },
    {
        path: '/iframePage',
        name: 'IframePage',
        meta: { tx: 1 },
        component: () =>
            import('@/views/iframePage')
    },
    {
        path: '/share',
        name: 'Share',
        meta: { tx: 3 },
        component: () =>
            import('@/views/share')
    },
    {
        path: '/property',
        name: 'Property',
        meta: { tx: 3 },
        component: () =>
            import('@/views/property')
    },
    {
        path: '/exchange',
        name: 'Exchange',
        meta: { tx: 3 },
        component: () =>
            import('@/views/property/exchange')
    },
    {
        path: '/tsOrderInfo',
        name: 'TsOrderInfo',
        meta: { tx: 3 },
        component: () =>
            import('@/views/property/tsOrderInfo')
    },
    {
        path: '/auth',
        name: 'Auth',
        meta: { tx: 3 },
        component: () =>
            import('@/views/auth')
    },
    {
        path: '/bill',
        name: 'Bill',
        meta: { tx: 3 },
        component: () =>
            import('@/views/bill')
    },
    {
        path: '/safeCenter',
        name: 'SafeCenter',
        meta: { tx: 3 },
        component: () =>
            import('@/views/safeCenter')
    },
    {
        path: '/editLoginPwd',
        name: 'EditLoginPwd',
        meta: { tx: 3 },
        component: () =>
            import('@/views/safeCenter/editLoginPwd')
    },
    {
        path: '/editPayPwd',
        name: 'EditPayPwd',
        meta: { tx: 3 },
        component: () =>
            import('@/views/safeCenter/editPayPwd')
    },
    {
        path: '/setPayPwd',
        name: 'SetPayPwd',
        meta: { tx: 3 },
        component: () =>
            import('@/views/safeCenter/setPayPwd')
    },
    {
        path: '/message',
        name: 'Message',
        meta: { tx: 3 },
        component: () =>
            import('@/views/message')
    },
    {
        path: '/withAddress',
        name: 'WithAddress',
        meta: { tx: 3 },
        component: () =>
            import('@/views/withAddress')
    },
    {
        path: '/about',
        name: 'About',
        meta: { tx: 3 },
        component: () =>
            import('@/views/about')
    },
    {
        path: '/recharge',
        name: 'Recharge',
        meta: { tx: 3 },
        component: () =>
            import('@/views/recharge')
    },
    {
        path: '/forgotPassword',
        name: 'ForgotPassword',
        meta: { tx: 2 },
        component: () =>
            import('@/views/forgotPassword')
    },
    { path: '*', redirect: '/404' }
]

const router = new VueRouter({ routes: routes })

router.afterEach((to) => {
    localStorage.setItem('fullPath', to.fullPath)
    //设置跳转页面回到顶部
    window.scrollTo(0,0);
})
export default router