module.exports = {
    pcregister: {
        p1: 'すでにアカウントがありますか。',
        dl: 'ログイン',
        zc: '登録',
        p4: 'アカウント',
        p5: 'アカウントを入力してください',
        p6: 'パスワードを入力してください',
        p7: '確認パスワードを入力してください',
        p8: '招待コードを入力してください',
        p9: '同意します',
        p10: '『ユーザー合意およびプライバシーポリシー』',
        p11: 'メールボックス',
        p12: 'メールボックスを入力してください',
        p13: 'メールボックスの認証コードを入力してください',
        p14: '送信',
        p15: '年',
        p16: '月',
        p17: '天',
        p18: 'アワー',
        p19: '分',
        p20: '秒'
    },
    pclogin: {
        l1: 'アカウントがない？',
        l2: '登録',
        l3: 'ログイン',
        l4: 'ユーザー名を入力してください',
        l5: 'パスワードを入力してください',
        l6: 'パスワードを忘れた',
        l7: 'ログイン'
    },
    pccontract: {
        ss: '時価',
        xj: '指値',
        gg: 'レバー'
    },
    pcrapidly: {
        zf: '上げ幅',
        dd: '注文',
        bdsj: 'ローカル時間',
        ky: '利用可能'
    },
    pchome: {
        global1: '世界有数のデジタル資産取引プラットフォーム',
        global2: '100カ国以上の数百万人のユーザーに、安全で信頼性の高いデジタル資産取引および資産管理サービスを提供しています。',
        global3: '安全性',
        global4: '5年間のデジタル資産金融サービス経験。分散アーキテクチャとDDoS攻撃に強いシステムに焦点を当てる。',
        global5: 'グローバルエコレイアウト',
        global6: 'すでに多くの国にローカライズされた取引サービスセンターを設立し、複数の業態を融合したブロックチェーンエコシステムを構築しています。',
        global7: 'ユーザーファースト',
        global8: '先行補償メカニズムを構築し、投資家保護基金を設立する。',
        whever1: 'いつでもどこでもマルチプラットフォーム端末取引',
        whever2: 'iOS、Android、Windowsの複数プラットフォームをカバーし、全業務機能に対応しています。',
        register1: '登録壱個',
        register3: 'グローバルアカウント取引開始',
        register2: '今すぐ登録',
        commodity: '商品',
        news: 'ニュース',
        ttop1: 'Pinnacleの謎を発見してください',
        ttop2: 'Pinnacleは、先物やオプション契約を扱う国際取引所です。農業エネルギー通貨金属指数など。設立以来、取引所は取引ルールを継続的に改善し、様々な商品の量は増加しています。同社は30カ国以上の小売業者や機関投資家にサービスを提供するまでに拡大し、現在も成長しています。私たちは、取引コストを可能な限り低く抑え、お客様が利益を得るだけのお客様ではなく、利益を得るトレーダーになるための協働精神でサービスを提供するよう努めています。結局のところ、あなたがうまくやれば私たちもうまくいきます。',
        ttop3: 'これから取引',
        product1: '迅速かつ信頼性の高い注文実行',
        product2: '高度な分析ツール',
        product3: 'リアルタイムの引用',
        product4: '迅速かつ安全な引き出し',
        trade1: '賢い',
        trade2: '戦略を練習しなさい',
        trade3: '実際の取引口座を開設する前に、無料のデモ口座をお試しください。',
        trade4: '知識を拡充する',
        trade5: 'Pinnacleの包括的な教材を使用して、cfd取引について学びましょう。',
        trade6: 'ちゃんと自分のリスクをコントロールして',
        trade7: '当社の高度なリスク管理ツールを使用して、損失を最小限に抑え、利益を確保してください。',
        allselect1: 'なぜジクムを選ぶんだ?',
        allselect2: '保護&確保',
        allselect3: 'あなたのデータは安全であり、あなたの資金は規制要件に従って分離された銀行口座に保管されます。',
        allselect4: '専門的な支援を',
        allselect5: '24時間対応の専任カスタマーサービスを多言語でご利用いただけます。',
        allselect6: '規制',
        allselect7: '様々な世界的な規制当局によって認可され、規制されています。',
        allselect8: '信頼できる',
        allselect9: 'Pinnacle ltdは、カナダ証券取引所の上場企業の主要市場に上場しているftse250会社です。',
        down1: '携帯端末上の任意の携帯電話モデルは、操作と使用をサポートすることができます',
        down2: 'プラットフォームアプリ',
    },
    pcbottom: {
        box1: 'グローバル・リーディング',
        box2: '最高のチーム之壱',
        box3: 'もっと',
        box4: '私たちについて',
        box5: '白書',
        box6: 'サービス',
        box7: 'ダウンロード',
        box8: 'よくある質問',
        box9: 'オンラインカスタマーサービス',
        box10: '規定',
        box11: 'ユーザプロトコル',
        box12: 'プライバシーポリシー',
        box13: '法的注意事項',
        box14: 'コンタクト'
    },
    pcAbout_us: {
        t1: '道程',
        t2: '我ら、7年の道',
        t3: '2015年に誕生して以来、私たちは無心にブロックチェーンのサービスに没頭して、専任の技術、通路の構築サービスを提供しています。',
        t4: '2014-2017年は「安定の中には上昇がある」という上昇期を経て、2017-2018年上半期は「お金を買って豊かになる」というクレイジーな期で、2019年下半期から今日の真冬期にかけて、私は本物の腕を身につけた。多くの成功したパートナーが、低調な私たちを業界で高く評価しています。2022年には、新しいプラットフォームV 3.0を保有し、世界100カ国以上で事業を展開する新しい株式会社BitGoldEXを設立します。',
        t5: '私たちは誰を探しているのか',
        t6: '結成から今日まで',
        t7: '困っていることを探していて、自分と気の合うプロバイダーを探して、数年の時間に成功して数十のプロバイダーの問題を解决します。',
        t8: '理性の今日、もしあなたは良い助っ人を探して、手を携えて自分の前進を実現することができる仲間を探すことを望むならば——もしかすると、私たちに話をすることができます。',
        t9: '信じてください:あなたが探している人は、実はあなたを探しています。',
        t10: 'サービス',
        t11: '業務員がいない, 壱様有業務',
        t12: '友達があなたに商売を紹介してくれたということは、あなたの人柄がまあまあだということを示している。同行為君が商売を紹介してくれるのは、君の評判が悪くないからだ。取引先があなたを紹介してくれるなら、それはあなたが信頼できる証拠です。',
        t13: 'モラハラ',
        t14: '私たちはレンガです。どこへ行く必要がありますか',
        t15: 'お客様のご要望は異なりません。製品によって特性は異なっている。別のタイミングで、目標は壱様ではありません。わたしたちの存在意義は,さまざまなプロバイダーの理念,制度,要求に適応し,さまざまな問題を解決することです。',
        t16: '私たちは、アイデアと効果のあるれんがを作りたいです。必要なところはどこへでも行きます。'
    },
    pcproblem: {
        p1: 'よくある質問',
        p2: 'って切り替え後の資産数が変わる？',
        p3: '資産の等価計算は、現在保有しているデジタル通貨の価値をUSDに換算したもので、デジタル資産の価格の変動に応じて変化します。デジタル資産の数は変わっていません。',
        p4: 'プラットフォーム取引忘れ資金暗号処理方法',
        p5: 'プラットフォーム取引資金のパスワードを忘れた場合は、「マイ-設定-資金パスワードの設定をクリック」するか、カスタマーサービスリセットに連絡してください',
        p6: '取引購入後の資金はどこに流れるのか？',
        p7: '発注が完了すると、資金は資産の法貨口座に入ります。資産を購入して契約取引や先物取引を行う必要がある場合は、資金振替モジュールで振替を行ってください。',
        p8: 'ニックネームの変更回数を制限するには',
        p9: 'あなたの取引の安全を考慮して、現在ニックネームの修正を1回に制限しています。',
        p10: '移行するときってどういう意味？移行するには',
        p11: 'あなたの口座間の資金の独立性を保証し、あなたが合理的にリスクをコントロールすることを容易にするため、私達はここで各主要な取引モジュールの口座を分割します。振替とは、取引口座間で資産の転換を行うプロセスのこと。',
        p12: 'ユーザー契約のアカウント権益はどのように計算しますか。',
        p13: '契約勘定純額=ポジション保証金+ポジション浮動損益+当座勘定利用可能額',
        p14: 'オープン時のストップロスとストップロスの設定値はどのくらいですか。どう設置すべき？',
        p15: '損益を、あなたが設定した損益の上限とします。設定した満期と損失の金額に達すると、システムは自動的に倉庫を平積みします。購入契約時のリスクコントロールに活用できる。設定ストップ量の壱半は:増加量x数量xてこの倍数、設定ストップロス。実際の資産状況に基づいて設定し、合理的にリスクをコントロールすることをお勧めします。',
        p16: '契約リスクを減らすには？',
        p17: '残りの口座の利用可能な資産を、資金を振り替えることによって契約口座に振り込むか、保有資産を減らすことによってリスクを軽減することができます',
        p18: '契約開倉の数量はどういう意味ですか。',
        p19: 'オープンポジションの数は、購入を希望する通貨の数を表します。例:BTC/USD取引ペアのオープンページで選択して、購入/多く、入力価格は1000USD、入力数量は10BTC、つまり:10 BTCの購入を予想して、単価は1000USDです。',
        p20: '契約における手数料はどのように計算されますか？',
        p21: '手数料=オープン価格*オープン数量*手数料率',
        p22: '強制平積み注意事項',
        p23: 'リスク度合は、資産のリスクを測定する指標です。リスク度が100%に等しい場合、あなたのポジションは平蔵とみなされ、リスク度=(保有保証金/契約口座の持分)*100%、ユーザーのポジションを防ぐために、システム設定はリスク度比を調整します。リスクレベルがシステムの設定したリスク値に達すると、システムは強制的に倉庫を平準化します。例えば、システム設定の調整率が10%である場合、リスクが90%以上になると、すべてのポジションはシステムによって強制的に平準化されます。',
        p24: '契約取引ルールってなんですか？',
        p25: '取引タイプ',
        p26: '取引タイプはロング(買い)とショート(売り)の2方向に分かれる:',
        p27: '買い越し(コール)は、あなたが現在の指数が上昇する可能性があると考えて、あなたが設定した価格または現在の市場価格で契約を締結するために1定数量の1定数量を買いたいことを示します。',
        p28: '空売り(プット)とは、現在の指数が下落する可能性があると考えて、あなたが設定した価格または現在の市場価格で新しい契約の数を売りたいことを指します。',
        p29: '注文方法',
        p30: '指値注文: 注文する価格と数量を指定する必要があります',
        p31: '市場価格:注文数を設定するだけで、価格は現在の市場価格',
        p32: '持ち高',
        p33: '発行された注文が完了した時点で、在庫保有と呼ばれます',
        p34: '契約受渡問題',
        p35: 'プラットフォーム契約は永続契約で、納期を設けない。システムがフラット条件を満たしていない場合、または手動でフラット化していない場合は、永続的に保管することができます。システムフラット',
        p36: '1:ストップロス設定値達成システム自働平倉',
        p37: '2:リスクが高すぎます。システムが強制的に平準化します',
        p38: '契約取引の保証金はいくらですか。',
        p39: '契約取引では、ユーザーは契約価格と数量、対応するレバレッジの倍数に基づいて契約の売買に参加することができます。利用者が口座を開設する際には証拠金が使われる。口座の保証金が多ければ多いほど、口座のリスクが高くなります。',
        p40: '指値注文と相場注文ってなんですか。',
        p41: '指値書とは、あなたが何の価格でプラットフォームに取引を依頼することを希望することを指し、時価書とは、プラットフォームに直接現在の価格で取引を依頼することを指します。オープンルールでは、指値注文より相場注文が優先されます。もしあなたが指値注文を選択するならば、通貨の現在の市場価格に基づいて合理的に開倉してください。開倉価格の不合理による損失を避けるために。',
        p42: '契約取引のリスクランクは何を表していますか。',
        p43: '危険度は、契約アカウントのリスク指標です。リスクの程度が100%に等しいと清算とみなされる。リスクが50%を超える場合は、慎重に開倉して、平積みによる損失を避けることをお勧めします。契約資産の使用可能な資金を補充するか、在庫を減らすことで、リスクを軽減できます。',
        p44: '通貨両替って何が必要？',
        p45: '取引所の目的はあなたの資産の中の異なる通貨の資金を円滑に流通させることです。先物口座で穫得したQCCは自由にに交換して取引することができます。他の口座にあるUSDTも自由にQCCに交換して取引できる。',
        p46: 'なぜ振り込み後に相手が振り込みをきちんと受け取っていないのか？',
        p47: 'まず、ご注文の詳細ページに表示されているお支払い方法と同じお支払い方法をお支払いの際に記入してください。その上で、支払い方法が即時か遅延かを確認してください。最後に、システムの保守やその他の理由については、銀行や支払機関に連絡してください。',
        p48: 'プラットフォーム経由でUSDを購入するには？',
        p49: '方法壱:プラットフォーム売買リストで購入する注文を選択して売買します。',
        p50: '方法2:クリックしてボタンを発行して、あなたの必要に応じて買い注文や売り注文を発行して迅速に取引します。このシステムは、売買ユーザーを迅速にマッチングします。',
        p51: '注:注文書が15分経過しても未払いの場合は、注文書が自動的にキャンセルされますので、お支払いください。当日の注文取り消しが3回を超えた場合、当日に再度取引することができず、翌日に取引権限を回復する。'
    },
    public: {
        zxkf: 'オンラインカスタマーサービス',
        sure: '確認',
        cancel: 'キャンセル',
        RefreshSucceeded: '更新成功',
        noMoreData: 'これ以上のデータはありません',
        pullingText: 'プルダウンで更新...',
        ReleaseText: '解放すれば更新できます...',
        loadingText: 'ロード中...',
        noData: 'レコードなし',
        xq: '詳細',
        submit: '確認',
        kefu: 'オンラインカスタマーサービス',
        amount: '金額',
        startInvestment: '倉庫ポジション',
        endInvestment: '終了点',
        profit: 'メリット'
    },
    ChangeType: {
        Upper: 'システムチャージ',
        Lower: 'システムキャッシュアウト',
        Win: '利益',
        Bet: '投資',
        BuyMining: 'マイニング',
        CoinWin: '新しいコインで利益が出る',
        SettleMining: 'マイニング元本リターン',
        GiveInterest: 'マイニング収益',
        BuyCoin: '新しいコインが当たりませんでした',
        BuyCoinBack: '新貨購入返却',
        UsdtToBtc: 'UsdからBtc',
        UsdtToEth: 'UsdからEth',
        BtcToUsdt: 'Btc→Usd',
        BtcToEth: 'Btc転Eth',
        EthToUsdt: 'Eth to Usd',
        EthToBtc: 'Eth to Btc',
        CoinFrozen: '新貨当せん凍結',
        CoinThaw: '新貨幣解凍',
        CoinCancel: 'コイン注文取消し',
        CoinBuy: 'コイン買い',
        CoinBuyEntrust: 'コイン買いの依頼',
        CoinSellEntrust: 'コイン売りの依頼',
        CoinCell: 'コイン売り',
        ContractBuy: '契約発注、保証金控除',
        ContractCharge: '契約注文、手数料控除',
        ContractCancelToBond: '契約取り消し保証金の払い戻し',
        ContractCancelToCharge: '契約取り消し手数料の払い戻し',
        ContractCloseProfit: '契約クローズメリット'
    },
    trade: {
        title: 'コインコイン',
        Buy: '買い',
        Sell: '販売',
        pc: '平倉',
        cb: 'コスト',
        xj1: '現価',
        yk: '損益',
        xianjia: '指し値',
        jiage: '価格',
        shuliang: '数',
        keyong: '使用可能',
        yonghuchicang: 'ユーザーホルダー',
        fxlts: 'リスクレート=保有保証金/顧客資本に100%を乗算したもの。ここで、顧客資本は、口座の使用可能な資金+初期取引保証金+変動損益と等しい',
        fxl: 'リスクレート',
        dqwt: '現在の委任',
        all: 'すべて',
        name: 'name',
        shijian: '時間',
        zyjg: '現在の最適価格で取引',
        caozuo: 'アクション',
        xj: '指値',
        cc: '保有',
        leixing: '型',
        long: '買い/多くする',
        short: '売り/空売り',
        sj: '時価',
        jiaoyie: '取引高',
        orderNo: '注文番号',
        weituojia: '依頼価格',
        chengjiaojia: '落札価格',
        chengjiaoe: '成約額',
        weituo: '依頼方法',
        qbwt: 'すべて委任',
        lswt: '履歴委任',
        tips: '正しい価格を入力してください',
        tips1: '数量を入力してください',
        Create: '依頼中',
        Wait: '取引中',
        Complete: '平積み',
        Cancel: 'キャンセルしました',
        zsj: '損切り',
        zyj: '締まり価格',
        cczsy: '保有総利益',
        ykbl: '損益比率',
        bili: 'プロポーション',
        JYS: '取引手数',
        bzj: '保証金',
        sxf: '手数料',
        gg: 'てこ倍数',
        yjpc: '壱鍵平倉'
    },
    auth: {
        title: 'ID認証',
        title1: 'ID認証',
        low: '初歩認定',
        high: 'Advanced Certification',
        success: '認証成功',
        tips: '身元確認をお願いします',
        tips1: '実名を入力してください',
        tips2: '証明書番号を入力してください',
        tips3: '証明書表の写真をアップしてください',
        tips4: '証明書の裏面写真をアップしてください',
        tips5: '手持ち写真をアップしてください',
        tips6: '実名認証タイプを選択してください',
        qrz: '認証に行く',
        shz: 'レビュー中',
        rzsb: '認証失敗',
        userName: '実名',
        IDNumber: '証明書番号',
        zmz: '証明書正面写真',
        bmz: '証明書里面写真',
        scz: '手持ち写真',
        IDCard: 'ID',
        Passport: 'パスポート',
        AuthenticationType: '実名認証タイプ',
        HighAuth: '上級認証です',
        gj: '国籍です',
        tipsgj: '国籍を入力願います',
    },
    share: {
        title: 'マイ・プロモーション・コード',
        cp: 'コピーアドレス',
        success: 'コピーに成功しました'
    },
    withdrawal: {
        Wallet: 'ウォレット送金',
        Internal: '内部振替',
        Currency: '通貨',
        Main: 'マスターネットワーク(プロトコル)',
        WithdrawAddress: '現金引き出し先',
        Frequently: '共通アドレス',
        placeholder: 'USD引き出し先',
        note: '取引に関する注意',
        placeholder1: '取引に関するコメントを入力してください',
        Amount: 'キャッシュアウト数',
        placeholder2: '最小引き出し単位は10',
        All: 'すべて',
        Balance: '口座残高',
        Fee: 'キャッシング手数料',
        FundPassword: '資金パスワード',
        placeholder3: '資金調達のパスワードを入力してください',
        forget: '資金のパスワードを忘れた？',
        OTP: 'ベリファイ',
        placeholder4: '認証コードを入力してください',
        send: '送信',
        Resend: '再送',
        Reminder: '心温まるヒント',
        Reminder1: 'キャッシュアウト処理期間は週1~日曜日、9:00~22:00(UTC+8)',
        Reminder2: '最大引出可能残高=口座残高、引出手数料込み',
        Reminder3: '現金引出を申請する前に、現金引出先が正しいかどうかを確認してください。取引はブロックチェーンネットワークに送られれば取り下げることはできません',
        Confirm: 'キャッシュアウトの確定',
        noPassword: '資金パスワードが設定されていません',
        Fees: '手数料',
        Free: '無料',
        Transfer: '転送数',
        Limitation: '1日の振込限度額',
        placeholder6: '1回の振替数量0-100000',
        Available: '引き出し手数料',
        Receiving: '受信アカウント',
        placeholder5: 'ニックネームを入力してください',
        TransferBtn: '振替',
        Reminder4: '心温まるヒント:振込の際には必ずユーザー情報を確認してください',
        Reminder5: '内部振替処理バケット09:00~22:00(UTC+8)',

        title: '詳細',
        title1: '現金口座',
        title2: '契約口座',
        title3: '資金',
        title4: 'チャージ',
        title5: 'キャッシュアウト',
        Rate: 'レート',
        allRate: '決算',
        PlaceOrder: '注文',
        Freeze: '凍結します',
        Thaw: '解凍します',
        backgroundProgram: '舞台裏',
        AuditReject: 'キャッシュアウト監査拒否',
        OrderSettlement: '注文決済',
        AccountBalance: 'アカウント残高:',
        CancelOrder: '注文をキャンセル',
        Created: '作成済み',
        Paid: '支払い済み',
        UnAuthed: '審査待ちです',
        Accepted: 'パスしました',
        Canceled: '注文をキャンセル',
        Refuce: '拒否',
        placeholderMC: '販売成功',
        read: '既読です',
        unread: '未読です',
        chat: 'おしゃべりです',
    },
    newRecharge: {
        Deposit: 'デジタル通貨',
        Credit: 'クレジットカード',
        Wallet: '財布にお金を入れて、何度もお年玉を送ります',
        FAQ: 'チャージガイド',
        Currency: '通貨の選択',
        placeholder: '通貨を選択してください',
        Main: 'マスターネットワーク(プロトコル)',
        DepositAds: 'チャージアドレス',
        Copy: 'アドレスのコピー',
        remark: '•上記のアドレスへの非チャージは行わないでください',
        remark1: 'USD',
        remark2: '資産,そうしないと取り戻せません;',
        remark3: '•上記のアドレスにチャージした後、ネットワークノード全体で確認する必要があります。6つのネットワークで確認してから入金することができます;',
        remark4: '•15USD以上の入力がないと、正しく入力されません;',
        remark5: '•毎回このページでアドレスをコピーしてください。アドレスが正確であることを確認してください。間違ったアドレスはチャージに失敗します。',
        Pay: '支払い',
        Buy: '購入',
        Merchant: 'プラットフォーム認定マーチャント',
        remark6: '*クリックすると表示',
        remark7: '注意事項',
        remark8: ',現在一致するベンダーがない場合は、Webリンクの更新を確認するか、お問い合わせください',
        remark9: 'オンラインカスタマーサービス',
        ChipPay: 'ChipPayチャージ取引',
        Purchasebyamount: '金額で購入',
        AccountBalance: '口座残高',
        Tips: '心温まるヒント'
    },
    ChipPay: {
        copySUC: 'コピー成功',
        copyERR: 'コピーに失敗しました',
        Buy: '購入',
        TOBECONFIRMED: '確認待ち',
        TransactionCompletion: '取引完了',
        OrderingInformation: '注文情報',
        oddNumbers: '単号:',
        TransferAmount: '振込金額:',
        ApproximatelyQuantity: 'は数:',
        UnitPrice: '単価:',
        remark1: '完了ボタンをクリックしていない場合、または転送金額が一致していない場合は、入金の遅延、本人以外の口座振替および注意/付言は、取引の失敗と返金の原因となります',
        remark2: '心温まるヒント',
        remark3: '各注文の入金情報は1回のみ有効です。【二回目の振替情報を保存しないでください】。',
        remark4: '【未払いの注文を何度も作成すると、再度注文することができません。】注意してください。',
        remark5: '所定の時間内に送金を完了したら【必ず「送金完了」ボタンをクリックしてください】、即時入金を確認してください。',
        remark6: '「パーティ割当に失敗しました」というプロンプトが表示された場合、現在の取引量が大きすぎてパーティがビジーになるので、5～10分待ってから注文を再発行してください。',
        remark7: 'あなたの送金は直接取引相手の銀行口座に転入して、取引の過程で取引相手が売却したデジタル資産はプラットフォームが管理して保護して、安心して取引してください。',
        cancellation: 'キャンセルオーダー',
        remittance: '送金を完了しました',
        remark8: '取引に関する注意事項',
        remark9: 'あなたはChipPayプラットフォーム内のUSD保有者とデジタル通貨取引を行います。取引の過程でプラットフォームはあなたのUSD資産の信頼性を保証します。取引を開始する前に、以下の条件を読んで同意してください。',
        remark10: 'USDはChipPayプラットフォームでは発行されておらず、法償性もありませんことをご了承ください。',
        remark11: 'プラットフォーム内のUSD保有者と取引する場合は、マネーロンダリング防止宣言書に従って取引する必要があります',
        remark12: 'まずリスクステートメントを読んで、デジタル通貨取引のリスクを承知し、上記の事項に同意してください',
        remark13: '取引リスクを承知しており、サービス約款に同意しています',
        remark14: '完了ボタンをクリックしていない場合、または転送金額が一致していない場合は、入金の遅延、本人以外の口座振替および注意/付言は、取引の失敗と返金の原因となります',
        remark15: '1.あなたの送金金額と注文金額を確認してください送金していない場合はこのボタンをクリックすると、システムがブラックリストに登録される可能性があります',
        ConfirmedRemittance: '送金の確認',
        ConfirmRemittance: '送金確認',
        WrongPoint: '注文が間違っていました',
        Ialreadyknow: '私は知っています',
        payattentionto: '注意してください',
        agree: '同意する',
        Bank: '入金銀行:',
        ReceivingAccount: '入金口座:',
        Party: 'パーティ:',
        Authenticated: 'すでに実名',
        Margin: '保証金',
        tips1: '取引リスクについて熟知していることをチェックしてください'
    },
    newProperty: {
        title: '総資産(USD)',
        Spot: '現品あり',
        Contract: '契約は使用可能',
        Bonus: 'お年玉',
        Purchase: 'チャージ',
        Deposit: 'チャージ',
        Withdraw: '現金引き出し',
        Transfer: 'Transfer',
        Spot1: '現金資産',
        Contract1: '契約資産',
        Commission1: 'コミッション資産',
        Financial1: '資産運用',
        Balance: '現物資産総額',
        Hide: '0アセットを非表示'
    },
    property: {
        Contract: 'コインコイン',
        Exchange: '秒契約',
        zzc: '総資産換算',
        mhyzh: '秒契約口座',
        bbzh: 'コインアカウント',
        available: '使用可能',
        OnOrders: 'フリーズ',
        Estimated: '換算',
        cw: '財務記録',
        number: '数',
        record: 'レコード',
        Transfer: 'Transfer',
        time: '時間',
        dhsl: '両替数量',
        tips: '両替数量を入力してください',
        qbdh: 'すべて両替',
        dhhl: '為替レート',
        ky: '利用可能',
        kd: '可得',
        sxfbl: '手数料比率',
        sxf: '手数料'
    },
    statusLang: {
        Review: 'レビュー',
        Success: '成功',
        Fail: '失敗しました'
    },
    with: {
        title: 'キャッシュアウト',
        history: 'キャッシュアウト記録',
        ldz: 'チェーンアドレス',
        tbdz: 'ティッピングアドレス',
        tips: 'ティッピングアドレスを入力してください',
        tips1: 'コインの数を入力してください',
        tips2: '支払いパスワードを入力してください',
        tbsl: 'トークン数',
        ky: '利用可能',
        zjmm: '支払いパスワード',
        sxf: '手数料',
        dz: '入金予定数'
    },
    tabBar: {
        home: 'トップページ',
        Quotation: '相場',
        Quotation2: '市況',
        Trade: 'コインコイン',
        Contract: '秒契約',
        newContract: '取引',
        assets: 'アセット',
        qhyy: '言語の切り替え',
        zyjb: 'ロックマイニング',
        jsjy: 'スピード取引',
        jryw: '金融ビジネス',
        hyjy: '契約取引',
        Frequently: 'よく使う',
        mine: '私'
    },
    exchange: {
        //フラッシュ交換
        title: 'フラッシュ',
        canChange: '交換可能数量',
        canChange1: '交換する数量を入力してください',
        title1: '両替明細',
        Available: '使用可能な限度額',
        Proposed: '参考価格',
        Redeem: 'すべて交換',
        Confirm: '確認',
        CurrencyBalance: '通貨残高'
    },
    home: {
        ///grid
        gridcz: '法貨チャージ',
        gridsd: '法貨キャッシュバック',
        gridxs: '初心者必読',
        gridbd: 'ランキング',
        Balance: '残高',

        UserName: 'アカウント',
        kjmb: 'チャージ',
        score: '信用スコア',
        zc: 'BTC、USDなどのサポート',
        scwk: 'ロック・マイニング',
        sgxb: '新貨申込み',
        aqkx: 'Security Trusted',
        aqkx1: '世界有数のデジタル資産取引プラットフォーム',

        ///番付
        rmcjb: '人気落札',
        xbb: '新貨ランキング',
        zfb: '値上がり率番付',
        dfb: '下げ幅ランキング',

        ///思い込む
        identify: '未検証',
        identifyYes: '認証済み',
        changeName: 'ニックネームを入力してください',
        changeName1: 'ニックネームは修正することしかできません壱回',
        confirmName: 'OK',
        closeName: 'キャンセル',

        mc: '名称',
        hy: '契約',
        zxj: '最新価格',
        zdb: '騰落率',
        sfyz: 'ID認証',
        skfs: '入金方法',
        share: '私の共有リンク',
        kjcb: 'チャージ',
        kjtb: 'キャッシュアウト',
        qbdz: 'ウォレットアドレス',
        bps: 'ホワイトペーパー',
        xgmm: 'パスワードの変更',
        xgzfmm: '支払いパスワードの変更',
        gywm: '私たちについて',
        tcdl: '終了',

        ///パーソナルセンター
        zjmx: '資金明細',
        cdsz: '設定',
        cdaq: '実名認証',
        bzzx: 'ヘルプセンター',
        ysxy: 'プライバシー契約',
        xzyy: '言語を選択'
    },
    commonUse: {
        title: 'サービス',
        Finance: 'ファイナンス',
        Notice: '告知',
        customerService: 'カスタマーサービス',
        cc: '保有',
        zjmx: '資金明細',
        cpdm: '製品',
        mm: '買う/売る',
        wtj: '委託価格',
        wtsl: '委任数',
        Buy: '買い',
        Sell: '売る',
        Buy1: '購入',
        Sell1: '販売',
        Buy2: '壱鍵買幣',
        Exchangequantity: '交換数量',
        Exchangeamount: '換算金額',
        Currency: '通貨',
        Status: 'オーダーステータス',
        Type: 'タイプ',
        Service: 'カスタマーサービス',
        p1: '通貨を選択してください',
        p2: '金額を入力してください',
        czlb: '操作'
    },
    product: {
        Buy: '買い',
        Sell: '販売',
        Gain: '騰落率',
        Trade: '取引',
        Real: '実',
        High: '最高',
        ClosingPrice: '昨日のコレクション',
        Low: '最低',
        Volume: '24h成約',
        Limit: '指値注文',
        Contract: '売り注文',
        News: '平積み注文',
        Liquidations: 'Webデータ',
        ContractType: 'コントラクトタイプ',
        OpeningPrice: '現価コンコスト',
        Leverage: 'レバー',
        LeverageP: 'レバーを空にすることはできません',
        LimitPrice: '取引金額です',
        LimitPlaceholder: '指値金額を入力してください',
        Overnight: '夜を過ごす',
        Direction: '方向',
        Operate: 'アクション',
        Long: '買い/多くする',
        Short: '売り/空売り',
        Balance: '契約資産合計',
        Floating: '浮動損益',
        CloseAll: '壱鍵平倉',
        MarketOrder: '時価取引',
        LimitOrder: '指値取引',
        Cross: 'フル倉敷',
        Isolated: '倉ごとに',
        placeholder: '現在の最適価格で取引',
        Margin: '数',
        MarginBalance: 'マージン残高',
        TradingFee: '手数料',
        Position: '持ち高',
        Success: '操作成功',
        Commission: '委任中',
        Transaction: '取引中',
        Completed: '完了',
        Cancellation: 'キャンセル',
        unitPrice: '単価',
        LeverageRatio: 'レバー倍数',
        cancellation: 'キャンセル',
        ClosingPosition: '平倉',
        productName: '製品名',
        Margin1: '保証金',
        SettlementPrice: '決済価格',
        EndTime: '精算時刻',
        CreateTime: '注文時刻',
        WalletBalance: 'ウォレット残高',
        AvailableFunds: '使用可能な資金',
        Text1: 'ヒント',
        Text2: 'この操作を実行するかどうか',
        qr: '確定',
        qx: 'キャンセル',
        ddbh: 'オーダー番号',
        wtsj: '委任時間',
        szzyzs: 'は損を止めます',
    },
    notice: {
        title: '告知',
        ckxq: '詳細の表示'
    },
    helpCenter: {
        title: '初心者必読'
    },
    pwdGroup: {
        title: 'パスワード設定',
        pwdDL: 'ログインパスワード',
        pwdZj: '資金パスワード'
    },
    quotation: {
        title: '相場',
        mc: '本数',
        zxj: '最新価格',
        zdb: '騰落率',
        liang: '量'
    },
    contract: {
        kcslfw: 'オープン数量の範囲:',
        gao: '高',
        di: '低',
        liang: '24H量',
        placeholder: '数量を入力してください',
        SuccessfulPurchase: '購入成功',
        smallMin: '分',
        hour: '時',
        dayMin: '天です',
        Trend: 'タイムシェアリング',
        ccjl: '保有リスト',
        ddjl: '注文レコード',
        sl: '数量',
        gmj: '購入価格',
        dqj: '現在の価格',
        yjyk: '損益予想',
        djs: 'カウントダウン',
        gbjg: '落札価格',
        sc: '手数料',
        pal: '損益',
        mhy: '秒契約',
        sj: '時間',
        yll: '利益率',
        mz: '買い上がる',
        md: '買い安',
        kcsl: 'オープン数量',
        kcsj: 'オープン時間',
        zhye: '口座残高',
        qc: 'キャンセル'
    },
    editPassword: {
        title: 'ログインパスワードの変更',
        jmm: '古いパスワード',
        xmm: '新しいパスワード',
        qrmm: 'パスワードの確認',
        placeholder: '古いパスワード',
        placeholder1: '新しいパスワードを設定',
        placeholder2: '新しいパスワードを再入力してください',
        qrxf: '確認'
    },
    secure: {
        //セキュリティ認証
        title: 'セキュリティ',
        title1: '二重認証',
        title2: 'アカウントを保護するために、少なくとも1つの実名認証を行うことをお勧めします',
        gridTxt: 'パスワード設定',
        gridTxt1: 'ケータイ認証',
        gridTxt2: 'メールボックスの検証',
        gridTxt3: 'ID認証',
        listTxt: '銀行カード',
        listTxt1: 'キャッシュ・アドレス管理'
    },
    bindBank: {
        ///銀行カード
        title: 'プリペイドカード',
        addCard: '銀行カードの追加',
        editCard: '検証資金パスワードの変更',
        ts1: 'まだ認証されていません,',
        ts2: 'まず本人認証を行ってください',
        addTitle: '銀行カードの追加',
        userName: '実名',
        tips1: '実名を入力してください',
        CollectionDetailArea: '受取人詳細住所',
        tips6: '受取人の詳細な住所を入力してください',
        userPhone: '予約済み携帯電話番号',
        tips2: '予約携帯電話番号を入力してください',
        bankName: '口座名',
        tips3: '口座開設名を入力してください',
        bankAds: '口座開設住所',
        tips5: '口座番号を入力してください',
        bankId: '入金アカウント',
        tips4: '入金アカウントを入力してください',
        CountryCode: '国コード',
        tips7: '国コードを選択してください',
        tips8: 'SWIFT Codeを入力してください',
        tips9: '金額を入力してください',
        tips10: 'チャージまたは引き出しの金額は 100 未満にすることはできません',
        addTxt: '保存'
    },
    bindMail: {
        //バインディングメールボックス
        title: 'バインディングメールボックス',
        placeholder1: 'メールアドレスを入力してください',
        ptitle: '携帯電話の修正',
        placeholder2: '新しい携帯電話番号を入力してください',
        next: '下壱歩',
        zjmm: '資金パスワード',
        placeholder3: '資金調達のパスワードを入力してください'
    },
    setting: {
        //設定
        title: '設定',
        setTxt: 'アバター',
        setTxt1: '言語',
        setTxt2: '為替レート',
        setTxt3: '私たちについて'
    },
    withPass: {
        //資金のパスワードを設定する
        title: '資金調達パスワードの設定',
        title1: '資金パスワードはログインパスワードと同じにすることはできません',
        //バインディングメールボックス
        aqyz: 'セキュリティ認証',
        aqyzm: 'ベリファイ',

        jmm: '古いパスワード',
        xmm: '新しいパスワード',
        qrmm: 'パスワードの確認',
        placeholder: '古いパスワード',
        placeholder1: '新しいパスワードを設定',
        placeholder2: '新しいパスワードを再入力してください',
        qrxf: '確認',
        tips: '6桁以上の古いパスワードを入力してください',
        tips1: '6桁以上の新しいパスワードを入力してください',
        tips2: '正しい確認パスワードを入力してください',
        tips3: '新しいパスワードを二度入力しないでください',
        tips4: 'パスワードの設定に成功しました',
        confirm: '確認'
    },
    newBe: {
        title: '新貨申込み',
        title1: '私の申し込み',
        scqx: 'ロック期間',
        day: '天',
        sy: '残り',
        sdsg: 'レコード',
        sysl: '残り数',
        gmsl: '購入数量',
        dj: '単価',
        tips: '購入数量を入力してください',
        gmzj: '購入総額',
        qbye: 'ウォレット残高',
        fmzj: '購入総額',
        gm: '購入',
        sgsj: '購入時期',
        Currency: '通貨',
        Request: '申し込み数',
        Passing: '通過数',
        MarketTime: '発売日',
        sqz: '申し込み中'
    },
    register: {
        title: '登録',
        lang: '言語',
        send: '送信',
        Resend: '再送',
        Account: 'アカウント',
        Password: 'パスワード',
        ConfirmPassword: 'パスワードの確認',
        InvitationCode: '招待コード',
        btn: '登録',
        amount: 'アカウント',
        mailbox: 'メールボックス',
        haveAccount: 'アカウントはすでに存在しますか？',
        haveLogin: '今すぐログイン',
        Login: 'ログイン',
        placeholder: 'メールボックスを入力してください',
        placeholder0: '携帯電話番号を入力してください',
        placeholder1: 'パスワードを入力してください',
        placeholder2: '確認パスワードを入力してください',
        placeholder3: '招待コードを入力してください',
        placeholder4: '認証コードを入力してください',
        placeholder5: '正しいメールボックスを入力してください',
        success: '登録成功',
        message: '6桁以上のパスワード',
        message1: '6桁以上のパスワードを確認',
        message2: '2回のパスワードの不一致',
        message3: 'プロトコルを読んでチェックしてください',
        agree: '同意します',
        xy: 'ユーザー契約およびプライバシーポリシー',
        qr: '今すぐ登録',
        //パスワードを取り戻す
        ftitle: 'パスワードを取り戻す',
        qrzh: '確認',
        placeholder7: '新しいパスワードを入力してください',
        placeholder6: '新しいパスワードを確認してください',
        placeholder8: 'グラフィック認証コードを入力してください',
        placeholderName: 'ユーザー名を入力してください'
    },
    lockming: {
        title: 'ロックリスト',
        wkb: '採掘宝',
        wkgjd: 'マイニングが簡単',
        qsljl: '楽領奨励',
        yjzsr: '予想される総収益',
        ck: '近3日参考年化',
        tips: '少なくとも',
        rlx: '日歩',
        day: '天',
        scqx: 'ロック期間',
        buy: '購入',
        zysl: '担保数',
        qbye: 'ウォレット残高',
        finished: '完了',
        doing: '進行中...',
        sl: '数量',
        shouyilv: '収益率',
        ks: '開始時刻',
        js: '終了時間'
    },
    login: {
        title: 'アカウントログイン',
        lang: '言語',
        mobile: '携帯電話番号',
        mailbox: 'メールボックス',
        Account: 'アカウント',
        Password: 'パスワード',
        btn: 'ログイン',
        placeholder1: 'パスワードを入力してください',
        placeholder: 'アカウントを入力してください',
        fail: 'フォームに記入してください',
        ForgotPassword: 'パスワードをお忘れですか。',
        NoAccount: 'アカウントはまだありませんか。',
        Registration: '今すぐ登録',
        server: 'オンラインカスタマーサービス',
        jzmm: 'パスワードを記憶する',
        success: 'ログイン成功',
        Home: 'トップページ',
        userbox: 'ユーザー名'
    },
    moneyAddr: {
        title: 'ウォレットアドレス',
        Currency: '通貨',
        Address: 'アドレス',
        placeholder: '選択してください',
        DepositAddress: 'チャージアドレス',
        WalletAddress: 'ウォレットアドレス',
        Date: '時間',
        //ウォレットアドレスを追加
        add: 'ウォレットアドレス',
        addTxt: 'チェーンアドレスごとに最大追加',
        remark: 'コメント',
        Walletremark: 'このアドレスのコメント情報を追加してください',
        network: 'マスターネットワーク(プロトコル)',
        networkXy1: '選択したネットワークは',
        networkXy2: ',アドレスに間違いがないことを確認してください。選択したブロックチェーンが本資産を取得できない場合は、資産を失うことになります。',

        ChainAddress: 'チェーンアドレス',
        qrcode: 'ウォレットQRコード',
        submit: '確認',
        tips: 'ウォレットタイプを選択してください',
        tips1: 'チェーンアドレスタイプを選択してください',
        tips2: 'ウォレットのアドレスを入力してください',
        tips3: 'お財布のQRコードをアップロードしてください',
        success: '成功'
    },
    recarge: {
        title: 'チャージ',
        title1: 'チャージ明細',
        ls: 'チャージ明細',
        td: '次のチャージチャネルを選択してください',
        bc: 'QRコードを保存',
        address: 'チャージアドレス',
        copy: 'アドレスのコピー',
        num: 'チャージ枚数',
        Upload: '支払い詳細のスクリーンショットをアップロード',
        Submit: '確認',
        tip: '数量を入力してください',
        tip1: 'スクリーンショットをアップロードしてください',
        copySuc: 'コピー成功'
    }
}