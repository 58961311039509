module.exports = {
    pcregister: {
        p1: '이미 계정이 있다?',
        dl: '로그인',
        zc: '등록',
        p4: '계정',
        p5: '계정을 입력하십시오',
        p6: '암호를 입력하십시오',
        p7: '암호를 입력하십시오',
        p8: '초대 코드를 입력하십시오',
        p9: '동의합니다',
        p10: '"사용자 계약 및 개인 정보 보호 정책"',
        p11: '우편함',
        p12: '우편함을 입력하십시오',
        p13: '사서함 인증 번호를 입력하십시오',
        p14: '보내기',
        p15: '연도',
        p16: '월',
        p17: '일',
        p18: '시간',
        p19: '분 시간',
        p20: '초'
    },
    pclogin: {
        l1: '아이디가 없다?',
        l2: '등록',
        l3: '로그인',
        l4: '사용자 이름을 입력하십시오',
        l5: '암호를 입력하십시오',
        l6: '비밀번호 분실',
        l7: '로그인'
    },
    pccontract: {
        ss: '시가',
        xj: '가격 제한',
        gg: '조이스틱'
    },
    pcrapidly: {
        zf: '상승 폭',
        dd: '주문',
        bdsj: '현지 시간',
        ky: '사용 가능'
    },
    pchome: {
        global1: '디지털 자산에 대한 세계 최고의 거래',
        global2: '100개 이상의 국가에서 수백만 명의 사용자에게 안전하고 신뢰할 수 있는 디지털 자산 거래 및 자산 관리 서비스를 제공한다. ',
        global3: '안전하고 신뢰할 수 있는',
        global4: '디지털 자산 금융 서비스 5년간의 경험. 디도스 공격에 대비한 분산 아키텍처와 항디도스 시스템 ',
        global5: '글로벌 생태계',
        global6: '이미 여러 국가에 현지화 거래 서비스 센터를 설립하여 다양한 업태를 융합하는 블록체인 생태계를 조성하고 있다. ',
        global7: '사용자 우선',
        global8: '선행 보상 메커니즘을 구축하고 투자자 보호 기금을 설립한다. ',
        whever1: '언제 어디서나 여러 평평한 단말기 거래',
        whever2: 'iOS, Android, Windows 여러 평판을 커버하며 모든 업무 기능을 지원합니다. ',
        register1: '등록',
        register3: '글로벌 계정 거래 시작',
        register2: '지금 등록',
        commodity: '물품',
        news: '뉴스',
        ttop1: 'Pinnacle의 신비를 발견하다',
        ttop2: 'Pinnacle는 선물 및 옵션 계약 농업 에너지 통화 금속 지수 등을 거래하는 국제 거래소입니다.설립 이후 거래소는 거래 규칙을 지속적으로 개선해 왔고 다양한 상품의 거래량이 증가하고 있다.이 회사는 30개국 이상의 소매 및 기관 고객에게 서비스를 제공하기 위해 성공적으로 확장했으며 여전히 성장하고 있습니다.우리는 거래 비용을 가능한 낮게 유지하고 고객이 단순히 수익성 있는 고객이 아닌 수익성 있는 트레이더가 될 수 있도록 돕는 협업 정신으로 서비스를 제공하기 위해 노력합니다.결국 네가 잘하면 우리도 잘한다.',
        ttop3: '지금 거래를 시작하세요',
        product1: '빠르고 신뢰할 수 있는 주문 실행',
        product2: '고급 분석 도구 Advanced analytical tools',
        product3: '실시간 따옴표',
        product4: '빠르고 안전하게 인출할 수 있습니다',
        trade1: '똑똑',
        trade2: '전략을 실행하라',
        trade3: '실제 거래 계좌를 개설하여 직관적인 거래 플랫폼을 탐색하고 기술을 향상하기 전에 무료 데모 계정을 사용해 보십시오.',
        trade4: '지식을 넓히다',
        trade5: '당사의 종합 교육 자료를 사용하여 Pinnacle 와의 CFDs 거래에 대해 알아보십시오.',
        trade6: '리스크 관리 Manage your risk',
        trade7: '당사의 고급 위험 관리 도구를 사용하여 손실을 제한하고 수익을 고정하십시오.',
        allselect1: '다들 왜 Pinnacle를 선택하나요?',
        allselect2: '보호 및 보안 Protected & secure',
        allselect3: '귀하의 데이터는 안전하고 자금은 규제 요건에 따라 분리된 은행 계좌에 보관됩니다.',
        allselect4: '전문적인 지원',
        allselect5: '다국어로 24시간 전담 고객 서비스를 받으세요.',
        allselect6: '규제',
        allselect7: '다양한 글로벌 선도 규제 기관에 의해 허가 및 규제됩니다.',
        allselect8: '신뢰 할 수 있는',
        allselect9: 'Pinnacle Ltd는 FTSE 250회사로서 캐나다 증권거래소 주식시장에 상장되어 있다.',
        down1: '모바일 단말기의 휴대폰 모델이면 누구나 조작 및 사용을 지원할 수 있습니다',
        down2: '플랫폼 앱',
    },
    pcbottom: {
        box1: 'World Lead',
        box2: '최고의 팀 중 하나',
        box3: '자세히',
        box4: '우리에게',
        box5: '백서',
        box6: '서비스',
        box7: '다운로드',
        box8: '자주 묻는 질문',
        box9: '온라인 고객센터',
        box10: '규정',
        box11: '사용자 프로토콜',
        box12: '개인 정보 보호 정책',
        box13: '법적 고지 사항',
        box14: '연락'
    },
    pcAbout_us: {
        t1: '차트',
        t2: '우리, 7년의 길',
        t3: '2015년부터 가장 중요한 일이라고 할 수 있는 블록체인 서비스, 전문 기술, 채널 구축 서비스에 몰두하고 있다. ',
        t4: '2014년-2017년은 ‘안정적인’ 상향기를 거쳤고, 2017년-2018년은 ‘돈사서 부자되기’의 광란기였는데, 2019년하반기부터 오늘까지 한겨울 동안 나는 진짜 솜씨를 익혔다. 무수히 많은 성공적인 파트너들은, 저조한 우리들을 업계에서 높은 평판을 갖게 한다. 2022년에 우리는 새로운 평대V3.0을 소유하고 새로운 주식회사 BitGoldEX를 설립하여 전 세계 100여개국에 사업을 전개할 것입니다. ',
        t5: '우리는 누구를 찾고 있는가',
        t6: '설립부터 오늘날까지',
        t7: '어려움을 겪고 자신과 마음이 맞는 서비스 업체를 찾고, 몇 년 동안 수십 명의 서비스 업체를 위해 성공적으로 문제를 해결했다.',
        t8: '이성적인 오늘에, 만약 당신이 좋은 조력자를 찾고 싶다면, 자신의 계속 전진을 실현할 수 있는 파트너-어쩌면, 우리와 이야기를 나눌 수 있다. ',
        t9: '믿어주세요: 당신이 찾고 있는 사람, 사실 당신을 찾고 있습니다. ',
        t10: '서비스',
        t11: '실무자가 없어도, 마찬가지로 업무가 있다',
        t12: '친구가 당신을 위해 사업을 소개해 주는 것은 당신이 사람으로서 괜찮다는 것을 의미합니다. 동업자가 너를 위해 사업을 소개한다는 것은 너의 평판이 괜찮다는 것을 의미한다. 고객이 너를 위해 장사를 소개할 때, 그것은 네가 믿을 만하다는 것을 증명한다. ',
        t13: '정신',
        t14: '우리는 벽돌이다. 어디가 필요한가',
        t15: '다른 고객, 다른 요구 사항. 다른 제품, 특성이 다릅니다. 서로 다른 시기, 목표가 다르다. 우리의 존재의 의미는 서로 다른 서비스 업체의 이념, 제도, 요구에 부응하고 다양한 문제를 해결하는 것이다. ',
        t16: '우리는 생각이 있고 작용이 있는 벽돌을 만들고 싶고, 필요한 곳이 있으면 어디든 갈 것이다. '
    },
    pcproblem: {
        p1: '자주 묻는 질문',
        p2: '변환된 자산의 양이 어떻게 변경됩니까?',
        p3: '자산에서의 등가 계산은 현재 보유하고 있는 디지털 화폐를 USD로 환산한 가치로, 디지털 자산의 가격 변동에 따라 변한다. 귀하의 디지털 자산의 양은 변함이 없습니다. ',
        p4: '잠금 거래 비밀번호를 잊어버린 경우',
        p5: '지평선 거래 자금 비밀번호를 잊어버린 경우, "내-설정-자금 비밀번호 설정 클릭"이나 고객센터에 연락하여 재설정하십시오.',
        p6: '거래 매수 후 자금 흐름은 어디로?',
        p7: '구매 발주가 완료되면 자금은 자산의 법화 계좌로 들어갑니다. 만약 자산을 구매하여 계약거래 또는 선물거래를 진행해야 한다면, 자금 이체 모듈에 가서 이체를 진행하십시오. ',
        p8: '왜 닉네임 변경 횟수를 제한하는가',
        p9: '귀하의 거래에 대한 보안상, 저희는 현재 닉네임을 한번만 수정할 수 있도록 제한하고 있습니다. ',
        p10: '전이할 때 무슨 뜻인가? 왜 이동이 필요한지',
        p11: '귀하의 계좌간 자금의 독립성을 보장하고, 귀하가 위험을 합리적으로 통제할 수 있도록 하기 위하여, 우리는 이에 각 주요 거래 모듈의 계좌를 구분합니다. 계좌이체는 거래 계좌 간에 자산을 전환하는 과정을 가리킨다. ',
        p12: '이용자 계약의 계정 권익은 어떻게 계산하나?',
        p13: '계약 계좌 순 가치 = 포지션 보증금 + 포지션 변동 손익 + 당좌 계좌 사용 가능 금액',
        p14: '창고를 열 때 손상 방지 및 차감 설정의 값은 얼마인가? 어떻게 설정해야 하나요?',
        p15: '손익으로 당신이 설정한 손익의 상한선입니다. 차감 및 손실이 설정되어 있는 금액에 도달하면 시스템은 자동으로 매각을 정리한다. 계약서를 살 때 사용할 수 있는 위험관리 검정고의 절반을 양 x 양 x 조이스틱 배수를 늘리고, 검정고를 설정합니다. 우리는 당신이 자신의 실제 자산 상황에 근거하여 설정을 하고 위험을 합리적으로 통제할 것을 건의합니다. ',
        p16: '어떻게 계약 위험을 줄일 수 있는가?',
        p17: '잔여 계좌의 가용 자산을 계약 계좌로 이체하거나, 보유량을 줄임으로써 위험을 줄일 수 있다',
        p18: '계약개장의 물량이란 무엇인가?',
        p19: '미결산 포지션의 수량은 당신이 구매하고자 하는 화폐의 수량을 나타낸다. 예: BTC/USD 거래 쌍의 시작 페이지에서 선택, 매입/다수를 선택하고 가격을 1000USD로 입력하고 수량을 10BTC로 입력합니다. 즉, 10BTC를 매입하고 단가를 1000USDT로 예상합니다. ',
        p20: '계약서 상의 수수료는 어떻게 계산되나?',
        p21: '수수료 = 개설 가격 * 개설 수량 * 수수료율',
        p22: '강제 평창 등록',
        p23: '위험도는 자산의 위험을 측정하는 지표입니다. 위험도가 100%일 경우, 고객님의 보유 지점은 마진으로 간주됩니다. 위험도 = (보유보증금/계약계좌 자기자본) * 100%입니다. 사용자의 보유를 방지하기 위해 시스템 설정은 위험도 비율을 조정합니다. 위험 수준이 시스템이 설정한 위험 값에 도달하면 시스템은 강제로 바닥을 정리하게 된다. 예를 들어, 10%의 조정 비율로 시스템을 설정하면 90%보다 위험도가 더 크면 모든 창고가 강제로 정리됩니다. ',
        p24: '계약거래규칙은 무엇인가?',
        p25: '거래 유형',
        p26: '거래 유형은 복수 (매수)와 공매 (매도)의 두 방향으로 나뉜다.',
        p27: '복수매수(상승추세) 는 현재 지수가 상승할 가능성이 있다고 판단하고 일정 수량을 매수하여 당신이 설정한 가격 또는 현재 시장가격으로 계약을 체결하고자 하는 것을 의미합니다. ',
        p28: '공매도(하락세)란 현재 지수가 하락할 수 있다고 판단하고 당신이 설정한 가격이나 현재 시장가격에 일정 수량을 매도하고자 하는 새로운 계약을 말한다. ',
        p29: '주문 방식',
        p30: '가격 제한 주문: 주문할 가격과 수량을 지정해야 합니다.',
        p31: '시가 명세서: 주문 수량만 설정하면 됩니다. 가격은 현재 시가 가격입니다',
        p32: '보유',
        p33: '창고 개설 지시가 완료되면 이를 보유라고 부른다.',
        p34: '계약 납부 문제',
        p35: '평대계약은 영속계약이므로 인도시간을 두지 않는다. 시스템이 고정 평준화 조건을 만족하지 않거나 수동으로 평준화하지 않는 한 영구적으로 보유할 수 있습니다. 시스템 스플라인 ',
        p36: '1: 차감 손실 설정, 시스템 자동 마스킹',
        p37: '2: 위험, 시스템 강제 정리',
        p38: '계약거래의 보증금은 얼마인가?',
        p39: '계약거래에서 이용자는 계약가격과 수량, 그에 상응하는 베스킷의 배수에 따라 계약매매에 참여할 수 있다. 사용자는 창고를 개설할 때 보증금을 차지할 것이다. 창고 개설 보증금이 많을수록 계좌의 위험은 높아진다. ',
        p40: '가격제한표와 시가표란 무엇인가?',
        p41: '가격제한표는 당신이 어떤 가격으로 평당에 위탁하여 거래를 진행하기를 기대하는지를 가리키며, 시가표는 직접 평당에 위탁하여 현재 가격으로 거래를 진행하는 것을 가리킨다. 창고 개설 규칙에서, 시가표는 가격제한표보다 우선한다. 만약 당신이 가격제한서를 선택한다면, 화폐의 현재 시장가격에 따라 합리적으로 창고를 개설하여, 창고 개설 가격이 불합리하여 손실을 초래하지 않도록 하십시오. ',
        p42: '계약거래의 위험등급은 무엇을 나타내는가?',
        p43: '위험도는 귀하의 계약 계좌에 있는 위험의 지표입니다. 위험도 100%는 청산으로 간주된다. 우리는 당신의 위험이 50%를 넘을 때 당신은 신중하게 창고를 열어야 하고, 창고 정리로 인한 손실을 피해야 한다고 조언합니다. 계약 자산의 가용 자금을 보충하거나 창고를 줄임으로써 위험을 줄일 수 있다. ',
        p44: '무엇을 위해 환전이 필요한가?',
        p45: '거래소의 목적은 귀하의 자산에서 다른 통화의 자금이 원활하게 유통되도록 하는 것이며, 선물계좌에서 얻은 QCC는 자유롭게 USD로 교환하여 거래할 수 있습니다. 다른 계좌에 있는 USD도 QCC로 자유롭게 교환하여 거래할 수 있다. ',
        p46: '왜 이체를 했더니 상대방이 제때 이체를 받지 못했나?',
        p47: '먼저, 계좌이체 시 주문 상세 페이지에 표시된 결제 방식과 정확히 같은 결제 방식을 기입해 주시기 바랍니다. 그리고 당신의 지불 방식이 즉시 지불인지 아니면 지연 지불인지 확인하세요. 마지막으로, 시스템 유지 관리 또는 기타 사유로 은행 및 결제 기관에 문의하십시오. ',
        p48: '평판을 통해 USD를 구입하려면 어떻게 해야 하는가?',
        p49: '방법 1: 매수 명세서를 통해 당신이 매수할 주문을 선택하여 매매한다.',
        p50: '방법 2: 게시 버튼을 클릭하시면 원하는 대로 구매 또는 매도 청구서를 게시하여 빠른 거래를 하실 수 있습니다. 이 시스템은 고객과 거래하는 사용자를 빠르게 맞출 것이다. ',
        p51: '등록: 구매 주문서가 15분까지 지불되지 않으면 자동으로 취소됩니다. 즉시 지불하십시오. 당일 취소주문이 3회를 초과하면 당일 다시 거래할 수 없고 다음날 거래권한을 회복합니다. '
    },
    public: {
        zxkf: '온라인 고객센터',
        sure: '확인',
        cancel: '취소',
        RefreshSucceeded: '새로 고침 성공',
        noMoreData: '더 이상 데이터가 없다',
        pullingText: '드롭다운하면 새로 고침...',
        ReleaseText: '해제하면 새로 고침...',
        loadingText: '로드 중...',
        noData: '레코드 없음',
        xq: '상세정보',
        submit: '확인',
        kefu: '온라인 고객센터',
        amount: '금액',
        startInvestment: '창고 지점',
        endInvestment: '끝 지점',
        profit: '이익'
    },
    ChangeType: {
        Upper: '시스템 충전',
        Lower: '시스템 현금 인출',
        Win: '수익',
        Bet: '투자',
        BuyMining: '채굴',
        CoinWin: '신권 수익',
        SettleMining: '채굴 원금 반환',
        GiveInterest: '채굴 수익',
        BuyCoin: '새 지폐는 당첨되지 않았습니다',
        BuyCoinBack: '새 지폐 요청서 반환',
        UsdtToBtc: 'Usd에서 Btc로',
        UsdtToEth: 'Usd에서 Eth로',
        BtcToUsdt: 'Btc에서 Usd로',
        BtcToEth: 'Btc에서 Eth로',
        EthToUsdt: 'Eth에서 Usd',
        EthToBtc: 'Eth에서 Btc로',
        CoinFrozen: '신권 동결 당첨',
        CoinThaw: '신권 해동',
        CoinCancel: '통화 주문 취소',
        CoinBuy: '코인 매입',
        CoinBuyEntrust: '코인 매입 의뢰',
        CoinSellEntrust: '코인 판매 의뢰',
        CoinSell: '코인 세일즈',
        ContractBuy: '계약 주문, 보증금 공제',
        ContractCharge: '계약 주문, 수수료 공제',
        ContractCancelToBond: '계약 해지 보증금 반환',
        ContractCancelToCharge: '계약 해지 환불 수수료',
        ContractCloseProfit: '계약 마감 이익'
    },
    trade: {
        title: '화폐 화폐',
        Buy: '매입',
        Sell: '판매',
        pc: '플랫',
        cb: '비용',
        xj1: '현재 가격',
        yk: '손익',
        xianjia: '가격제한',
        jiage: '가격',
        shuliang: '수량',
        keyong: '사용 가능',
        yonghuchicang: '사용자 보유',
        fxlts: '위험률 = 보유 보증금/고객 자기자본 곱하기 100%, 여기서 고객 자기자본은 가용 자금 + 초기 거래 보증금 + 변동 손익',
        fxl: '위험률',
        dqwt: '현재 위임',
        all: '모두',
        name: '이름',
        shijian: '시간',
        zyjg: '현재 최적 가격으로 거래',
        caozuo: '작업',
        xj: '가격 제한',
        cc: '보유',
        leixing: '유형',
        long: '매입/많이 하기',
        short: '팔기/비우기',
        sj: '시가',
        jiaoyie: '거래액',
        orderNo: '주문 번호',
        weituojia: '위탁가격',
        chengjiaojia: '거래가',
        chengjiaoe: '거래액',
        weituo: '위임 기준',
        qbwt: '모두 위임',
        lswt: '역사에 대한 의뢰',
        tips: '정확한 가격을 입력하십시오',
        tips1: '수량을 입력하십시오',
        Create: '위임 중',
        Wait: '거래 중',
        Complete: '마스킹',
        Cancel: '철회 완료',
        zsj: '손상 방지 가격',
        zyj: '감지 가격',
        cczsy: '총 소유 이익',
        ykbl: '손익비율',
        빌리: '축척',
        jyss: '거래 수',
        bzj: '보증금',
        sxf: '수수료',
        gg: '조이스틱 배수',
        yjpc: '원클릭 플랫'
    },
    auth: {
        title: 'ID 인증',
        title1: 'ID 인증',
        low: '기본 인증',
        high: '고급 인증',
        success: '인증 성공',
        tips: '본인인증부터 해주세요',
        tips1: '실제 이름을 입력하십시오',
        tips2: '증명서 번호를 입력하십시오',
        tips3: '증명서 앞면 사진을 올려주세요.',
        tips4: '증명서 뒷면 사진을 올려주세요.',
        tips5: '들고 있는 사진을 올려주세요.',
        tips6: '실명 인증 유형을 선택하십시오',
        qrz: '인증하기',
        shz: '평가 중',
        rzsb: '인증 실패',
        userName: '실제 이름',
        IDNumber: '증명서',
        zmz: '증명서 앞면 사진',
        bmz: '증명서 뒷면 사진',
        scz: '손에 든 사진',
        IDCard: 'ID',
        Passport: '여권',
        AuthenticationType: '실명 인증 유형',
        HighAuth: '고급 인증',
        gj: '국적',
        tipsgj: '국적을 입력하십시오',
    },
    share: {
        title: '내 홍보 코드',
        cp: '주소 복사',
        success: '복사 성공'
    },
    withdrawal: {
        Wallet: '지갑 이체',
        Internal: '내부 이체',
        Currency: '통화',
        Main: '기본 네트워크(프로토콜)',
        WithdrawAddress: '현금 인출 주소',
        Frequently: '공통 주소',
        placeholder: 'USD 현금 인출 주소',
        note: '거래 준비 상태',
        placeholder1: '거래 준비 기간을 입력하십시오.',
        Amount: '현금 인출 수량',
        placeholder2: '최소 현금 인출 단위는 10입니다',
        All: '모두',
        Balance: '계정 잔액',
        Fee: '현금 인출 수수료',
        FundPassword: '자금 암호',
        placeholder3: '자금 암호를 입력하십시오',
        forget: '자금 비밀번호를 잊어버렸나요?',
        OTP: '인증 코드',
        placeholder4: '인증 번호를 입력하십시오',
        send: '보내기',
        Resend: '다시 보내기',
        Reminder: '훈훈한 힌트',
        Reminder1: '현금 인출 처리 기간 월-일요일, 9:00-22:00(UTC+8)',
        Reminder2: '최대 인출 가능 잔액 = 계좌 잔액, 인출 수수료 포함 현금 인출 금액',
        Reminder3: '현금 인출을 신청하기 전에 인출 주소가 맞는지 확인하세요. 거래가 블록체인 네트워크로 전송되면 철회할 수 없습니다.',
        Confirm: '현금 인출 확정',
        noPassword: '자금 암호가 설정되지 않음',
        Fees: '수수료',
        Free: '무료',
        Transfer: '이체 수',
        Limitation: '일일 이체 한도',
        placeholder6: '단일 이체 수량 0-100,000',
        Available: '현금 인출 수수료',
        Receiving: '수신 계좌',
        placeholder5: '닉네임을 입력하십시오',
        TransferBtn: '이체',
        Reminder4: '훈훈한 팁: 계좌이체 시 사용자 정보를 꼭 확인하세요',
        Reminder5: '내부 이체 처리 기간 09:00-22:00 (UTC+8)',

        title: '세부 정보',
        title1: '현금 계좌',
        title2: '계약 계정',
        title3: '자금',
        title4: '충전',
        title5: '현금 인출',
        Rate: '환율',
        allRate: '결제',
        PlaceOrder: '주문',
        Freeze: '동결',
        Thaw: '해제',
        backgroundProgram: '뒤쪽',
        AuditReject: '현금 인출평가 거부',
        OrderSettlement: '주문 마감',
        AccountBalance: '계정 잔액:',
        CancelOrder: '주문 취소',
        Created: '만들었습니다',
        Paid: '지불됨',
        UnAuthed: '심의를 기다리다',
        Accepted: '통과됨',
        Canceled: '주문 취소',
        Refuce: '거부',
        placeholderMC: '매도 성공',
        read: '읽은',
        unread: '읽지 않은',
        chat: '채팅',
    },
    newRecharge: {
        Deposit: '디지털 화폐',
        Credit: '신용 카드',
        Wallet: '지갑에 돈을 충전하고, 다음 번에는 돈 봉투를 주세요.',
        FAQ: '충전 안내',
        Currency: '통화 선택',
        placeholder: '통화를 선택하십시오',
        Main: '기본 네트워크(프로토콜)',
        DepositAds: '주머니를 채우는 주소',
        Copy: '주소 복사',
        remark: '• 위 주소에 아무 것도 입력하지 마십시오',
        remark1: 'USD',
        remark2: '자산, 그렇지 않으면 자산을 되찾을 수 없다; ',
        remark3: '• 상기 주소로 충전 후 전체 네트워크 노드 확인, 6개 네트워크 확인 후 입금 가능; ',
        remark4: '• 1펜당 15USD 이하로 충전할 수 없습니다. 그렇지 않으면 충전되지 않습니다; ',
        remark5: '• 매번 충전하기 전에 이 페이지에 가서 주소를 복사해야 합니다. 주소가 정확한지 확인해야 합니다. 잘못된 주소로 인해 충전이 실패할 수 있습니다.',
        Pay: '지불',
        Buy: '구매',
        Merchant: '평층 인증 사업가',
        remark6: '*보기 클릭',
        remark7: '등록 정보',
        remark8: ',현재 일치하는 상인이 없다면, 웹 링크를 확인한 후 새로 고침을 시도하거나 연락해 주십시오.',
        remark9: '온라인 고객센터',
        ChipPay: '칩페이 충전 거래',
        Purchasebyamount: '금액으로 구매하기',
        AccountBalance: '계정 잔액',
        Tips: '훈훈한 힌트'
    },
    ChipPay: {
        copySUC: '복제 성공',
        copyERR: '복제 실패',
        Buy: '구매',
        TOBECONFIRMED: '확인 대기',
        TransactionCompletion: '딜 완료',
        OrderingInformation: '주문 정보',
        oddNumbers: '단일 번호:',
        TransferAmount: '이체 금액:',
        ApproximatelyQuantity: '약 수량:',
        UnitPrice: '단가:',
        remark1: '입금 완료 버튼을 클릭하지 않거나 이체 금액이 일치하지 않으면 입금이 지연될 수 있으며, 본인 계좌가 아닌 다른 계좌로 이체 및 입금 준비/설명을 하면 거래가 실패하여 환불될 수 있습니다.',
        remark2: '훈훈한 힌트',
        remark3: '각 주문 수금 정보는 한 번만 유효합니다. "2차 이체를 위해 정보를 저장하지 마십시오." ',
        remark4: '"주문서를 여러 번 생성하면 지불을 하지 않으면 다시 주문할 수 없습니다. "주의하십시오. ',
        remark5: '당신이 규정된 시간 내에 송금을 완료한 후, 반드시 ‘나는 이미 송금을 완료했다’ 버튼을 클릭해서 즉시 입금할 수 있도록 하십시오. ',
        remark6: '당사자 분배 실패’라는 메시지가 표시되면 현재 거래량이 너무 많아서 당사자가 바쁩니다. 5 - 10분 후에 주문을 다시 실행하십시오. ',
        remark7: '귀하의 송금은 거래 상대방의 은행 계좌로 직접 이체되며, 거래 과정에서 거래 상대방이 판매한 디지털 자산은 관리하에 보호되므로 안심하고 거래하시기 바랍니다. ',
        cancellation: '주문 취소',
        remittance: '송금을 완료했습니다.',
        remark8: '거래의 주의사항',
        remark9: '칩페이 언더웨어에 있는 USD 소유자와 디지털 화폐를 거래합니다. 언더웨어는 거래 과정에서 USD 자산의 진실성을 보증합니다. 거래를 시작하기 전에 다음 조항을 읽고 동의하십시오.',
        remark10: 'USD는 ChipPay를 위해 발행되지 않으며, 법률이 없다는 것을 알고 계십시오.',
        remark11: '귀하는 평판 내의 USD 소유자와 거래할 때 돈세탁 방지 성명서의 내용을 준수해야 한다',
        remark12: '디지털 화폐 거래의 위험성을 알고 있는 위험 선언문을 읽어 보시고 동의하십시오.',
        remark13: '거래의 위험을 알고 있으며 서비스 약관에 동의합니다',
        remark14: '입금 완료 버튼을 클릭하지 않거나 이체 금액이 일치하지 않으면 입금이 지연될 수 있으며, 본인 계좌가 아닌 다른 계좌로 이체 및 입금 준비/설명을 하면 거래 실패 및 환불이 이루어질 수 있습니다.',
        remark15: '1. 주문한 금액과 송금한 금액이 일치하는지 확인하십시오.‘ 만약 송금하지 않으면 이 버튼을 클릭하면 블랙리스트에 오를 수 있습니다.',
        ConfirmedRemittance: '송금 확인',
        ConfirmRemittance: '송금 확인',
        WrongPoint: '잘못 찍었습니다',
        Ialreadyknow: '나는 이미 알고 있다',
        payattentionto: '오시옵소서',
        agree: '동의',
        Bank: '수금 은행:',
        ReceivingAccount: '수금 계좌:',
        Party: '당사자:',
        Authenticated: '실제 이름',
        Margin: '보증금',
        tips1: '거래 위험에 대해 잘 알고 있다고 체크해 주세요.'
    },
    newProperty: {
        title: '총 자산(USD)',
        Spot: '사용 가능',
        Contract: '사용 가능한 계약',
        Bonus: '돈 봉투',
        Purchase: '충전',
        Deposit: '동전 충전',
        Withdraw: '현금 인출',
        Transfer: '돌림',
        Spot1: '현금 자산',
        Contract1: '계약 자산',
        Commission1: '커미션 자산',
        Financial1: '자산 관리',
        Balance: '총 재고자산',
        Hide: '0 자산 숨기기'
    },
    property: {
        Contract: '통화 통화',
        Exchange: '초 계약',
        zzc: '총 자산 환산',
        mhyzh: '초계약계정',
        bbzh: '코인 통장',
        available: '사용 가능',
        OnOrders: '동결',
        Estimated: '환산',
        cw: '재무 기록',
        number: '개수',
        record: '기록',
        Transfer: '돌림',
        time: '시간',
        dhsl: '변환 수량',
        tips: '변환 수량을 입력하십시오',
        qbdh: '모두 변환',
        dhhl: '환율',
        ky: '사용 가능',
        kd: '이용 가능',
        sxfbl: '수수료 비율',
        sxf: '수수료'
    },
    statusLang: {
        Review: '평가',
        Success: '성공',
        Fail: '실패'
    },
    with: {
        title: '현금 인출',
        history: '현금 인출 레코드',
        ldz: '체인 주소',
        tbdz: '통화 주소',
        tips: '주화 주소를 입력하십시오',
        tips1: '통화 수량을 입력하십시오',
        tips2: '결제 암호를 입력하십시오',
        tbsl: '통화 수량',
        ky: '사용 가능',
        zjmm: '암호 지불',
        sxf: '수수료',
        dz: '예상 입금 수량'
    },
    tabBar: {
        home: '첫 페이지',
        Quotation: '시세',
        Quotation2: '시장 시세',
        Trade: '화폐 화폐',
        Contract: '초 계약',
        newContract: '거래',
        assets: '자산',
        qhyy: '언어 전환',
        zyjb: '창고 채굴',
        jsjy: '익스트림 딜',
        jryw: '금융 사업',
        hyjy: '계약 거래',
        Frequently: '일반',
        mine: '나'
    },
    exchange: {
        //플래시백
        title: '플래시',
        canChange: '변환 수량',
        canChange1: '바꿀 수량을 입력하십시오',
        title1: '변환 명세서',
        Available: '사용 가능 한도',
        Proposed: '참조 가격',
        Redeem: '전체 변환',
        Confirm: '확인',
        CurrencyBalance: '통화 잔액'
    },
    home: {
        //그리드
        gridcz: '법폐 충전',
        gridsd: '법폐 현금 인출',
        gridxs: '초보자의 필독',
        gridbd: '차트',
        Balance: '잔액',

        UserName: '계정',
        kjmb: '충전',
        score: '신용 점수',
        zc: 'BTC, USD 등 지원',
        scwk: '창고 채굴',
        sgxb: '신권 구매',
        aqkx: '신뢰할 수 있는 보안',
        aqkx1: '디지털 자산에 대한 세계 최고의 거래',

        //차트
        rmcjb: '인기있는 거래',
        xbb: '새 지폐',
        zfb: '상승 폭',
        dfb: '낙폭 차트',

        //인정
        identify: '검증되지 않음',
        identifyYes: '검증됨',
        changeName: '닉네임을 입력하십시오',
        changeName1: '닉네임은 한 번만 수정할 수 있습니다',
        confirmName: '확인',
        closeName: '취소',

        mc: '이름',
        hy: '계약',
        zxj: '최신 가격',
        zdb: '상승과 하락',
        sfyz: 'ID 인증',
        skfs: '수금 방법',
        share: '내 공유 링크',
        kjcb: '충전',
        kjtb: '현금 인출',
        qbdz: '지갑 주소',
        bps: '백서',
        xgmm: '암호 수정',
        xgzfmm: '지불 암호 수정',
        gywm: '우리에게',
        tcdl: '종료',

        //개인 센터
        zjmx: '자금 상세내역',
        cdsz: '설정',
        cdaq: '실명 인증',
        bzzx: '도움말 센터',
        ysxy: '개인 정보 보호 프로토콜',
        xzyy: '언어 선택'
    },
    commonUse: {
        title: '서비스',
        Finance: '금융',
        Notice: '알림',
        customerService: '고객 센터',
        cc: '보유',
        zjmx: '자금 상세내역',
        cpdm: '제품',
        mm: '매입/매각',
        wtj: '위탁가',
        wtsl: '위임 수량',
        Buy: '산다',
        Sell: '판매',
        Buy1: '구매',
        Sell1: '판매',
        Buy2: '원클릭 동전 구매',
        Exchangequantity: '교환 수량',
        Exchangeamount: '환전 금액',
        Currency: '통화',
        Status: '주문 상태',
        Type: '유형',
        Service: '고객 센터',
        p1: '통화를 선택하십시오',
        p2: '금액을 입력하십시오',
        czlb: '작업'
    },
    product: {
        Buy: '매입',
        Sell: '판매',
        Gain: '상승과 하락',
        Trade: '거래',
        Real: '실제',
        HA: '최고',
        ClosingPrice: '어제 받았습니다',
        Low: '가장 낮음',
        Volume: '24h',
        Limit: '가격 제한 주문',
        Contract: '주문 보유',
        News: '주문 정리',
        Liquidations: '전체 네트워크 데이터',
        ContractType: '계약 유형',
        OpeningPrice: '현재 가격',
        Leverage: '조이스틱',
        LeverageP: '조이스틱은 비워둘 수 없다',
        LimitPrice: '거래 금액',
        LimitPlaceholder: '한정 가격을 입력하십시오.',
        Overnight: '1박 2일',
        Direction: '방향',
        Operate: '작업',
        Long: '매입/많이 하기',
        Short: '팔기/비우기',
        Balance: '계약 자본 합계',
        Floating: '유동 손익',
        CloseAll: '원클릭 플랫',
        MarketOrder: '시가 거래',
        LimitOrder: '가격 제한 거래',
        Cross: '모든 창고',
        Isolated: '쓸모없이',
        placeholder: '현재 최적 가격으로 거래',
        Margin: '개수',
        MarginBalance: '보증금 잔액',
        TradingFee: '수수료',
        Position: '보유',
        Success: '작업 성공',
        Commission: '위임중',
        Transaction: '거래 중',
        Completed: '완료됨',
        Cancellation: '취소',
        unitPrice: '단가',
        LeverageRatio: '조이스틱 배수',
        cancellation: '취소',
        ClosingPosition: '플랫',
        productName: '제품 이름',
        Margin1: '보증금',
        SettlementPrice: '정산 가격',
        EndTime: '마감 시간',
        CreateTime: '주문 시간',
        WalletBalance: '지갑 잔액',
        AvailableFunds: '가용 자금',
        Text1: '프롬프트',
        Text2: '이 작업을 수행할지',
        qr: '확인',
        qx: '취소',
        ddbh: '주문 번호',
        wtsj: '위탁 시간',
        szzyzs: '흑자와 손실을 막는 설정',
    },
    notice: {
        title: '알림',
        ckxq: '자세히 보기'
    },
    helpCenter: {
        title: '초보자의 필독'
    },
    pwdGroup: {
        title: '암호 설정',
        pwdDL: '로그인 암호',
        pwdZj: '자금 암호'
    },
    quotation: {
        title: '시세',
        mc: '개수',
        zxj: '최신 가격',
        zdb: '상승과 하락',
        liang: '양'
    },
    contract: {
        kcslfw: '개설 수량 범위:',
        gao: '높음',
        di: '낮음',
        liang: '24H',
        placeholder: '수량을 입력하십시오',
        SuccessfulPurchase: '구매 성공',
        smallMin: '시계',
        hour: '시간',
        dayMin: '일',
        Trend: '시간 분할',
        ccjl: '보유 목록',
        ddjl: '주문 레코드',
        sl: '수량',
        gmj: '구매 가격',
        dqj: '현재 가격',
        yjyk: '예상 손익',
        djs: '카운트다운',
        gbjg: '거래가',
        sc: '수수료',
        pal: '손익',
        mhy: '초계약',
        sj: '시간',
        yll: '수익률',
        mz: '매수 상승',
        md: '매수 하락',
        kcsl: '개설 수량',
        kcsj: '창고 개방 시간',
        zhye: '계정 잔액',
        qc: '취소'
    },
    editPassword: {
        title: '로그인 암호 수정',
        jmm: '이전 암호',
        xmm: '새 암호',
        qrmm: '암호 확인',
        placeholder: '이전 암호',
        placeholder1: '새 암호 설정',
        placeholder2: '새 암호를 다시 입력하십시오',
        qrxf: '확인'
    },
    secure: {
        //보안 인증
        title: '보안',
        title1: '이중 인증',
        title2: '계정을 보호하기 위해 적어도 하나의 실명 인증을 완료하는 것이 좋습니다',
        gridTxt: '암호 설정',
        gridTxt1: '휴대폰 인증',
        gridTxt2: '사서함 확인',
        gridTxt3: 'ID 인증',
        listTxt: '은행 카드',
        listTxt1: '현금 인출 주소 관리'
    },
    bindBank: {
        //은행 카드
        title: '은행 카드',
        addCard: '카드 추가',
        editCard: '자금 확인 비밀번호 수정',
        ts1: '아직 인증되지 않았습니다.',
        ts2: '먼저 인증하십시오',
        addTitle: '카드 추가',
        userName: '실제 이름',
        tips1: '실제 이름을 입력하십시오',
        CollectionDetailArea: '수취인 상세내역 주소',
        tips6: '수취인 상세 주소를 입력하십시오.',
        userPhone: '예약된 핸드폰',
        tips2: '예약된 휴대폰 번호를 입력하십시오',
        bankName: '개설자 이름',
        tips3: '개설자 이름을 입력하십시오.',
        bankAds: '개설된 주소',
        tips5: '개설자 주소를 입력하십시오',
        bankId: '수금 계좌',
        tips4: '수금 계좌번호를 입력하십시오',
        CountryCode: '국가 코드',
        tips7: '국가 번호를 선택하십시오',
        tips8: 'SWIFT 코드를 입력하십시오',
        tips9: '금액을 입력하십시오',
        tips10: '충전 또는 출금 금액은 100 미만일 수 없습니다.',
        addTxt: '저장'
    },
    bindMail: {
        //메일박스 바인딩
        title: '사서함 바인딩',
        placeholder1: '메일 주소를 입력하십시오',
        ptitle: '핸드폰 수정',
        placeholder2: '새 휴대폰 번호를 입력하십시오.',
        next: '다음',
        zjmm: '자금 암호',
        placeholder3: '자금 비밀번호를 입력하십시오.'
    },
    setting: {
        //설정
        title: '설정',
        setTxt: '아바타',
        setTxt1: '언어',
        setTxt2: '환율',
        setTxt3: '우리에 대한'
    },
    withPass: {
        //자금 비밀번호 설정
        title: '자금 암호 설정',
        title1: '자금 암호는 로그인 암호와 같을 수 없습니다',
        //메일박스 바인딩
        aqyz: '보안 인증',
        aqyzm: '인증 코드',

        jmm: '이전 암호',
        xmm: '새 암호',
        qrmm: '암호 확인',
        placeholder: '이전 암호',
        placeholder1: '새 암호 설정',
        placeholder2: '새 암호를 다시 입력하십시오',
        qrxf: '확인',
        tips: '최소 6자리 이상의 이전 암호를 입력하십시오',
        tips1: '최소 6자리 이상의 새 암호를 입력하십시오',
        tips2: '올바른 확인 암호를 입력하십시오',
        tips3: '새 암호가 두 번 일치하지 않음',
        tips4: '암호 설정 성공',
        confirm: '확인'
    },
    newBe: {
        title: '신권 구매',
        title1: '내 요청서',
        scqx: '잠금 기간',
        day: '일',
        sy: '나머지',
        sdsg: '레코드',
        sysl: '잔액',
        gmsl: '구매 수량',
        dj: '단가',
        tips: '구매 수량을 입력하십시오',
        gmzj: '총 구매 가격',
        qbye: '지갑 잔액',
        fmzj: '총 구매 가격',
        gm: '구매',
        sgsj: '요청 시간',
        통화: '통화',
        요청: '요청 수량',
        패싱: '통과 수량',
        MarketTime: '출시 시점',
        sqz: '지원 중'
    },
    register: {
        title: '등록',
        lang: '언어',
        send: '보내기',
        Resend: '다시 보내기',
        Account: '계정',
        Password: '암호',
        ConfirmPassword: '암호 확인',
        InvitationCode: '초대 코드',
        btn: '등록',
        amount: '계정',
        mailbox: '메일함',
        haveAccount: '이미 계정이 있습니까?',
        haveLogin: '지금 로그인',
        Login: '로그인',
        placeholder: '사서함을 입력하십시오',
        placeholder0: '휴대폰 번호를 입력하십시오',
        placeholder1: '암호를 입력하십시오',
        placeholder2: '확인 암호를 입력하십시오',
        placeholder3: '초대 코드를 입력하십시오',
        placeholder4: '인증 번호를 입력하십시오',
        placeholder5: '올바른 사서함을 입력하십시오',
        success: '등록이 성공적이다',
        message: '최소 6자리 비밀번호',
        message1: '최소 6자리 이상의 암호 확인',
        message2: '암호가 두 번 일치하지 않음',
        message3: '프로토콜을 읽고 체크하십시오',
        agree: '동의합니다',
        xy: '사용자 프로토콜 및 개인 정보 보호 정책',
        qr: '지금 등록',
        //비밀번호 찾기
        ftitle: '암호 찾기',
        qrzh: '확인',
        placeholder7: '새 암호를 입력하십시오',
        placeholder6: '새 암호를 확인하십시오',
        placeholder8: '도면 인증 코드를 입력하십시오',
        placeholderName: '사용자 이름을 입력하십시오.'
    },
    lockming: {
        title: '잠금 목록',
        wkb: '광산 캐기',
        wkgjd: '채굴이 더 쉽다',
        qsljl: '쉽게 보너스를',
        yjzsr: '예상 총 이익',
        ck: '최근 3일 기준 연도화',
        tips: '최소한',
        rlx: '일일 이자',
        day: '일',
        scqx: '잠금 기간',
        buy: '구매',
        zysl: '담보 수',
        qbye: '지갑 잔액',
        finished: '완료',
        doing: '진행 중...',
        sl: '수량',
        shouyilv: '수익률',
        ks: '시작 시간',
        js: '종료 시간'
    },
    login: {
        title: '계정 로그인',
        lang: '언어',
        모바일: '핸드폰 번호',
        mailbox: '메일함',
        계정: '계정',
        Password: '암호',
        btn: '로그인',
        placeholder1: '암호를 입력하십시오',
        placeholder: '계정을 입력하십시오',
        fail: '전체 양식을 작성하십시오',
        ForgotPassword: '비밀번호를 잊으셨습니까?',
        NoAccount: '아직 계좌번호가 없다?',
        Registration: '지금 등록',
        server: '온라인 고객 센터',
        jzmm: '암호 기억',
        success: '로그인 성공',
        Home: '첫 페이지',
        userbox: '사용자 이름'
    },
    moneyAddr: {
        title: '지갑 주소',
        Currency: '통화',
        Address: '주소',
        placeholder: '선택하십시오',
        DepositAddress: '주머니를 채우는 주소',
        WalletAddress: '지갑 주소',
        Date: '시간',
        //지갑 주소 추가
        add: '지갑 주소',
        addTxt: '체인 주소당 최대 추가',
        remark: '준비 중',
        Walletremark: '이 주소에 대한 사전 정보를 추가하십시오.',
        network: '기본 네트워크(프로토콜)',
        networkXy1: '선택한 네트워크는',
        networkXy2: ', 주소를 확인하십시오. 만약 당신이 선택한 블록체인으로 이 자산을 검색할 수 없다면, 당신은 당신의 자산을 잃게 될 것입니다. ',

        ChainAddress: '체인 주소',
        qrcode: '지갑 QR코드',
        submit: '확인',
        tips: '지갑 유형을 선택하십시오',
        tips1: '체인 주소 유형을 선택하십시오',
        tips2: '지갑 주소를 입력하십시오',
        tips3: '지갑 QR코드를 올려주세요.',
        success: '성공'
    },
    recharge: {
        title: '충전',
        title1: '충전 세부 정보',
        ls: '충전 세부 정보',
        td: '다음 충전 채널을 선택하십시오.',
        bc: 'QR코드 저장',
        address: '주머니를 채우는 주소',
        copy: '주소 복사',
        num: '충전 수량',
        Upload: '지불 상세 정보 캡처 업로드',
        Submit: '확인',
        tip: '수량을 입력하십시오',
        tip1: '캡처를 올려주세요',
        copySuc: '복사 성공'
    }
}