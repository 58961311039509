module.exports = {
    pcregister: {
        p1: 'មានគណនីរួចហើយឬទេ?',
        dl: 'ចូល',
        zc: 'ចុះឈ្មោះ',
        p4: 'គណនី',
        p5: 'បញ្ចូលគណនី',
        p6: 'បញ្ចូលពាក្យសម្ងាត់',
        p7: 'បញ្ចូលពាក្យសម្ងាត់ត្រូវបានបញ្ជាក់',
        p8: 'បញ្ចូលលេខកូដអញ្ជើញ',
        p9: 'ខ្ញុំយល់ព្រម',
        p10: 'អំណោយរដ្ឋបាលនិងបរិស្ថានសំណភាពអ្នកប្រើ',
        p11: 'អ៊ីម៉ែល',
        p12: 'បញ្ចូលអ៊ីម៉ែល',
        p13: 'បញ្ចូលលេខកូដផ្ទៃកូដអ៊ីមែល',
        p14: 'ផ្ញើ',
        p15: 'ឆ្នាំ',
        p16: 'ខែ',
        p17: 'ថ្ងៃ',
        p18: 'ម៉ោង',
        p19: 'នាទី',
        p20: 'វិនាទី'
    },
    pclogin: {
        l1: 'មិនមានគណនីទេ?',
        l2: 'ចុះឈ្មោះ',
        l3: 'ចូលគណនី',
        l4: 'បញ្ចូលឈ្មោះអ្នកប្រើ',
        l5: 'បញ្ចូលពាក្យសម្ងាត់',
        l6: 'ភ្លេចពាក្យសម្ងាត់',
        l7: 'ចូលគណនី'
    },
    pccontract: {
        ss: 'តម្លៃទីផ្សារ',
        xj: 'ដកតាមតម្លៃដែលកំណត់',
        gg: 'រដ្ឋំការ'
    },
    pcrapidly: {
        zf: 'កម្លាំងការជួនកាល',
        dd: 'ការបញ្ជាទិញ',
        bdsj: 'ពេលវេលាដែលមាននៅក្នុងតំបន់បណ្តាញ',
        ky: 'អាចប្រើបាន'
    },
    pchome: {
        global1: 'កម្ពុជាពិភពលោកជំនុំសារីអនុញ្ញាតិចនិងសន្តិសុខពិភពលោក',
        global2: 'វាផ្តល់ជូននូវសេវាកម្មប្រព័ន្ធអនុញ្ញាតិក្នុងប្រទេសច្រើននៃការតុល្យភាពសន្តិសុខនិងការគ្រប់គ្រងទីប្រជុំសារីអនុញ្ញាតិតាមប្រព័ន្ធតាមបណ្តាញ។',
        global3: 'សុវត្ថិភាពនិងសន្តិសុខ',
        global4: 'មានបទពិសោធន៍ក្នុងការផ្សាយចំណេះដឹងលំហូរការជួយរឿងចក្រភពនិងប្រកបដោយប្រព័ន្ធការរិះគន់ប្រព័ន្ធកំណត់ទឹកចិត្តនិងប្រព័ន្ធការកាត់បន្ថយបញ្ចូល DDoS។',
        global5: 'ការរំខានអនុញ្ញាតិសកម្មសុវត្ថិភាពលើពិភពលោក',
        global6: 'បានរៀបចំពិភពលោកមានប្រទេសច្រើនដែលផ្តល់នូវនូវសេវាកម្មក្នុងតំបន់ប្រជុំសារីអនុញ្ញាតិតាមប្រព័ន្ធសារីអនុញ្ញាតិពិត។',
        global7: 'អ្នកប្រើនេះជាច្រើនជាងគេ',
        global8: 'បានការសង្កេតអន្ដរជាតិដណ្ដើក្រដាសកាត់កម្រិតមូលដ្ឋានសិទ្ធិនិយោជិក។',
        whever1: 'ការអនុញ្ញាតល្បឿនចូលចិត្តនិងទំហំប្រចូលនិងទូទៅសម្រាប់ការជួនជុំផ្សារក្នុងក្រុមទី',
        whever2: 'រួមបញ្ចូល iOS, Android, Windows ផ្សេងៗទីប្រតិបត្តិការប្រើនេះ, ស្រាបៀរបែបការពាក្យសរសៃសំរាប់ការធ្វើអាជីវកម្ម។',
        register1: 'ចុះឈ្មោះលើក្',
        register3: 'គណនីសកម្មអាចធ្វើការក្នុងព្រឹត្តិការណ៍បរិយាបថ',
        register2: 'ចុះឈ្មោះនៅបន្ទាប់ពីចាប់ផ្តើម',
        commodity: 'ផលិតផល',
        news: 'ព័ត៌មាន',
        ttop1: 'ស្វែងយល់ពីអាថ៌កំបាំងនៃPinnacle',
        ttop2: 'Pinnacle គឺជាការប្រតិបត្តិការស្ថិតិអន្តរជាតិដ៏ទូលាយក្រុមប្រុងប្រយ័ត្ន។ ចំណូលប្រតិបត្តិនៃកម្លាំងប្រដាល់របស់ Pinnacle ត្រូវបានធ្វើអន្តរជាតិខ្ពស់ដែលពាក់ពន្ធិករណ៍កាលពីវត្តមានគ្រោះថ្នាក់ផ្នែករដ្ឋាភិបាលមិនធ្វើឲ្យបានព្រមព្រៀងទេ។ ផលិតផលនេះមិនមានជំនាញជាច្រើនដដែលបានកំណត់ដែលមានការផ្លាស់ប្តូរនូវមុខវិជ្ជាសម្រាប់ការដកប្រាក់និងការដឹកជញ្ជូនអតិថិជនតំលៃនៅសហរដ្ឋអាមេរិកដែលនៅរដ្ឋអាមេរិកជា។ ជម្រើសទីផ្សារសំរាប់ប្រតិបត្តិដ៏ខ្ពស់ដែលបានភ្ជាប់ទៅនឹងអតិថិជននិងក្រុមហ៊ុនអគ្គិសនីនៅប្រទេស 30 ដូចជាតំបន់បរិស្ថានលើពិភពលោក។ ហើយបើប្រសើរទៅវិញទៅមកមិនទទួលលើកទីក្រុងរបស់យើង កម្លាំងប្រដាល់របស់យើងគឺបានអនុញ្ញាតឱ្យមាននិន្នាការជោគជ័យ។',
        ttop3: 'ចាប់ផ្តើមពិនិត្យតាមថ្នាំងនេះ​​ឥឡូវនេះ',
        product1: 'ការអនុវត្តន៍លឿននិងមានសមត្ថភាពស្ថិតនៅ, ដំបូងបណ្តាញសេវាកម្ម',
        product2: 'ឧបករណ៍វិភាគការណែនាំគ្នាកំពុងធ្វើការមួយ',
        product3: 'តម្លៃកំហុសពេលវេលាប្រតិបត្តិការនៅពេលវេលាបច្ចុប្បន្ន',
        product4: 'ការដកចេញធ្វើឲ្យសារទុកឡើងរហូតដល់ប្រសិទ្ធិភាពប្រតិបត្តិការ',
        trade1: 'ច្រើនទៀត',
        trade2: 'ទាប់ស្កាត់ដោយគ្មានការងារនៃការពិពណ៌នារបស់អ្នក',
        trade3: 'សាកល្បងសាងសង់សំណើសាររៀនសាកល្បងរបស់យើងដើម្បីលើកទិន្នន័យនៃករណីស្វ័យប្រវត្តិរបស់អ្នក',
        trade4: 'ពពកបុគ្គលិកនិងជួយតាមរយៈការរៀន',
        trade5: 'ស្វាគមន៏អំពីការប្រតិបត្តិករបស់អ្នកជាមួយគ្នានេះដោយមានបណ្តោយច្បាប់អប្បបរសាទិន',
        trade6: 'គ្រប់គ្រងជីវិតនៃការរង្វាន់របស់អ្នក',
        trade7: 'ប្រើឧបករណ៍គ្រប់គ្រងគ្រប់ប្រភេទនិងការវិធានដោយស៊េរលិចនិងការបកស្រាយប្រាក់ចាក់ចេញរហូតដល់ប្រសិទ្ធិភាព',
        allselect1: 'តើអ្នកកំណត់វិចិត្រអ្នកទាំងអស់ទទួលបានអ្វីខ្លះនេះទីនេះ?',
        allselect2: 'បានការពារនិងបានគាំទ្រការសាងសង់អតិថិជនរបស់អ្នក',
        allselect3: 'ទិន្នន័យរបស់អ្នកគឺត្រូវបានការពារដោយគ្រោះថ្នាក់និងនឹងមូលដ្ឋានធនាគារដោយសារតែរាល់ច្រកព្រោះតម្រូវឲ្យបានសង្ស័យក្នុងនិពន្ធទាន់ហេតុជាត្រា។',
        allselect4: 'ការគាំទ្រទំនុកចំនុចការបង្ការជាក់ស្ដែងដោយបើកជួយជំនាញគ្រប់គ្រងមូលដ្ឋានដើម្បីយកប្រដាប់។',
        allselect5: 'បន្ទាន់ពេលមួយក្នុងអាយុកាលបានជួយអ្នកប្រើប្រាស់ការគាំទ្រជំនាញលាបញ្ជាជូនអំពីសំណួរអំពីការបង្ការនិងទាន់សម័យអ្នកជំនាញឥតខ្នាត។',
        allselect6: 'បានការពារ',
        allselect7: 'មានអាជ្ញាធរត្រា និងធានាគ្រប់គ្រងដោយការជាន់គ្រប់គ្រងដោយជំណាក់កាលតាមភាពអនា្ករគណនាហេតុនិងអនុសាសន៍លេខាធើរណេតនៅក្នុងជីវវិតនឹងប្រកាសលិនធើនគឺ។',
        allselect8: 'ប្រសិទ្ធភាព',
        allselect9: 'Pinnacle Ltd ជាផ្លូវការ FTSE 250 ដែលបានតែបង្ហាញនៅលើកម្មសិទ្ធិបញ្ចូលលក្ខណៈរដ្ឋាភិបាលកម្ពុជាប៉ាតាណាស៊ីហ្ស៊ីយែមទីលាននៅពីការលក្ខណៈរដ្ឋាភិបាលកម',
        down1: 'ព័ត៌មានថ្មីៗនឹងរបស់អ្នកអាចត្រូវបានគាំទ្រទៅតាមទូរស័ព្ទទូរស័ព្ទបណ្តាញដែលគ្រប់គ្រងអាសយដ្ឋានហាងបានបើកប្រព័ន្ធរបស់វា។',
        down2: 'កម្មវិធីផ្ទាល់ខ្លួនបំផុតអាចត្រូវបានរក្សាទុកក្នុងទូរស័ព្ទម៉ាស៊ីនភាព។',
    },
    pcbottom: {
        box1: 'រដ្ឋបាលមូលដ្ឋាន',
        box2: 'ក្រុមហ៊ុនល្អបំផុត',
        box3: 'ច្រើនទៀត',
        box4: 'អំពីយើង',
        box5: 'សៀវភៅបៃតង',
        box6: 'សេវាកម្ម',
        box7: 'ទាញយក',
        box8: 'សំណួរចម្លើយទូទៅ',
        box9: 'សេវាកម្មតាមអ៊ីម៉ែល',
        box10: 'ច្បាស់លាស់',
        box11: 'កិច្ចព្រឹត្តិរបស់អ្នកប្រើ',
        box12: 'គំរូភាពឯកជន',
        box13: 'ការប្រកាសជានិច្ច',
        box14: 'ទំនាក់ទំនង'
    },
    pcAbout_us: {
        t1: 'ប្រវត្តិ',
        t2: 'យើងមានគោលដៅតែមួយពីរឆ្នាំ',
        t3: 'ពីឆ្នាំ​ ២០១៥ ដល់ឥលូវនេះ, យើងទើបផ្ដល់សេវាឆ្នាំក្នុងការបង្កើតសេវាកម្មតាមប្រព័ន្ធប្រតិបត្តិការចំនួនគ្រប់គ្រាន់។',
        t4: 'សម្រាប់រយៈពេលពីឆ្នាំ ២០១៤ ដល់ឆ្នាំ ២០១៧, ការអានកាត់តូចឆ្នាំបន្ទាន់នេះគឺបានទទួលបានពិន្ទុខ្ពស់នៅតាមប្រភេទច្បាស់ប្លោក។ ពីឆ្នាំ ២០១៧ ដល់ដើមឆ្នាំ២០១៨កើតឡើងជារៀនកើនឡើងច្បាស់ប្លោក, ត្រជាក់ទិន្នបើក ទៅនឹងភារកិច្ចមើលសម្បត្តិនៅពេលនេះ។ ពីកាលបរិសុទ្ធភាពនេះទៅពេលនេះ យើងបានស្រែអំពីបុព្វប្រព័ន្ធសកម្មជាមួយមិត្តភាពខ្លួន។ នៅឆ្នាំ២០១៩, ពួកយើងបានចែកពីពាក្យរបស់គ្រឹះស្ថានោះជាអ្វីមួយដែលត្រូវការជាប់ស្រេចចិត្តនិងពន្ធនាគារខ្ពស់ៗដូចនេះ។ ឆ្នាំ ២០២២, យើងនឹងមានប្រាប់ប្រជែងថ្មីមួយហើយលេងលោក V3.0, និងបង្កើតក្រុមហ៊ុនជាមួយក្រុមប្រឹក្សាឈរថ្មីមួយឈ្មោះ BitGoldEX ដែលកំពុងធ្វើអំណរគ្រោះពេលនេះក្នុងប្រទេស១០០០ក្រុមានប្រទេស។',
        t5: 'យើងកំពុងស្វែងរកនិយាយនិងសេវាកម្មដែល',
        t6: 'ពីការបង្កើតដើម្បីការបង្កើតក្នុងថ្ងៃនេះ',
        t7: 'យើងត្រូវបានជ្រើសរើសច្រើននិយាយនិងសេវាទៅតាមគ្នា, បន្ទាប់ពីការជោគជ័យនៃការដោះស្រាយបញ្ហាច្រើនរបស់ច្រើនអ្នកផ្គត់ផ្គង់។',
        t8: 'នៅពេលនេះក្នុងស្ថានភាពត្រីដោយហួសយនេះ, ប្រសិនបើអ្នកចង់រកមិនបានដែនដីល្អមួយប្រសិទ្ធដែលអាចជួយកើតឡើងកាន់ពត៌មានមួយចំនួននោះទេ-ឬឬាប្រសិនបើបង់ថាជំនួយល្អនោះទៀតហើយអាចរកបាន។',
        t9: 'សូមត្រូវបានស្រស់: អ្នកដែលអ្វីខ្លះដែលអ្នកកំពុងស្វែងរកអ្នក វាគឺផ្នែកនៃការស្វែងរកអ្នករបស់ខ្ញុំដែលកំពុងស្វែងរកអ្នកផ្សេង ក៏មានយ៉ាងណាំប្រសិទ្ធិដែលកំពុងស្វែងរកខ្លួនទេ។',
        t10: 'សេវាកម្ម',
        t11: 'មិនមានហាងផ្លែពាក់ព័ន្ធ, យើងអាចផ្ដល់នូវសេវាចោះចំនួន',
        t12: 'មិត្តភក្ដិរបស់អ្នកដើរមើលបំផុតពេទ្យថ្លៃនេះ, ពិន្ទុថាអ្នកជាមនុស្សមួយដែលមិនមានបណ្ដោយជោគជ័យនោះទេ។ មិនត្រូវអត់ព្រម្ដាប់សុវត្ថិភាពរបស់អ្នកអាចបំផុតពេទ្យនោះទេ។ ជំនុំគឺហេតុអ្វីជាធរមានសិទ្ធិនោះដូច្នេះពួកយើងក៏ចង់បង្រៀនជូនដំណឹងអំពីបញ្ហាដោយជោគជ័យរបស់នរកនិងស្វែងរកដែនដីដំណើរកាល់ទៅកាន់ការទិញអាវនិយមទៀត។ ប្រសិនបើចង់បានយ៉ាងហោចណាស់ច្បាស់គ្នានោះគឺឲ្យយើងទាន',
        t13: 'សេវាយើងជំនាញតម្រូវការគួរតែធ្វើជាប្រចាំថ្ងៃ យ៉ាងហើយមិនដឹងតើវាមានសេវាភាពប្តូរយ៉ាងទៅទេ។',
        t14: 'យើងគឺជាប្រើប្រាស់បំពង់ក្រនុងក្នុងការជ្រើសរើសការ។ គឺមានតម្លៃនិងប្រសិនបើមានការត្រឡប់ប្រៀបនឹងការគ្រប់គ្រង។',
        t15: 'ការស្ដាប់នៃអត្ថប្រយោជន៍ក្នុងអនុសំរាប់កម្រិតធម្មតាគឺជាការធ្វើឱ្យប្រសិទ្ធិនោះអាចបង្ហាញនូវវិក្ក័យបំណងដែលមាននៅក្នុងសេវាធ្វើឱ្យប្រសិទ្ធិគ្រប់ជំនាញនិងសេវាកម្មអ្នកផ្គត់ផ្គង់។',
        t16: 'យើងចង់បេតជើងឱ្យមានអំពីបែបនៃការបង្សារសេវាគួរតែមានបែបនៃការធ្វើចលនាធ្លារណែនាំ យើងត្រូវទាក់ទងជាមួយវិញកាលណាស់ហើយ។'
    },
    pcproblem: {
        p1: 'សំណួរដែលទាក់ទងនឹងប្រវត្តិសាស្រ្តធម្មតាបន្តិចនេះ',
        p2: 'តើរក្សាទុកនៃការបានប្តូរនឹងចំនួនទំនិញមកហើយទេ?',
        p3: 'ការគណនាតម្លៃនៃប្រាក់បញ្ជាបន្តចំនួនលេខទាក់ទងនឹងគោលដៅនៃប្រតិបត្តិការនៅក្នុងតម្លៃនៃUSD។ ការប្តូរនេះមិនធ្វើឲ្យប្រតិបត្តិការរបស់អ្នកកាន់តែប្តូរតាមតម្លៃនៃប្រាក់របស់វា។',
        p4: 'វិធីសាស្រ្តបង្កើតដូរលេខកូដលេខាលុបចោលសម្រាប់លេខសំងាត់នៅក្នុងការប្តូរទឹកចិត្តលើតម្លៃស្ថានភាពនេះ?',
        p5: 'ប្រសិនបើអ្នកភ្លាម្អន់គឺរន្ទត់ទៅរកទំនិញកាតលេខាចូលដោយចុចតំណ "របស់ខ្ញុំ-ការកំណត់-ចុចប្ដូរលេខសំងាត់" ឬទាក់ទងអ៊ីនធឺណិតដោយមន្ត្រីទូរស័ព្ទដើម្បីជាវជ្រាវការនេះឬទេ។',
        p6: 'តើទឹកចិត្តនៃប្រតិបត្តិការកាតលេខាបន្ទាប់ពីការបានទាក់ទងផលិតផលនៅឯកសណ្ឋានណា?',
        p7: 'ការកាតលេខាធ្វើសន្តិប្រតិបត្តិការតម្កូវបីកើតក្រុមហ៊ុនប្រាក់របស់អ្នកនៅក្នុងគណនីមូលប្រតិបត្តិការដែលបង្កើតឡើង។ ប្រសិនបើតម្លៃនៃការទ្រព្យសម្បូររបស់អ្នកត្រូវកាតលេខាចូលដើម្បីធ្វើសន្តិប្រតិបត្តិការអោយបានប្រតិបត្តការឬទេ សូមចូលទៅក្នុង"សន្តិសាណប័ណ្ណប្រាក់-ការដឹកប្រាក់"ដើម្បីធ្វើសន្តិប្រតិបត្តិការនេះ។',
        p8: 'តើវិធីប្រកាន់តែការផ្លាស់ប្ដូរឈ្មោះពុំអាចធ្វើឲ្យប្រតិបត្តិការរបស់អ្នកត្រូវកាត់អះអាងកែប្រែវាច្រើនដងវិញបាន?',
        p9: 'ដើម្បីទាក់ទងលើគោលនេះរបស់អ្នកទេ ពីព្យួពេលនៃការប្តូរនានាយប្រាក់នៅក្នុងពិធីសាស្រ្តនេះទេ ខ្ញុំក៏បានដាក់កំណត់លុបនេះដូចនេះហើយ។',
        p10: 'ការផ្លាស់ប្ដូរការដឹកនាំមាសូមរក្សាថាតើដើម្បីឈប់ដឹកទៅកន្លងទេ? តើវាំងបានធ្វើអ្វីនៅក្នុងគោលដៅនេះ?',
        p11: 'ដើម្បីការការការព្យាបាលលើភាពខុសគ្នានៃគណនីរបស់អ្នកនៅខាងក្នុងគ្នានេះបើឡើងនិងជួយការដែនកំណត់របស់អ្នកនិងការគ្រប់គ្រងជំនួយច្បាប់យកចិត្តគ្រប់សំណួរនិងការផ្សាយផ្សេងៗរបស់ការការទូទាត់។',
        p12: 'តើរបស់អ្នកតើប្រាក់គណនីប្រចាំថ្ងៃរបស់អ្នកត្រូវបានគណនាយ៉ាងដូចម្តេច?',
        p13: 'តម្លៃសម្គាល់គណនីប្រចាំថ្ងៃ = សមតម្រួតិកម្រិតផលិតផលក្នុងគណនី + ប្រាក់ចំណាញផ្សាយផ្សេងៗគណនីជំនួសម្រាំងនៅត្រង់កាលបរិច្ឆេទអស់ក្នុងគណនី + ចំនួនប្រាក់អនុគ្រោះបារម្ភគិតជាប្រាក់សមតម្រួត',
        p14: 'តម្លៃការដោះបន្ថែមផ្អែកយឺតនៃការបើកការប្រាក់និងតម្រ្យដែលត្រូវបានកំណត់? តើគោលការណ៍ប្រាក់បង្ហាញតម្លៃអ្វី?',
        p15: 'កំណត់ប្រាក់ពិន្ទុដែលអ្នកបានកំណត់ដើម្បីការពិន្ទុ។ ពេលទាល់តែបានបើកការប្រាក់ដើម្បីគ្រប់ផ្សាយរបស់អ្នកត្រូវការការគ្រប់គ្រងតាមរយៈការបង្កើត។ ដូច្នេះរបស់អ្នកត្រូវការការពិន្ទុរបស់អ្នកស្វ័យប្រវត្តិសង្គមនៅសមាគម។ ការកំណត់សឣ្ពើភាពគឺជាបណ្ដោះអាសន្ននៃប្រតិបត្តកិច្ចការរបស់អ្នក។ យើងសូមណែនាំអ្នកប្រើគោលការណ៍របស់អ្នកដើម្បីការខាតគោលការ។',
        p16: 'តើដើម្បីការទូទាត់ភ្នាតពិបាករបស់ភ្នាក់ងារ?',
        p17: 'អ្នកអាចសម្រេចសិនដោះស្រាយភាពខុសគ្នានៃការភ្នាក់ងារតាមរយៈការផ្ទេរប្រាក់ពីគណនីភាគច្រើននៅខាងក្រោមទៅកាន់គណនីទូទាត់នៃកិច្ចការអោយអនុញ្ញាត្តិសកម្មប៉ាន់ស្មានស្រាប់។ ឬដោះស្រាយភាពខុសគ្នានៃការកាន់តែយន្តដែលបានទទួលទាន់ពេលវេលាកាន់តែបានតូចបំផុតនៃប្រាក់របស់អ្នក។',
        p18: 'ចំនួននៃការភ្នាក់ងារការបើកកិច្ចការមានន័យអ្វី?',
        p19: 'ចំនួននៃការភ្នាក់ងារការកកិច្ចផ្សេងៗពិតរបស់អ្នក។ ឧទាហរណ៍: នៅការបើកទំនាក់ទំនងBTC/USDអ្នកអាចរើសក្រុមហ៊ុននេះដោយធ្វើការជ្រើសរើសលក្ខណៈពិសោធន៍​ដូចជាធ្វើការទិញ/បើកក្រុមហ៊ុននិងបញ្ចូលតម្លៃក្រុមហ៊ុនគឺ1000USD និងបញ្ចូលចំនួន10BTC ដូច្នេះអ្នកបញ្ចេញឋានៈ10សរុបដែលក្នុងមួយទឹកប្រាក់សមត្រប្រាក់សម្គាល់គឺសាច់ប្រាក់នេះនៅត្រង់1000USDT។ ',
        p20: 'ការគណនាថ្លៃឯកតាក្នុងកិច្ចការដែល៖ តម្លៃឯកតាថ្លៃរបរនៃភ្នាក់ងារឡើងវិញត្រូវបានគណនាជាមួយតម្លៃសេវាលការបើករបស់ភ្នាក់ងារដែលបានរើសរើសនិងចំនួននៃភ្នាក់ងារនៅត្រង់ក្បែរឡើងវិញ។',
        p21: 'ចំណាត់ថ្លៃឯកតាក្នុងកិច្ចការ = តម្លៃបើកនេះ * ចំនួននៃការបើករបស់ភ្នាក់ងារ * អត្រាថ្លៃភ្នាក់ងារ',
        p22: 'របៀបបិទការប្រាក់នៅត្រង់កិច្ចការរបស់អ្នកមានអ្វី?',
        p23: 'កម្រិតប្រាក់បំណុលជាកម្រិតសំណូមរបស់ភាគហ៊ុនអ្នកហៅម៉ាកីស្សាុនិងការគ្រប់គ្រងគណនី។ នៅពេលដែលកម្រិតភាគហ៊ុនរបស់អ្នកស្មើនឹង100%, គណនីរបស់អ្នកនឹងត្រូវបានកំណត់ថាជាប្រាក់ពិន្ទុ។ កម្រិតប្រាក់បំណុលមធ្យមរបស់អ្នកគឺ (ការធានាសុពលភាពដល់ប្រាក់ដើម/តុល្យអានិច្ចនៃគណនីក្នុងកិច្ចការ) * 100%, ដើម្បីការពារប្រតិបត្តិការភ្នាក់ងារទៅខាងមិន',
        p24: 'ច្បាប់ការជួសជុលកិច្ចការអ្នកប្រាក់ជាមួយអ្នកផ្គូរផ្គិតមួយមួយនៅត្រង់កិច្ចការរបស់អ្នក។',
        p25: 'ប្រភេទការជួសជុល',
        p26: 'ការជួសជុលបែងចែកទៅជាពីរដែលប្រភេទហើយគឺការទិញ (ការជួសជុល) និងការលក់ (ការជួសជុល)។',
        p27: 'ទិញច្រើន（មើលការកើនឡើង）មានន័យថាអ្នកគិតថាសន្ទស្សន៍បច្ចុប្បន្នទំនងជាកំពុងកើនឡើងហើយអ្នកចង់ទិញចំនួនថេរនៃចំនួនថេរនៃកិច្ចសន្យាដែលបានចុះហត្ថលេខាក្នុងតម្លៃដែលអ្នកបានកំណត់ឬតម្លៃទីផ្សារបច្ចុប្បន្ន។ ',
        p28: 'ការលក់ខ្លី（ធ្លាក់ចុះ）មានន័យថាអ្នកគិតថាសន្ទស្សន៍បច្ចុប្បន្នអាចនឹងធ្លាក់ចុះហើយចង់លក់កិច្ចសន្យាថ្មីមួយចំនួនក្នុងតម្លៃដែលអ្នកបានកំណត់ឬតម្លៃទីផ្សារបច្ចុប្បន្ន។ ',
        p29: '៖‘របៀបចុះក្រោម',
        p30: 'គណនីបរិមាណដែលអ្នកតម្លៃកំណត់បន្ទាន់ហើយបរិមាណដែលត្រូវបញ្ជាក់មិនវិញនឹងតម្លៃនិងបរិមាណក្នុងការបញ្ជាទិញ។',
        p31: 'គណនីផលិតដើម្បីដេញអោយអ្នកតម្លៃនៅកន្លែងចុះបញ្ជាទិញជាមួយបរិមាណបញ្ជាបន្តិច។',
        p32: 'ទីជន់ដែលមានចំនួនចុះបញ្ជ',
        p33: 'តក្កវិជ្ជានៅពេលអ្នកដាក់ស្នើតក្កវិជ្ជា',
        p34: 'បញ្ហានៃការផ្តល់កិច្ចសន្យា',
        p35: 'កិច្ចព្រមានរបស់ផ្ទៃកម្មសល្យជាលំនាំដើម្បីធ្វើក្រោមនិយមមិនអនុញ្ញាតឱ្យទទួលបានពេលការផ្តល់ជូន។ តម្លៃជូនដែលអ្នកព្យាយាមបង់ប្រាក់ក្នុងការផ្ដល់បន្ជាទិញម្នាក់នោះគឺមិនអាចរក្សាទុកសូម្បីចាក់ស្រាយធ្វើក្រោមដោយវិបាននឹងតម្លៃនិងបរិមាណក្នុងការផ្ដល់បន្ជាទិញ។ ការផ្តល់ជូននៃការចាក់ស្រាយធ្វើក្រោមនឹងបរិមាណក្នុងការផ្ដល់បន្ជាទិញរបស់អ្នកនោះត្រូវបានគេទទួលបានបាន។',
        p36: '១៖ បានទទួលនៅត្រឹមត្រូវដែលកំណត់សំរាប់ធាតុចំណែកនិងផ្តាច់បាត់បង់បន្សាទិញ។ ប្រព័ន្ធអាចចាក់ស្រាយធ្វើក្រោមបានដោយស្របដោយជោគជ័យ។',
        p37: '២៖ មានប្រដាប់ខ្ពស់គេហទំព័រចាក់ស្រាយធ្វើក្រោមរបស់កម្មសិក្សា។',
        p38: 'តម្លៃនៃរួមបញ្ចូលនៃការផ្ដល់ជូនមានតម្លៃអប្បបរមាដដែល?',
        p39: 'ក្នុងការផ្ដល់បន្ជាទិញក្នុងការចំណាត់ថ្នាក់ការិយាល័យចំពោះពិភាក្សានិងប្រព័ន្ធយើងដាក់ធំជាងគេ។ ក្នុងការចាក់ស្រាយប្រើប្រាស់នៅខណៈដែលខ្លះហើយពីថ្ងៃដែលអ្នកបន្ទាន់ចេះចូលចុះបញ្ជាទិញ។ អ្នកកំពុងចូលចិត្តក្នុងស្ថានភាពថាសិក្សាប្រព័ន្ធការរក្សាទុកគ្រោងទេសអ្វីមួយៗនេះជូនការប្រែប្រួលទៅកាន់តែប្រព័ន្ធប្រើប្រាស់ពិក្សាដោយស្តង់ដាំណែនាំសព្វថ្ងៃនិងបរិមាណប្រើប្រាស់ដែលគ្រប់គ្រង។',
        p40: 'តើអ្វីទៅជាប្រូមេរ៉ានិងតម្លៃទូទៅគ',
        p41: 'ការចាក់ស្រាយដែលមានបំណងចំណាយគឺជាបានរៀបចំប្រកាសនៃកំហុសហើយនិយោជិតនៃការសល្យកម្ម។ កម្មសិក្សាការកម្តៅជាប់ស្បែកក្នុងការចាក់ស្រាយដែលលោកអ្នកនឹងបើកវាដោយតម្លៃបច្ចុប្បន្នបច្ចុប្បន្ននៅក្នុងការទិញលើប្រព័ន្ធនេះ។ ថ្ងៃដែលអ្នកបានរៀបថ្មីការបញ្ជាទិញអនុវត្តតែមួយ។ ប្រសិនបើអ្នកជ្រើសរើសគ្រប់គ្រងបែបទិញអាចសម្រួលតែយ៉ាងសំខាន់ដែលអ្នកបានជ្រើសរើសនិងមិនធ្វើការបញ្ជាក់តម្លៃការចាក់ទិញដែលមិនមានសាកល្បងបង្កើតជាក្រោមអំពីការផលិតរបស់ការប្រព័ន្ធប្រើរបស់អ្នក។',
        p42: 'ការកំណត់កម្រិតបំបាត់នៃគ្រោងការសល្យកម្មគឺជាអ្វី?',
        p43: 'ការប្រព្រឹត្តការរាល់កំហុសនៃគណនីការរស់នៅកាន់គន្លងទាំងសកម្មនៃការប្រើប្រាស់គោលដែន។ កម្មសិក្សានិងប្រព័ន្ធយើងណាមួរប្រភេទនៃការថ្លៃអ៊ុនធាតុពាណិជ្ជកម្មកំពុងប្រើប្រាស់។ យ៉ាងហោចណាស់ពេលណាមួរកើតឡើងការប្រព័ន្ធការប្រើប្រាស់របស់អ្នកលើតម្រូវការ។ អ្នកអាចនឹងធ្វើឱ្យការរម្លស់គ្រោងតម្រូវការនេះមានគ្រោងបកស្រាយដូចបង្ហាញក្នុងការគាំពានទាន់សម័យនិងការធ្វើការបង់ដំបូងក្នុងកងនូវគណនីអាជីករោង។',
        p44: 'តើហេតុអ្វីបានទាក់ទងរវាងការប្រើប្រាស់និងការផ្លាស់ប្តូររូបិយប័ណ្ណ?',
        p45: 'គោលបំណងនៃការប្រើប្រាស់គឺដើម្បីធ្វើឱ្យលុបចោលប្រភេជ្ជនិតនៃប្រកាសផ្លាស់ប្តូរទាំងសង្ខារជា្នូយគ្រប់គ្រងរបស់អ្នក។ ក្នុងគណនីគោលដែនរបស់ការផ្សព្វផ្សាយសាខា QCC នេះអាចត្រូវបានផ្លាស់ប្តូរដោយសេរីផ្សេងទៀតទៅUSDដែលអាចគិតពីការជាវនៅក្នុងការជ្រើសរើសនៃពេលវេលាដ៏ប្រសើរវារបស់អ្នក។ ផ្សេងទៀតនៅក្នុងគណនីផ្សព្វផ្សាយរបស់អ្នក USDអាចបានផ្លាស់ប្តូរដោយសេរីតាមទំនាក់ទំនងទៅ QCCក្នុងការជ្រើសរើសជម្រើសនៃពេលវេលាដ៏អស្ចារ្យ។',
        p46: 'ហេតុអ្វីដែលកម្មវិធីមិនបានទទួលទានលើរបៀបប្រគល់ប្រាក់មកដល់ប្រអប់និយាយរបៀបរបៀបប្រគល់ប្រាក់នឹងប្រអប់ចំពោះការប្រតិបត្តិការ?',
        p47: 'ដោយសារទាំងពីររងនូវព័ត៌មានបង្ហាញថាវា​មិនស្ថិតនៅក្នុងរបៀបបង់ប្រាក់ដែលអ្នកបានបំពេញ​ក្នុងរយៈពេលដែលប្រតិបត្តិកម្មមានប្រភេទដែលដូចគ្នា។ បន្ទាប់ពីនេះសូមពិនិត្យទិន្នន័យដែលអ្នកបានបញ្ជាក់នៅក្នុងការបង់ប្រាក់ថាត្រូវបង់រួចរាល់ឬទេ។ ចុចពិនិត្យទៅចូលទៅកាន់ការទូទាត់សាងសង់ដែលអ្នកបានជ្រើសរើសសម្រាប់បង់ប្រាក់ដើម្បីបញ្ជាក់។ ចុះភ្ជាប់ជាមួយធនាគាររបស់អ្នកហើយប្រមូលផ្សាយមកពីនឹងគ្រប់គ្រងភ្នាក់ងារបង់ប្រាក់ដែលផ្សព្វផ្សាយអាជោយឬមួយផ្សេង។',
        p48: 'តើអ្នកអាចទិញ USD តាមរយៈប្រព័ន្ធបានអូនរបស់ក្នុងប្រដេសមួយមែនទេ?',
        p49: 'វិធីមួយ៖ រូបសម្រាប់ការទិញឬលក់សម្ភាវិទ្យារបស់អ្នកក៏អាចជ្រើសរើសបានមួយ។',
        p50: 'វិធីពីរ៖ ចុចលេខប៊ីចូលទៅប៊ូតុងបញ្ជាទិញ៥ ។ បន្ទាប់មកអ្នកនឹងត្រូវចុចលេខលក់ជាបន្តក់តែអ្នកត្រូវទាញយកយកក្បាលក្បាលទៀតដើម្បីវេតាមនុកច្រើន។ ប្រព័ន្ធនេះនឹងប្រសិនបើអ្នកមានបញ្ហាអ្វីដែលអ្នកក៏អាចកើតឡើងទេ។',
        p51: 'សូមដោះស្រាយ៖ ប្រសិនបើដែកការបញ្ជាទិញរបស់អ្នកមិនទទួលបានការបង់ប្រាក់រួចទាំង ១៥ នាទីបន្ទាប់ពីទៅនេះប្រព័ន្ធនឹងបោះបង់ការបញ្ជា‌ទិញកនុងរយៈពេលឆ្នាំ ១០ នៅពេលដែលអ្នកមានកម្រិតលទ្ធផល ០០៣ ច្រកម្មអនុស្សាយុទ្ធនាការ។'
    },
    public: {
        sure: 'យល់ព្រម',
        cancel: 'បោះបង់',
        RefreshSucceeded: 'បញ្ជាក់ថាបាន​កំណត់​ឱ្យ​ទាន់​សម័យ',
        noMoreData: 'គ្មានទិន្នន័យច្រើនទេ',
        pullingText: 'ទាញដុំបានដើម្បីស្រេចចិត្ត ...',
        ReleaseText: 'ដំណើរការឡើងវិញបានដើម្បីស្រេចចិត្ត ...',
        loadingText: 'កំពុងផ្ទុក ...',
        noData: 'គ្មានទិន្នន័យ',
        xq: 'ព័ត៌មានលម្អិត',
        submit: 'យល់ព្រម',
        kefu: 'ការសេវាកម្មតាមអ៊ីនធឺណេត',
        amount: 'ចំនួន',
        startInvestment: 'ចាប់ផ្តើមការផ្លាស់ប្តូរប្រាក់',
        endInvestment: 'ចប់ការផ្លាស់ប្តូរប្រាក់',
        profit: 'ចំនូសិន'
    },
    ChangeType: {
        Upper: 'ការដោះស្រាយប្រព័ន្ធកម្មវិធី',
        Lower: 'ការដកប្រាក់ប្រព័ន្ធកម្មវិធី',
        Win: 'ចំណេញ',
        Bet: 'ការផលិតផល',
        BuyMining: 'ទិញសម្ភាសន៍',
        CoinWin: 'ផលិតលុយថ្មីចំណេញ',
        SettleMining: 'ប្រាក់បញ្ចូលដើមក្រោយពីការទិញសម្ភាសន៍',
        GiveInterest: 'ការចំណាត់ហេដ្ឋាននៃការទិញសម្ភាសន៍',
        BuyCoin: 'ទិញលុយថ្មីដែលមិនទទួលបាន',
        BuyCoinBack: 'ត្រលប់ក្រោយការទិញលុយថ្មី',
        UsdtToBtc: 'ប្រាក់ទូទឹក Usd ទៅជា Btc',
        UsdtToEth: 'ប្រាក់ទូទឹក Usd ទៅជា Eth',
        BtcToUsdt: 'ប្រាក់ទូទឹក Btc ទៅជា Usd',
        BtcToEth: 'ប្រាក់ទូទឹក Btc ទៅជា Eth',
        EthToUsdt: 'ការប្រាក់ ETH ទៅ USDT',
        EthToBtc: 'ការប្រាក់ ETH ទៅ BTC',
        CoinFrozen: 'ការទិញលុយថ្មីដែលផ្អាក',
        CoinThaw: 'ការដកយកលុយថ្មី',
        CoinCancel: 'ការបោះបង់ការទិញលុយថ្មី',
        CoinBuy: 'ការទិញលុយថ្មី',
        CoinBuyEntrust: 'ការបញ្ជាក់ការទិញលុយថ្មី',
        CoinSellEntrust: 'ការបញ្ជាក់ការលក់លុយថ្មី',
        CoinSell: 'ការលក់លុយថ្មី',
        ContractBuy: 'ការធានាការចូលទិញដែលកើតឡើងក្នុងការធានាការ',
        ContractCharge: 'ការកក់ការធានាការ',
        ContractCancelToBond: 'ការបោះបង់ការធានាការជូនដំណើរការ',
        ContractCancelToCharge: 'ការបោះបង់ការធានាការទទួលបាន',
        ContractCloseProfit: 'ចំណេញនៃការបោះបង់តាមក្រោយការធានាការ'
    },
    trade: {
        title: 'ប្រាក់ប្រែង',
        Buy: 'ទិញចូល',
        Sell: 'លក់ចេញ',
        pc: 'បិទការលក់',
        cb: 'ថ្លៃដើម',
        xj1: 'តម្លៃបច្ចុប្បន្ន',
        yk: 'ចំណេញ / និមិត្តសារពើភ័ណ្ឌ',
        xianjia: 'ការបំពេញតម្លៃ',
        jiage: 'តម្លៃ',
        shuliang: 'បរិមាណ',
        keyong: 'អនុញ្ញាត',
        yonghuchicang: 'ទំនិញរបស់អ្នកប្រើប្រាស់',
        fxlts: 'អត្រាប្រាក់ជាអនុគ្រោះ = ការជាប់ប្រាក់កន្រ្តការជាប់ប្រាក់ / សមតហ្វូនរបស់អតិថិជនថ្មីប្រាក់ជាការទទួលមកពីគុណភ្ញាក់ផ្អែករបស់គុណភ្ញាក់ផ្អែក+ សាខាទុកដល់ការយករហូតដល់',
        fxl: 'អត្រាប្រាក់ជាអនុគ្រោះ',
        dqwt: 'ការដាក់បញ្ជាប់បច្ចេកទេសបច្ចុប្បន្ន',
        all: 'ទាំងអស់',
        name: 'ឈ្មោះ',
        shijian: 'ពេលវេលាកិច្ចរបស់',
        zyjg: 'ពិសេសជាតម្លៃដែលល្អបំផុត',
        caozuo: 'ប្រតិបត្តិការងារ',
        xj: 'ដែរកំណត់',
        cc: 'ការរកបង្វិច',
        leixing: 'ប្រភេទ',
        long: 'ទិញ / រដូវកាល',
        short: 'លក់ / រដូវកាល',
        sj: 'តម្លៃផ្សេងៗ',
        jiaoyie: 'ការធ្វើទំនិញ',
        orderNo: 'លេខបញ្ជាទិញ',
        weituojia: 'តម្លៃដែលបានកែប្រែ',
        chengjiaojia: 'តម្លៃបង់កម្រៃ',
        chengjiaoe: 'តម្លៃការទិញលើស',
        weituo: 'វិជ្ជនជាប់ប៉ាន់',
        qbwt: 'វិជ្ជនជាប់ទាំងអស់',
        lswt: 'បញ្ជាថ្មី',
        tips: 'សូមបញ្ចូលតម្លៃត្រឹមត្រូវ',
        tips1: 'សូមបញ្ចូលបរិមាណ',
        Create: 'កំពុងវិជ្ជនជាប់',
        Wait: 'កំពុងការអនុទិពនិងការ',
        Complete: 'បានបិទធ្លាក់',
        Cancel: 'បានបោះបង់ការ',
        zsj: 'តែពិសេសរឺបលង្កនែង',
        zyj: 'តែពិសេសគ្មានប្រាក់ពិសេស',
        cczsy: 'ចំណេញទឹកប្រាក់របស់ការរកបង្វិច',
        ykbl: 'អនិភាពប្រាក់ចំណេញ',
        bili: 'អនុភាព',
        jyss: 'ចំនួនដែលអាចធ្វើការប្រាក់',
        bzj: 'កម្មវិធីទុកសត្វ',
        sxf: 'ថ្លែងប្រាក់',
        gg: 'ស្គាល់បក្រាក់និងជម្លោះ',
        yjpc: 'បិទការជួលផ្សព្វផ'
    },
    auth: {
        title: 'ការផ្ទៀងផ្ទាត់កម្រងប្រាក់',
        title1: 'ការផ្ទៀងផ្ទាត់កម្រងប្រាក់',
        low: 'ការផ្ទៀងផ្ទាត់ចាប់អារម្មណ៍ដំបូង',
        high: 'ការផ្ទៀងផ្ទាត់ចាប់អារម្មណ៍ខ្ពស់',
        success: 'ការធ្វើការផ្ទៀងផ្ទាត់បានជោគជ័យ',
        tips: 'សូមបញ្ជាក់ពីការផ្ទៀងផ្ទាត់',
        tips1: 'សូមបញ្ចូលឈ្មោះពិសេស',
        tips2: 'សូមបញ្ចូលលេខសំគាល់ប័ត្រ',
        tips3: 'សូមបញ្ចូលរូបថតពិសេសលំនាំដើម',
        tips4: 'សូមបញ្ចូលរូបថតពិសេសព្រោះ',
        tips5: 'សូមបញ្ចូលរូបថតដែលទាញយក',
        tips6: 'សូមជ្រើសរើសប្រភេទការផ្ទៀងផ្ទាត់ពិសេស',
        qrz: 'ទៅកាន់ការបញ្ជាក់',
        shz: 'កំពុងធ្វើតេស្ត',
        rzsb: 'បរាជ័យក្នុងការផ្ទៀងផ្ទាត់ចាប់អារម្មណ៍',
        userName: 'ឈ្មោះពិសេស',
        IDNumber: 'លេខសំគាល់អត្តសញ្ញាណ',
        zmz: 'រូបថតពិសេសលើស',
        bmz: 'រូបថតពិសេសក្រោយ',
        scz: 'រូបថតដែលឈ្នួលដើម្បី',
        IDCard: 'អត្តសញ្ញាណប័ណ្ណ',
        Passport: 'លិខិតឆ្លងដែន',
        AuthenticationType: 'ប្រភេទការផ្ទៀងផ្ទាត់ពិសេស',
        HighAuth: 'សិទ្ធិល្បីនៃការបកប្រែក្នុងភាសាខ្មែរ',
        gj: 'ប្រទេសនៃសញ្ជាតិ',
        tipsgj: 'សូមបញ្ចូលសញ្ជាតិ',
    },
    share: {
        title: 'លេខកូដបណ្តោះអាសន្នរបស់ខ្ញុំ',
        cp: 'ចម្លងទីតាំង',
        success: 'ចម្លងជោគជ័យ'
    },
    withdrawal: {
        Wallet: 'ការផ្ដល់ប្រាក់បង់',
        Internal: 'ការគ្រប់គ្រងផ្នែកខាងក្នុង',
        Currency: 'រូបិយប័ណ្ណ',
        Main: 'បណ្ដុំប្រតិបត្តិការនៅលើបណ្តាញគេ',
        WithdrawAddress: 'អាសយដ្ឋានដកប្រាក់',
        Frequently: 'អាសយដ្ឋានឆ្លុះបញ្ជីសកម្មភាព',
        placeholder: 'អាសយដ្ឋានដកប្រាក់ USD',
        note: 'កំណត់សិទ្ធិក្នុងការប្រតិបត្តិការ',
        placeholder1: 'សូមបញ្ចូលកំណត់សិទ្ធិក្នុងការប្រតិបត្តិការ',
        Amount: 'ចំនួនដកប្រាក់',
        placeholder2: 'ការអប្បបរមាដើម្បីដកចូលគឺ១០',
        All: 'ទាំងអស់',
        Balance: 'តុល្យក',
        Fee: 'ថ្លៃដកប្រាក់',
        FundPassword: 'ពាក្យសម្ងាត់មុន',
        placeholder3: 'សូមបញ្ចូលពាក្យសម្ងាត់មុន',
        forget: 'ភ្លេចពាក្យសម្ងាត់មុន?',
        OTP: 'កូដសម្ងាត់ OTP',
        placeholder4: 'សូមបញ្ចូលកូដបញ្ជាក់ OTP',
        send: 'ផ្ញើ',
        Resend: 'ផ្ទេរម្ដងទៀត',
        Reminder: 'សេចក្ដីប្រកាស',
        Reminder1: 'ការដកប្រាក់ត្រូវបានដំណើរការសម្រាប់ម៉ោងពីអាទិត្យលំហូរនាទីដល់អាទិភាព ១០ នៅពេលវេលា (UTC+8)',
        Reminder2: 'បរិមាណសំបុកដោយចូលទៅកន្លែងដកប្រាក់របស់អ្នកគឺស្មើនឹងតុល្យការ បែបនេះប្រែប្រួលការបង់ប្រាក់នៃការដកប្រាក់',
        Reminder3: 'សូមពិនិត្យមើលលុបចោលនៃការស្វែងយល់ដើម្បីដកប្រាក់របស់អ្នកមកកាន់អាណោក្នរង្វាន់លើបណ្តាញបណ្តាញព្យាបាល',
        Confirm: 'បញ្ជាក់ការដកប្រាក់',
        noPassword: 'មិនបានកំណត់ពាក្យសម្ងាត់មុន',
        Fees: 'ថ្លៃដកប្រាក់',
        Free: 'ថ្លៃដកប្រាក់ឥតគិតថ្លៃ',
        Transfer: 'ចំនួនដើម្បីផ្លាស់ប្តូរ',
        Limitation: 'លីនេអ៊ែរកាលបរិច្ឆេទផ្លាស់ប្តូរចុងក្រោយ',
        placeholder6: 'ប្រុងទំនេរក្នុងមួយការផ្លាស់ប្តូរគឺ០-១០០០០០',
        Available: 'ថ្លៃដកប្រាក់ឥតគិតថ្លៃ',
        Receiving: 'អាសយដ្ឋានទទួល',
        placeholder5: 'សូមបញ្ចូលពាក្យនៅទីនេះ',
        TransferBtn: 'ដកប្រាក់',
        Reminder4: 'ព័ត៌មានជំនួសខេត្ត។ សូមថ្លៃប្រព័ន្ធមើលអំពីព័ត៌មានអ្នកនៅខាងលើ',
        Reminder5: 'ព័ត៌មានជំនួស៖ ការផ្តល់ការត្រួតពិនិត្យមានក្នុងម៉ោង ០៩:០០ - ២២:០០ (UTC+8)',

        title: 'របាយការណ៍',
        title1: 'គណនីសាប់ប្រាក់',
        title2: 'គណនីសាប់កិច្ចតាមកិច្ចពាណិជ្ជកម្ម',
        title3: 'ជាតិក្រុម',
        title4: 'ការនាំចូល',
        title5: 'ការដកប្រាក់',
        Rate: 'អត្រា',
        allRate: 'ត្រូវបានរំលង',
        PlaceOrder: 'បញ្ជាទិញ',
        Freeze: 'បង្កក',
        Thaw: 'រលាយ',
        backgroundProgram: 'កម្មវិធីបណ្តាញបណ្តាល',
        AuditReject: 'ការដកប្រាក់ការលើកដំបូងត្រូវបានបដិសេធត្រឹមត្រូវ',
        OrderSettlement: 'ការសន្សំទិញលើកលេខារបស់កម្មវិធី',
        AccountBalance: 'សមតុល្យគណនីៈ',
        CancelOrder: 'បោះបង់ការបញ្ជាទិញ',
        Created: 'បានបង្កើត',
        Paid: 'បានទូទាត់',
        UnAuthed: 'ត្រូវបានពិនិត្យឡើងវិញ',
        Accepted: 'បានទទួលយក',
        Canceled: 'បានបោះបង់',
        Refuce: 'បានបដិសេធត្រឹមត្រូវ',
        placeholderMC: 'លក់ដោយជោគជ័យ',
        read: 'បាន​អាន',
        unread: 'មិនទាន់អាន',
        chat: 'ជជែក',
    },
    newRecharge: {
        Deposit: 'ប្រាក់ចំនួន',
        Credit: 'កាតឥណទាន',
        Wallet: 'កម្មវិធីសាប់ប្រាក់ក្នុងឃ្លាំងទុក, ឈ្នួលបន្ទាន់ប្រាក់ក្បាលប្រាំថ្ងៃ',
        FAQ: 'ណែនាំការប្រាក់ដាក់',
        Currency: 'ជ្រើសរើសប្រាក់',
        placeholder: 'សូមជ្រើសរើសប្រាក់',
        Main: 'បណ្តាញគោលបំណង (សមត្ថភាព)',
        DepositAds: 'អាស័យដ្ឋានប្រាក់ដាក់',
        Copy: 'ចម្លងអាស័យដ្ឋាន',
        remark: '•សូមកុំប្រាក់ទៅអាស័យដ្ឋានដែលបង្ហាញនេះលើកំប៉ុងប្រាំ',
        remark1: 'USDT',
        remark2: 'ប្រភព, បើមានប្រាក់ជាមួយ USDT នោះកម្មវិធីនឹងមិនអាចទទួលសម្រាប់អង្វេត្តវិន័យដើម្បីត្រឡប់ប្រាក់ទេ។',
        remark3: '• ក្នុងពេលដែលលោកអ្នកកាន់ប្រាក់ទៅអាស័យដ្ឋានដែលបង្ហាញនេះ រក្សាសិទ្ធិនៃដែលផ្តល់ច្បាប់របស់បណ្តាញវិនិយោគត្រូវបានផ្ទៀងផ្ទាត់ដោយដែលមានវាសនា។ អាស័យដ្ឋាននឹងត្រូវបានទទួលការផ្តល់អនុស្សាវិញក្នុងរយៈពេល 6 វិនាទីបន្ទាប់ពីប៉ាន់ស្វែងស្វាគម។',
        remark4: '• ការប្រាក់ទំនើបនេះមិនអាចទទួលបាននៅតម្លៃទាបជាង 15 USD ទេ។',
        remark5: '• មុននឹងរួមចំការការបំពេញការប្រាក់លំដាប់ អ្នកត្រូវតែចូលទៅទំព័រនេះដើម្បីចម្លងអាស័យដ្ឋាន ដោយការពិនិត្យឡើងវិញដោយប្រើនៅពេលណាមួយ។ អាស័យដ្ឋានខុសគ្នានឹងត្រូវធ្វើឱ្យការចំការការប្រាក់បរាជ័យ។',
        Pay: 'ទូទាត់',
        Buy: 'ទិញ',
        Merchant: 'អាជ្ញាប័ណ្ណតាមវិក្កយបត្ររបស់ប៉ុស្តិ៍',
        remark6: '* ចុចដើម្បីមើល',
        remark7: 'រៀបចំថ្លៃណាក៏អាចផ្លាស់ប្តូរ',
        remark8: 'ប្រៀបធៀបជាមួយនឹងបញ្ជាក់ទំនិញដែលបានបញ្ជាក់ សូមត្រូវការតាមដើម្បីប្តូរសេវាកម្មវិនិយោគតទ',
        remark9: 'សេវាកម្មអតិថិជនលើកទី',
        ChipPay: 'ប្រាក់កម្មសាន់ ChipPay',
        Purchasebyamount: 'ទិញតាមចំនួនទឹកប្រាក់',
        AccountBalance: 'សមតុល្យគណនី',
        Tips: 'ការណែនាំស្រេចចិត្ត'
    },
    ChipPay: {
        copySUC: 'បាន​ចម្លង​ដោយ​ជោគជ័យ',
        copyERR: 'ការ​ចម្លង​បាន​បរាជ័យ',
        Buy: 'ទិញ',
        TOBECONFIRMED: 'រង់ចាំការបញ្ជាក់',
        TransactionCompletion: 'ការបញ្ជាក់ជំនួស',
        OrderingInformation: 'ព័ត៌មានការបញ្ជាទិញ',
        oddNumbers: 'លេខ​សម្គាល់​: ',
        TransferAmount: 'ចំនួន​ទឹក​ប្រាក់​ដែលបាន​ផ្ទេរ​: ',
        ApproximatelyQuantity: 'ចំនួន​ប្រហែល​: ',
        UnitPrice: 'តម្លៃ​ឯកតា​: ',
        remark1: 'ពេលមិនចុច​ប៊ូតុង "ខ្ញុំ​បាន​ទទួល​ផ្ទេរ​ប្រាក់​ហើយ" ឬ​ចំនួន​ទឹក​ប្រាក់​ដែល​មិន​ត្រឹមតែ​ត្រឹម​ត្រូវ នឹង​បរាជ័យ​លើកទី​ហើយនិង​ដែលត្រឹមត្រូវតាម​មត្តា​លេខ​កូដ​ដូរ​ស្តង់ដាល/កំណែ​និង​សំគាល់/ការដាក់​ក្នុងបាតុភូតអ្នក​និង​ធ្វើឱ្យការបញ្ចុះតម្លៃ​លុយ​បានបង្ហាញ',
        remark2: 'ការណែនាំពេញលេញ',
        remark3: 'ពេលមានការទទួលបាននៅក្នុងការបញ្ជាទិញមួយពេលទេបានប្រសើរឡើងតែមួយដែលប្រយ័ត្ន(សូមកុំសរសេរិសាលទុកព័ត៌មានឡើងវិញសំរាប់ការទទួលគ្រាន់តែទៀត)',
        remark4: '【ពីរដងការបង្កើនបញ្ជាទិញដែលមិនបង់ក្នុងពេលក្រោយអាចបញ្ចុះលុយនៅហើយ】សូមព្យាយារត្រូវការប្រព័ន្ធប្រើ។',
        remark5: 'សូមអ្នកផ្ទេរប្រាក់រឺនៅក្នុងពេលវេលាដែលកំណត់ដើម្បីបង់ប្រាក់【សូមប្រើប្រាស់ប៊ូតុង "ខ្ញុំបានទទួលប្រាក់មួយស្រាប់ហើយ"】ដើម្បីបញ្ជាទិញសុវត្តិកររបស់អ្នក។',
        remark6: 'ប្រសិនបើអ្នកបានបាក់សម្រាប់【ប្រមូលជុំចំនួនប្រការណ៍បានបំបែក】ដោយជោគជ័យថា, ការប្រតិបត្តិការរបស់អ្នកធំជាងមធ្យោបាយដែលធ្វើឱ្យអ្នកដេញថ្លៃច្រើនខ្លួនឯងហើយ, សូមរង់ចាំតំបន់ពីរ​​-១០ នាទីយក្រោយពេលវេលាបន្ថែមមកហើយព្យាយាមផ្លាស់ប្ដូរការបញ្ជាទិញសុវត្តិ។',
        remark7: 'បញ្ជាប់ប្រាក់របស់អ្នកនឹងប្រតិបត្តិការតាមសន្សំរបស់អ្នកនៅក្នុងគណនីធនាគាររបស់អ្នក, ការប្រតិបត្តិការរបស់អ្នកនៅពេលបញ្ជាទិញនឹងត្រូវជាយួរតែដើម្បីលាស់គុណលុយដែលធ្វើអោយប្រាក់ឈ្នួល។',
        cancellation: 'បោះបង់ការបញ្ជាទិញ',
        remittance: 'ខ្ញុំបានបញ្ជាទិញរួចរាល់',
        remark8: 'ការប្រតិបត្តិការ',
        remark9: 'អ្នកនឹងយកនូវការពិពណ៌នាលើការប្រតិបត្តិការប្រាក់សាច់ប្រាក់របស់ USD របស់អ្នកក្នុងប្រព័ន្ធនេះ។ ព័ត៌មានដែលមាននៅក្នុងការប្រតិបត្តិការគឺអាចត្រូវបានការពិពណ៌នាដោយឱ្យចែករំលែកនៅពេលដែលការប្រតិបត្តិការចាប់ផ្តើម។ សូមអ្នកអានធ្វើការចូលទៅក្នុងលក្ខន្តិក័យឡើងវិញដើម្បីយល់ពីលក្ខន្តិវិនិច្ឆ័យរបស់ការជាសាច់ប្រាក់របស់អ្នកក្នុងក្រុមរបស់ USD។',
        remark10: 'សូមដឹងថាស្របច្បាប់នានាមិនត្រូវបាន ChipPay បោះបង់និងមិនអនុវត្តបង់កុំព្យូទ័រឡានិងមិនមានសកម្មភាពរួមគ្នា។',
        remark11: 'ពេលអ្នកអាចចូលទៅធ្វើការប្រតិបត្តិការជាសាច់ប្រាក់របស់អ្នកនៅក្នុងគណនី USD មានការលែងឆ្ពោះលើការសម្ភាសឲ្យយើងក្តីហ្វូនរបស់អ្នក។',
        remark12: 'សូមអានបន្តការប្រធានប្រព័ន្ធរបស់យើង៖ សុវត្ថិភាពមិនដែលមានសន្សំអ្នកក្នុងការប្រើប្រាស់របស់ឿងសាច់ប្រាក់គឺអ្នករក្សានៅលើការជាសាច់ប្រាក់នេះ។ សូមយល់ពីការលួបណ្ហចព្រមទាំងអ្នកដើម្បីឆ្លើយបញ្ជាក់ពីការប្រតិបត្តិការសាច់ប្រាក់នេះរវាងអ្នកនិងអ្នកក្នុងការឆ្លើយត្រឹមត្រូវ។',
        remark13: 'ខ្ញុំស្វែងរកឃើញហាមគ្រួសារការដែលអាចកើតឡើងពីការធ្វើការជាសាច់ប្រាក់ហើយបានទទួលយកនូវជំនួយពាក្យសំងាត់របស់ការផ្តល់សេវាកម្ម។',
        remark14: 'បើអ្នកមិនចុចលើប៊ូតុង "ខ្ញុំបានបញ្ចប់ការដែលបានផ្ទេររួចរាល់" ឬចំនូនទឹកប្រាក់ដែលអ្នកផ្ញើមិនដូចជាចង់នៅពេលផ្ញើម្ដងទៀតប្រាប់ថា នឹងបញ្ចប់នៅភាពរងកព្រំរួចទេហើយគ្មានការដែលគ្មាននៅពេលដែលផ្ញើម្ដងទេនិងពាក្យ/សំគាល់នឹងការដែលអាចបង់ក្រោយគ្រោងរងពេក។',
        remark15: '១.សូមបញ្ជាក់បានថា  ចំនូនទឹកប្រាក់ដែលអ្នកផ្ញើទាំងអស់គ្នាត្រូវតែនឹងបំពេញជាមធ្យោបាយប្រចាំថ្ងៃនៅជំពាក់គ្នានេះ។ បើអ្នកមិនផ្ញើទេរឬចំនូនទឹកប្រាក់ដែលអ្នកផ្ញើតាមកម្មវិធីនេះ ប្រសិនបើម្នកមិនរកឃើញការផ្ញើនោះទេប្រព័ន្ធនោះនឹងរកឃើញចិត្តទៅកាន់កម្មវិធីត្រួតគ្រប់យ៉ាង។',
        ConfirmedRemittance: 'បានបញ្ជាក់បានថាអ្នកបានបញ្ចប់ការដែលបានផ្ទេររួចរាល់',
        ConfirmRemittance: 'បញ្ជាក់ការដែលបានបញ្ចប់ហើយ',
        WrongPoint: 'ចំណាំខ្មែរ',
        Ialreadyknow: 'ខ្ញុំគ្រាន់ខ្ញុំដឹង',
        payattentionto: 'សូមយល់ពី',
        agree: 'យល់ព្រម',
        Bank: 'ធនាគារ​ទទួលប្រាក់៖',
        ReceivingAccount: 'គណនីទទួលបាន៖',
        Party: 'អ្នកដែលបានបញ្ជាទិញ៖',
        Authenticated: 'បានផ្ទៀងផ្ទាត់ទីតាំង',
        Margin: 'កម្រៃប្រាក់បំណុល',
        tips1: 'សូមធ្វើការជ្រើសរើសថាខ្ញុំបានសង្គ្រោះជំនួយពីព្រោះរាល់ការជាសាច់ប្រាក់'
    },
    newProperty: {
        title: 'ទឹកប្រាក់សរុប (USD)',
        Spot: 'អាចប្រើបានក្នុងការលក់ទំនិញ',
        Contract: 'អាចប្រើបានក្នុងការជួលទំនិញ',
        Bonus: 'បង្កាន់ដៃ',
        Purchase: 'ការទូទាត់',
        Deposit: 'ការបង្វិលប្រាក់',
        Withdraw: 'ការដកប្រាក់',
        Transfer: 'ការផ្ទេរ',
        Spot1: 'ទឹកប្រាក់ពិសេស',
        Contract1: 'ទឹកប្រាក់កិច្ចការ',
        Commission1: 'ទឹកប្រាក់ជំនួយ',
        Financial1: 'ទឹកប្រាក់និងប្រាក់ចង់ប្រើ',
        Balance: 'ទឹកប្រាក់ពិសេសសរុប',
        Hide: 'លាក់ទឹកប្រាក់ថាមប្រតិបត្តការ 0'
    },
    property: {
        Contract: 'ក្រុមហ៊ុន',
        Exchange: 'ការផ្លាស់ប្តូរលើតម្លៃជាសន្បិសុទ្ធ',
        zzc: 'តម្លៃសរុបពិសេស',
        mhyzh: 'គណនីក្នុងការផ្លាស់ប្តូរលើតម្លៃជាសន្បិសុទ្ធ',
        bbzh: 'គណនីក្នុងក្រុមហ៊ុន',
        available: 'អាចប្រើបាន',
        OnOrders: 'ការបញ្ជាទិញ',
        Estimated: 'តម្លៃសរុប',
        cw: 'ការចំណាយទូទាត់',
        number: 'លេខកូដ',
        record: 'កំណត់ត្រាការដកប្រាក់',
        Transfer: 'ការផ្ទេរ',
        time: 'ពេលវេលា',
        dhsl: 'ចំនួនប្តូរ',
        tips: 'សូមបញ្ជូលចំនួនប្តូរ',
        qbdh: 'ប្រអប់ប្តូរទាំងអស់',
        dhhl: 'អត្រា​ប្តូរ',
        ky: 'អាចប្រើបាន',
        kd: 'អាចទទួលបាន',
        sxfbl: 'អត្រា​ពិនិត្យថាស',
        sxf: 'អត្រា​ពិនិត្យថាស'
    },
    statusLang: {
        Review: 'ពិនិត្យឡើងវិញ',
        Success: 'ជោគជ័យ',
        Fail: 'បរាជ័យ'
    },
    with: {
        title: 'ការដកប្រាក់',
        history: 'ប្រវត្តិដកប្រាក់',
        ldz: 'អាសយដ្ឋានតំបន់',
        tbdz: 'អាសយដ្ឋានកាត់ប្រាក់',
        tips: 'សូមបញ្ចូលអាសយដ្ឋានកាត់ប្រាក់',
        tips1: 'សូមបញ្ចូលចំនួនកាត់ប្រាក់',
        tips2: 'សូមបញ្ចូលពាក្យសង្គ្រាម',
        tbsl: 'ចំនួនកាត់ប្រាក់',
        ky: 'អាចប្រើបាន',
        zjmm: 'ពាក្យសង្គ្រាមទូទាត់',
        sxf: 'អត្រាថាស',
        dz: 'ចំនួនប្រដាល់គណនី'
    },
    tabBar: {
        home: 'ទំព័រដើម',
        Quotation: 'តម្លៃទំនេញ',
        Quotation2: 'តម្លៃទំនាក់ទំនងផ្សារ',
        Trade: 'ប្រតិបត្តិការ',
        Contract: 'កិច្ចការពិនិត្យ',
        newContract: 'ការជួសជុលថ្មី',
        assets: 'ទ្រព្យសកម្ម',
        qhyy: 'ប្ដូរភាសា',
        zyjb: 'បង្គន់បុកប្រែក្រោយ',
        jsjy: 'ពានរង្វាន់យ៉ាងហោចណាស់',
        jryw: 'មូលប្រតិបត្តិការហិរញ្ញវត្ថុ',
        hyjy: 'ប្រតិបត្តិការកិច្ចការ',
        Frequently: 'ជាសំបុត្រ',
        mine: 'ខ្ញុ'
    },
    exchange: {
        // 闪兑换
        title: 'ប្រតិបត្តិការប្រែប្រួល',
        canChange: 'ប្រលប្រួលដោយចំនួលបាន',
        canChange1: 'សូមបញ្ចូលចំនួនប្រែប្រួលដែលអាច',
        title1: 'បញ្ជីប្រែប្រួល',
        Available: 'ជាធានាគណនេយ្យដែលអាច',
        Proposed: 'តម្លៃគិតថ្លៃ',
        Redeem: 'ទាំងអស់ការប្រែប្រួល',
        Confirm: 'បញ្ជាក់',
        CurrencyBalance: 'សមតុល្យប្រាក'
    },
    home: {
        // grid
        gridcz: 'ការបញ្ចូលរូបិយប័ណ្ណស្របច្បាប់',
        gridsd: 'ការដកប្រាក់រូបិយប័ណ្ណ',
        gridxs: 'ការអានអំពីការចូលរួមជាភូមិសាស្ត្រ',
        gridbd: 'តារាង',
        Balance: 'តុល្យប្រាក់',
        UserName: 'ឈ្មោះគណនី',
        kjmb: 'ការប្រាក់បញ្ចូល',
        score: 'ពិន្ទុការងារ',
        zc: 'គាំទ្រ BTC និង USD ជាដើម',
        scwk: 'ការធ្វើស្តុកបុកប្រែ',
        sgxb: 'ការបញ្ជាទិញប្រាក់ថ្មី',
        aqkx: 'សុវត្ថិភាពត្រឹមត្រូវប្រុសិន',
        aqkx1: 'ប្រព័ន្ធដើម្បីអាប់ដេតនិងអនុវើកទីផ្សារប្រេអិហារស្តង់ដា។',

        // 榜單
        rmcjb: 'ការលក់ពេញលេញ',
        xbb: 'តារាងប្រភេទមូលដ្ឋានថ្មី',
        zfb: 'តារាងនិងចុងក្រោយថ្មី',
        dfb: 'តារាងនិងចុងក្រោយថ្ម',

        //認定
        identify: 'មិនបានផ្ទៀងផ្ទាត់',
        identifyYes: 'បានផ្ទៀងផ្ទាត់',
        changeName: 'បញ្ចូលឈ្មោះហៅ',
        changeName1: 'ឈ្មោះហៅអាចប្ដូរបានចំនុះបន្ទាប់មកប៉ុណ្ណោះ',
        confirmName: 'បញ្ជាក់',
        closeName: 'បោះបង់',

        mc: 'ឈ្មោះ',
        hy: 'កិច្ចការ',
        zxj: 'តម្លៃ​ថ្មី',
        zdb: 'បញ្ចុះ​តម្លៃ',
        sfyz: 'ការ​ផ្ទៀងផ្ទាត់​សម្រាប់​អតិថិជន',
        skfs: 'វិធីសាស្ត្រ​ការទូទាត់',
        share: 'តំណភ្ជាប់ចែករំលែក​របស់ខ្ញុំ',
        kjcb: 'ការផ្ទៀងផ្ទាត់',
        kjtb: 'ការដកប្រាក់',
        qbdz: 'អាសយដ្ឋានអំណាប់ត្រាដើម',
        bps: 'សៀវភៅសម្រាប់តំណក់',
        xgmm: 'កែប្រែពាក្យសម្ងាត់',
        xgzfmm: 'កែប្រែពាក្យសម្ងាត់ទូទាត់',
        gywm: 'អំពីពួកយើង',
        tcdl: 'ចេញពីប្រព័ន្ធតុ',

        // 個人中心
        zjmx: 'ប្រវត្តិនៃជំនាញសាធារណៈ',
        cdsz: 'កំណត់',
        cdaq: 'ការផ្ទៀងផ្ទាត់ព័ត៌មានឯកជន',
        bzzx: 'មជ្ឈមណ្ឌលជំនួយ',
        ysxy: 'របាយការណ៍ភាពឯកជន',
        xzyy: 'ជ្រើសរើសភាសា'
    },
    commonUse: {
        title: 'សេវាកម្ម',
        Finance: 'ហិរញ្ញវត្ថុ',
        Notice: 'សេចក្ដីជូនដំណឹង',
        customerService: 'ការបង្រៀនដៃអតិថិជន',
        cc: 'ការទុកគីឡូក្រាម',
        zjmx: 'ប្រវត្តិចំណាយ',
        cpdm: 'ឈ្មោះផលិតផល',
        mm: 'ទិញ/លក់',
        wtj: 'តម្លៃប្រាក់',
        wtsl: 'ចំនួនភ្នាល់សង្វាក់',
        Buy: 'ទិញ',
        Sell: 'លក់',
        Buy1: 'ទិញ',
        Sell1: 'លក់',
        Buy2: 'ទិញពាស់មួយគ្រាប់',
        Exchangequantity: 'ចំនួនប្ដូរ',
        Exchangeamount: 'ចំនួនជាក់លាក់',
        Currency: 'រូបិយប័ណ្ណ',
        Status: 'ស្ថានភាពការកុម្មង់',
        Type: 'ប្រភេទ',
        Service: 'សេវាកម្ម',
        p1: 'សូមជ្រើសរើសរបស់រូបិយប័ណ្ណ',
        p2: 'សូមបញ្ចូលចំនួនជាក់លាក់',
        czlb: 'ប្រតិបត្តិការ'
    },
    product: {
        Buy: 'ទិញ',
        Sell: 'លក់',
        Gain: 'កម្រិតបន្ថែម',
        Trade: 'ការជួសជុល',
        Real: 'ពិត',
        High: 'ខ្ពស់ជាងគេ',
        ClosingPrice: 'តម្លៃបិទ',
        Low: 'ទាបជាងគេ',
        Volume: 'ការចំណាយក្នុង 24 ម៉ោង',
        Limit: 'ការទិញតាមដានតម្លៃអតិបរិមា',
        Contract: 'កិច្ចការទុកគីឡូក្រាម',
        News: 'កិច្ចការទូរស័ព្ទ',
        Liquidations: 'ព័ត៌មានលក់ទិញបានជាវ',
        ContractType: 'ប្រភេទកិច្ចការ',
        OpeningPrice: 'តម្លៃកែប្រែចាប់ផ្តើម',
        Leverage: 'ភាពស្រួលដល់',
        LeverageP: 'ភាគរយមិនអាចទទួលបានទឹកប្រាក់',
        LimitPrice: 'ចំនួនទឹកប្រាក់នៃប្រតិបត្តិការ',
        LimitPlaceholder: 'សូមបញ្ចូលតម្លៃទឹកប្រាក់កែប្រែ',
        Overnight: 'របៀបទន់ហេតុប៉ុណ្ណោះ',
        Direction: 'ទិសដៅ',
        Operate: 'ប្រតិបត្តិការ',
        Long: 'ទិញ/ចូលក្នុង',
        Short: 'លក់/ចេះក្នុង',
        Balance: 'សមត្ថភាពក្រុមគណនីកិច្ចការ',
        Floating: 'ការចាប់ផ្តើមរបស់ទិន្នន័យ',
        CloseAll: 'សាកល្បងទាំងអស់',
        MarketOrder: 'ការបង្វិលតម្លៃផ្សេងៗ',
        LimitOrder: 'ការបញ្ចូលតម្លៃកែប្រែ',
        Cross: 'លក់ផ្សេងៗ',
        Isolated: 'មិនអាចចាប់យកជាមួយ',
        placeholder: 'ប្រើតម្លៃស្មើអត់ត្រូវរក្សា​តាម​​តម្លៃ​អតិបរិមា​ប៉ុ',
        Margin: 'បរិមាណ',
        MarginBalance: 'សមត្ថភាពប្រាក់បញ្ចុះបរិមាណ',
        TradingFee: 'ថ្លៃប្រើការជួសជុល',
        Position: 'ការរៀបចំ',
        Success: 'បានជោគជ័យ',
        Commission: 'ការកំណត់',
        Transaction: 'ការជួសជុល',
        Completed: 'បានបញ្ចេញជាប់',
        Cancellation: 'បោះបង់',
        unitPrice: 'តម្លៃឯកតា',
        LeverageRatio: 'អត្រា​កម្រិត​បំបន់​ខ្សែ',
        cancellation: 'បោះបង់',
        ClosingPosition: 'បិទការរៀបចំ',
        productName: 'ឈ្មោះផលិតផល',
        Margin1: 'ប្រាក់បញ្ចុះបរិមាណ',
        SettlementPrice: 'តម្លៃការរក្សាទុក',
        EndTime: 'ពេលវេលា​ចុងក្រោយ',
        CreateTime: 'ពេលការបង្កើត',
        WalletBalance: 'សមត្ថភាពសន្តិសុខ',
        AvailableFunds: 'ឧបករណ៍​ដែល​អាច​ប្រើ​បាន',
        Text1: 'សំអាត',
        Text2: 'តើអ្នកចង់អនុវត្តការបំពេញប្រតិបត្តិការនេះដែរឬទេ?',
        qr: 'យល់ព្រម',
        qx: 'បោះបង់',
        ddbh: 'លេខ​លំដាប់',
        wtsj: 'ពេលវេលា​ធាតុ​',
        szzyzs: 'កំណត់យកប្រាក់ចំណេញ និងបញ្ឈប់ការខាតបង់',
    },
    notice: {
        title: 'ប្រកាស',
        ckxq: 'មើល​សេចក្ដី​លម្អិត'
    },
    helpCenter: {
        title: 'អាចចូលរៀន'
    },
    pwdGroup: {
        title: 'ការដាក់ពាក្យសម្ងាត់',
        pwdDL: 'ពាក្យសម្ងាត់ចូលគណនី',
        pwdZj: 'ពាក្យសម្ងាត់ធនាគារ'
    },
    quotation: {
        title: 'ស្តុក',
        mc: 'បរិមាណ',
        zxj: 'តម្លៃថ្មីៗ',
        zdb: 'បរិមាណកម្រិតការចូលដំណើរការ',
        liang: 'ចំនួ'
    },
    contract: {
        kcslfw: 'ជួរឈរចំនួនបើកភាពជារូបភាព:',
        gao: 'ខ្ពស់',
        di: 'ទាប',
        liang: 'បរិមាណក្នុងរយៈពេល ២៤ម៉ោង',
        placeholder: 'សូមបញ្ចូលបរិមាណ',
        SuccessfulPurchase: 'ការទិញជោគជ័យ',
        smallMin: 'នាទីតិច',
        hour: 'ម៉ោង',
        dayMin: 'ថ្ងៃ',
        Trend: 'ដំណើរការ',
        ccjl: 'បញ្ចូលការកាត់តាមការរួមចំនួនសម្រាប់បើក',
        ddjl: 'កំណត់ការបរិច្ឆេទចាក់មិនស្នើសុំ',
        sl: 'បរិមាណ',
        gmj: 'តម្លៃទិញ',
        dqj: 'តម្លៃបច្ចុប្បន្ន',
        yjyk: 'ចំណេះដឹងសម្រាប់ប្រាក់គំរូ',
        djs: 'រយៈពេលបោះត្រង់ថយក្រោយ',
        gbjg: 'តម្លៃលក់កក់ប្រាក់',
        sc: 'ថេរភ្ជាប់',
        pal: 'ប្រាក់រក្សាទុក',
        mhy: 'ប្រសិនធឺណិត',
        sj: 'ពេលវេលា',
        yll: 'អត្រាប្រាក់ចំណេះដឹង',
        mz: 'ទិញឡើង',
        md: 'ទិញចុះតំលៃ',
        kcsl: 'ចំនួនក្នុងការបើក',
        kcsj: 'ពេលបើក',
        zhye: 'សមតុល្យគណនី',
        qc: 'បោះបង់'
    },
    editPassword: {
        title: 'កែប្រែពាក្យសម្ងាត់ចូលគណនី',
        jmm: 'ពាក្យសម្ងាត់ចាស់',
        xmm: 'ពាក្យសម្ងាត់ថ្មី',
        qrmm: 'បញ្ជាក់ពាក្យសម្ងាត់',
        placeholder: 'ពាក្យសម្ងាត់ចាស់',
        placeholder1: 'កំណត់ពាក្យសម្ងាត់ថ្មី',
        placeholder2: 'សូមបញ្ជូនពាក្យសម្ងាត់ថ្មីម្តងទៀត',
        qrxf: 'បញ្ជាក់'
    },
    secure: {
        // 安全認證
        title: 'សុវត្ថិភាព',
        title1: 'ការផ្ទៀងផ្ទាត់លទ្ធផលពីរបីចាស់',
        title2: 'ដើម្បីគាំទ្រគណនីរបស់អ្នកយើងសុវត្ថិភាពការបន្តក្នុងគោលនយោបាយរបស់យើង',
        gridTxt: 'ការកំណត់ពាក្យសម្ងាត់',
        gridTxt1: 'ការផ្ទៀងផ្ទាត់លទ្ធផលពីទូរស័ព្ទ',
        gridTxt2: 'ការផ្ទៀងផ្ទាត់លទ្ធផលពីអ៊ីម៉ែល',
        gridTxt3: 'ការផ្ទៀងផ្ទាត់លទ្ធផលពីអត្តសញ្ញាណប័ណ្ណ',
        listTxt: 'កាតធនាគារ',
        listTxt1: 'ការគ្រប់គ្រងអាសយដ្ឋានយកប'
    },
    bindBank: {
        // 銀行卡
        title: 'កាតធនាគារ',
        addCard: 'បន្ថែមកាតដំណើរការ',
        editCard: 'កែប្រែពាក្យសម្ងាត់ការធ្វើបរិច្ឆេទបង់ប្រាក់',
        ts1: 'អ្នកមិនទាន់ធ្វើបរស់ប្រាក់សម្រាប់អ្នកនោះទេ',
        ts2: 'សូមធ្វើការធ្វើបរស់ប្រាក់ជាមុន',
        addTitle: 'បន្ថែមកាតធនាគារ',
        userName: 'ឈ្មោះពិតកម្ម',
        tips1: 'សូមបញ្ចូលឈ្មោះពិតកម្មអ្នកឡើងវិញ',
        CollectionDetailArea: 'អាសយដ្ឋានលំអិតអ្នកទទួលប្រាក់',
        tips6: 'សូមបញ្ចូលអាសយដ្ឋានលំអិតអ្នកទទួលប្រាក់',
        userPhone: 'លេខទូរស័ព្ទរបស់អ្នក',
        tips2: 'សូមបញ្ចូលលេខទូរស័ព្ទរបស់អ្នក',
        bankName: 'ឈ្មោះធនាគារ',
        tips3: 'សូមបញ្ចូលឈ្មោះធនាគារ',
        bankAds: 'អាសយដ្ឋានធនាគារ',
        tips5: 'សូមបញ្ចូលអាសយដ្ឋានធន',
        bankId: 'លេខគណនីទទួលប្រាក់',
        tips4: 'សូមបញ្ចូលលេខគណនីទទួលប្រាក់',
        CountryCode: 'លេខកូដប្រទេស',
        tips7: 'សូមជ្រើសរើសលេខកូដប្រទេស',
        tips8: 'សូមបញ្ចូល SWIFT Code',
        tips9: 'សូមបញ្ចូលចំនួនទឹកប្រាក់',
        tips10: 'ចំនួន នៃ ការ សងសឹក ឬ ការ ដក ចេញ មិន អាច មាន តិច ជាង ១០០',
        addTxt: 'រក្សាទុ'
    },
    bindMail: {
        // 綁定郵箱
        title: 'រក្សាទុកអ៊ីមែល',
        placeholder1: 'សូមបញ្ចូលអាសយដ្ឋានអ៊ីមែល',
        ptitle: 'កែប្រែលេខទូរស័ព្ទ',
        placeholder2: 'សូមបញ្ចូលលេខទូរស័ព្ទថ្មី',
        next: 'ជំហានទៀត',
        zjmm: 'ពាក្យសម្ងាត់មុនចូលបន្ទប់',
        placeholder3: 'សូមបញ្ចូលពាក្យសម្ងាត់មុនចូលបន្ទ'
    },
    setting: {
        // 設置
        title: 'ការកំណត់',
        setTxt: 'រូបភាពផ្ទាល់ខ្លួន',
        setTxt1: 'ភាសា',
        setTxt2: 'អត្រាប្តូរប្រាក់',
        setTxt3: 'អំពីយើង'
    },
    withPass: {
        // 設置資金密碼
        title: 'កំណត់លេខសម្ងាត់មុនចូលបន្ទប់',
        title1: 'លេខសម្ងាត់មុនចូលបន្ទប់មិនអាចដូចនឹងលេខសម្ងាត់ចូលធ្វើបរស់ដោយគ្មានការផ្លាស់ប្តូ',
        // 綁定郵箱
        aqyz: 'ការផ្ទៀងផ្ទាត់សុវត្ថិភាព',
        aqyzm: 'លេខកូដផ្ទៀងផ្ទ',

        jmm: 'លេខសម្ងាត់ចាស់',
        xmm: 'លេខសម្ងាត់ថ្មី',
        qrmm: 'បញ្ជាក់លេខសម្ងាត់',
        placeholder: 'លេខសម្ងាត់ចាស់',
        placeholder1: 'កំណត់លេខសម្ងាត់ថ្មី',
        placeholder2: 'សូមបញ្ចូលលេខសម្ងាត់ថ្មីម្តងទៀត',
        qrxf: 'បញ្ជាក់',
        tips: 'សូមបញ្ចូលលេខសម្ងាត់ចាស់ជាយលេណា។',
        tips1: 'សូមបញ្ចូលលេខសម្ងាត់ថ្មីជាយលេណា។',
        tips2: 'សូមបញ្ចូលលេខសម្ងាត់បានត្រឹមត្រូវ។',
        tips3: 'លេខសម្ងាត់ថ្មីមិនត្រូវគ្នានឹងលេខសម្ងាត់បច្ចុប្បន្ន។',
        tips4: 'កំណត់លេខសម្ងាត់បានជោគជ័យ។',
        confirm: 'បញ្ជាក់'
    },
    newBe: {
        title: 'ការទិញប្រាក់ថ្លៃថ្មី',
        title1: 'ការទិញរបស់ខ្ញុំ',
        scqx: 'រយៈពេលកំណត់បង់ប្អូន',
        day: 'ថ្ងៃ',
        sy: 'នៅសល់',
        sdsg: 'កំណត់យកប្រតិបត្តិការ',
        sysl: 'ចំនួនសមនុស្សដែលនៅសល់',
        gmsl: 'ចំនួនដែលបានទិញ',
        dj: 'តម្លៃលក់របស់មួយ',
        tips: 'សូមបញ្ចូលចំនួនដែលត្រូវទិញ',
        gmzj: 'ចំនួនទឹកប្រាក់សរុបដែលត្រូវទិញ',
        qbye: 'សមតុល្យប្រាក់សមរម្យ',
        fmzj: 'ចំនួនទឹកប្រាក់សរុបដែលត្រូវទិញ',
        gm: 'ទិញ',
        sgsj: 'ពេលវេលាក្នុងការបញ្ជាទិញ',
        Currency: 'រូបិយប័ណ្ណ',
        Request: 'ចំនួនសម្រួលដើម្បីស្នើសុំ',
        Passing: 'ចំនួនដែលត្រូវបានទទួលយក',
        MarketTime: 'ពេលវេលាត្រូវបានបញ្ចាំង',
        sqz: 'កំពុងស្នើសុំ'
    },
    register: {
        title: 'ចុះឈ្មោះ',
        lang: 'ភាសា',
        send: 'ផ្ញើ',
        Resend: 'ផ្ញើម្ដងទៀត',
        Account: 'គណនី',
        Password: 'ពាក្យសម្ងាត់',
        ConfirmPassword: 'បញ្ជាក់ពាក្យសម្ងាត់',
        InvitationCode: 'លេខកូដអញ្ជើញ',
        btn: 'ចុះឈ្មោះ',
        amount: 'ចំនួនគណនី',
        mailbox: 'អាសយដ្ឋានសារ',
        haveAccount: 'មានគណនីរួចហើយឬទេ?',
        haveLogin: 'ចូលទៅប្រើប្រាស់ឥឡូវនេះ',
        Login: 'ចូលប្រើប្រ',
        placeholder: 'សូមបញ្ចូលអាសយដ្ឋានអ៊ីមែល',
        placeholder0: 'សូមបញ្ចូលលេខទូរស័ព្ទ',
        placeholder1: 'សូមបញ្ចូលពាក្យសម្ងាត់',
        placeholder2: 'សូមបញ្ជាក់ពាក្យសម្ងាត់',
        placeholder3: 'សូមបញ្ចូលលេខកូដសម្ងាត់',
        placeholder4: 'សូមបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់',
        placeholder5: 'សូមបញ្ចូលអាសយដ្ឋានអ៊ីមែលត្រឹមត្រូវ',
        success: 'បានចុះឈ្មោះដោយជោគជ័យ',
        message: 'ពាក្យសម្ងាត់មិនអាចតូចជាង៦តួអក្សរ',
        message1: 'ពាក្យសម្ងាត់បញ្ជាក់មិនអាចតូចជាង៦តួអក្សរ',
        message2: 'ពាក្យសម្ងាត់ពីរនៃការបញ្ជាក់មិនផ្គូផ្គង',
        message3: 'សូមអាននិងគូសចម្រៀងការប្រើប្រាស់',
        agree: 'ខ្ញុំយល់ព្រម',
        xy: 'សម្រាប់គោលការណ៍អ្នកប្រើប្រាស់និងសមភាព',
        qr: 'ចុះឈ្មោះឥឡូវនេះ',
        // 找回密碼
        ftitle: 'រកឃើញពាក្យសម្ងាត់',
        qrzh: 'បញ្ជាក់',
        placeholder7: 'សូមបញ្ចូលពាក្យសម្ងាត់ថ្មី',
        placeholder6: 'សូមបញ្ចូលពាក្យសម្ងាត់ថ្មីម្តងទៀត',
        placeholder8: 'សូមបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់រូបសម្រាប់អេក្រង់',
        placeholderName: 'សូមបញ្ចូលឈ្មោះអ្នកប្រ'
    },
    lockming: {
        title: 'បញ្ជីនិមិត្តសង្វឹកដែលបានចុះកិច្ចការ',
        wkb: 'មើលណាស់',
        wkgjd: 'សង្វឹកពីរៀបចំច្រើនជាយ៉ាងហោចណាស់',
        qsljl: 'ទទួលបានរង្វាន់ច្រើន',
        yjzsr: 'ទឹកប្រាក់រង្វាន់សរុប',
        ck: 'អត្រា​ការចុះគ្រប់គ្រង់​អត្រា​ពីរក្នុងរយៈពេល៣ថ្ងៃ',
        tips: 'យ៉ាងមានយ៉ាងហោចណាស់ ',
        rlx: 'ការរក្សាចំនេះដើម្បីមានការប្រាក់ប្រំពេញក្នុងមួយថ្ងៃ',
        day: 'ថ្ងៃ',
        scqx: 'រយៈពេលនៃការបង់ប្រាក់អេក្រង់',
        buy: 'ទិញ',
        zysl: 'តម្លៃការរក្សាទុក',
        qbye: 'តុល្យការរបស់អ្នក',
        finished: 'បានបញ្ចប់',
        doing: 'កំពុងរត់...',
        sl: 'បញ្ជារ',
        shouyilv: 'អត្រាភាគរយ',
        ks: 'ចាប់ផ្ដើមពេលវេលា',
        js: 'បញ្ចប់ពេលវេលា'
    },
    login: {
        title: 'ចូលគណនី',
        lang: 'ភាសា',
        mobile: 'លេខទូរស័ព្ទ',
        mailbox: 'អាស៊ីម៉ែល',
        Account: 'គណនី',
        Password: 'ពាក្យសម្ងាត់',
        btn: 'ចូលគណនី',
        placeholder1: 'សូមបញ្ចូលពាក្យសម្ងាត់',
        placeholder: 'សូមបញ្ចូលគណនី',
        fail: 'សូមបំពេញពត៌មានទាំងអស់',
        ForgotPassword: 'ភ្លេចពាក្យសម្ងាត់?',
        NoAccount: 'គ្មានគណនី?',
        Registration: 'ចុះឈ្មោះឥឡូវនេះ',
        server: 'កំពុងសេវាកម្មលើអគ្គិសនី',
        jzmm: 'ចងចាំពាក្យសម្ងាត់',
        success: 'ចូលគណនីបានជោគជ័យ',
        Home: 'ទំព័រដើម',
        userbox: 'ឈ្មោះអ្នកប្រើប្រាស'
    },
    moneyAddr: {
        title: 'អាសយដ្ឋានសាកល្បង',
        Currency: 'រូបិយប័ណ្ណ',
        Address: 'អាសយដ្ឋាន',
        placeholder: 'សូមជ្រើសរើស',
        DepositAddress: 'អាសយដ្ឋានក្នុងគណនី',
        WalletAddress: 'អាសយដ្ឋានសាកល្បង',
        Date: 'កាលបរិច្ឆេទ',
        // 添加錢包地址
        add: 'បន្ថែមអាសយដ្ឋានសាកល្បង',
        addTxt: 'អាសយដ្ឋានត្រូវបានបន្ថែមច្រើនបំផុត',
        remark: 'សំគាល់',
        Walletremark: 'សូមបន្ថែមសំគាល់សេចក្តីអំពីអាសយដ្ឋាននេះ',
        network: 'បណ្តាញគំរូ (ប្រសិនបើវា)',
        networkXy1: 'សូមជ្រើសរើសបណ្តាញដែលអ្នកបានជ្រើសរើស',
        networkXy2: '។ សូមផ្ទៀងផ្ទាត់ថាអាសយដ្ឋានត្រូវបានបណ្ដូលតាមតម្រូវិស័យ។ ប្រសិនបើប្រព័ន្ធបញ្ជូនទិន្នន័យមិ',

        ChainAddress: 'អាសយដ្ឋានខ្លោច',
        qrcode: 'កូដ QR សាកល្បង',
        submit: 'បញ្ជាក់',
        tips: 'សូមជ្រើសរើសប្រភេទសាកល្បង',
        tips1: 'សូមជ្រើសរើសប្រភេទអាសយដ្ឋានខ្លោច',
        tips2: 'សូមបញ្ជូលអាសយដ្ឋានសាកល្បង',
        tips3: 'សូមផ្ទុកកូដ QR សាកល្បងអាសយដ្ឋានសាកល្បង',
        success: 'ជោគជ័យ'
    },
    recharge: {
        title: 'បញ្ចូល',
        title1: 'បញ្ចូលទៅលើសំណល់',
        ls: 'សំណល់បញ្ចូល',
        td: '​ សូមមើលទាំងនេះសំណល់បញ្ចូល',
        bc: 'រក្សាទុក QRcode',
        address: 'លេខកូដបញ្ចូល',
        copy: 'ចុះឈ្មោះ',
        num: 'ចំនួនបញ្ចូល',
        Upload: 'ចុះប្រកិតការបញ្ចូលពីការបញ្ចូល',
        Submit: 'សុំចុះ',
        tip: '​ សូមមើលទាំងនេះ',
        tip1: '​ សូមចុះប្រកិតការបញ្ចូល',
        copySuc: 'ចុះឈ្មោះបានប្រកិត'
    }
}