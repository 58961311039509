import { fetch, post } from '@/utils/axios'

export default {
    // 获取图片验证码 调用频率限制终端和IP
    getCaptchaImg: (params) => {
        return fetch('/Captcha/img', params)
    },
    // 发送国际短信
    getCaptchaSms: (data) => {
        return post('/Captcha/sms', data)
    },
    // 获取邮箱验证码，生成token，发送验证码并返回
    getCaptchaEmail: (data) => {
        return post('/Captcha/email', data)
    },
    // 用户注册接口
    Register: (data) => {
        return post('/Account/register', data)
    },
    // 登录获取刷新token
    getAccountRefresh: (data) => {
        return post('/Account/refresh', data)
    },
    // 通过刷新token 获取授权token
    getAccountToken: (token) => {
        return fetch(`/Account/${token}`)
    },
    // 获取用户基本信息
    getAccountBase: (params) => {
        return fetch('/ex/Account/base', params)
    },
    // 修改头像
    UpdateHead: (data) => {
        return post('/ex/Account/UpdateHead', data)
    },
    // 修改昵称
    UpdateNickName: (data) => {
        return post('/ex/Account/UpdateNickName', data)
    },
    // 修改默认语言
    UpdateLang: () => {
        return post('/ex/Account/UpdateLang')
    },
    // 修改登录密码
    UpdatePassword: (data) => {
        return post('/ex/Account/UpdatePassword', data)
    },
    // 修改资金密码
    UpdateFundPassword: (data) => {
        return post('/ex/Account/UpdateFundPassword', data)
    },
    // 设置资金密码
    SetFundPassword: (data) => {
        return post('/ex/Account/SetFundPassword', data)
    },
    // 校验资金密码
    CheckFundPassword: (data) => {
        return post('/ex/Account/CheckFundPassword', data)
    },
    // 实名认证
    Authentication: (data) => {
        return post('/ex/Account/authentic', data)
    },
    // 高级认证
    highAuthenticAccount: (data) => {
        return post('/ex/Account/senior', data)
    },
    // 获取搜索记录
    GetSearchLog: (params) => {
        return fetch('/ex/Account/GetSearchLog', params)
    },
    // 新增搜索记录
    AddSearchLog: (data) => {
        return post('/ex/Account/AddSearchLog', data)
    },
    // 清除搜索记录
    DelSearchLog: (params) => {
        return fetch('/ex/Account/DelSearchLog', params)
    },
    // 获取资金明细
    GetBalanceChange: (params) => {
        return fetch('/ex/Account/GetBalanceChange', params)
    },
    // 获取充币记录
    GetRechargeLog: (params) => {
        return fetch('/ex/Swap/GetRechargeLog', params)
    },
    // 获取提币记录
    GetWithdrawalRecord: (params) => {
        return fetch('/ex/Swap/GetWithdrawalRecord', params)
    },
    // 获取消息通知记录
    GetNotifyLog: (params) => {
        return fetch('/ex/Account/GetNotifyLog', params)
    },
    // 更新消息通知已读
    UpNotifyReader: (params) => {
        return fetch('/ex/Account/UpNotifyReader', params)
    },
    // 修改提币地址
    UpdateWalletAddress: (data) => {
        return post('/ex/Account/UpdateWalletAddress', data)
    },
    // 充币
    Recharge: (data) => {
        return post('/ex/Swap/Recharge', data)
    },
    // 提币
    Withdrawal: (data) => {
        return post('/ex/Swap/Withdrawal', data)
    },
    // 获取资金账户列表
    GetLegalWallets: (params) => {
        return fetch('/ex/Swap/GetLegalWallets', params)
    },
    // 申请成为经纪人
    ApplyTeamV: (params) => {
        return fetch('/ex/Account/ApplyTeamV', params)
    },
    // 币币兑换
    SwapExchange: (data) => {
        return post('/ex/Swap/SwapExchange', data)
    },
    // 获取资金账户余额
    GetLegalWalletsBalance: (params) => {
        return fetch('/ex/Swap/GetLegalWalletsBalance', params)
    },
}