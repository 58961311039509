module.exports = {
    pcregister: {
        p1: 'Gibt es eigentlich ein konto?',
        dl: 'Einloggen.',
        zc: 'Eine liste ausfüllen.',
        p4: 'Konto.',
        p5: 'Bitte geben sie die kontonummer ein',
        p6: 'Code eingeben.',
        p7: 'Bitte geben sie das bestätigungspasswort ein',
        p8: 'Bitte geben sie den zugangscode ein',
        p9: 'Ja, das sehe ich auch so.',
        p10: 'Nutzerfreundschaften und datenschutzrichtlinien zu erarbeiten',
        p11: 'Briefkasten.',
        p12: 'Bitte geben sie ihre e-mail-adresse ein',
        p13: 'Bitte geben sie einen authentifizierungscode für eine e-mail ein',
        p14: 'Senden.',
        p15: 'Ein jahr lang.',
        p16: 'im',
        p17: 'Für einen tag.',
        p18: 'Stunde.',
        p19: 'Minuten?',
        p20: 'sekunden',
    },
    pclogin: {
        l1: 'Es gibt kein konto?',
        l2: 'Eine liste ausfüllen.',
        l3: 'Einloggen.',
        l4: 'Geben sie einen benutzernamen ein',
        l5: 'Code eingeben.',
        l6: 'Ich hab die kombination vergessen.',
        l7: 'Einloggen.',
    },
    pccontract: {
        ss: 'Marktpreis.',
        xj: 'Fester preis.',
        gg: 'Auffällig hoch.',
    },
    pcrapidly: {
        zf: 'Erhöhen.',
        dd: 'Emir.',
        bdsj: 'Ortszeit?',
        ky: 'Nimm sie.',
    },
    pchome: {
        global1: 'Die weltweit führende plattform für digitale vermögenswerte',
        global2: 'Es bietet millionen Von nutzern in über 100 ländern sichere und verlässliche dienste für den handel mit digitalen vermögenswerten und die verwaltung Von vermögenswerten.',
        global3: 'Die ist sicher. Die ist sicher.',
        global4: 'Fünf jahre erfahrung bei digitalen anlagedienstleistungen. Fokussiert auf eine dezentralisierte struktur und systeme zur abwehr Von DDoS.',
        global5: 'Globale ökologie',
        global6: 'Mehrere länder haben gerade ortsgebundene handelszentren eingerichtet und dabei einen integrierten sektor-ökosystem geschaffen.',
        global7: 'Nummersex ist das erste mal.',
        global8: 'Mechanismen zur kompensierung der schäden und zum schutz der investoren einrichten.',
        whever1: 'Sie wird jederzeit und überall mit ihren plattformen gehandelt',
        whever2: 'Es greift mehrdimensionale betriebssysteme, beispielsweise iOS, Android und Windows, an und unterstützt vollgeschäftsfunktionen.',
        register1: 'Registriere jemanden.',
        register3: 'Globale konten sind transaktionen',
        register2: 'Melden sie sich zum 1. Mal an.',
        commodity: 'Handelswaren.',
        news: 'Nachrichten.',
        ttop1: 'Um die rätsel Von Pinnacle zu enträtseln',
        ttop2: 'Pinnacle ist eine internationale börse aus handel mit termingeschäften und optionen, landwirtschaftlichen, energiealternativen, währungen und metallzielen. Seit ihrer gründung haben börsen ihre handelsregeln ständig verfeinert, und die transaktionen Von verschiedenen produkten sind immer mehr zurückgegangen. Es hat das geschäft auf kunden - und institutionelle kunden in über 30 ländern ausgeweitet und wächst weiter. Wir versuchen, die transaktionskosten so niedrig wie möglich zu halten und dienstleistungen in kooperativer weise zu erbringen, die unseren kunden helfen, nicht nur profitable händler zu werden. Denn wenn du es richtig machst, machen wir es auch richtig.',
        ttop3: 'Möge der handel beginnen.',
        product1: 'Schnell und zuverlässig eine bestellung ausführen',
        product2: 'Ein modernes analytisches tool',
        product3: 'Preise in echtzeit.',
        product4: 'Durch schnelle und sichere auszahlung',
        trade1: 'Er war schlauer.',
        trade2: 'Übt eure strategie aus',
        trade3: 'Bevor sie sinnvolle konten eröffnen, sollten sie die möglichkeit haben, einen blick auf unsere kostenfreien simulierten konten zu werfen, die konkreten plattformen erkunden und die ihnen geläufig zur verfügung stehen.',
        trade4: 'Erweitert euer Wissen',
        trade5: 'Ja, ich konnte mit dem ganzen bildungsmaterial lernen, wie man Pinnacle in die zielkonflikte nutzen kann.',
        trade6: 'risikomanagement',
        trade7: 'Nutzen sie unsere hochmodernen risikomanagementfazilitäten, um den verlust zu begrenzen und die gewinne zu maximieren.',
        allselect1: 'Warum habt ihr alle gilman gewählt?',
        allselect2: 'Schutz und sicherheit.',
        allselect3: 'Ihre daten sind sicher, und die gelder werden auf einem abgetrennten bankkonto, wie Von der vorschrift vorgeschrieben, hinterlegt.',
        allselect4: 'Professionelle unterstützung.',
        allselect5: 'Von professionellen, rund um die uhr tätigen kunden zu betreuen.',
        allselect6: 'Aufsicht.',
        allselect7: 'Wie sie Von einer vielzahl führender globaler regulierungsbehörden genehmigt und reguliert werden.',
        allselect8: 'Das ist eine zuverlässige sache.',
        allselect9: 'Pinnacle freak arbeitet bei der shenz-250 börse, die besonders an der kanadischen börse nominiert ist.',
        down1: 'Jedes modell an einem mobilen terminal kann bearbeitet und genutzt werden',
        down2: 'Zoo-app app',
    },
    pcbottom: {
        box1: 'Führende globale persönlichkeiten',
        box2: 'Zu den besten',
        box3: 'Mehr. - mehr?',
        box4: 'Um uns.',
        box5: 'Weißbuch.',
        box6: 'dienen',
        box7: 'Laden!',
        box8: 'Gängiges problem.',
        box9: 'Online-service jetzt',
        box10: 'Regeln!',
        box11: 'Eine vereinbarung.',
        box12: 'Unsere privateigentum ist privat.',
        box13: 'Eine rechtliche aussage.',
        box14: 'Vernetzt.',
    },
    pcAbout_us: {
        t1: 'Kurse.',
        t2: 'Eine reise Von sieben jahren',
        t3: 'Seit ihrer gründung im jahr 2015 haben wir uns und dem aufbau Von technologie und kanälen gewidmet.',
        t4: 'Von 2014 bis 2017 erleben wir einen stetigen anstieg und in der ersten hälfte 2018 eine bemerkenswerte "wer reich wird" Von der zweiten hälfte des jahres 2019 bis in den heutigen winter bereits gelernt. Unzählige erfolgreiche partner genießen unseren ruf in unserer bescheidenen branche. Im jahr 2022 wollen wir ein neues betriebssystem mit der tk und der einrichtung eines neuen unternehmens namens BitGoldEX haben, das in über 100 ländern auf der ganzen welt tätig ist.',
        t5: 'Wir suchen wen',
        t6: 'Von anfang bis heute',
        t7: 'Ich suche immer nach anbietern, die denselben geschmack haben wie ich. Ich habe das problem für dutzende Von anbietern über die jahre gelöst',
        t8: 'In der heutigen rationalen welt würde man nach einem guten partner suchen, mit dem man weiter gehen könnte mit uns sprechen.',
        t9: 'Gehen sie sicher, dass der mann, den sie suchen, nach ihnen sucht.',
        t10: 'dienen',
        t11: 'Vernetzt.',
        t12: 'Wenn ein freund dir ein geschäft anbietet, bist du ein guter mann. Wenn ich dich über dein geschäft belehre, finde ich deinen ruf ausgezeichnet. Wenn kunden dir eine firma anbieten, beweist das, dass du vertrauenswürdig bist.',
        t13: 'Der geist.',
        t14: 'Wo wir gebraucht werden, da sind wir',
        t15: 'Verschiedene kunden haben verschiedene bedürfnisse. Die verschiedenen produkte weisen verschiedene merkmale auf. Die ziele sind zu verschiedenen zeiten verschieden. Der sinn unserer existenz ist es, auf die konzeptionalitäten und anforderungen der verschiedenen dienstleister einzugehen und probleme jeder art zu lösen.',
        t16: 'Wir wollen ein kreativer, funktioneller Stein sein, der uns dorthin führt, wo wir gebraucht werden.',
    },
    pcproblem: {
        p1: 'Fragen.',
        p2: 'Wie die beträge nach einer umwandlung Von vermögenswerten verändert werden?',
        p3: 'Der gegenwert in einem anlagewert ist der wert der aktuellen digitalwährung, die in dollar umgewandelt wird, was sich aufgrund der preisbewegungen für ein digitaler anlagewert ergibt. Die anzahl ihrer digitalen vermögenswerte hat sich nicht geändert.',
        p4: 'Vergessen sie den umgang mit bankcodes für plattformen',
        p5: 'Falls sie das passwort für die fondsfonds auf der plattform vergessen haben, starten sie den dialog einstellungen festlegen Oder wenden sie sich an den kontakteintrag',
        p6: 'Was passiert mit dem geld nach den transaktionen?',
        p7: 'Sobald der auftrag erledigt ist, fließen die mittel in das französische währungskonto der vermögenswerte. Falls ein termingeschäft Oder ein terminhandel mit einem erworbenen gegenstand erforderlich sein sollte, so kann man diese überweisung in das treuhand-modul vornehmen.',
        p8: 'Wieso blockst du die zahl der spitznamen',
        p9: 'Aus sicherheitsgründen möchten wir unsere kosenamen nur einmal ändern.',
        p10: 'Was heißt, du hast die schule gewechselt? Warum umsteigen',
        p11: 'Um die unabhängigkeit der finanzmittel zwischen ihren konten zu gewährleisten, und um die angemessene kontrolle des risikos sicherzustellen, werden die konten in allen wichtigen transaktionen aufgeteilt. E) girokonto ist der übergang Von einem konto zum anderen.',
        p12: 'Wie wird die anlagerechte im zusammenhang mit dem nutzervertrag berechnet?',
        p13: 'Wertpapiere für situ - und wechselkurssitting beziehungsweise den betrag, der dem offenen konto zugänglich ist',
        p14: 'Was ist der wert der haltevorrichtung? Und wie sollte es eingestellt werden?',
        p15: 'Zum gewinn und zum gewinn gibt es die obergrenze. Das system wird automatisch automatisch ausladen, wenn die angegebene infinitiv - und schadensfunktion erreicht wird Wenn sie einen vertrag kaufen, kann man damit risiken eindämmen. Setzen sie die hälfte des verlussensatzes ein: sie erhöhen die anzahl x, um den wert des x-hebel zu maximieren. Es wird empfohlen, das risiko einigermaßen unter kontrolle zu halten und dabei die tatsächlichen verhältnisse in ihrem unternehmen zu berücksichtigen',
        p16: 'Wie wird das abfindungsrisiko verringert?',
        p17: 'Sie können die verfügbaren vermögenswerte der verbleibenden konten so auf ein pflichtkonto übertragen Oder das risiko verringern, indem sie eine nichtbiten-position reduzieren',
        p18: 'Was meinen sie mit der zahl?',
        p19: 'Die anzahl der nichtpositionen gibt die zahl der währungen für die güter an, die sie zu kaufen wünschen Beispielsweise wird es bei der verbreitung der seite BTC/ dollar billiger, um mehr als eine seite zu konsumieren, um einen preis Von 1000 dollar und eine zahl Von 10BTC zu drücken, was heißt: sie wollen sie um 1000 dollar pro pfund zukaufen.',
        p20: 'Wie werden die gebühren im vertrag berechnet?',
        p21: 'Gebühren = honorargebühren * gebühren * gebühren',
        p22: 'Die anweisungen zu einer zwangsabwicklung',
        p23: 'Der grad des risikos ist ein indikator für das risiko eines anlagevermögens. Wenn ihr depot 100 prozent beträgt, wird ihr depot als gleichstand betrachtet, also gleich viel risiko = marginalem risikowert (pflichtpflichtpflichtpflichtwette)*100 prozent, um zu verhindern, dass die anwender dieses depot tragen. Wenn das gefährdungsniveau den vom system festgelegten risikowert erreicht, wird die abtrennung durchgesetzt. Zum beispiel: wenn das system eine anpassung im verhältnis Von 10% erlaubt, zwingt das system sie zum auslöschen, falls ihr risiko größer Oder größer als 90% ist.',
        p24: 'Wie lauten die vertragsbedingungen?',
        p25: 'Typen.',
        p26: 'Die händler bewegen sich in verschiedene richtungen: zum ankauf und zum verkauf.',
        p27: 'Ein "ausverkauf" bedeutet, dass man glaubt, der aktuelle index gehe nach oben, also will man eine bestimmte menge an aufträgen zu einem bestimmten preis Oder zum aktuellen marktpreis kaufen.',
        p28: 'Ausverkauf (" rausch ") bedeutet, dass man glaubt, der aktuelle index falle, und eine gewisse menge neuer verträge zu dem vom aktuellen markt festgelegten preis verkaufen möchte.',
        p29: 'Das ist tradition.',
        p30: 'Termingebundene order: sie brauchen den preis und die menge der bestellung angeben',
        p31: 'Marktbestellung: sie müssen nur die anzahl der bestellung festlegen, und der preis ist der aktuelle marktpreis',
        p32: 'Job.',
        p33: 'Wenn die vorgaben, die sie zur freigabe übermitteln, fertig sind, heißt sie: öffnung',
        p34: 'Probleme wegen lieferung Von aufträgen',
        p35: 'Der plattformen -vertrag ist ein dauervertrag, ohne eine festgelegte lieferzeit. So lange das system die bedingungen der plakette nicht erfüllt Oder sie nicht manuell balanciert, können sie dauerhaft eine position halten',
        p36: '1: wenn der ertrag begrenzt ist, wird das system automatisch flach gemacht',
        p37: '2: das risiko ist zu hoch und das system wurde zwangsgeräumt',
        p38: 'Nun, wie hoch ist die kaution für einen vertraglichen vertrag?',
        p39: 'Bei vertraglichen transaktionen können die kunden je nach preis und volumen des vertrages sowie mit dem jeweiligen verhältnis Von fremdkapital am vertrag teilnehmen. Bei eröffnung des lagerraums hinterlegen die nutzer eine anzahlung. Je mehr kaution sie hinterlegt, desto größer werden die risiken für das konto.',
        p40: 'Was ist das deals und der marktpreis?',
        p41: 'Eine pauschale bedeutet, was sie sich für ein angebot mit der plattform anrechnen wollen, und der marktpreis ist ein tausch zwischen der plattform selbst zum aktuellen preis In der auftrittsregel zieht der marktpreis vor der preisanordnung. Wenn sie eine pauschale eröffnen wollen, sollten sie ihre kursortierung an den derzeitigen preisen auf dem devisenmarkt angemessen eröffnen und einen ungebührlichen verlust durch die öffnung eines marktes vermeiden',
        p42: 'Was ist das risiko bei verträgen?',
        p43: 'Der grad des risikos ist ein indikator für das risiko in den vertragsbedingungen. Der grad des risikos Von 100% gilt als flach. Wir empfehlen, dass sie ihren raum vorsichtig öffnen, wenn ihr risiko um 50% steigt, um zu vermeiden, durch einen tausch verluste zu verursachen. Das risiko kann dadurch verringert werden, dass sie die verfügbaren mittel für bestehende aktiva auffüllen Oder das risiko verringern',
        p44: 'Mach schon! Warum muss geld wechselgeld sein?',
        p45: 'Die börse dient dazu, den fluss Von vermögenswerten in verschiedene währungen fließen zu lassen und die in termingeschäfte verkauften QCC zum verkauf in dollar umzutauschen. Für alle anderen aufträge ist USDT somit frei in QCC konvertiert.',
        p46: 'Wieso hat der empfänger den transfer nicht rechtzeitig erhalten?',
        p47: 'Erstens, stellen sie sicher, dass ihre zahlungen bei überweisung genau so bezahlt werden, wie sie auf der seite der detaillierten anweisungen der bestellung erscheinen. Dann überprüfen sie bitte, ob sie sofort Oder verspätet bezahlen sollen. Und schließlich sollten sie sich aus systemverwaltern Oder aus anderen gründen mit ihrer bank Oder zahlinstitution in verbindung setzen.',
        p48: 'Wie bekommt man dollars über die plattform?',
        p49: 'Option 1: über die plattform wird die bestellung ausgewählt, die sie kaufen möchten.',
        p50: '2. Methode: klicken sie auf den knopf ok. Sie veröffentlichen den kauf Oder verkaufen der bestellung, je nach bedarf der schnellhandel. Bald findet das system leute für euch.',
        p51: 'Hinweis: wenn immer länger als 15 minuten nicht bezahlt werden, storniert das system automatisch meine bestellung und bitte pünktlich zu bezahlen. Wird eine bestellung mindestens drei mal so oft gelöscht, kann die transaktion nicht mehr durchgeführt werden, und die berechtigungen für den verkauf werden am folgenden tag wieder erstattet.',
    },
    public: {
        zxkf: 'Dienstleistungen der online-klienten',
        sure: 'Abschuss bestätigen.',
        cancel: 'korrekt',
        RefreshSucceeded: 'Aktualisieren. Aktualisieren.',
        noMoreData: 'Mehr daten sind nicht verfügbar',
        pullingText: 'Und ziehen, pusten',
        ReleaseText: 'Öffne die aktualisieren',
        loadingText: 'Geladen.',
        noData: 'Keine aufzeichnungen.',
        xq: 'Einzelheiten.',
        submit: 'Eingereicht werden',
        kefu: 'Dienstleistungen der online-klienten',
        amount: '.',
        startInvestment: 'StartInvestment',
        endInvestment: 'EndInvestment',
        profit: 'Profit.',
    },
    ChangeType: {
        Upper: 'Der einzahlungsbeleg.',
        Lower: 'Uns zurückziehen.',
        Win: 'Profit.',
        Bet: 'Eine wette.',
        BuyMining: 'bergbau-bergbau',
        CoinWin: 'Neue marken zu erfinden',
        SettleMining: 'Die erträge aus abraten',
        GiveInterest: 'Interesse zu graben',
        BuyCoin: 'Neue marken kaufen',
        BuyCoinBack: 'Abzüglich der neuen marken',
        UsdtToBtc: 'UsdoBtc',
        UsdtToEth: 'UsdoEth',
        BtcToUsdt: 'BtcToUsd',
        BtcToEth: 'BtcToEth',
        EthToUsdt: 'EthToUsd',
        EthToBtc: 'EthToBtc',
        CoinThaw: 'Das neue geldsystem taut auf',
        CoinFrozen: 'Neue währung einfrieren',
        CoinCancel: 'Und die währungsaufträge sind storniert.',
        CoinBuy: 'Devisenkäufe.',
        CoinBuyEntrust: 'Die bestellung',
        CoinSellEntrust: 'Das devisengeschäft',
        CoinSell: 'Verkauft geld',
        ContractBuy: 'Danach wird eine pauschale für verträge nachgezahlt',
        ContractCharge: 'Verträge enthalten weniger gebühren',
        ContractCancelToBond: 'Du kriegst die kaution zurück, wenn der vertrag ausläuft',
        ContractCancelToCharge: 'Ich krieg das zurück',
        ContractCloseProfit: 'Der vertrag trägt ein einkommen',
    },
    trade: {
        title: 'Wechseln.',
        Buy: 'kaufen',
        Sell: 'Zu verkaufen',
        pc: 'Noch näher.',
        cb: 'Kosten?',
        xj1: 'Derzeitige preise.',
        yk: 'Gewinn und verlust.',
        xianjia: 'Fester preis.',
        jiage: 'Dem preis?',
        shuliang: 'Die nationale bergarbeitergewerkschaft.',
        keyong: 'Nimm sie.',
        yonghuchicang: 'Job.',
        fxlts: 'Risikoquote = margin-eigenkapital/kundenkapital multipliziert mit 100 prozent, wobei das kundenkapital dem verfügbaren kapital sowie den zu verkaufenden mindestforderungen und zu zinssätzen entspricht',
        fxl: 'Eine ausfallrate.',
        dqwt: 'Die bestellung öffnen.',
        name: 'Den namen.',
        leixing: 'Typen?',
        all: 'Mit allem.',
        shijian: 'Zeit, bitte.',
        long: '.',
        short: 'kurz',
        cc: 'Eine position halten',
        zyjg: 'Letztes angebot.',
        caozuo: 'betrieb',
        xj: 'Fester preis.',
        sj: 'Marktpreis.',
        jiaoyie: '.',
        orderNo: 'orderNo',
        weituojia: 'Dem preis?',
        chengjiaojia: 'Dem preis?',
        chengjiaoe: 'Der handelspreis',
        weituo: 'Strukturiert.',
        qbwt: 'Alle dienste.',
        lswt: 'Die nachmittagszeit.',
        tips: 'Bitte geben sie ihren preis ein.',
        tips1: 'Bitte geben sie zahlen ein.',
        Create: 'auftrag',
        Wait: 'geschäften',
        Complete: 'Noch näher.',
        Cancel: 'Widerrufen.',
        zsj: 'Fallende preise.',
        zyj: 'Damit bin ich zufrieden.',
        cczsy: 'Das gesamteinkommen eines amtes.',
        ykbl: 'Dass diese zahlen hoch sind.',
        bili: 'als',
        jyss: 'zahltag',
        bzj: 'Nachschusssicherheit.',
        sxf: 'Gebühr.',
        gg: 'Benutz mehrere.',
        yjpc: 'Okay, mach alle aus.',
    },
    auth: {
        title: 'Identifikation, bitte.',
        title1: 'Identifikation, bitte.',
        low: 'Identifikation der person.',
        high: 'Eine zertifizierte veranstaltung.',
        success: 'Hab erfolg.',
        tips: 'Bitte bestätigen sie die identität',
        tips1: 'Bitte geben sie ihren richtigen namen ein',
        tips2: 'Bitte geben sie ihre ausweis-nummer ein',
        tips3: 'Ausweis bitte hochladen. Positive id, bitte.',
        tips4: 'Bitte ihren ausweis hochladen. (hinten)',
        tips5: 'Schicken sie das foto mit dem personalausweis auf die fotoseite',
        tips6: 'Wählen sie die authentifizierung aus',
        qrz: 'Authentifizieren wir es.',
        shz: 'Zensur läuft.',
        rzsb: 'Hat bestätigung nicht.',
        userName: 'Der ihn kannte.',
        IDNumber: 'Deine ausweisnummer.',
        zmz: 'Ausweis, foto (kopf)',
        bmz: 'Fotos Von einem ausweis (hinten drauf)',
        scz: 'Wie ich das foto auf der seite mit dem personalausweis halt machen kann',
        IDCard: 'Dienstmädchen, die ausweise',
        Passport: 'Ihren pass, bitte.',
        AuthenticationType: 'Typ backup',
        HighAuth: 'Eine zertifizierte veranstaltung.',
        gj: 'Die nationalität.',
        tipsgj: 'Bitte geben sie ihre nationalität ein',
    },
    share: {
        title: 'Dürfte ich sie für einen schilling vorschlagen',
        cp: 'Die adresse ist kopiert.',
        success: 'Kopie erfolgreich.',
    },
    newRecharge: {
        Deposit: 'einzahlungen',
        Credit: 'Die kreditkarte ist hier.',
        Wallet: 'So sind die leute',
        FAQ: 'Häufig fragen',
        Currency: 'Währung: wählen',
        placeholder: ' ',
        Main: 'Wichtige.',
        DepositAds: 'Die einsendeadresse.',
        Copy: 'Kopie.',
        remark: 'Einlagen Von vermögenswerten dürfen nur beschränkt sein',
        remark1: 'Der dollar',
        remark2: 'A) an diese adresse gehen, sonst können die vermögenswerte nicht zurückgegeben werden.',
        remark3: 'Einzahlungen an diese adresse müssen Von dem knoten des gesamten netzwerks bestätigt werden, und sechs mal bestätigt werden, bevor die konten auf diesem konto erhalten werden;',
        remark4: 'Einzahlungen in höhe Von nicht weniger als 15 dollar müssen erforderlich sein, sonst müssen diese einzahlungen fehlschlagen.',
        remark5: 'Bevor sie einzahlungen tätigen, müssen sie auf diese seite gehen und die adresse kopieren, um sicher zu sein, dass die adresse stimmt Ein fehler bei der anschrift führt zu einem verlust der einzahlung.',
        Pay: 'Ist bezahlt.',
        Buy: 'kaufen',
        Merchant: 'Geschäftsmann.',
        remark6: '* klick weiter nach vorn.',
        remark7: 'Tipp.',
        remark8: 'Bei eventuellen fragen wenden sie sich bitte an uns',
        remark9: 'Dienstleistungen eines kunden',
        ChipPay: 'Silber kauft die transaktion',
        Purchasebyamount: 'Der erlös wird geteilt.',
        AccountBalance: 'Kontostand der kontostand',
        Tips: 'Tipp.',
    },
    ChipPay: {
        copySUC: 'Kopie erfolgreich.',
        copyERR: 'Kopie nicht möglich',
        Buy: 'kaufen',
        TOBECONFIRMED: 'warten',
        TransactionCompletion: 'Weg!',
        OrderingInformation: 'Daten geortet',
        oddNumbers: 'Nummer eins:',
        TransferAmount: 'Sofern der transfer zutreffend ist:',
        ApproximatelyQuantity: 'Ca. drei:',
        UnitPrice: 'Nicht mehr fracht.',
        remark1: 'Der empfang wird verschoben, wenn der knopf "ich habe das überweisungen abgeschlossen" Oder der betrag der überweisungen nicht ausreicht. Die überweisung ohne private konten und die anmerkung/austragung führen zu einem verlust der transaktionen und zu rückzahlungen',
        remark2: 'Tipp.',
        remark3: 'Informationen über die zahlung jeder bestellung gelten nur für den einzelnen transfer. Speichert keine nachrichten für den zweiten transport.',
        remark4: 'Sie können keine weitere bestellung nach schaffung mehrerer zahlungsloser bestellen Passen sie bitte auf.',
        remark5: 'Bitte erledigen sie den transfer innerhalb der angegebenen frist und klicken sie auf den knopf "ich habe das konto abgeschlossen", um sicherzustellen, dass er unverzüglich eingegangen wird',
        remark6: 'Wenn die hinweise darauf deuten, dass die handelsparteien nicht unter vertrag stehen und der umfang des laufenden handels zu groß ist, können sie jeweils 5 bis 10 minuten warten, bis die bestellung erneut eingereicht wird.',
        remark7: 'Ihr transfer wird direkt auf das bankkonto des gegners übertragen, dessen digitale vermögenswerte, die sie zu verkaufen ansonsten verkaufen, Von der plattform verwaltet und geschützt werden. Den deal mach ich garantiert.',
        cancellation: 'korrekt',
        remittance: 'Übertragung.',
        remark8: 'Schalte um.',
        remark9: 'Sie werden geldtransaktionen mit den dollarbesitzern auf der chipay-plattform durchführen, die während der gesamten transaktion die echtheit ihrer dollar-anlagen garantiert. Bevor sie mit der transaktion anfangen, lesen sie bitte die folgenden artikel ab und stimmen sie ein:',
        remark10: 'Und seht her, es wird nicht billiger und es wird keine klagen',
        remark11: 'Wenn sie mit dollarbesitzern auf dem plattform geschäfte machen, müssen sie sich an die richtlinien gegen die geldwäsche halten',
        remark12: 'Bitte lesen sie die risikoerklärungen, verstehen sie die risiken im zusammenhang mit digitalen geldtransaktionen und stimmen ihnen zu',
        remark13: 'Ich habe das transaktionsrisiko erkannt und bin mit den servicebedingungen einverstanden',
        remark14: 'Der empfang wird verschoben, wenn der knopf "ich habe das überweisungen abgeschlossen" Oder der betrag der überweisungen nicht ausreicht. Die überweisung ohne private konten und die anmerkung/austragung führen zu einem verlust der transaktionen und zu rückzahlungen',
        remark15: 'Das macht $125. Sorgen sie dafür, dass die summe der überweisungen mit der der bestellung übereinstimmt. Wenn sie das geld nicht überweisen, könnte ein klick auf diesen knopf die liste der aktivitäten des systems bedeuten',
        ConfirmedRemittance: 'Überprüfen sie das als agent',
        ConfirmRemittance: 'Transfer bestätigen',
        WrongPoint: 'Falsches spiel.',
        Ialreadyknow: 'Ich weiß es schon',
        payattentionto: '(durchsage) achtung.',
        agree: '(sheriff) einverstanden.',
        Bank: 'Bank verstanden.',
        ReceivingAccount: 'Empfangskonto:',
        Party: 'Können wir das später klären?',
        Authenticated: 'Überprüfe die identität.',
        Margin: 'Nachschusssicherheit.',
        tips1: 'Überprüfen sie mich, ob ich mit transaktionsrisiken vertraut bin',
    },
    newProperty: {
        title: 'Vermögen (in dollar)',
        Spot: 'Die kiste auf lager.',
        Contract: 'Der vertrag ist ungültig.',
        Bonus: 'Belohnung?',
        Purchase: 'Ladung.',
        Deposit: 'einzahlungen',
        Withdraw: 'Uns zurückziehen.',
        Transfer: 'Übertragung.',
        Spot1: 'Die stadt.',
        Contract1: 'Vertrag?',
        Commission1: 'Ein komitee.',
        Financial1: 'Im finanzwesen.',
        Balance: 'Ein gleichgewicht.',
        Hide: 'Die versteckten vermögenswerte sind: 0.20',
    },
    withdrawal: {
        Wallet: 'Holen sie die brieftasche raus.',
        Internal: 'Eine interne übertragung.',
        Currency: 'Den geldbeutel.',
        Main: 'Wichtige.',
        WithdrawAddress: 'Man hat ihnen eine adresse gegeben.',
        Frequently: 'Häufige adressen.',
        placeholder: 'Die neue adresse des dollars',
        note: 'Geben sie mir die unterlagen.',
        placeholder1: 'bemerkungen',
        Amount: 'Geringen anteil.',
        placeholder2: '10. Niedriger.',
        All: 'Mit allem.',
        Balance: 'Kontostand der kontostand',
        Fee: 'Beruhige das geld.',
        FundPassword: 'Code des fonds.',
        placeholder3: 'Bitte geben sie den fondscode ein',
        forget: 'Du hast die anlageschlüssel vergessen?',
        OTP: 'Telefon-e-mail und so weiter',
        placeholder4: 'Authentifizierungscode eingeben',
        send: 'Senden.',
        Resend: 'Und Senden.',
        Reminder: 'Eine mahnung?',
        Reminder1: 'Wohnst du bei denen jetzt schon? Hör zu, Suzanne!',
        Reminder2: 'Girokonto = saldo',
        Reminder3: 'B) vor dem antrag auf das abheben bitte überprüfen, ob die auf das konto gerichtete adresse stimmt Sobald der handel zum gitter der gitter geleitet wird, ist er unwiderruflich',
        Confirm: 'Abschuss bestätigen.',
        noPassword: 'Es wurde kein passwort angegeben',
        Fees: 'Kostet.',
        Free: 'Es kostet nichts.',
        Transfer: 'Der betrag?',
        Limitation: 'abtretung verboten.',
        placeholder6: 'Verlegung beträgt 0 100.000',
        Available: 'Die verfügbaren Mengen.',
        Receiving: 'Das ist ein empfangskonto.',
        placeholder5: 'Bitte geben sie die benutzernamen ein',
        TransferBtn: 'Übertragung.',
        Reminder4: 'Tipp: bitte vergewissern sie sich, dass die nutzerinformationen beim transfer bestätigt werden',
        Reminder5: 'Keiner kümmert sich bei dir um den verkauf, bis die kinder in dir sind, wenn die agentur bei dir zu hause ist.',
        title: 'aussagen',
        title1: 'Die stadt.',
        title2: 'Vertrag?',
        title3: 'Aus dem fonds.',
        title4: 'Kauf die goldmünzen.',
        title5: 'Wir verkaufen goldmünzen.',
        Rate: 'umtauschkurs',
        allRate: 'Abrechnungen.',
        PlaceOrder: 'Bestellung aufnehmen.',
        Freeze: 'Gefroren',
        Thaw: 'Tauwetter auftauen',
        backgroundProgram: 'Verfahren,',
        AuditReject: 'Der verkauf Von münzen wurde abgelehnt',
        OrderSettlement: 'Abrechnungen rechnen.',
        AccountBalance: 'Ein stand:',
        CancelOrder: 'Stornieren sie die bestellung.',
        Created: 'erschaffen',
        Paid: 'Ist bezahlt.',
        UnAuthed: 'Keine uberwachung.',
        Accepted: 'Nehme an.',
        Canceled: 'Es ist abgesagt.',
        Refuce: 'Refuce',
        placeholderMC: 'Erfolgreiche verkäufe.',
        read: 'Gelesen',
        unread: 'Nicht gelesen',
        chat: 'Zu plaudern',
    },
    property: {
        Contract: 'Münzen.',
        Exchange: 'Zweiter vertrag.',
        zzc: 'vermögen',
        mhyzh: 'Zweites konto:',
        bbzh: 'Ein treuhandkonto.',
        available: 'Nimm sie.',
        OnOrders: 'Gefroren.',
        Estimated: 'Ein gleichwertige summe.',
        cw: 'Finanzielle unterlagen.',
        number: 'Mengen.',
        record: 'Notiert.',
        Transfer: 'Übertragung.',
        time: 'Zeit, bitte.',
        dhsl: 'Wir tauschen die anzahl der karten',
        tips: 'Bitte geben sie die menge der zu tauschen tauschen marken ein',
        qbdh: 'Nimm alles, was du hast.',
        dhhl: 'umtauschkurs',
        ky: 'Nimm sie.',
        kd: 'Nimm sie.',
        sxfbl: 'Gebühren/gebühren',
        sxf: 'Ich bearbeite die rechnungen.',
    },
    statusLang: {
        Review: 'Zensur.',
        Success: 'Der erfolg',
        Fail: 'Gescheitert.',
    },
    with: {
        title: 'Einwand.',
        history: 'Geschichte.',
        ldz: 'Wie ist die adresse',
        tbdz: 'Die adresse, die wir räumen sollen.',
        tips: 'Bitte geben sie die einschreibung ein',
        tips1: 'Bitte geben sie den betrag ihres betrags ein',
        tips2: 'Bitte geben sie den fondscode ein',
        tbsl: 'Ich möchte abgehoben werden.',
        ky: 'Nimm sie.',
        zjmm: 'Code des fonds.',
        sxf: 'Gebühr.',
        dz: 'E) in erwartung eines zuschlags',
    },
    tabBar: {
        home: 'Die titelseite.',
        Quotation: '[der markt]',
        Quotation2: 'Die börsenkurse.',
        Trade: 'handelshäuser',
        Contract: 'Vertrag?',
        newContract: 'handelshäuser',
        assets: 'vermögen',
        qhyy: 'Neue sprachen tauschen.',
        zyjb: 'Sie suchen den bergbau.',
        jsjy: 'Ein schnellgeschäft.',
        jryw: 'Finanzielle angelegenheiten',
        hyjy: '3. Verträge',
        Frequently: 'Regelmäßig.',
        mine: 'ich',
    },
    exchange: {
        title: 'Wechseln.',
        canChange: 'Die verfügbaren Mengen.',
        canChange1: 'Bitte geben sie einen wertbetrag ein',
        title1: 'Einzelheiten einer transaktion.',
        Available: 'Höhe einrichten.',
        Proposed: 'Preis, der ersteigert wird.',
        Redeem: 'Rückerstattung der anzahlungen.',
        Confirm: 'Abschuss bestätigen.',
        CurrencyBalance: 'Der ausgleich der währungen',
    },
    home: {
        gridcz: 'Münzen sind erträglich',
        gridsd: 'Ein sperrgeld in bar',
        gridxs: 'Häufig fragen',
        gridbd: 'Listen.',
        Balance: 'Ein gleichgewicht.',
        UserName: 'benutzername',
        score: 'bruchzahlen',
        kjmb: 'Kaufen schnell.',
        zc: 'Unterstützung für bitcoin, dollar und so weiter.',
        scwk: 'Bergbau einstellen.',
        sgxb: 'Neue abonnements',
        aqkx: 'Die ist sicher. Die ist sicher.',
        aqkx1: 'Die weltweit führende plattform für digitale vermögenswerte',
        rmcjb: 'heiß',
        xbb: 'Neue münzen.',
        zfb: 'wachstum',
        dfb: 'Gehe tiefer.',
        identify: 'Es gibt keine bestätigung.',
        identifyYes: 'Überprüfe die identität.',
        changeName: 'Geben sie bitte ihren benutzernamen ein',
        changeName1: 'Die benutzernamen dürfen nur einmal überarbeitet werden',
        confirmName: 'Abschuss bestätigen.',
        closeName: 'korrekt',
        mc: 'Den namen.',
        zxj: 'Neueste preise.',
        zdb: 'nettoveränderungen',
        hy: 'Benutz ihn.',
        btyh: 'Gewöhnlicher benutzer.',
        sfyz: 'Identifikation, bitte.',
        skfs: 'Die zahlungsweise',
        share: 'Ich habe dich reingelegt',
        kjcb: 'Ladung.',
        kjtb: 'Uns zurückziehen.',
        qbdz: 'Die brieftasche.',
        bps: 'Weißbuch.',
        xgmm: 'Das passwort ändern.',
        xgzfmm: 'Codes des fonds ändern.',
        gywm: 'Ranzoomen.',
        tcdl: 'Annullieren.',
        zjmx: 'Erklärungen des fonds.',
        cdsz: 'Und einstellung.',
        cdaq: 'Identifizierung, bitte.',
        bzzx: 'Häufig fragen',
        ysxy: 'Es ist ein datengeschäft.',
        xzyy: 'Wählen sie die sprache.',
    },
    commonUse: {
        title: 'dienen',
        Finance: 'Im finanzwesen.',
        Notice: 'Eine durchsage an alle fluggäste.',
        customerService: 'dienen',
        cc: 'Position.',
        zjmx: 'aussagen',
        cpdm: 'Den namen.',
        mm: 'Kaufen und verkaufen.',
        wtj: 'Dem preis?',
        wtsl: 'Mengen.',
        Buy: 'kaufen',
        Sell: 'Zu verkaufen',
        Buy1: 'kaufen',
        Sell1: 'Zu verkaufen',
        Buy2: 'Ein münzwurf',
        Exchangequantity: 'Mengen.',
        Exchangeamount: '.',
        Currency: 'Den geldbeutel.',
        Status: 'Versetzen.',
        Type: 'Typen?',
        Service: 'dienen',
        p1: 'Bitte wählen sie lhre währung aus.',
        p2: 'Bitte zahlen eingeben.',
        czlb: 'betrieb',
    },
    product: {
        Buy: 'kaufen',
        Sell: 'Zu verkaufen',
        Gain: 'Zugang.',
        Trade: 'handelshäuser',
        Real: 'Das war echt.',
        High: 'hoch',
        ClosingPrice: 'Zielpersonen gehen',
        Low: 'niedriger',
        Volume: 'Volumen: 24 stunden.',
        Limit: 'Eine termingebundene bestellung',
        Contract: 'Nimm die bestellung.',
        News: 'Beenden sie die bestellung.',
        Liquidations: 'Abrechnung.',
        ContractType: 'Art/vertrag',
        OpeningPrice: 'Preis/eröffnungspreis: preise/eröffnungspreis',
        Leverage: 'Benutz ihn.',
        LeverageP: 'Und die hebel dürfen nicht Leer sein',
        LimitPrice: 'Umfang der transaktionen.',
        LimitPlaceholder: 'Bitte geben sie einen geplanten betrag ein',
        Overnight: 'Dazwischen gibt es nichts.',
        Direction: 'Kurs.',
        Operate: 'betrieb',
        Long: 'Ich will mehr geld',
        Short: 'Einkaufen/Leer',
        Balance: 'Ein gleichgewicht.',
        Floating: 'Nein, schweben, P/L',
        CloseAll: 'Okay, mach alle aus.',
        MarketOrder: 'Die ordnung der marktwirtschaft',
        LimitOrder: 'Eine termingebundene bestellung',
        Cross: 'Kreuz.',
        Isolated: 'Den isolierten',
        placeholder: 'Bester ausverkaufspreis',
        Margin: 'Mengen.',
        MarginBalance: 'Restfinanzierung:',
        TradingFee: 'Das ist eine transaktionsgebühr.',
        Position: 'Position.',
        Success: 'Operation erfolgreich.',
        Commission: 'InCommission',
        Transaction: 'InTransaction',
        Completed: 'Weg!',
        Cancellation: 'korrekt',
        unitPrice: 'Pro ladung?',
        LeverageRatio: 'Fremdkapitalverhältnis.',
        cancellation: 'korrekt',
        ClosingPosition: 'ClosePosition',
        productName: 'Name der produkte',
        Margin1: 'Nachschusssicherheit.',
        SettlementPrice: 'Der endpreis',
        EndTime: 'Die zeit ist um.',
        CreateTime: 'Zeit kreieren?',
        WalletBalance: 'Kontostand der kontostand',
        AvailableFunds: 'Mehr mittel verfügbar.',
        Text1: 'Tipp.',
        Text2: 'Würden sie das machen',
        qr: 'Abschuss bestätigen.',
        qx: 'korrekt',
        ddbh: 'Null.',
        wtsj: 'Oh, delegiert.',
        szzyzs: 'Wer gewinn und verlust will, muss zahlen.',
    },
    notice: {
        title: 'Eine durchsage an alle fluggäste.',
        ckxq: 'Einzelheiten.',
    },
    helpCenter: {
        title: 'Ein lehrling.'
    },
    pwdGroup: {
        title: 'Passwort eingeben',
        pwdDL: 'Das passwort.',
        pwdZj: 'Code des fonds.',
    },
    quotation: {
        title: '[der markt]',
        mc: 'Mengen.',
        zxj: 'Neueste preise',
        zdb: 'nettoveränderungen',
        liang: 'Volumen.',
    },
    contract: {
        kcslfw: 'Begrenzter umfang:',
        gao: 'hoch',
        di: 'niedriger',
        liang: 'Volumen: 24 stunden.',
        placeholder: 'Bitte zahlen eingeben.',
        SuccessfulPurchase: 'Einkauf erfolgreich',
        smallMin: 'Wen noch?',
        hour: 'Stunde.',
        dayMin: 'Für einen tag.',
        Trend: 'Zeit, bitte.',
        ccjl: 'Nimm die liste.',
        ddjl: 'Liste der bestellungen.',
        sl: 'Mengen.',
        gmj: 'Der kaufpreis.',
        dqj: 'Aktueller preis',
        yjyk: 'Dass die bilanzen geändert werden.',
        djs: 'Countdown.',
        gbjg: 'Preis einhalten',
        sc: 'Ich bearbeite die rechnungen.',
        pal: 'Gewinn und verlust.',
        mhy: 'Zweiter vertrag.',
        sj: 'Zeit, bitte.',
        yll: 'Rechnung.',
        mz: '.',
        md: 'kurz',
        kcsl: 'Handarbeit anzubieten.',
        kcsj: 'Macht frei.',
        zhye: 'Kontostand der kontostand',
        qc: 'korrekt',
    },
    editPassword: {
        title: 'Das passwort ändern.',
        jmm: 'Der alte code.',
        xmm: 'Neuer code.',
        qrmm: 'Passwort bestätigt.',
        placeholder: 'Der alte code.',
        placeholder1: 'Neuer zugangscode eingeben',
        placeholder2: 'Bitte geben sie ihr neues passwort erneut ein',
        qrxf: 'Ok. - gut.',
    },
    secure: {
        title: 'Sauber, sarge.',
        title1: 'Duale identität',
        title2: 'Um sicher zu sein, tragen sie bitte KYC ein.',
        gridTxt: 'Passwort eingeben',
        gridTxt1: 'Handy authentifiziert.',
        gridTxt2: 'E-mail bestätigt.',
        gridTxt3: 'Identifikation, bitte.',
        listTxt: 'Eine ec-karte.',
        listTxt1: 'Die adresse, die wir räumen sollen.',
    },
    bindBank: {
        title: 'Eine ec-karte.',
        addCard: 'Transport der ec-karten.',
        editCard: 'Ändert ändert ändert ändert authentifizierungs-passwort',
        ts1: 'Du hast noch keine identifizierung durchgeführt',
        ts2: 'Bitte bestätigen sie die identität zuerst',
        addTitle: 'Transport der ec-karten.',
        userName: 'Der ihn kannte.',
        tips1: 'Bitte geben sie ihren richtigen namen ein',
        CollectionDetailArea: 'Zum claim sammeln.',
        tips6: 'Bitte geben sie die bereich für die detaillierten angaben des empfängers ein',
        userPhone: 'Um die telefonnummer zurückzubekommen.',
        tips2: 'Bitte geben sie die reservierte telefonnummer ein',
        bankName: 'Name der bank',
        tips3: 'Bitte geben sie den namen der bank ein, die eröffnen soll',
        bankAds: 'Adresse.',
        tips5: 'Bitte geben sie die bankadresse ein',
        bankId: 'Das bankkonto',
        tips4: 'Bitte geben sie ihre pin-nummer ein',
        CountryCode: 'Nationale codes.',
        tips7: 'Bitte geben sie den nationalen code ein',
        tips8: 'Bitte geben sie den swift-code ein',
        tips9: 'Bitte zahlen eingeben.',
        tips10: 'Der Auflade- oder Abhebungsbetrag darf nicht weniger als 100 betragen',
        addTxt: 'Intakt?',
    },
    bindMail: {
        title: 'Postfach einrichten.',
        placeholder1: 'Die e-mail-adresse?',
        ptitle: 'Ändere die nummer!',
        placeholder2: 'Bitte geben sie ihre handynummer ein',
        next: 'Die nächste bitte.',
        zjmm: 'Code des fonds.',
        placeholder3: 'Bitte geben sie das passwort für den fonds ein',
    },
    setting: {
        title: 'Und einstellung.',
        setTxt: 'Ein fahndungsbild.',
        setTxt1: 'Sprache.',
        setTxt2: 'umtauschkurs',
        setTxt3: 'Um uns.',
    },
    withPass: {
        title: 'Bereitet den "fonds code" vor',
        title1: 'Die fondspasswort können nicht mit dem eingegeben werden',
        aqyz: 'Ich hab es überprüft.',
        aqyzm: 'Telefon-e-mail und so weiter',
        jmm: 'Der alte code.',
        xmm: 'Neuer code.',
        qrmm: 'Passwort bestätigt.',
        placeholder: 'Der alte code.',
        placeholder1: 'Neuer zugangscode eingeben',
        placeholder2: 'Bitte geben sie ihr neues passwort erneut ein',
        qrxf: 'Ok. - gut.',
        tips: 'Bitte geben sie den alten code mindestens sechs ziffern ein',
        tips1: 'Bitte geben sie ein neues passwort mit mindestens sechs ziffern ein',
        tips2: 'Bitte geben sie das bestätigungspasswort ein',
        tips3: 'Zwei neue codes Kamen unerwartet',
        tips4: 'Passwort akzeptiert',
        confirm: 'Abschuss bestätigen.',
    },
    newBe: {
        title: 'Abonnieren sie das',
        title1: 'Ein abo!',
        scqx: 'Verkaufszeit nicht möglich',
        day: 'Für einen tag.',
        sy: 'Der rest',
        sdsg: 'Geschichte.',
        sysl: 'Restliches gewicht.',
        gmsl: 'Einkauf.',
        dj: 'Pro ladung?',
        tips: 'Bitte geben sie die kaufsumme ein',
        gmzj: 'Floyd: der gesamtkaufpreis',
        qbye: 'Kontostand der kontostand',
        fmzj: 'Floyd: der gesamtkaufpreis',
        gm: 'kaufen',
        sgsj: 'Zeit für die bestellung',
        Currency: 'Die art der tafel',
        Request: 'Anzahl der anfragen',
        Passing: 'Durch die anzahl.',
        MarketTime: 'Zeit für die ankündigung.',
        sqz: 'In der app.',
    },
    register: {
        title: 'Tragen sie es ein.',
        lang: 'Sprache.',
        send: 'Senden.',
        Resend: 'Und Senden.',
        Account: 'Konto.',
        Password: 'kennwort',
        ConfirmPassword: 'Passwort bestätigt.',
        InvitationCode: 'Größe 47.',
        btn: 'Eine liste ausfüllen.',
        mobile: 'Telefon nummern.',
        mailbox: 'Briefkasten.',
        haveAccount: 'Gibt es schon ein konto?',
        haveLogin: 'Einloggen.',
        Login: 'Einloggen.',
        placeholder: 'Bitte geben sie ihren briefkasten ein.',
        placeholder0: 'Bitte geben sie ihre telefonnummer ein',
        placeholder1: 'Code eingeben.',
        placeholder2: 'Bitte geben sie ihr passwort nochmal ein',
        placeholder3: 'Bitte geben sie den zugangscode ein',
        placeholder4: 'Bitte geben sie die authentifizierungscodes ein',
        placeholder5: 'Bitte geben sie den richtigen briefkasten ein',
        success: 'Mit erfolg.',
        message: 'Das passwort hat zwischen 6 und 16 zeichen',
        message1: 'Vergewissern sie sich, dass die eingeben mindestens sechs zeichen lang ist',
        message2: 'Die geheimzahl stimmt nicht überein',
        message3: 'Lesen sie die protokolle und geben sie an',
        agree: 'Ja, das sehe ich auch so.',
        xy: 'Nutzerfreundschaften und datenschutzrichtlinien zu erarbeiten',
        qr: 'Eine liste ausfüllen.',
        ftitle: 'Codes holen.',
        qrzh: 'Ok. - gut.',
        placeholder7: 'Neuer code.',
        placeholder6: 'Neuer code bestätigt.',
        placeholder8: 'Bitte geben sie den dokument-authentifizierungscode ein',
        placeholderName: 'Bitte geben sie einen benutzernamen ein',
    },
    lockming: {
        title: 'Die tafel ist verriegelt',
        wkb: 'Sie ging nach hause.',
        wkgjd: 'Vielleicht ist es einfacher, eine mine zu graben',
        qsljl: 'Easy bonus.',
        yjzsr: 'Geschätzte einnahmen.',
        ck: 'Seit drei tagen auf jahre errechnet',
        tips: 'Niedriger.',
        rlx: 'Täglich zinsen.',
        day: 'Für einen tag.',
        scqx: 'Verkaufszeit nicht möglich',
        buy: 'kaufen',
        zysl: 'Ich will wetten.',
        qbye: 'Kontostand der kontostand',
        finished: 'Das ganze.',
        doing: 'Im fortschritt.',
        sl: 'Mengen.',
        shouyilv: 'der',
        ks: 'Zeit zu starten.',
        js: 'Die zeit ist um.',
    },
    login: {
        title: 'Einloggen.',
        lang: 'Sprache.',
        mobile: 'Telefon nummern.',
        mailbox: 'E-mail. Ja.',
        Account: 'Konto.',
        Password: 'kennwort',
        btn: 'Einloggen.',
        placeholder1: 'Code eingeben.',
        placeholder: 'Bitte geben sie eine kontonummer ein',
        fail: 'Bitte füllen sie das formular aus',
        ForgotPassword: 'Du hast die kombination vergessen?',
        NoAccount: 'Noch kein konto?',
        Registration: 'Melden sie sich zum 1. Mal an.',
        OtherLogin: 'Mehr loggen.',
        server: 'Dienstleistungen online',
        jzmm: 'Denk an den code.',
        success: 'Erfolgreich im logbuch.',
        ageent: 'Lesen und stimmen zu.',
        ageentTitle: 'Ein dienstleistungsgeschäft.',
        ageentFail: 'Lesen sie bitte die unterlagen gut durch und vergleichen sie sie mit den dienstverträgen',
        Home: 'Flieg nach hause!',
        userbox: 'benutzername',
    },
    moneyAddr: {
        title: 'Die brieftasche.',
        Currency: 'Den geldbeutel.',
        Address: 'Die adresse.',
        placeholder: 'Wählen sie aus.',
        DepositAddress: 'Die einsendeadresse.',
        WalletAddress: 'Die brieftasche.',
        Date: 'Daten.',
        add: 'Adresse hinzufügen',
        addTxt: 'Jede zellenkette fügt maximal drei adressen zu',
        remark: 'Legen sie los.',
        Walletremark: 'Bitte fügen sie dieser adresse bemerkungen hinzu',
        network: 'Wichtige.',
        networkXy1: 'Du wählst das netzwerk',
        networkXy2: '. Wenn sie ihr vermögen fälschlicherweise an die falsche adresse Senden, verlieren sie ihr vermögen.',
        ChainAddress: 'Reihum adresse.',
        qrcode: 'Die geldbörse ist zwei meter entfernt',
        submit: 'Eingereicht werden',
        tips: 'Bitte wählen sie die brieftasche',
        tips1: 'Bitte wählen sie die falsche adresse aus',
        tips2: 'Bitte geben sie die brieftasche ein',
        tips3: 'Bitte hochladen sie die brieftasche mit zwei dimensionen',
        success: 'Der erfolg',
    },
    recharge: {
        title: 'Ladung.',
        title1: 'Einzelheiten über das aufladen.',
        ls: 'Geschichte.',
        td: 'Wählen sie die folgenden kanäle',
        bc: 'Datei datei QR',
        address: 'Die einsendeadresse.',
        copy: 'Die adresse ist kopiert.',
        num: 'Ihnen unbekannt.',
        Upload: 'Uploade hoch.',
        Submit: 'Eingereicht werden',
        tip: 'Bitte zahlen eingeben.',
        tip1: 'Laden sie zwei dimensionen hoch',
        copySuc: 'Kopie erfolgreich.',
    }
}