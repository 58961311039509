module.exports = {
    pcregister: {
        p1: 'Sudah memiliki akun?',
        dl: 'Gabung',
        zc: 'Daftar',
        p4: 'Akun',
        p5: 'Silahkan masuk ke akun',
        p6: 'Silakan masukkan kata sandi',
        p7: 'Silakan masukkan kata sandi konfirmasi',
        p8: 'Silakan masukkan kode undangan',
        p9: 'Saya setuju',
        p10: '"Perjanjian Pengguna dan Kebijakan Privasi"',
        p11: 'Surat',
        p12: 'Silahkan masukan email anda',
        p13: 'Silakan masukkan kode verifikasi email',
        p14: 'Kirim',
        p15: 'Tahun',
        p16: 'Bulan',
        p17: 'Langit',
        p18: 'Jam',
        p19: 'Menit',
        p20: 'Kedua'
    },
    pclogin: {
        l1: 'Tidak ada akun?',
        l2: 'Daftar',
        l3: 'Gabung',
        l4: 'Silakan masukkan nama pengguna',
        l5: 'Silakan masukkan kata sandi',
        l6: 'Tidak ingat kata sandi',
        l7: 'Gabung'
    },
    pccontract: {
        ss: 'Harga pasar',
        xj: 'Batas harga',
        gg: 'Tuas'
    },
    pcrapidly: {
        zf: 'Meningkat',
        dd: 'Memesan',
        bdsj: 'Waktu lokal',
        ky: 'Tersedia'
    },
    pchome: {
        global1: 'Platform perdagangan aset digital terkemuka di dunia',
        global2: 'Ini memberikan transaksi aset digital dan layanan manajemen aset yang aman dan tepercaya kepada jutaan pengguna di lebih dari 100 negara.',
        global3: 'Aman dan terpercaya',
        global4: 'Lima tahun pengalaman dalam layanan keuangan aset digital. Fokus pada arsitektur terdistribusi dan sistem serangan anti-DDoS.',
        global5: 'Tata letak ekologi global',
        global6: 'Pusat layanan transaksi lokal telah didirikan di banyak negara untuk menciptakan ekosistem blockchain yang mengintegrasikan berbagai format bisnis.',
        global7: 'pengguna terlebih dahulu',
        global8: 'Tetapkan mekanisme kompensasi di muka dan siapkan dana perlindungan investor.',
        whever1: 'Transaksi terminal multi-platform kapan saja, di mana saja',
        whever2: 'Mencakup berbagai platform termasuk iOS, Android, dan Windows, dan mendukung fungsi bisnis penuh.',
        register1: 'Daftarkan a',
        register3: 'Akun global mulai berdagang',
        register2: 'Daftar sekarang',
        commodity: 'komoditas',
        news: 'berita',
        ttop1: 'Temukan misteri Pinnacle',
        ttop2: 'Pinnacle adalah pertukaran internasional yang berkaitan dengan masa depan dan pilihan kontrak mata uang pertanian logam menunjukkan dan banyak lagi. Sejak pemecatannya telah terus meningkatkan aturan jual-beli dan volume berbagai produk telah meningkat. Perusahaan tersebut berhasil berkembang untuk melayani klien ritel dan kelembagaan di lebih dari 30 negara dan masih terus berkembang. Kami berusaha untuk mengurangi biaya perdagangan dan menyediakan layanan dalam semangat kolaborasi yang membantu klien kami menjadi pedagang yang menguntungkan bukan hanya pelanggan yang menguntungkan. Setelah semua jika anda melakukannya dengan baik kita melakukannya dengan baik.',
        ttop3: 'Mulai perdagangan sekarang',
        product1: 'Eksekusi dengan cepat dan dapat diandalkan',
        product2: 'Alat analitis mutakhir',
        product3: 'Kutipan Real-time',
        product4: 'Penarikan cepat dan aman',
        trade1: 'kepandaian',
        trade2: 'Terapkan strategi anda',
        trade3: 'Cobalah akun demo gratis kami sebelum anda membuka rekening perdagangan yang nyata untuk menjelajahi platform perdagangan intuitif kami dan meningkatkan keterampilan anda.',
        trade4: 'Perluas pengetahuan saudara',
        trade5: 'Pelajari tentang berdagang CFDs dengan Pinnacle menggunakan bahan pendidikan kita yang komprehensif.',
        trade6: 'Kelola risikony',
        trade7: 'Gunakan alat manajemen risiko mutakhir kami untuk membatasi kerugian anda dan mengunci keuntungan.',
        allselect1: 'Kenapa kalian memilih Pinnacle?',
        allselect2: '& aman dilindungi',
        allselect3: 'Data anda aman dan dana anda disimpan dalam segregated rekening bank sesuai dengan persyaratan peraturan.',
        allselect4: 'Dukungan profesional',
        allselect5: 'Dapatkan layanan pelanggan sepenuh waktu didedikasikan dalam berbagai bahasa.',
        allselect6: 'diatur',
        allselect7: 'Berlisensi dan diatur oleh berbagai regulator terkemuka global.',
        allselect8: 'terpercaya',
        allselect9: 'Pinnacle Ltd adalah perusahaan FTSE 250 terdaftar di Canadian Securities Exchange Main Market of Public.',
        down1: 'Setiap model ponsel di terminal ponsel dapat mendukung operasi dan penggunaan',
        down2: 'Aplikasi platform',
    },
    pcbottom: {
        box1: 'Terkemuka di dunia',
        box2: 'Salah satu tim terbaik',
        box3: 'Lagi',
        box4: 'Tentang kami',
        box5: 'Kertas putih',
        box6: 'Melayani',
        box7: 'Unduh',
        box8: 'Masalah umum',
        box9: 'Layanan daring',
        box10: 'Peraturan',
        box11: 'Perjanjian Pengguna',
        box12: 'Kebijakan pribadi',
        box13: 'Pemberitahuan Hukum',
        box14: 'Menghubung'
    },
    pcAbout_us: {
        t1: 'Kursus',
        t2: 'Kami, 7 tahun jalan',
        t3: 'Sejak kelahirannya pada tahun 2015, kami telah mengabdikan diri untuk melayani blockchain dengan teknologi penuh waktu dan layanan konstruksi saluran.',
        t4: '2014-Pada tahun 2017, mengalami periode kenaikan "stabil dan meningkat", dan pada tahun 2017-Paruh pertama tahun 2018 adalah periode gila "beli uang dan menjadi kaya" Dari paruh kedua tahun 2019 hingga periode musim dingin saat ini, saya telah melatih keterampilan saya yang sebenarnya. Banyak mitra sukses telah memberi kami reputasi rendah di industri ini. Pada tahun 2022, kami akan memiliki platform baru V3.0, dan mendirikan perusahaan saham gabungan baru, BitGoldEX, untuk menjalankan bisnis di lebih dari 100 negara di seluruh dunia.',
        t5: 'Siapa yang kita cari',
        t6: 'Dari awal sampai hari ini',
        t7: 'Saya telah mencari penyedia layanan yang mengalami kesulitan dan memiliki selera yang sama dengan saya, dan telah berhasil memecahkan masalah puluhan penyedia layanan dalam beberapa tahun.. ',
        t8: 'Dalam rasionalitas hari ini, jika Anda ingin menemukan penolong yang baik, pasangan yang dapat bekerja sama untuk menyadari diri sendiri dan melanjutkan hidup—mungkin, Anda dapat berbicara dengan kami.',
        t9: 'Harap percaya bahwa orang yang Anda cari telah mencari Anda.',
        t10: 'Melayani',
        t11: 'Tidak ada penjual, tetapi ada bisnis',
        t12: 'Ketika seorang teman memperkenalkan Anda pada bisnis, itu berarti Anda adalah orang yang baik. Jika rekan Anda memperkenalkan bisnis kepada Anda, itu berarti Anda memiliki reputasi yang baik. Ketika klien memperkenalkan bisnis kepada Anda, itu membuktikan bahwa Anda dapat dipercaya.',
        t13: 'Roh',
        t14: 'Kami batu bata, tidak perlu pergi ke mana',
        t15: 'Pelanggan yang berbeda memiliki persyaratan yang berbeda. Produk yang berbeda memiliki karakteristik yang berbeda. Waktu yang berbeda memiliki tujuan yang berbeda. Arti keberadaan kami adalah untuk beradaptasi dengan konsep, sistem, dan persyaratan penyedia layanan yang berbeda dan menyelesaikan berbagai masalah.',
        t16: 'Kami bersedia menjadi batu bata dengan pemikiran dan fungsi, di mana pun ada kebutuhan, kami akan pergi ke sana.'
    },
    pcproblem: {
        p1: 'Masalah umum',
        p2: 'Mengapa jumlah aset yang dikonversi berubah?',
        p3: 'Perhitungan ekuivalen dalam aset adalah nilai mata uang digital yang dipegang saat ini yang diubah menjadi USD, yang berubah karena fluktuasi harga aset digital. Jumlah aset digital Anda tidak berubah.',
        p4: 'Cara mengatasi lupa password dana dalam transaksi platform',
        p5: 'Jika Anda lupa kata sandi dana transaksi platform, silakan buka "My-mempersiapkan-Klik "Tetapkan Kata Sandi Dana" atau hubungi layanan pelanggan untuk mengatur ulang',
        p6: 'Kemana perginya uang setelah transaksi pembelian?',
        p7: 'Setelah pesanan pembelian selesai, dana akan masuk ke akun mata uang fiat di aset. Jika Anda perlu membeli aset untuk perdagangan kontrak atau perdagangan berjangka, silakan buka modul transfer dana untuk mentransfer.',
        p8: 'Mengapa membatasi jumlah perubahan nama panggilan',
        p9: 'Demi keamanan transaksi Anda, saat ini kami membatasi nickname hanya boleh dimodifikasi satu kali.',
        p10: 'Apa yang dimaksud dengan pengalihan? mengapa transfer',
        p11: 'Untuk memastikan independensi dana antara akun Anda dan untuk memfasilitasi pengendalian risiko yang wajar, kami dengan ini membagi akun dari setiap modul perdagangan utama. Transfer mengacu pada proses konversi aset antar akun trading.',
        p12: 'Bagaimana ekuitas akun dari kontrak pengguna dihitung?',
        p13: 'Nilai bersih akun kontrak = margin posisi + keuntungan dan kerugian mengambang posisi + jumlah akun saat ini tersedia',
        p14: 'Berapa nilai pengaturan Stop Loss dan Take Profit saat membuka posisi? Bagaimana cara mengaturnya?',
        p15: 'Ambil untung dan rugi sebagai batas atas untung dan rugi Anda. Ketika jumlah stop profit dan stop loss yang ditetapkan tercapai, sistem akan menutup posisi secara otomatis. Ini dapat digunakan untuk pengendalian risiko saat membeli kontrak. Tetapkan setengah dari jumlah take profit: tingkatkan jumlah x kuantitas x kelipatan leverage, atur stop loss. Kami menyarankan Anda untuk mengaturnya sesuai dengan aset Anda yang sebenarnya dan mengendalikan risiko secara wajar.',
        p16: 'Bagaimana cara mengurangi risiko kontrak?',
        p17: 'Anda dapat mentransfer aset yang tersedia dari akun yang tersisa ke akun kontrak dengan mentransfer dana, atau mengurangi risiko dengan mengurangi posisi',
        p18: 'Apa yang dimaksud dengan kuantitas pembukaan kontrak?',
        p19: 'Jumlah bunga terbuka mewakili jumlah mata uang yang ingin Anda beli. Contoh: dalam BTC/Di halaman pembuka pasangan perdagangan USD, pilih long, masukkan harga sebagai 1000USD, dan masukkan kuantitas sebagai 10BTC, yaitu, Anda berharap untuk membeli 10 BTC dengan harga satuan 1000USD.',
        p20: 'Bagaimana biaya penanganan dalam kontrak dihitung?',
        p21: 'Biaya penanganan = harga pembukaan * kuantitas pembukaan * tingkat biaya penanganan',
        p22: 'Tindakan pencegahan untuk likuidasi paksa',
        p23: 'Tingkat risiko adalah ukuran risiko aset Anda. Ketika tingkat risiko sama dengan 100%, posisi Anda dianggap ditutup, tingkat risiko = (memegang margin / ekuitas akun kontrak) * 100%, untuk mencegah pengguna melampaui posisi, sistem menyesuaikan rasio tingkat risiko. Ketika tingkat risiko mencapai nilai risiko yang ditetapkan oleh sistem, sistem akan memaksa posisi ditutup. Misalnya: rasio penyesuaian yang ditetapkan oleh sistem adalah 10%, maka ketika tingkat risiko Anda lebih besar atau sama dengan 90%, maka semua posisi Anda akan ditutup paksa oleh sistem.',
        p24: 'Apa aturan perdagangan kontrak?',
        p25: 'Tipe transaksi',
        p26: 'Jenis transaksi dibagi menjadi arah panjang (beli) dan pendek (jual):',
        p27: 'Membeli panjang (bullish) berarti Anda percaya bahwa indeks saat ini cenderung naik, dan Anda ingin membeli sejumlah tertentu untuk masuk ke dalam kontrak pada harga yang Anda tetapkan atau harga pasar saat ini.',
        p28: 'Short selling (bearish) berarti Anda percaya bahwa indeks saat ini mungkin turun dan ingin menjual sejumlah kontrak baru dengan harga yang Anda tetapkan atau harga pasar saat ini.',
        p29: 'Metode pesanan',
        p30: 'Limit order: Anda perlu menentukan harga dan jumlah pesanan',
        p31: 'Pesanan pasar: Anda hanya perlu mengatur jumlah pesanan, dan harganya adalah harga pasar saat ini',
        p32: 'Posisi',
        p33: 'Saat pesanan yang Anda kirimkan untuk membuka posisi selesai, itu disebut posisi',
        p34: 'Masalah Pengiriman Kontrak',
        p35: 'Kontrak platform adalah kontrak abadi tanpa waktu pengiriman. Selama sistem tidak memenuhi kondisi likuidasi paksa atau Anda tidak menutup posisi secara manual, Anda dapat menahan posisi tersebut secara permanen. Tutup sistem',
        p36: '1: Sistem secara otomatis menutup posisi ketika nilai stop profit dan stop loss yang ditetapkan tercapai',
        p37: '2: Risikonya terlalu tinggi, sistem memaksa likuidasi',
        p38: 'Berapa margin untuk perdagangan kontrak?',
        p39: 'Dalam perdagangan kontrak, pengguna dapat berpartisipasi dalam perdagangan kontrak sesuai dengan harga dan jumlah kontrak, serta leverage yang sesuai. Saat pengguna membuka posisi, margin akan ditempati. Semakin besar margin pembukaan, semakin tinggi risiko akun.',
        p40: 'Apa itu limit order dan market order?',
        p41: 'Perintah batas mengacu pada harga yang Anda harapkan untuk mempercayakan platform untuk melakukan transaksi, dan pesanan pasar mengacu pada mempercayakan platform secara langsung untuk melakukan transaksi dengan harga saat ini. Order pasar lebih diutamakan daripada limit order dalam aturan pembukaan order. Jika Anda memilih limit order, harap buka posisi secara wajar sesuai dengan harga pasar mata uang saat ini untuk menghindari kerugian yang disebabkan oleh harga pembukaan yang tidak wajar.',
        p42: 'Apa yang diwakili oleh tingkat risiko dari transaksi kontrak?',
        p43: 'Risiko adalah indikator risiko di akun kontrak Anda. Tingkat risiko sama dengan 100% dianggap dilikuidasi. Kami menyarankan ketika risiko Anda melebihi 50%, Anda perlu membuka posisi dengan hati-hati untuk menghindari kerugian yang disebabkan oleh penutupan posisi. Anda dapat mengurangi risiko dengan mengisi kembali dana yang tersedia dalam aset kontrak atau dengan mengurangi posisi Anda. ',
        p44: 'Mengapa Anda membutuhkan penukaran mata uang?',
        p45: 'Tujuan pertukaran adalah untuk memungkinkan kelancaran sirkulasi dana dalam berbagai mata uang dalam aset Anda, dan QCC yang diperoleh di akun berjangka dapat secara bebas dikonversi menjadi USD untuk diperdagangkan. USD di akun lain juga dapat dengan bebas dikonversi menjadi QCC untuk transaksi. ',
        p46: 'Mengapa pihak lain tidak menerima transfer tepat waktu setelah transfer?',
        p47: 'Pertama-tama, pastikan metode pembayaran yang Anda isi saat mentransfer uang sama persis dengan metode pembayaran yang ditampilkan di halaman detail pesanan. Kemudian konfirmasikan apakah metode pembayaran Anda adalah pembayaran segera atau pembayaran tertunda. Terakhir, harap hubungi bank dan lembaga pembayaran Anda untuk pemeliharaan sistem atau alasan lainnya. ',
        p48: 'Bagaimana cara membeli USD melalui platform?',
        p49: 'Metode 1: Pilih pesanan yang ingin Anda beli melalui daftar beli dan jual platform untuk membeli atau menjual.',
        p50: 'Metode 2: Klik tombol terbitkan untuk menerbitkan pesanan beli atau jual sesuai kebutuhan Anda untuk transaksi cepat. Sistem akan dengan cepat mencocokkan Anda dengan pembeli dan penjual.',
        p51: 'Catatan: Jika pesanan pembelian tidak dibayar setelah 15 menit, sistem akan membatalkan pesanan secara otomatis, harap bayar tepat waktu. Jika pesanan dibatalkan lebih dari 3 kali pada hari yang sama, pesanan tidak dapat diperdagangkan kembali pada hari yang sama, dan otoritas perdagangan akan dipulihkan pada hari berikutnya.'
    },
    public: {
        zxkf: 'Layanan daring',
        sure: 'Konfirmasi',
        cancel: 'Membatalkan',
        RefreshSucceeded: 'Berhasil menyegarkan',
        noMoreData: 'Tidak ada data lagi',
        pullingText: 'Tarik ke bawah untuk menyegarkan ...',
        ReleaseText: 'Lepaskan untuk menyegarkan ...',
        loadingText: 'Memuat ...',
        noData: 'Tidak ada catatan',
        xq: 'Detail',
        submit: 'Konfirmasi',
        kefu: 'Layanan daring',
        amount: 'Jumlah',
        startInvestment: 'Posisi bangunan posisi',
        endInvestment: 'Titik akhir',
        profit: 'Ingatan'
    },
    ChangeType: {
        Upper: 'Isi ulang sistem',
        Lower: 'Penarikan sistem',
        Win: 'Laba',
        Bet: 'Menginvestasikan',
        BuyMining: 'Pertambangan',
        CoinWin: 'Keuntungan SGD',
        SettleMining: 'Pengembalian pokok pertambangan',
        GiveInterest: 'Pendapatan pertambangan',
        BuyCoin: 'SGD tidak menang',
        BuyCoinBack: 'Pengembalian Langganan SGD',
        UsdtToBtc: 'USD ke Btc',
        UsdtToEth: 'Usd ke Et',
        BtcToUsdt: 'Btc ke Usd',
        BtcToEth: 'Btc ke Et',
        EthToUsdt: 'Et ke Usd',
        EthToBtc: 'Eth ke Btc',
        CoinFrozen: 'Pembekuan lotre mata uang baru',
        CoinThaw: 'SGD mencairkan',
        CoinCancel: 'Pembatalan Pesanan Mata Uang',
        CoinBuy: 'Beli mata uang',
        CoinBuyEntrust: 'Pesanan Beli Koin',
        CoinSellEntrust: 'Pesanan penjualan token',
        CoinSell: 'Jual mata uang',
        ContractBuy: 'Perintah kontrak, pengurangan margin',
        ContractCharge: 'Pesanan kontrak, pengurangan biaya penanganan',
        ContractCancelToBond: 'Pembatalan Kontrak Pengembalian Uang Jaminan',
        ContractCancelToCharge: 'Biaya pengembalian dana pembatalan kontrak',
        ContractCloseProfit: 'Pendapatan likuidasi kontrak'
    },
    trade: {
        title: 'Mata uang',
        Buy: 'Membeli',
        Sell: 'Menjual',
        pc: 'Menutup posisi',
        cb: 'Biaya',
        xj1: 'Harga sekarang',
        yk: 'Laba rugi',
        xianjia: 'Batas harga',
        jiage: 'Harga',
        shuliang: 'Kuantitas',
        keyong: 'Tersedia',
        yonghuchicang: 'Posisi pengguna',
        fxlts: 'Tingkat risiko = margin posisi/ekuitas pelanggan dikalikan 100%, di mana ekuitas pelanggan sama dengan dana akun yang tersedia + margin transaksi awal + keuntungan dan kerugian mengambang',
        fxl: 'Tingkat risiko',
        dqwt: 'Komisi saat ini',
        all: 'Semua',
        name: 'Nama',
        shijian: 'Waktu',
        zyjg: 'Trading dengan harga terbaik saat ini',
        caozuo: 'Beroperasi',
        xj: 'Batas harga',
        cc: 'Posisi',
        leixing: 'Tipe',
        long: 'Melakukan lebih',
        short: 'Pendek',
        sj: 'Harga pasar',
        jiaoyie: 'Jumlah transaksi',
        orderNo: 'Jumlah order',
        weituojia: 'Harga komisi',
        chengjiaojia: 'Harga akhir',
        chengjiaoe: 'Pergantian',
        weituo: 'Metode amanah',
        qbwt: 'Percayakan semua',
        lswt: 'Komisi sejarah',
        tips: 'Masukkan harga yang benar',
        tips1: 'Masukkan jumlahnya',
        Create: 'Dipercayakan',
        Wait: 'Dalam transaksi',
        Complete: 'Posisi tertutup',
        Cancel: 'Dibatalkan',
        zsj: 'Hentikan harga',
        zyj: 'Ambil Harga Untung',
        cczsy: 'Posisi keuntungan total',
        ykbl: 'Rasio untung dan rugi',
        bili: 'Proporsi',
        jyss: 'Banyak perdagangan',
        bzj: 'Batas',
        sxf: 'Biaya penanganan',
        gg: 'Manfaat',
        yjpc: 'Tutup posisi dengan satu klik'
    },
    auth: {
        title: 'Autentikasi identitas',
        title1: 'Autentikasi identitas',
        low: 'Sertifikasi primer',
        high: 'Sertifikasi Lanjutan',
        success: 'Sertifikasi berhasil',
        tips: 'Lanjutkan dengan otentikasi pertama',
        tips1: 'Silakan masukkan nama asli Anda',
        tips2: 'Silakan masukkan nomor ID Anda',
        tips3: 'Silakan mengunggah foto dari depan ID Anda',
        tips4: 'Silakan mengunggah foto dari belakang ID Anda',
        tips5: 'Silakan mengunggah foto komputer telpon',
        tips6: 'Silakan pilih tipe autentikasi nama asli',
        qrz: 'Untuk mengidentifikasi',
        shz: 'Dipertimbangkan',
        rzsb: 'Pengesahan gagal',
        userName: 'Nama asli',
        IDNumber: 'Nomor ID',
        zmz: 'Foto depan ID',
        bmz: 'Foto dari belakang kartu ID',
        scz: 'Foto komputer tangan',
        IDCard: 'Kartu ID',
        Passport: 'Pasport',
        AuthenticationType: 'Jenis autentikasi nama asli',
        HighAuth: 'Akreditasi lanjutan',
        gj: 'Kewarganegaraan',
        tipsgj: 'Silahkan masuk kewarganegaraan',
    },
    share: {
        title: 'Kode promo saya',
        cp: 'Alamat salinan',
        success: 'Berhasil menyalin'
    },
    withdrawal: {
        Wallet: 'Transfer dompet',
        Internal: 'Transfer interna',
        Currency: 'Valuta',
        Main: 'Rangkaian utama (protokol)',
        WithdrawAddress: 'Alamat penarikan',
        Frequently: 'Alamat umum',
        placeholder: 'Alamat penarikan USD',
        note: 'Nota Transaksi',
        placeholder1: 'Silakan masukkan catatan transaksi',
        Amount: 'Kuantitas tarik',
        placeholder2: 'Unit penarikan minimum adalah 10',
        All: 'Seluruh',
        Balance: 'Balas rekening',
        Fee: 'Gaji pengurusan pengunduran',
        FundPassword: 'Sandi dana',
        placeholder3: 'Silakan masukkan sandi dana',
        forget: 'Lupakan kata sandinya?',
        OTP: 'Kode verifikasi',
        placeholder4: 'Silakan masukkan kode verifikasi',
        send: 'Kirim',
        Resend: 'Ulangi',
        Reminder: 'Peringatan jenis',
        Reminder1: 'Periode proses penarikan adalah dari Senin hingga Minggu, 9:00 hingga 22:00 (UTC+8)',
        Reminder2: 'Sisa penarikan maksimum=sisa akun, jumlah penarikan termasuk biaya penarikan penarikan',
        Reminder3: 'Sebelum mengajukan penarikan, silakan konfirmasikan jika alamat penarikan benar. Setelah transaksi dikirim ke jaringan blockchain memori, tidak dapat ditarik',
        Confirm: 'Konfirmasi penarikan',
        noPassword: 'Tidak ada sandi dana ditetapkan',
        Fees: 'Gaji pengurusan',
        Free: 'Bebas',
        Transfer: 'Beralih jumlah',
        Limitation: 'Batas transfer satu hari',
        placeholder6: 'Kwantitas transfer tunggal 0-100000',
        Available: 'Gaji pengurusan pengunduran',
        Receiving: 'Menerima akun',
        placeholder5: 'Silakan masukkan nama panggilan',
        TransferBtn: 'Transfer',
        Reminder4: 'Peringatan jenis: Silakan konfirmasi informasi pengguna ketika memindahkan dana',
        Reminder5: 'Periode proses transfer interna 09:00-22:00 (UTC+8)',

        title: 'Detail',
        title1: 'Akun tunai',
        title2: 'Akun Kontrak',
        title3: 'Kapital',
        title4: 'Isi ulang',
        title5: 'Pengunduran',
        Rate: 'Kurse pertukaran',
        allRate: 'Balance',
        PlaceOrder: 'Tempatkan perintah',
        Freeze: 'membekukan',
        Thaw: 'Membeku',
        backgroundProgram: 'Latar belakang',
        AuditReject: 'Pengunduran Audit Ditolak',
        OrderSettlement: 'Pemutusan perintah',
        AccountBalance: 'Balas rekening:',
        CancelOrder: 'Batal Order',
        Created: 'Dicipta',
        Paid: 'Dibayar',
        UnAuthed: 'Untuk ditinjau',
        Accepted: 'Dilulus',
        Canceled: 'Batal Order',
        Refuce: 'Menolak',
        placeholderMC: 'Dijual dengan sukses',
        read: 'Telah membaca',
        unread: 'Tidak terbaca',
        chat: 'bicara',
    },
    newRecharge: {
        Deposit: 'Uang digital',
        Credit: 'Kartu kredit',
        Wallet: 'Memuatkan dompet dengan koin dan mengirim amplop merah setiap kali',
        FAQ: 'Arah Muat',
        Currency: 'Pilih Uang',
        placeholder: 'Silakan pilih uang',
        Main: 'Rangkaian utama (protokol)',
        DepositAds: 'Memuatkan alamat',
        Copy: 'Salin Alamat',
        remark: '•Tolong jangan memuat ulang non apapun',
        remark1: 'USD',
        remark2: 'Aset, jika tidak aset tidak akan dapat dikembalikan;',
        remark3: '•Setelah Anda memuat ulang ke alamat di atas, seluruh node jaringan perlu mengkonfirmasi, dan hanya setelah 6 jaringan mengkonfirmasi dapat Anda menerima rekening;',
        remark4: '•Sebuah muatan ulang tunggal tidak boleh kurang dari 15 USD, sebaliknya muatan ulang tidak akan berhasil;',
        remark5: '•Sebelum setiap ulang muatan, Anda harus pergi ke halaman ini untuk menyalin alamat untuk memastikan akurasinya. Alamat yang salah mungkin menyebabkan gagal ulang muatan.',
        Pay: 'Pembayaran',
        Buy: 'Membeli',
        Merchant: 'Penjual sertifikasi platform',
        remark6: '* Klik untuk melihat',
        remark7: 'Hal yang membutuhkan perhatian',
        remark8: ',Jika saat ini tidak ada pedagang yang cocok, silakan konfirmasikan koneksi jaringan Anda dan coba menyegarkan, atau kontak',
        remark9: 'Layanan Online',
        ChipPay: 'ChipPay Isi ulang transaksi',
        Purchasebyamount: 'Beli dengan jumlah',
        AccountBalance: 'Balas rekening',
        Tips: 'Peringatan jenis'
    },
    ChipPay: {
        copySUC: 'Disalin dengan sukses',
        copyERR: 'salinan gagal',
        Buy: 'Membeli',
        TOBECONFIRMED: 'Menunggu konfirmasi',
        TransactionCompletion: 'Transaksi selesai',
        OrderingInformation: 'Informasi Perintah',
        oddNumbers: 'Nomor perintah:',
        TransferAmount: 'Jumlah transfer:',
        ApproximatelyQuantity: 'Sekitar jumlah:',
        UnitPrice: 'Harga unit:',
        remark1: 'Gagal mengklik pada ‘Aku telah menyelesaikan tombol pengiriman’ atau ketidakcocokan dalam jumlah pengiriman akan menyebabkan tangguh menerima rekening. Transfer rekening bukan pribadi dan komentar/posting akan menyebabkan kegagalan transaksi dan restitution',
        remark2: 'Peringatan jenis',
        remark3: 'Informasi pembayaran untuk setiap perintah hanya valid untuk satu transfer. Silakan jangan menyimpan informasi untuk transfer kedua.',
        remark4: 'Setelah membuat banyak perintah tanpa pembayaran, Anda tidak akan dapat menempatkan perintah lain. Silakan terus berhati-hati.',
        remark5: 'Silakan selesaikan pengiriman dalam waktu yang ditentukan dan pastikan untuk klik pada tombol "Saya telah menyelesaikan pengiriman" untuk memastikan menerima segera.',
        remark6: 'Jika prompt adalah ‘Allokasi partai perdagangan gagal’ dan volum transaksi saat ini terlalu besar, menyebabkan partai perdagangan sibuk, silakan tunggu selama 5-10 menit sebelum menyampaikan ulang perintah.',
        remark7: 'Pengiriman Anda akan secara langsung dipindahkan ke rekening bank kontraparti, dan aset digital yang dijual oleh kontraparti selama transaksi akan dikelola dan dilindungi oleh platform. Silakan pastikan transaksi.',
        cancellation: 'Batal Order',
        remittance: 'Saya telah menyelesaikan pengiriman',
        remark8: 'Instruksi Transaksi',
        remark9: 'Anda akan terlibat dalam transaksi valuta digital dengan pemegang USD di platform ChipPay, dan platform akan menjamin autentisitas aset USD Anda selama transaksi. Silakan baca dan setuju dengan istilah berikut sebelum memulai transaksi:',
        remark10: 'Silakan diberitahu bahwa USD tidak dikeluarkan oleh platform ChipPay dan tidak memiliki tanggung jawab hukum untuk kompensasi',
        remark11: 'Ketika melakukan transaksi dengan pemegang USD di platform, Anda diperlukan untuk mematuhi isi deklarasi anti pencucian uang selama proses transaksi',
        remark12: 'Silakan baca pernyataan risiko pertama, sadarkan risiko perdagangan mata uang digital, dan setuju pada hal-hal yang di atas',
        remark13: 'Saya menyadari risiko transaksi dan setuju dengan syarat layanan',
        remark14: 'Gagal mengklik pada ‘Aku telah menyelesaikan tombol pengiriman’ atau ketidakcocokan dalam jumlah pengiriman akan menyebabkan tangguh menerima rekening. Transfer rekening bukan pribadi dan komentar/posting akan menyebabkan kegagalan transaksi dan restitution',
        remark15: '1. Silakan pastikan jumlah pengiriman Anda konsisten dengan jumlah perintah. Jika Anda klik tombol ini tanpa pengiriman, itu mungkin terdaftar hitam oleh sistem',
        ConfirmedRemittance: 'Pengiriman dikonfirmasi',
        ConfirmRemittance: 'Konfirmasi pengiriman',
        WrongPoint: 'Titik yang salah',
        Ialreadyknow: 'Aku sudah tahu',
        payattentionto: 'Silakan catat',
        agree: 'Setuju',
        Bank: 'Bank menerima:',
        ReceivingAccount: 'Menerima akun:',
        Party: 'Pesta Penjualan:',
        Authenticated: 'Diautentikasi',
        Margin: 'Margin',
        tips1: 'Silakan periksa apakah saya akrab dengan risiko transaksi'
    },
    newProperty: {
        title: 'Total aset (USD)',
        Spot: 'Tersedia dalam stok',
        Contract: 'Kontrak tersedia',
        Bonus: 'Ampulop merah',
        Purchase: 'Isi ulang',
        Deposit: 'Isi ulang uang',
        Withdraw: 'Pengunduran',
        Transfer: 'Transfer',
        Spot1: 'Aset tunai',
        Contract1: 'Aset kontrak',
        Commission1: 'Aset komisi',
        Financial1: 'Aset manajemen kekayaan',
        Balance: 'Total aset spot',
        Hide: 'Sembunyikan 0 aset'
    },
    property: {
        Contract: 'Coins',
        Exchange: 'Kontrak kedua',
        zzc: 'Total aset dikonvertir',
        mhyzh: 'Akun kontrak kedua',
        bbzh: 'Akun monet',
        available: 'Tersedia',
        OnOrders: 'Freeze',
        Estimated: 'Sama',
        cw: 'Catatan keuangan',
        number: 'Jumlah',
        record: 'Rekaman',
        Transfer: 'Transfer',
        time: 'Waktu',
        dhsl: 'Bertukar jumlah',
        tips: 'Silakan masukkan jumlah penebusan',
        qbdh: 'Buang Semuanya',
        dhhl: 'Rata Exchange',
        ky: 'Tersedia',
        kd: 'Kede',
        sxfbl: 'Rasio bayaran',
        sxf: 'Gaji pengurusan'
    },
    statusLang: {
        Review: 'Audit',
        Success: 'Sukses',
        Fail: 'Gagal'
    },
    with: {
        title: 'Pengunduran',
        history: 'Catatan penarikan',
        ldz: 'Alamat Rantai',
        tbdz: 'Alamat penarikan',
        tips: 'Silakan masukkan alamat penarikan',
        tips1: 'Silakan masukkan kuantitas penarikan',
        tips2: 'Silakan masukkan sandi pembayaran',
        tbsl: 'Kuantitas tarik',
        ky: 'Tersedia',
        zjmm: 'Kata sandi pembayaran',
        sxf: 'Gaji pengurusan',
        dz: 'Kuantitas Diterima'
    },
    tabBar: {
        home: 'Halaman rumah',
        Quotation: 'Pasar',
        Quotation2: 'Situasi pasar',
        Trade: 'Coins',
        Contract: 'Kontrak kedua',
        newContract: 'Transaksi',
        assets: 'Aset',
        qhyy: 'Tukar bahasa',
        zyjb: 'Kunci penambangan',
        jsjy: 'Penjualan kecepatan',
        jryw: 'Bisnis keuangan',
        hyjy: 'Transaksi kontrak',
        Frequently: 'Biasanya digunakan',
        mine: 'Aku'
    },
    exchange: {
        // 闪兑换
        title: 'Penukaran Flash',
        canChange: 'Kuantitas konvertibel',
        canChange1: 'Silakan masukkan jumlah yang ingin kau tebus',
        title1: 'Ganti rincian',
        Available: 'Kredit tersedia',
        Proposed: 'Harga referensi',
        Redeem: 'Buang Semuanya',
        Confirm: 'konfirmasi',
        CurrencyBalance: 'Balas uang'
    },
    home: {
        // grid
        gridcz: 'Mengisi ulang mata uang',
        gridsd: 'Penarikan uang kertas',
        gridxs: 'Diperlukan untuk pemula',
        gridbd: 'Daftar',
        Balance: 'Balance',

        UserName: 'Akun',
        kjmb: 'Isi ulang',
        score: 'Skor kredit',
        zc: 'Dukungan BTC, USD, dll.',
        scwk: 'Kunci penambangan',
        sgxb: 'Pencatatan uang baru',
        aqkx: 'Aman dan dipercaya',
        aqkx1: 'Platform perdagangan aset digital utama di dunia',

        // 榜單
        rmcjb: 'Transaksi popular',
        xbb: 'Senarai Uang Baru',
        zfb: 'Graf pertumbuhan',
        dfb: 'Daftar Jatuh',

        //認定
        identify: 'Tidak Diverifikasi',
        identifyYes: 'Diverifikasi',
        changeName: 'Silakan masukkan nama panggilan',
        changeName1: 'Nama panggilan hanya dapat diubah sekali',
        confirmName: 'konfirmasi',
        closeName: 'Batalkan',

        mc: 'Nama',
        hy: 'Kontrak',
        zxj: 'Harga terakhir',
        zdb: 'Jarak fluktuasi',
        sfyz: 'Autentikasi identitas',
        skfs: 'Metode pembayaran',
        share: 'Koneksi berbagi saya',
        kjcb: 'Isi ulang',
        kjtb: 'Pengunduran',
        qbdz: 'Alamat dompet',
        bps: 'kertas putih',
        xgmm: 'Ubah kata sandi',
        xgzfmm: 'Ubah kata sandi pembayaran',
        gywm: 'Tentang kita',
        tcdl: 'Keluar',

        // 個人中心
        zjmx: 'Rincian dana',
        cdsz: 'Mengatur',
        cdaq: 'Autentikasi nama asli',
        bzzx: 'Pusat Bantuan',
        ysxy: 'Perjanjian Privasi',
        xzyy: 'Pilih Bahasa'
    },
    commonUse: {
        title: 'Layanan',
        Finance: 'keuangan',
        Notice: 'Pengumuman',
        customerService: 'Layanan pelanggan',
        cc: 'Posisi memegang',
        zjmx: 'Rincian dana',
        cpdm: 'Produk',
        mm: 'Beli/Jual',
        wtj: 'Harga komisi',
        wtsl: 'Jumlah Komisi',
        Buy: 'Membeli',
        Sell: 'Menjual',
        Buy1: 'Membeli',
        Sell1: 'Menjual',
        Buy2: 'Pembelian koin satu klik',
        Exchangequantity: 'Bertukar jumlah',
        Exchangeamount: 'Jumlah pertukaran',
        Currency: 'Valuta',
        Status: 'Status Perintah',
        Type: 'Tipe',
        Service: 'Layanan pelanggan',
        p1: 'Silakan pilih uang',
        p2: 'Silakan masukkan jumlah',
        czlb: 'operasi'
    },
    product: {
        Buy: 'Beli',
        Sell: 'Jual',
        Gain: 'Jarak fluktuasi',
        Trade: 'Transaksi',
        Real: 'Nyata',
        High: 'Tertinggi',
        ClosingPrice: 'Diterima kemarin',
        Low: 'Minimum',
        Volume: 'Transaksi 24 jam',
        Limit: 'Perintah harga terbatas',
        Contract: 'Perintah memegang',
        News: 'Perintah menutup',
        Liquidations: 'Data lebar jaringan',
        ContractType: 'Kontrak丨tipe',
        OpeningPrice: 'Harga saat ini丨biaya',
        Leverage: 'Tingkat',
        LeverageP: 'Bantuan tidak bisa kosong',
        LimitPrice: 'Jumlah transaksi',
        LimitPlaceholder: 'Silakan masukkan jumlah batas harga',
        Overnight: 'Selama malam',
        Direction: 'Arah',
        Operate: 'Operasi',
        Long: 'Beli/Long',
        Short: 'Jual/Pendek',
        Balance: 'Total aset kontrak',
        Floating: 'P/L mengapung',
        CloseAll: 'Satu klik menutup posisi',
        MarketOrder: 'Penjualan nilai pasar',
        LimitOrder: 'Batas perdagangan',
        Cross: 'Penuh gudang',
        Isolated: 'Gudang oleh gudang',
        placeholder: 'Penjualan pada harga optimal saat ini',
        Margin: 'Jumlah',
        MarginBalance: 'Keseimbangan margin',
        TradingFee: 'Gaji pengurusan',
        Position: 'Posisi memegang',
        Success: 'Operasi berhasil',
        Commission: 'Dalam komisi',
        Transaction: 'Dalam transaksi',
        Completed: 'Selesai',
        Cancellation: 'Batalkan',
        unitPrice: 'Unit price',
        LeverageRatio: 'Nisbah tinggi',
        cancellation: 'Batalkan',
        ClosingPosition: 'Posisi menutup',
        productName: 'Nama Produk',
        Margin1: 'Margin',
        SettlementPrice: 'Harga penyelesaian',
        EndTime: 'Waktu penyelesaian',
        CreateTime: 'Waktu perintah',
        WalletBalance: 'Balansi dompet',
        AvailableFunds: 'Dana yang tersedia',
        Text1: 'Prompt',
        Text2: 'Apakah Anda ingin melakukan operasi ini',
        qr: 'Konfirmasi',
        qx: 'Batalkan',
        ddbh: 'Nomor perintah',
        wtsj: 'Waktu tugas',
        szzyzs: 'pengaturan iess',
    },
    notice: {
        title: 'Pengumuman',
        ckxq: 'Lihat Rincian'
    },
    helpCenter: {
        title: 'Diperlukan untuk pemula'
    },
    pwdGroup: {
        title: 'Pengaturan sandi',
        pwdDL: 'Sandi daftar masuk',
        pwdZj: 'Sandi dana'
    },
    quotation: {
        title: 'Pasar',
        mc: 'Jumlah',
        zxj: 'Harga terakhir',
        zdb: 'Jarak fluktuasi',
        liang: 'Jumlah'
    },
    contract: {
        kcslfw: 'Membuka jangkauan kuantitas:',
        gao: 'Tinggi',
        di: 'Rendah',
        liang: 'Kwantitas 24H',
        placeholder: 'Silakan masukkan jumlah',
        SuccessfulPurchase: 'Pembelian berhasil',
        smallMin: 'Menit',
        hour: 'Jam',
        dayMin: 'Hari',
        Trend: 'Pembahagian waktu',
        ccjl: 'Senarai Posisi',
        ddjl: 'Catatan Perintah',
        sl: 'Jumlah',
        gmj: 'Harga pembelian',
        dqj: 'Harga saat ini',
        yjyk: 'Profit dan kehilangan yang diharapkan',
        djs: 'Kiraan mundur',
        gbjg: 'Harga transaksi',
        sc: 'Gaji pengurusan',
        pal: 'Profit dan kehilangan',
        mhy: 'Kontrak kedua',
        sj: 'Waktu',
        yll: 'Margin keuntungan',
        mz: 'Membeli',
        md: 'Buy down',
        kcsl: 'Membuka jumlah',
        kcsj: 'Waktu Buka',
        zhye: 'Balas rekening',
        qc: 'Batalkan'
    },
    editPassword: {
        title: 'Ubah kata sandi daftar masuk',
        jmm: 'Kata sandi lama',
        xmm: 'Kata sandi baru',
        qrmm: 'Konfirmasi Sandi',
        placeholder: 'Kata sandi lama',
        placeholder1: 'Tetapkan kata sandi baru',
        placeholder2: 'Silakan masukkan ulang kata sandi baru',
        qrxf: 'Konfirmasi'
    },
    secure: {
        // 安全認證
        title: 'Keamanan',
        title1: 'Otentikasi Dua',
        title2: 'Untuk melindungi akun Anda, disarankan untuk menyelesaikan setidaknya satu autentikasi nama asli',
        gridTxt: 'Pengaturan sandi',
        gridTxt1: 'Verifikasi ponsel',
        gridTxt2: 'Verifikasi surel',
        gridTxt3: 'Autentikasi identitas',
        listTxt: 'Kartu bank',
        listTxt1: 'Manajemen alamat tarik'
    },
    bindBank: {
        // 銀行卡
        title: 'Kartu bank',
        addCard: 'Tambah Kartu Bank',
        editCard: 'Ubah kata sandi dana verifikasi',
        ts1: 'Anda belum menerima pengesahan identitas,',
        ts2: 'Silakan lakukan autentikasi identitas pertama',
        addTitle: 'Tambah Kartu Bank',
        userName: 'Nama asli',
        tips1: 'Silakan masukkan nama asli Anda',
        CollectionDetailArea: 'Alamat rincian penerima',
        tips6: 'Silakan masukkan alamat rincian penerima',
        userPhone: 'Nomor telepon cadangan',
        tips2: 'Silakan masukkan nomor telepon cadangan',
        bankName: 'Membuka Nama Bank',
        tips3: 'Silakan masukkan nama bank pembukaan rekening',
        bankAds: 'Alamat Bank',
        tips5: 'Silakan masukkan alamat bank pembukaan rekening',
        bankId: 'Akaun koleksi',
        tips4: 'Silakan masukkan akun penerima',
        CountryCode: 'Kode Negara',
        tips7: 'Silakan pilih kode negara',
        tips8: 'Silakan masukkan Kode SWIFT',
        tips9: 'Silakan masukkan jumlah',
        tips10: 'Jumlah isi ulang atau penarikan tidak boleh kurang dari 100',
        addTxt: 'Simpan'
    },
    bindMail: {
        // 綁定郵箱
        title: 'Ikat email',
        placeholder1: 'Silakan masukkan alamat email',
        ptitle: 'Ubah telepon',
        placeholder2: 'Silakan masukkan nomor telepon baru',
        next: 'Langkah berikutnya',
        zjmm: 'Sandi dana',
        placeholder3: 'Silakan masukkan sandi dana'
    },
    setting: {
        // 設置
        title: 'Mengatur',
        setTxt: 'Avatar',
        setTxt1: 'Bahasa',
        setTxt2: 'Kurse pertukaran',
        setTxt3: 'Tentang kita'
    },
    withPass: {
        // 設置資金密碼
        title: 'Tetapkan sandi dana',
        title1: 'Kata sandi dana tidak dapat sama dengan kata sandi daftar masuk',
        // 綁定郵箱
        aqyz: 'Verifikasi keamanan',
        aqyzm: 'Kode verifikasi',

        jmm: 'Kata sandi lama',
        xmm: 'Kata sandi baru',
        qrmm: 'Konfirmasi Sandi',
        placeholder: 'Kata sandi lama',
        placeholder1: 'Tetapkan kata sandi baru',
        placeholder2: 'Silakan masukkan ulang kata sandi baru',
        qrxf: 'Konfirmasi',
        tips: 'Silakan masukkan kata sandi lama dengan setidaknya 6 digit',
        tips1: 'Silakan masukkan kata sandi baru dengan setidaknya 6 digit',
        tips2: 'Silakan masukkan sandi konfirmasi yang benar',
        tips3: 'Kata sandi baru yang dimasukkan dua kali tidak cocok',
        tips4: 'Sukses menetapkan sandi',
        confirm: 'Konfirmasi'
    },
    newBe: {
        title: 'Pencatatan uang baru',
        title1: 'Tanda tanganku',
        scqx: 'Periode penguncian',
        day: 'Hari',
        sy: 'Surplus',
        sdsg: 'Rekaman',
        sysl: 'Jumlah yang tersisa',
        gmsl: 'Kuantitas pembelian',
        dj: 'Unit price',
        tips: 'Silakan masukkan jumlah pembelian',
        gmzj: 'Total harga pembelian',
        qbye: 'Balansi dompet',
        fmzj: 'Total harga pembelian',
        gm: 'Membeli',
        sgsj: 'Waktu subskripsi',
        Currency: 'Valuta',
        Request: 'Kwantitas aplikasi',
        Passing: 'Melalui jumlah',
        MarketTime: 'Waktu daftar',
        sqz: 'Menggunakan'
    },
    register: {
        title: 'Register',
        lang: 'Bahasa',
        send: 'Kirim',
        Resend: 'Ulangi',
        Account: 'Akun',
        Password: 'Sandi',
        ConfirmPassword: 'Konfirmasi Sandi',
        InvitationCode: 'Kode undangan',
        btn: 'Register',
        amount: 'Akaun',
        mailbox: 'kotak surat',
        haveAccount: 'Apakah Anda sudah memiliki rekening?',
        haveLogin: 'Daftar masuk sekarang',
        Login: 'Login',
        placeholder: 'Silakan masukkan alamat email Anda',
        placeholder0: 'Silakan masukkan nomor telepon Anda',
        placeholder1: 'Silakan masukkan sandi',
        placeholder2: 'Silakan masukkan sandi konfirmasi',
        placeholder3: 'Silakan masukkan kode undangan',
        placeholder4: 'Silakan masukkan kode verifikasi',
        placeholder5: 'Silakan masukkan alamat email yang benar',
        success: 'Login berhasil',
        message: 'Kata sandi harus memiliki setidaknya 6 digit',
        message1: 'Konfirmasi kata sandi dengan setidaknya 6 digit',
        message2: 'Dua kata sandi tidak cocok',
        message3: 'Silakan baca dan periksa kesepakatan',
        agree: 'Aku setuju.',
        xy: 'Perjanjian Pengguna dan Kebijaksanaan',
        qr: 'Daftar Sekarang',
        // 找回密碼
        ftitle: 'Dapatkan sandi',
        qrzh: 'Konfirmasi',
        placeholder7: 'Silakan masukkan sandi baru',
        placeholder6: 'Silakan konfirmasi kata sandi baru',
        placeholder8: 'Silakan masukkan kode verifikasi grafik',
        placeholderName: 'Masukkan satu nama pengguna'
    },
    lockming: {
        title: 'Daftar Kunci',
        wkb: 'Harta Menombongan',
        wkgjd: 'Menyembang lebih sederhana',
        qsljl: 'Mudah untuk menerima hadiah',
        yjzsr: 'Penghargaan total pendapatan',
        ck: 'Annualisasi referensi dalam 3 hari terakhir',
        tips: 'Setidaknya ',
        rlx: 'Interes sehari',
        day: 'Hari',
        scqx: 'Periode penguncian',
        buy: 'Membeli',
        zysl: 'Jumlah janji',
        qbye: 'Balansi dompet',
        finished: 'Selesai',
        doing: 'Dalam proses...',
        sl: 'Jumlah',
        shouyilv: 'Yield',
        ks: 'Waktu awal',
        js: 'Waktu akhir'
    },
    login: {
        title: 'Login Akaun',
        lang: 'Bahasa',
        mobile: 'Nomor ponsel',
        mailbox: 'Kotak surat',
        Account: 'Akun',
        Password: 'Sandi',
        btn: 'Login',
        placeholder1: 'Silakan masukkan sandi',
        placeholder: 'Silakan masukkan akun',
        fail: 'Silakan isi formulir lengkap',
        ForgotPassword: 'Lupakan kata sandi?',
        NoAccount: 'Belum punya rekening?',
        Registration: 'Daftar Sekarang',
        server: 'Layanan Online',
        jzmm: 'Ingat kata sandi',
        success: 'Login berhasil',
        Home: 'Halaman rumah',
        userbox: 'Nama pengguna'
    },
    moneyAddr: {
        title: 'Alamat dompet',
        Currency: 'Valuta',
        Address: 'Alamat',
        placeholder: 'Silakan pilih',
        DepositAddress: 'Memuatkan alamat',
        WalletAddress: 'Alamat dompet',
        Date: 'Waktu',
        // 添加錢包地址
        add: 'Alamat dompet',
        addTxt: 'Tambah sampai maksimum alamat per rantai',
        remark: 'Catatan',
        Walletremark: 'Silakan tambah informasi catatan untuk alamat ini',
        network: 'Rangkaian utama (protokol)',
        networkXy1: 'Jaringan yang Anda pilih adalah',
        networkXy2: 'Silakan konfirmasi bahwa alamatnya benar. Jika rantai blok ingatan yang telah Anda pilih tidak dapat mendapatkan aset ini, Anda akan kehilangan aset Anda.',

        ChainAddress: 'Alamat Rantai',
        qrcode: 'Kode QR dompet',
        submit: 'konfirmasi',
        tips: 'Silakan pilih tipe dompet',
        tips1: 'Silakan pilih tipe alamat rantai',
        tips2: 'Silakan masukkan alamat dompet',
        tips3: 'Silakan mengunggah kode QR dompet',
        success: 'Sukses'
    },
    recharge: {
        title: 'Isi ulang',
        title1: 'Rincian Muat Semula',
        ls: 'Rincian Muat Semula',
        td: 'Silakan pilih saluran muat ulang berikutnya',
        bc: 'Simpan kode QR',
        address: 'Memuatkan alamat',
        copy: 'Salin Alamat',
        num: 'Kuantitas muat',
        Upload: 'Naik gambar layar rincian pembayaran',
        Submit: 'konfirmasi',
        tip: 'Silakan masukkan jumlah',
        tip1: 'Silakan mengunggah foto layar',
        copySuc: 'Disalin dengan sukses'
    }
}