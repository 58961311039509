<template>
  <div id="app" v-if="show">
    <transition :name="SkipSwitchName">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { EventBus } from '@/utils/event-bus'
import { getSignalR } from '@/utils/signal'
import { mapGetters } from 'vuex'
import { returnLang, showId } from '@/utils/validate'

export default {
  name: 'App',

  data() {
    return {
      SkipSwitchName: '',
      show: false
    }
  },
  created() {
    this.redirectToFastest(window.apis)
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    EventBus.$on('Login', () => {
      if (this.$store.getters.connection.state === 'Connected') {
        this.$store.getters.connection.stop()
      }
      this.getConnection()
    })
    EventBus.$on('Logout', () => {
      if (this.$store.getters.connection.state === 'Connected') {
        this.$store.getters.connection.stop()
      }
      this.getConnection()
    })
    if (process.env.NODE_ENV === 'development') {
      // new this.$vconsole()
    }
  },
  methods: {
    returnLang,
    async redirectToFastest(targetDomains) {
      const tests = targetDomains.map(domain => this.testSpeed(domain));
      const results = await Promise.all(tests);
      results.sort((a, b) => a.time - b.time);
      const fastest = results.find(result => result.time < Infinity);
      if (fastest) {
        window.baseURL = fastest.domain
        EventBus.$emit('init', fastest.domain)
        setTimeout(() => { this.getbasic(); this.getConnection() }, 50);
      }
    },
    testSpeed(domain) {
      return new Promise((resolve) => {
        const startTime = performance.now();
        fetch(domain+'/img/1.png', { mode: 'no-cors' })
          .then(() => {
            const endTime = performance.now();
            resolve({ domain, time: endTime - startTime });
          })
          .catch(() => {
            resolve({ domain, time: Infinity });
          });
        setTimeout(() => {
          resolve({ domain, time: Infinity });
        }, 3000); // 3s超时跳过
      });
    },
    getConnection() {
      const connection = getSignalR()
      this.$store.commit('SET_CONNECTION', connection)
    },
    async getbasic() {
      let localLang = ''
      localLang = localStorage.getItem('language') || 'zh' // 默认语言
      this.$i18n.locale = localLang
      this.$store.commit('SET_LANG', localLang, false)
      localStorage.setItem('language', localLang)
      this.show = true
    }
  }
}
</script>
