module.exports = {
    pcregister: {
        p1: 'Đã có tài khoản?',
        dl: 'Đăng nhập',
        zc: 'Đăng ký',
        p4: 'Tài khoản',
        p5: 'Vui lòng nhập số tài khoản',
        p6: 'Vui lòng nhập mật khẩu',
        p7: 'Vui lòng nhập mật khẩu xác nhận',
        p8: 'Vui lòng nhập mã mời',
        p9: 'Tôi đồng ý',
        p10: 'Thỏa thuận người dùng và Chính sách bảo mật',
        p11: 'Hộp thư',
        p12: 'Vui lòng nhập hộp thư',
        p13: 'Vui lòng nhập mã xác minh hộp thư',
        p14: 'Gửi',
        p15: 'Năm',
        p16: 'Tháng',
        p17: 'Ngày',
        p18: 'Giờ',
        p19: 'phút',
        p20: 'giây'
    },
    pclogin: {
        l1: 'Không có tài khoản?',
        l2: 'Đăng ký',
        l3: 'Đăng nhập',
        l4: 'Vui lòng nhập tên người dùng',
        l5: 'Vui lòng nhập mật khẩu',
        l6: 'Quên mật khẩu',
        l7: 'Đăng nhập'
    },
    pccontract: {
        ss: 'Giá thị trường',
        xj: 'Giới hạn',
        gg: 'Trang chủ'
    },
    pcrapidly: {
        zf: 'Tăng',
        dd: 'Đặt hàng',
        bdsj: 'Giờ địa phương',
        ky: 'Có sẵn'
    },
    pchome: {
        global1: 'Nền tảng giao dịch tài sản kỹ thuật số hàng đầu thế giới',
        global2: 'Nó cung cấp các dịch vụ quản lý tài sản và giao dịch tài sản kỹ thuật số an toàn, đáng tin cậy cho hàng triệu người dùng ở hơn 100 quốc gia.',
        global3: 'An toàn và bảo mật',
        global4: '5 năm kinh nghiệm trong lĩnh vực dịch vụ tài chính Digital Asset Tập trung vào cấu trúc phân tán và hệ thống chống tấn công DDoS.',
        global5: 'Bố cục sinh thái toàn cầu',
        global6: 'Đã thành lập trung tâm dịch vụ giao dịch địa phương hóa tại nhiều nước, xây dựng hệ thống sinh thái chuỗi khối bộ nhớ hội nhập nhiều loại hình ngành nghề.',
        global7: 'Người dùng trên hết',
        global8: 'Xây dựng cơ chế bồi thường trước, thành lập quỹ bảo vệ nhà đầu tư.',
        whever1: 'Giao dịch thiết bị đầu cuối đa nền tảng mọi lúc, mọi nơi',
        whever2: 'Bao gồm nhiều nền tảng iOS, Android, Windows, hỗ trợ đầy đủ các chức năng kinh doanh.',
        register1: 'Đăng ký một',
        register3: 'Tài khoản toàn cầu Bắt đầu giao dịch',
        register2: 'Đăng ký ngay',
        commodity: 'Hàng hóa',
        news: 'Tin tức.',
        ttop1: 'Khám phá bí ẩn của Pinnacle',
        ttop2: 'Pinnacle là một sàn giao dịch quốc tế mà thỏa thuận trong tương lai và quyền chọn hợp đồng với các chỉ số năng lượng nông nghiệp Kim loại và nhiều hơn nữa. Kể từ khi thành lập, sở giao dịch đã liên tục cải thiện các quy tắc giao dịch của nó và khối lượng các sản phẩm khác nhau đã tăng lên. Công ty đã mở rộng thành công để phục vụ các khách hàng bán lẻ và tổ chức ở hơn 30 quốc gia và vẫn đang phát triển. Chúng tôi cố gắng giữ cho chi phí giao dịch càng thấp càng tốt và cung cấp các dịch vụ theo tinh thần hợp tác giúp khách hàng của chúng tôi trở thành thương nhân có lợi nhuận không chỉ là khách hàng có lợi nhuận. Sau tất cả nếu anh làm tốt chúng tôi đã làm tốt.',
        ttop3: 'Bắt đầu giao dịch bây giờ',
        product1: 'Thực hiện lệnh nhanh và đáng tin cậy',
        product2: 'Công cụ phân tích tiên tiến',
        product3: 'Những trích dẫn trong thời gian thực',
        product4: 'Rút lui nhanh và an toàn',
        trade1: 'Thông minh hơn.',
        trade2: 'Thực hành chiến lược',
        trade3: 'Hãy thử tài khoản demo miễn phí của chúng tôi trước khi bạn mở một tài khoản thương mại thực để khám phá nền tảng giao dịch trực quan của chúng tôi và nâng cao kỹ năng của bạn.',
        trade4: 'Mở rộng kiến thức',
        trade5: 'Hãy tìm hiểu về việc trao đổi CFD với Pinnacle sử dụng tài liệu giáo dục toàn diện của chúng ta.',
        trade6: 'Kiểm soát rủi ro',
        trade7: 'Sử dụng các công cụ quản lý rủi ro tiên tiến của chúng tôi để hạn chế tổn thất của bạn và khoá chặt lợi nhuận.',
        allselect1: 'Sao lại chọn Pinnacle?',
        allselect2: 'Được bảo vệ và an toàn',
        allselect3: 'Số liệu của bạn an toàn và số tiền của bạn được lưu giữ trong các tài khoản ngân hàng tách biệt theo các yêu cầu quản lý.',
        allselect4: 'Sự hỗ trợ chuyên nghiệp',
        allselect5: 'Thực hiện dịch vụ khách hàng chuyên dụng suốt 24 giờ bằng nhiều ngôn ngữ.',
        allselect6: 'Được quy định',
        allselect7: 'Được cấp phép và quy định bởi nhiều nhà quản lý hàng đầu thế giới.',
        allselect8: 'Đáng tin cậy',
        allselect9: 'Pinnacle Ltd là một công ty FTSE 250 được niêm yết trên thị trường chứng khoán chính của các công ty đại chúng Canada.',
        down1: 'Bất kỳ mô hình điện thoại di động trên thiết bị đầu cuối di động có thể hỗ trợ hoạt động và sử dụng',
        down2: 'Ứng dụng nền tảng',
    },
    pcbottom: {
        box1: 'Dẫn đầu toàn cầu',
        box2: 'Một trong những đội tốt nhất',
        box3: 'Thêm',
        box4: 'Về chúng tôi',
        box5: 'Sách trắng',
        box6: 'Dịch vụ',
        box7: 'Tải về',
        box8: 'Câu hỏi thường gặp',
        box9: 'Hỗ trợ trực tuyến',
        box10: 'Quy định',
        box11: 'Thỏa thuận người dùng',
        box12: 'Chính sách bảo mật',
        box13: 'Tuyên bố pháp lý',
        box14: 'Liên hệ'
    },
    pcAbout_us: {
        t1: 'Lịch sử',
        t2: 'Lời bài hát: 7 Years Road',
        t3: 'Kể từ khi ra đời vào năm 2015 đến nay, chúng tôi đã chuyên tâm vũ đầu vào dịch vụ chuỗi khối bộ nhớ, dịch vụ xây dựng kỹ thuật, kênh chuyên nghiệp.',
        t4: 'Năm 2014 - 2017 đã trải qua giai đoạn đi lên "ổn định có thăng", nửa đầu năm 2017 - 2018 là giai đoạn điên cuồng "mua tiền làm giàu", từ nửa cuối năm 2019 đến mùa đông giá lạnh hôm nay, tôi đã luyện được một bản lĩnh thực sự. Vô số đối tác thành công, khiến cho chúng ta khiêm tốn có được danh tiếng cao điệu trong giới. Năm 2022, chúng tôi sẽ có nền tảng hoàn toàn mới V3.0 và thành lập công ty cổ phần mới BitGoldEX, hoạt động tại hơn 100 quốc gia trên toàn thế giới.',
        t5: 'Chúng tôi đang tìm ai?',
        t6: 'Từ khi thành lập đến nay',
        t7: 'Trực tiếp tìm kiếm các nhà cung cấp dịch vụ có khó khăn và hợp khẩu vị với mình, trong vài năm đã thành công trong việc giải quyết vấn đề cho hàng chục nhà cung cấp dịch vụ. ',
        t8: 'Trong lý trí hôm nay, nếu như ngươi hy vọng tìm một trợ thủ tốt, có thể dắt tay thực hiện đồng bọn tiếp tục đi tới của mình - - có lẽ, có thể tìm chúng ta tâm sự.',
        t9: 'Hãy tin rằng: Người mà bạn đang tìm kiếm thực ra cũng đang tìm kiếm bạn.',
        t10: 'Dịch vụ',
        t11: 'Không có nhân viên, cũng có việc làm.',
        t12: 'Bạn bè giới thiệu làm ăn cho bạn, chứng tỏ bạn làm người cũng được. Đồng nghiệp giới thiệu làm ăn cho bạn, chứng tỏ danh tiếng của bạn cũng không tệ lắm. Khi khách hàng giới thiệu doanh nghiệp cho bạn, điều đó chứng tỏ bạn đáng tin cậy.',
        t13: 'Tinh thần',
        t14: 'Chúng ta là những viên gạch. Chúng ta phải đi đâu?',
        t15: 'Khách hàng khác nhau, yêu cầu không giống nhau. Các sản phẩm khác nhau, thuộc tính khác nhau. Thời cơ khác nhau, mục tiêu không giống nhau. Ý nghĩa tồn tại của chúng ta, chính là đi điều tiết quan niệm, chế độ và yêu cầu của các nhà cung cấp dịch vụ khác nhau, giải quyết đủ loại vấn đề.',
        t16: 'Chúng ta sẵn sàng làm một viên gạch có tư tưởng, có tác dụng, nơi nào cần, chúng ta liền đi đến nơi đó.'
    },
    pcproblem: {
        p1: 'Câu hỏi thường gặp',
        p2: 'Tại sao số lượng tài sản chuyển đổi thay đổi?',
        p3: 'Tính toán tương đương trong tài sản là giá trị của đồng tiền kỹ thuật số đang nắm giữ hiện tại được chuyển đổi sang USD, thay đổi do biến động giá của tài sản kỹ thuật số. Số lượng tài sản kỹ thuật số của bạn không thay đổi.',
        p4: 'Nền tảng giao dịch Quên tiền Phương pháp xử lý mật khẩu',
        p5: 'Nếu bạn quên mật khẩu tiền giao dịch trên nền tảng, vui lòng truy cập "My - Settings - Click Settings" hoặc liên hệ với dịch vụ khách hàng để đặt lại mật khẩu.',
        p6: 'Tiền chảy về đâu sau khi giao dịch mua?',
        p7: 'Sau khi hoàn thành đơn đặt hàng, tiền sẽ được chuyển vào tài khoản fiat trong tài sản. Để mua tài sản để giao dịch hợp đồng hoặc giao dịch tương lai, vui lòng tham khảo mô-đun chuyển tiền để chuyển tiền.',
        p8: 'Tại sao nên hạn chế số lượng thay đổi nickname',
        p9: 'Vì lý do bảo mật cho giao dịch của bạn, chúng tôi hiện đang giới hạn các biệt danh chỉ có thể được sửa đổi một lần.',
        p10: 'Điều này có nghĩa là gì khi chuyển? Tại sao cần chuyển',
        p11: 'Để đảm bảo tính độc lập của các quỹ giữa các tài khoản của bạn và tạo điều kiện cho bạn kiểm soát rủi ro một cách hợp lý, chúng tôi phân chia các tài khoản cho từng mô-đun giao dịch chính. Chuyển tiền đề cập đến quá trình chuyển đổi tài sản giữa các tài khoản giao dịch.',
        p12: 'Vốn chủ sở hữu tài khoản trong hợp đồng người dùng được tính như thế nào?',
        p13: 'Giá trị tài khoản hợp đồng=ký quỹ vị trí+lãi và lỗ thả nổi+số tiền có sẵn cho tài khoản hiện tại',
        p14: 'Giá trị của thiết lập Stop Loss và Stop Earning khi mở một vị trí là gì? Nên thiết lập như thế nào?',
        p15: 'Giới hạn lãi và lỗ được thiết lập cho bạn với lợi nhuận và lỗ. Hệ thống sẽ tự động đóng các vị trí khi đạt đến số tiền stop loss đã đặt. Có thể được sử dụng để kiểm soát rủi ro khi mua hợp đồng. Thiết lập một nửa số lượng giảm doanh thu là: tăng lượng x số lượng x bội số đòn bẩy, thiết lập dừng lỗ. Chúng tôi khuyên bạn nên thiết lập dựa trên tình hình tài sản thực tế của mình và kiểm soát rủi ro một cách hợp lý.',
        p16: 'Làm thế nào để giảm thiểu rủi ro hợp đồng?',
        p17: 'Bạn có thể chuyển tài sản có sẵn từ tài khoản còn lại vào tài khoản hợp đồng bằng cách chuyển tiền hoặc giảm thiểu rủi ro bằng cách giảm các vị trí mở.',
        p18: 'Số lượng hợp đồng mở có nghĩa là gì?',
        p19: 'Số lượng vị trí mở đại diện cho số tiền bạn muốn mua. Ví dụ: chọn trên trang mở của cặp giao dịch BTC/USD, mua/dài, giá nhập là 1000USD và số lượng đầu vào là 10BTC, tức là: bạn dự kiến sẽ mua 10 BTC với đơn giá 1000USD.',
        p20: 'Phí xử lý trong hợp đồng được tính như thế nào?',
        p21: 'Phí xử lý=Giá mở vị trí * Số lượng vị trí mở * Tỷ lệ xử lý',
        p22: 'Các biện pháp phòng ngừa buộc đóng vị trí',
        p23: 'Mức độ rủi ro là thước đo rủi ro đối với tài sản của bạn. Khi mức độ rủi ro bằng 100%, vị trí của bạn được coi là đóng, mức độ rủi ro=(giữ ký quỹ/vốn chủ sở hữu tài khoản hợp đồng) * 100%, để ngăn người dùng bị thủng, hệ thống điều chỉnh tỷ lệ rủi ro. Khi mức độ rủi ro đạt đến giá trị rủi ro do hệ thống thiết lập, hệ thống sẽ buộc phải đóng các vị trí. Ví dụ: thiết lập hệ thống được điều chỉnh theo tỷ lệ 10%, sau đó khi rủi ro của bạn lớn hơn hoặc bằng 90%, tất cả các vị trí của bạn sẽ bị buộc phải đóng bởi hệ thống.',
        p24: 'Quy tắc giao dịch hợp đồng là gì?',
        p25: 'Loại giao dịch',
        p26: 'Các loại giao dịch được chia thành hai hướng: dài (mua) và ngắn (bán):',
        p27: 'Mua dài (tăng) cho thấy bạn tin rằng chỉ số hiện tại có khả năng tăng và bạn muốn mua một số lượng nhất định để ký hợp đồng với giá bạn đặt hoặc giá thị trường hiện tại.',
        p28: 'Bán ngắn là khi bạn nghĩ rằng chỉ số hiện tại có thể giảm và muốn bán một lượng hợp đồng mới nhất định với giá bạn đã đặt hoặc giá thị trường hiện tại.',
        p29: 'Cách đặt hàng',
        p30: 'Đặt lệnh giới hạn: Bạn cần xác định giá và số lượng đặt lệnh',
        p31: 'Lệnh thị trường: Bạn chỉ cần đặt số lượng đặt hàng với giá thị trường hiện tại',
        p32: 'Giữ vị trí',
        p33: 'Khi lệnh mở mà bạn đã gửi hoàn tất, nó được gọi là lệnh mở',
        p34: 'Vấn đề đóng hợp đồng',
        p35: 'Hợp đồng nền tảng là hợp đồng vĩnh viễn và không đặt thời gian giao hàng. Bạn có thể giữ vị thế của mình vĩnh viễn miễn là hệ thống không đáp ứng các điều kiện hòa bình mạnh hoặc bạn không đóng nó theo cách thủ công. Hệ thống đóng vị trí',
        p36: '1：Đạt được giá trị thiết lập chốt lời và hệ thống tự động đóng các vị trí',
        p37: '2：Rủi ro quá cao và hệ thống buộc phải đóng các vị trí',
        p38: 'Margin cho giao dịch hợp đồng là gì?',
        p39: 'Trong giao dịch hợp đồng, người dùng có thể tham gia mua và bán hợp đồng dựa trên giá và số lượng hợp đồng, cũng như bội số đòn bẩy tương ứng. Margin được sử dụng khi người dùng mở một vị trí. Số tiền ký quỹ càng nhiều, rủi ro tài khoản càng cao.',
        p40: 'Lệnh giới hạn và lệnh thị trường là gì?',
        p41: 'Lệnh giới hạn đề cập đến mức giá mà bạn mong đợi để ủy thác nền tảng để giao dịch và lệnh thị trường đề cập đến việc ủy thác trực tiếp nền tảng để giao dịch ở mức giá hiện tại. Trong quy tắc mở một vị trí, lệnh thị trường được ưu tiên hơn lệnh giới hạn. Nếu bạn chọn lệnh giới hạn, vui lòng mở vị trí hợp lý với giá thị trường hiện tại của đồng tiền và tránh tổn thất do giá mở vị trí không hợp lý.',
        p42: 'Mức độ rủi ro của giao dịch hợp đồng là gì?',
        p43: 'Mức độ rủi ro là một chỉ số rủi ro trong tài khoản hợp đồng của bạn. Mức độ rủi ro bằng 100% được coi là thanh toán bù trừ. Chúng tôi khuyên bạn nên mở các vị trí của mình một cách thận trọng khi rủi ro của bạn vượt quá 50% và tránh thua lỗ do đóng chúng. Bạn có thể giảm thiểu rủi ro bằng cách bổ sung vốn có sẵn cho tài sản hợp đồng hoặc bằng cách giảm vị thế.',
        p44: 'Tại sao cần đổi tiền?',
        p45: 'Mục đích của việc trao đổi là cho phép tiền của các loại tiền tệ khác nhau trong tài sản của bạn lưu thông trơn tru và QCC thu được trong tài khoản tương lai có thể được tự do chuyển đổi sang USD để giao dịch. USD trong các tài khoản khác cũng có thể được tự do chuyển đổi sang QCC để giao dịch.',
        p46: 'Tại sao sau khi chuyển khoản đối phương không nhận được chuyển khoản kịp thời?',
        p47: 'Trước tiên, hãy đảm bảo rằng bạn điền chính xác phương thức thanh toán khi chuyển tiền như được hiển thị trên trang chi tiết đơn đặt hàng. Sau đó, vui lòng xác nhận phương thức thanh toán của bạn là thanh toán ngay lập tức hoặc thanh toán chậm. Cuối cùng, vui lòng liên hệ với ngân hàng và tổ chức thanh toán của bạn để bảo trì hệ thống hoặc các lý do khác.',
        p48: 'Làm thế nào để mua USD thông qua nền tảng?',
        p49: 'Phương pháp 1: Chọn đơn đặt hàng bạn muốn mua thông qua danh sách mua bán nền tảng để mua và bán.',
        p50: 'Phương pháp 2: Nhấp vào nút xuất bản để xuất bản thanh toán hoặc lệnh bán theo nhu cầu của bạn để giao dịch nhanh chóng. Hệ thống sẽ nhanh chóng phù hợp với người dùng mua và bán cho bạn.',
        p51: 'Lưu ý: Nếu đơn đặt hàng không được thanh toán sau 15 phút, hệ thống sẽ tự động hủy đơn đặt hàng, vui lòng thanh toán kịp thời. Lệnh bị hủy hơn 3 lần trong ngày, không thể giao dịch lại trong ngày và quyền giao dịch được khôi phục vào ngày hôm sau.'
    },
    public: {
        zxkf: 'Hỗ trợ trực tuyến',
        sure: 'Xác nhận',
        cancel: 'Hủy bỏ',
        RefreshSucceeded: 'Làm mới thành công',
        noMoreData: 'Không còn dữ liệu nữa.',
        pullingText: 'Kéo xuống là có thể đổi mới......',
        ReleaseText: 'Giải phóng là có thể đổi mới......',
        loadingText: 'Đang tải......',
        noData: 'Không có hồ sơ',
        xq: 'Chi tiết',
        submit: 'Xác nhận',
        kefu: 'Hỗ trợ trực tuyến',
        amount: 'Số lượng',
        startInvestment: 'Xây dựng kho',
        endInvestment: 'Điểm kết thúc',
        profit: 'Lợi nhuận'
    },
    ChangeType: {
        Upper: 'Nạp tiền hệ thống',
        Lower: 'Rút tiền hệ thống',
        Win: 'Lợi nhuận',
        Bet: 'Đầu tư',
        BuyMining: 'Khai thác mỏ',
        CoinWin: 'Lợi nhuận New Coin',
        SettleMining: 'Khai thác tiền gốc Return',
        GiveInterest: 'Lợi nhuận khai thác mỏ',
        BuyCoin: 'Đồng xu mới không được ký',
        BuyCoinBack: 'Coin mới trả lại',
        UsdtToBtc: 'Usd đến Btc',
        UsdtToEth: 'Usd đến eth',
        BtcToUsdt: 'BTC đến USD',
        BtcToEth: 'BTC đến ETH',
        EthToUsdt: 'ETH đến USD',
        EthToBtc: 'ETH đến BTC',
        CoinFrozen: 'Đồng xu mới đóng băng',
        CoinThaw: 'Đồng xu mới rã đông',
        CoinCancel: 'Hoàn tác lệnh Coin',
        CoinBuy: 'Tiền tệ mua vào',
        CoinBuyEntrust: 'Mua đồng xu ủy thác',
        CoinSellEntrust: 'Giao dịch bán Coin',
        CoinSell: 'Bán đồng xu',
        ContractBuy: 'Đặt lệnh theo hợp đồng, trừ tiền ký quỹ',
        ContractCharge: 'Đặt hàng theo hợp đồng, trừ phí thủ tục',
        ContractCancelToBond: 'Hoàn tiền ký quỹ',
        ContractCancelToCharge: 'Hoàn trả phí hoàn trả hợp đồng',
        ContractCloseProfit: 'Lợi nhuận đóng hợp đồng'
    },
    trade: {
        title: 'Tiền xu',
        Buy: 'Mua vào',
        Sell: 'Bán hàng',
        pc: 'Đóng vị trí',
        cb: 'Chi phí',
        xj1: 'Giá hiện tại',
        yk: 'Lợi nhuận và lỗ',
        xianjia: 'Giới hạn',
        jiage: 'Giá cả',
        shuliang: 'Số lượng',
        keyong: 'Có sẵn',
        yonghuchicang: 'Người dùng giữ vị trí',
        fxlts: 'Tỷ lệ rủi ro=ký quỹ mở/vốn chủ sở hữu của khách hàng nhân với 100%, trong đó vốn chủ sở hữu của khách hàng bằng số tiền có sẵn trong tài khoản+ký quỹ giao dịch ban đầu+lãi và lỗ thả nổi',
        fxl: 'Tỷ lệ rủi ro',
        dqwt: 'Ủy nhiệm hiện tại',
        all: 'Tất cả',
        name: 'Tên',
        shijian: 'Thời gian',
        zyjg: 'Giao dịch ở mức giá tốt nhất hiện tại',
        caozuo: 'Hoạt động',
        xj: 'Giới hạn',
        cc: 'Giữ vị trí',
        leixing: 'Loại',
        long: 'Mua/Bán',
        short: 'Bán/Bán',
        sj: 'Giá thị trường',
        jiaoyie: 'Khối lượng giao dịch',
        orderNo: 'Số thứ tự',
        weituojia: 'Giá ủy thác',
        chengjiaojia: 'Giá ủy thác',
        chengjiaoe: 'Khối lượng giao dịch',
        weituo: 'Cách ủy quyền',
        qbwt: 'Tất cả ủy thác',
        lswt: 'Lịch sử ủy thác',
        tips: 'Vui lòng nhập đúng giá',
        tips1: 'Vui lòng nhập số lượng',
        Create: 'Trong ủy quyền',
        Wait: 'Giao dịch',
        Complete: 'Đóng vị trí',
        Cancel: 'Bỏ lệnh',
        zsj: 'Giá dừng lỗ',
        zyj: 'Giá dừng doanh thu',
        cczsy: 'Tổng lợi nhuận giữ vị trí',
        ykbl: 'Tỷ lệ lãi và lỗ',
        bili: 'Tỷ lệ',
        jyss: 'Số lot giao dịch',
        bzj: 'Ký quỹ',
        sxf: 'Phí xử lý',
        gg: 'Nhiều đòn bẩy',
        yjpc: 'Một phím để đóng vị trí'
    },
    auth: {
        title: 'Chứng nhận',
        title1: 'Chứng nhận',
        low: 'Chứng nhận chính',
        high: 'Chứng nhận nâng cao',
        success: 'Chứng nhận thành công',
        tips: 'Xin vui lòng xác nhận danh tính',
        tips1: 'Vui lòng nhập tên thật',
        tips2: 'Vui lòng nhập số giấy tờ',
        tips3: 'Vui lòng tải lên ảnh chính diện của giấy tờ.',
        tips4: 'Vui lòng tải ảnh mặt sau của giấy tờ',
        tips5: 'Vui lòng tải ảnh cầm tay lên',
        tips6: 'Vui lòng chọn tên thật',
        qrz: 'Chứng nhận',
        shz: 'Đang xem xét',
        rzsb: 'Chứng nhận thất bại',
        userName: 'Tên thật',
        IDNumber: 'Số giấy tờ',
        zmz: 'Ảnh chính diện của giấy tờ',
        bmz: 'Ảnh mặt sau của thẻ',
        scz: 'Ảnh cầm tay',
        IDCard: 'Chứng minh thư',
        Passport: 'Hộ chiếu',
        AuthenticationType: 'Loại xác thực tên thật',
        HighAuth: 'Chứng nhận cao cấp',
        gj: 'Nơi sinh',
        tipsgj: 'Xin vui lòng nhập quốc tịch',
    },
    share: {
        title: 'Mã khuyến mãi của tôi',
        cp: 'Sao chép địa chỉ',
        success: 'Sao chép thành công'
    },
    withdrawal: {
        Wallet: 'Chuyển khoản ví',
        Internal: 'Chuyển khoản nội bộ',
        Currency: 'Tiền tệ',
        Main: 'Mạng chính (Protocol)',
        WithdrawAddress: 'Địa chỉ rút tiền',
        Frequently: 'Địa chỉ thường dùng',
        placeholder: 'Địa chỉ rút tiền USD',
        note: 'Ghi chú giao dịch',
        placeholder1: 'Vui lòng nhập ghi chú giao dịch',
        Amount: 'Số lượng rút tiền',
        placeholder2: 'Đơn vị rút tiền tối thiểu là 10',
        All: 'Tất cả',
        Balance: 'Số dư tài khoản',
        Fee: 'Phí rút tiền',
        FundPassword: 'Mật khẩu tiền',
        placeholder3: 'Vui lòng nhập mật khẩu tiền',
        forget: 'Quên mật khẩu tiền?',
        OTP: 'Mã xác nhận',
        placeholder4: 'Vui lòng nhập CAPTCHA',
        send: 'Gửi',
        Resend: 'Gửi lại',
        Reminder: 'Gợi ý ấm áp',
        Reminder1: 'Thời gian rút tiền từ Thứ Hai đến Chủ Nhật, 9:00 - 22:00 (UTC+8)',
        Reminder2: 'Số dư có thể rút tối đa=Số dư tài khoản, số tiền rút bao gồm phí rút tiền',
        Reminder3: 'Trước khi yêu cầu rút tiền, vui lòng xác nhận địa chỉ rút tiền là chính xác và giao dịch không thể rút lại khi được gửi đến mạng blockchain bộ nhớ.',
        Confirm: 'Xác định rút tiền',
        noPassword: 'Không đặt mật khẩu tiền',
        Fees: 'Phí xử lý',
        Free: 'Miễn phí',
        Transfer: 'Số lượng chuyển khoản',
        Limitation: 'Giới hạn chuyển tiền trong một ngày',
        placeholder6: 'Số lượng chuyển khoản đơn 0-100000',
        Available: 'Phí rút tiền',
        Receiving: 'Nhận tài khoản',
        placeholder5: 'Vui lòng nhập nickname',
        TransferBtn: 'Chuyển khoản',
        Reminder4: 'Lưu ý: Vui lòng xác nhận thông tin người dùng khi chuyển tiền',
        Reminder5: 'Thời gian xử lý chuyển khoản nội bộ 09:00 - 22:00 (UTC+8)',

        title: 'Chi tiết',
        title1: 'Tài khoản tiền mặt',
        title2: 'Tài khoản hợp đồng',
        title3: 'Tài trợ',
        title4: 'Nạp tiền',
        title5: 'Rút tiền',
        Rate: 'Tỷ giá hối đoái',
        allRate: 'tổng số',
        PlaceOrder: 'đặt hàng',
        Freeze: 'Đóng băng',
        Thaw: 'Dã đông',
        backgroundProgram: 'trình quản lý',
        AuditReject: 'Rút tiền phản hồi từ chức năng kiểm toán',
        OrderSettlement: 'tính toán đơn hàng',
        AccountBalance: 'tài khoản ngân hàng: ',
        CancelOrder: 'hủy đơn hàng',
        Created: 'tạo thành công',
        Paid: 'thanh toán',
        UnAuthed: 'Chờ xem xét',
        Accepted: 'đã được chấp nhận',
        Canceled: 'hủy đơn hàng',
        Refuce: 'đã từ chức hủy',
        placeholderMC: 'bán hàng thành công',
        read: 'Đã đọc',
        unread: 'Chưa đọc',
        chat: 'Nói chuyện',
    },
    newRecharge: {
        Deposit: 'vốn cổ điển',
        Credit: 'tín dụng ngân hàng',
        Wallet: 'thư mục cộng đồng, mỗi lần gửi thưởng',
        FAQ: 'hướng dẫn cộng đồng',
        Currency: 'chọn ngân hàng',
        placeholder: 'hãy chọn ngân hàng',
        Main: 'mạng chính (thuộc mỹ)',
        DepositAds: 'địa chỉ lưu trữ',
        Copy: 'sao chép địa chỉ',
        remark: '•Hãy tránh lưu trữ bất kỳ tài sản nào khác vào địa chỉ này nếu không, tài sản của bạn sẽ không thể được quản lý được nữa; •Sau khi bạn lưu trữ vào địa chỉ này, tất cả các điểm liên kết của mạng sẽ phải xác nhận, sau 6 điểm liên kết xác nhận được, tài sản của bạn sẽ được trao đổi vào tài khoản của bạn; •Mỗi lần lưu trữ không thể dưới 15 USD, nếu không, lưu trữ sẽ không thành công; •Mỗi lần trước khi lưu trữ, bạn phải truy cập trang này để sao chép địa chỉ, đảm bảo rằng địa chỉ của bạn không sai và không gặp sự phạm vi, nếu địa chỉ sai sẽ gây ra việc lưu trữ không thành công.',
        remark1: 'USD',
        remark2: 'Tài sản, nếu không tài sản sẽ không thể tìm lại;',
        remark3: '•Sau khi bạn lưu trữ vào địa chỉ này, bạn phải đợi xác nhận từ tất cả các điểm liên kết của mạng, sau 6 điểm liên kết được xác nhận, tài sản của bạn sẽ được trao đổi vào tài khoản của bạn',
        remark4: '•Mỗi lần lưu trữ không thể dưới 15 USD, nếu không, lưu trữ sẽ không thành công',
        remark5: '•Mỗi lần trước khi lưu trữ, bạn phải truy cập trang này để sao chép địa chỉ, đảm bảo rằng địa chỉ của bạn không sai và không gặp sự phạm vi, nếu địa chỉ sai sẽ gây ra việc lưu trữ không thành công.',
        Pay: 'Thanh toán',
        Buy: 'Mua',
        Merchant: 'Cửa hàng chứng cứ',
        remark6: '* Klicken Sie hier, um mehr zu sehen',
        remark7: 'Hinweis',
        remark8: 'Wenn keine passenden Händler vorhanden sind, überprüfen Sie bitte die Netzwerkverbindung und aktualisieren Sie die Seite oder wenden Sie sich an den',
        remark9: 'Online-Service-Team',
        ChipPay: 'ChipPay-Zahlung',
        Purchasebyamount: 'Kauf nach Menge',
        AccountBalance: 'Kontostand',
        Tips: 'Gut zu wissen'
    },
    ChipPay: {
        copySUC: 'Sao chép thành công',
        copyERR: 'Sao chép thất bại',
        Buy: 'Mua',
        TOBECONFIRMED: 'Chờ xác nhận',
        TransactionCompletion: 'Hoàn thành giao dịch',
        OrderingInformation: ' thông tin đặt hàng',
        oddNumbers: 'Số đơn hàng: ',
        TransferAmount: 'Số lượng trao đổi: ',
        ApproximatelyQuantity: 'Khoảng số lượng: ',
        UnitPrice: 'Giá cả mỗi đơn vị: ',
        remark1: 'Nếu không click vào nút "Tôi đã hoàn thành thanh toán" hoặc số tiền trao đổi không đúng sẽ gây ra thất bại trong quá trình thanh toán và không được chuyển tiền vào tài khoản của bạn. Sử dụng tài khoản của người khác và nhắn tin/ghi chú sẽ gây ra thất bại trong giao dịch và sẽ được trả tiền lại.',
        remark2: 'Chào bạn, lưu ý khi thanh toán để đảm bảo thành công của giao dịch.',
        remark3: 'Mỗi đơn hàng sẽ chỉ có độ lệnh một lần, vui lòng không lưu thông tin để chuyển tiền một lần nữa.',
        remark4: 'Sau khi tạo nhiều đơn hàng mà không thanh toán, bạn sẽ không thể tạo đơn hàng nữa, vui lòng lưu ý trong việc sử dụng.',
        remark5: 'Vui lòng hoàn thành thanh toán trước khi thời hạn và chạy nút "Tôi đã hoàn thành thanh toán" để đảm bảo rằng tiền sẽ được trao đổi nhanh chóng.',
        remark6: 'Nếu hiển thị thông điệp "Lỗi phân bổng", hiện tượng là số lượng đơn hàng hiện tại quá lớn, bạn vui lòng chờ trong vòng 5-10 phút và quay lại đăng ký đơn hàng.',
        remark7: 'Tiền của bạn sẽ được trao đổi directly vào tài khoản ngân hàng của đối tác, quá trình bán cổ điển của đối tác được bảo vệ bởi trang web, vui lòng tin tưởng trong quá trình giao dịch.',
        cancellation: 'Hủy đơn hàng',
        remittance: 'Tôi đã hoàn thành thanh toán',
        remark8: 'Giao dịch yêu cầu',
        remark9: 'Bạn sẽ thực hiện giao dịch với ChipPay trên mạng, trong đó một trong những chứa USD của mạng sẽ thực hiện giao dịch với bạn. Trong quá trình giao dịch, mạng sẽ bảo vệ tính chính xác của tài sản USD của bạn, và bạn cần đọc và chấp nhận các điều khoản sau đây trước khi bắt đầu giao dịch:',
        remark10: 'USD không được phát triển bởi ChipPay và không có tính chính xác hóa pháp nào.',
        remark11: 'Trong quá trình giao dịch với những chứa USD của mạng trong ChipPay, bạn phải tuân thủ các quy định của Bảo mật tài chính và truyền thông thông tin (AML / CFT).',
        remark12: 'Bạn cần đọc và chấp nhận các thông điệp về rủi ro của giao dịch số đồng và đồng chính xác các điều khoản trước khi chấp nhận giao dịch này.',
        remark13: 'Tôi đã hiểu rủi ro của giao dịch số đồng và đồng chấp nhận các điều khoản của dịch vụ.',
        remark14: 'Nếu bạn không hoàn thành thanh toán hoặc chưa đủ số tiền để thanh toán, giao dịch sẽ bị trả lại và sẽ không được hoàn thành. Bạn cũng không thể hoàn thành giao dịch nếu chuyển tiền vào tài khoản của người khác hoặc nhắn tin/ghi chú.',
        remark15: '1. Vui lòng đảm bảo rằng số tiền của bạn thực hiện giao dịch đang giống với số tiền trong đơn hàng. Nếu không, bạn có thể bị tồn tại trong danh sách đối tượng đen của hệ thống.',
        ConfirmedRemittance: 'Đã xác nhận đã thanh toán',
        ConfirmRemittance: 'Xác nhận thanh toán',
        WrongPoint: 'Đã nhấn nhầm',
        Ialreadyknow: 'Tôi đã biết rõ',
        payattentionto: 'Hãy lưu ý',
        agree: 'Đồng ý',
        Bank: 'Ngân hàng chuyển khoản: ',
        ReceivingAccount: 'Tài khoản nhận: ',
        Party: 'Đối tác:',
        Authenticated: 'Đã xác nhận danh tính',
        Margin: 'Đặc biệt',
        tips1: 'Hãy chọn "Tôi đã hiểu rủi ro của giao dịch" để xác nhận rằng bạn đã hiểu rủi ro của giao dịch số đồng.'
    },
    newProperty: {
        title: 'Tổng số cổ điển (USD)',
        Spot: 'Cổ điển hiện tại có sẵn',
        Contract: 'Hợp đồng có sẵn',
        Bonus: 'Tặng quà',
        Purchase: 'Làm thế giới',
        Deposit: 'Làm cổ điển',
        Withdraw: 'Tính tiền',
        Transfer: 'Chuyển khoản',
        Spot1: 'Cổ điển hiện tại',
        Contract1: 'Hợp đồng hiện tại',
        Commission1: 'Phí chuyển khoản',
        Financial1: 'Ví dụng chính trị',
        Balance: 'Tổng số cổ điển hiện tại',
        Hide: 'Ẩn 0 cổ điển'
    },
    property: {
        Contract: 'Tiền mua bán',
        Exchange: 'Tránh chuyển',
        zzc: 'Tổng số cổ điển',
        mhyzh: 'Tổng số cổ điển trong tài khoản chuyển trừ',
        bbzh: 'Tổng số cổ điển trong tài khoản tiền mua bán',
        available: 'Có sẵn',
        OnOrders: 'Trong đợi',
        Estimated: 'Trả lời',
        cw: 'Đăng ký',
        number: 'Số lượng',
        record: 'Lưu trữ',
        Transfer: 'Chuyển khoản',
        time: 'Thời gian',
        dhsl: 'Số lượng thực hiện',
        tips: 'Xin nhập số lượng cổ điển bạn muốn thực hiện',
        qbdh: 'Tất cả thực hiện',
        dhhl: 'Mức thực hiện',
        ky: 'Có sẵn',
        kd: 'Có thể',
        sxfbl: 'Phần tử chuyển khoản',
        sxf: 'Chuyển khoản'
    },
    statusLang: {
        Review: 'Phản hồi',
        Success: 'Thành công',
        Fail: 'Thất bại'
    },
    with: {
        title: 'Thực hiện',
        history: 'Lịch sử thực hiện',
        ldz: 'Địa chỉ liên kết',
        tbdz: 'Địa chỉ thanh toán',
        tips: 'Vui lòng nhập địa chỉ thanh toán',
        tips1: 'Vui lòng nhập số lượng thanh toán',
        tips2: 'Vui lòng nhập mật khẩu thanh toán',
        tbsl: 'Số lượng thanh toán',
        ky: 'Còn sử dụng',
        zjmm: 'Mật khẩu thanh toán',
        sxf: 'Phí thực hiện',
        dz: 'Số lượng tiền đang chờ giao'
    },
    tabBar: {
        home: 'Trang chủ',
        Quotation: 'Thị trường',
        Quotation2: 'Thị trường giao dịch',
        Trade: 'Tiền bạc',
        Contract: 'Sổ đỏ',
        newContract: 'Giao dịch',
        assets: 'Tài sản',
        qhyy: 'Chuyển đổi ngôn ngữ',
        zyjb: 'Khóa lắp đặt',
        jsjy: 'Giao dịch nhanh chóng',
        jryw: 'Chính phủ',
        hyjy: 'Giao dịch kỳ hạn',
        Frequently: 'Sử dụng thường',
        mine: 'Tôi'
    },
    exchange: {
        // 闪兑换
        title: 'Trao đổi',
        canChange: 'Số lượng có thể trao đổi',
        canChange1: 'Vui lòng nhập số lượng muốn trao đổi',
        title1: 'Chi tiết trao đổi',
        Available: 'Tồi đủ',
        Proposed: 'Giá cả khuyến nghị',
        Redeem: 'Tất cả trao đổi',
        Confirm: 'Xác nhận',
        CurrencyBalance: 'Số lượng tiền tệ còn lại'
    },
    home: {
        // grid
        gridcz: 'Nạp tiền pháp lý',
        gridsd: 'Tiền mặt pháp xu',
        gridxs: 'Các bài viết cần hiểu',
        gridbd: 'Nhật ký',
        Balance: 'Số dưng',

        UserName: 'Tên tài khoản',
        kjmb: 'Làm lền',
        score: 'Số điểm tin cậy',
        zc: 'Tích hợp BTC, USD và các loại tiền kỳ hưu khác',
        scwk: 'Kiểm soát lưu trữ và lợi nhuận từ đào tạo',
        sgxb: 'Mua mới',
        aqkx: 'An toàn và tin cậy',
        aqkx1: 'Trang web đầu tiên tại toàn cầu chuyên về giao dịch đồng bằng số',

        // 榜單
        rmcjb: 'Trang chủ đề hot',
        xbb: 'Bảng tiền mới',
        zfb: 'Bảng tăng giá',
        dfb: 'Bảng giảm giá',

        //認定
        identify: 'Chưa được xác nhận',
        identifyYes: 'Đã được xác nhận',
        changeName: 'Nhập tên mới',
        changeName1: 'Tên mới chỉ có thể thay đổi một lần',
        confirmName: 'Xác nhận',
        closeName: 'Hủy',
        mc: 'Tên',
        hy: 'Kế hoạch',
        zxj: 'Giá mới',
        zdb: 'Phân tích trị lượng',
        sfyz: 'Xác nhận tên',
        skfs: 'Phương thức thanh toán',
        share: 'Đường dẫn chia sẻ của tôi',
        kjcb: 'Làm lền',
        kjtb: 'Xuất tiền',
        qbdz: 'Địa chỉ ví',
        bps: 'Bản văn khai trương',
        xgmm: 'Thay đổi mật khẩu',
        xgzfmm: 'Thay đổi mật khẩu thanh toán',
        gywm: 'Về chúng tôi',
        tcdl: 'Xuất khỏi',

        // 個人中心
        zjmx: 'Tổng số tiền',
        cdsz: 'Cài đặt',
        cdaq: 'Xác nhận tên thật',
        bzzx: 'Trợ giúp',
        ysxy: 'Chính sách bảo mật',
        xzyy: 'Chọn ngôn ngữ'
    },
    commonUse: {
        title: 'Dịch vụ',
        Finance: 'Tài chính',
        Notice: 'Thông báo',
        customerService: 'Dịch vụ khách hàng',
        cc: 'Ví',
        zjmx: 'Tổng số tiền',
        cpdm: 'Sản phẩm',
        mm: 'Mua/Bán',
        wtj: 'Giá mua',
        wtsl: 'Số lượng',
        Buy: 'Mua',
        Sell: 'Bán',
        Buy1: 'Mua',
        Sell1: 'Bán',
        Buy2: 'Mua tiền một cách nhanh',
        Exchangequantity: 'Số lượng đổi',
        Exchangeamount: 'Số lượng đổi',
        Currency: 'Văn hóa',
        Status: 'Trạng thái đơn hàng',
        Type: 'Loại',
        Service: 'Dịch vụ',
        p1: 'Hãy chọn loại tiền',
        p2: 'Hãy nhập số lượng',
        czlb: 'Hoạt động'
    },
    product: {
        Buy: 'Mua',
        Sell: 'Bán',
        Gain: 'Tăng giá',
        Trade: 'Giao dịch',
        Real: 'Thực tế',
        High: 'Cao nhất',
        ClosingPrice: 'Giá đóng hôm qua',
        Low: 'Thấp nhất',
        Volume: '24h chạy',
        Limit: 'Lệnh giá trị',
        Contract: 'Lệnh thực hiện',
        News: 'Lệnh chấm dứt',
        Liquidations: 'Tổng số lệnh chấm dứt',
        ContractType: 'Loại chương trình',
        OpeningPrice: 'Giá mở',
        Leverage: 'Lãi suất',
        LeverageP: 'Lãi suất không thể làm trừ',
        LimitPrice: 'Số lượng giao dịch',
        LimitPlaceholder: 'Nhập giá lệnh',
        Overnight: 'Giao dịch qua đêm',
        Direction: 'Hướng',
        Operate: 'Thực hiện',
        Long: 'Mua/Trên',
        Short: 'Bán/Dưới',
        Balance: 'Tổng số tiền',
        Floating: 'Tổng số lợi nhuận',
        CloseAll: 'Mở tất cả các lệnh',
        MarketOrder: 'Lệnh thị trường',
        LimitOrder: 'Lệnh giá trị',
        Cross: 'Tất cả các lệnh',
        Isolated: 'Mỗi lệnh riêng',
        placeholder: 'Giao dịch với giá tốt nhất hiện tại',
        Margin: 'Số lượng',
        MarginBalance: 'Tổng số tiền bảo mật',
        TradingFee: 'Phí giao dịch',
        Position: 'Lệnh hiện tại',
        Success: 'Thực hiện thành công',
        Commission: 'Đang chờ',
        Transaction: 'Đang giao dịch',
        Completed: 'Hoàn thành',
        Cancellation: 'Hủy',
        unitPrice: 'Giá trị mỗi unit',
        LeverageRatio: 'Tỉ lệ lãi suất',
        cancellation: 'Hủy',
        ClosingPosition: 'Lệnh chấm dứt',
        productName: 'Tên sản phẩm',
        Margin1: 'Tổng số tiền bảo mật',
        SettlementPrice: 'Giá chấm dứt',
        EndTime: 'Thời gian chấm dứt',
        CreateTime: 'Thời gian tạo lệnh',
        WalletBalance: 'Tổng số tiền trong tài khoản',
        AvailableFunds: 'Tổng số tiền có sẵn',
        Text1: 'Gợi nhắc',
        Text2: 'Bạn có muốn thực hiện lệnh này không',
        qr: 'Đồng ý',
        qx: 'Hủy',
        ddbh: 'Số thứ tự',
        wtsj: 'Thời gian ủy thác',
        szzyzs: 'thiết lập điểm dừng lại',
    },
    notice: {
        title: 'Thông báo',
        ckxq: 'Xem chi tiết'
    },
    helpCenter: {
        title: 'Dành cho những người mới bắt đầu'
    },
    pwdGroup: {
        title: 'Cài đặt mật khẩu',
        pwdDL: 'Mật khẩu đăng nhập',
        pwdZj: 'Mật khẩu tiền'
    },
    quotation: {
        title: 'Kinh doanh',
        mc: 'Số lượng',
        zxj: 'Giá mới',
        zdb: 'Phân tích trị lượng',
        liang: 'Số lượng'
    },
    contract: {
        kcslfw: 'Kích thước cổng hàng: ',
        gao: 'Cao',
        di: 'Thấp',
        liang: '24H Số lượng',
        placeholder: 'Nhập số lượng',
        SuccessfulPurchase: 'Mua thành công',
        smallMin: 'Phút',
        hour: 'Giờ',
        dayMin: 'Ngày',
        Trend: 'Kịch bản thời gian',
        ccjl: 'Danh sách vật tư',
        ddjl: 'Lịch sử đặt hàng',
        sl: 'Số lượng',
        gmj: 'Giá mua',
        dqj: 'Giá hiện tại',
        yjyk: 'Tổng số lợi nhuận',
        djs: 'Số thời gian còn lại',
        gbjg: 'Giá giao dịch',
        sc: 'Phí giao dịch',
        pal: 'Lợi nhuận',
        mhy: 'Số phút契約',
        sj: 'Thời gian',
        yll: 'Tỉ lệ lợi nhuận',
        mz: 'Mua trên',
        md: 'Mua dưới',
        kcsl: 'Số lượng cổng hàng',
        kcsj: 'Thời gian cổng hàng',
        zhye: 'Tổng số tiền trong tài khoản',
        qc: 'Hủy'
    },
    editPassword: {
        title: 'Thay đổi mật khẩu đăng nhập',
        jmm: 'mật khẩu cũ',
        xmm: 'mật khẩu mới',
        qrmm: 'mật khẩu xác nhận',
        placeholder: 'mật khẩu cũ',
        placeholder1: 'thiết lập mật khẩu mới',
        placeholder2: 'xin hãy nhập lại mật khẩu mới',
        qrxf: 'xác nhận'
    },
    secure: {
        // 安全認證
        title: 'An ninh',
        title1: 'Xác nhận bảo mật hai đầu',
        title2: 'Để bảo vệ tài khoản của bạn, tôi khuyến khích bạn hoàn thành ít nhất một xác nhận tên thật',
        gridTxt: 'Cài đặt mật khẩu',
        gridTxt1: 'Xác nhận qua điện thoại',
        gridTxt2: 'Xác nhận qua email',
        gridTxt3: 'Xác nhận tên thật',
        listTxt: 'Thẻ tín dụng',
        listTxt1: 'Quản lý địa chỉ thu hồi'
    },
    bindBank: {
        // 銀行卡
        title: 'tài khoản ngân hàng',
        addCard: 'Thêm tài khoản ngân hàng',
        editCard: 'cập nhật mật khẩu xác nhận tài chính',
        ts1: 'Bạn chưa xác nhận tình hình của mình,',
        ts2: 'hãy xác nhận tình hình của mình trước khi sử dụng dịch vụ này',
        addTitle: 'Thêm tài khoản ngân hàng',
        userName: 'tên chính xác',
        tips1: 'hãy nhập tên chính xác của bạn',
        CollectionDetailArea: 'địa chỉ chi tiết của nhận viên',
        tips6: 'hãy nhập địa chỉ chi tiết của nhận viên',
        userPhone: 'số điện thoại liên lạc',
        tips2: 'hãy nhập số điện thoại liên lạc của bạn',
        bankName: 'tên nhà cửa ngân hàng',
        tips3: 'hãy nhập tên nhà cửa ngân hàng',
        bankAds: 'địa chỉ của nhà cửa ngân hàng',
        tips5: 'hãy nhập địa chỉ của nhà cửa ngân hàng',
        bankId: 'tên tài khoản',
        tips4: 'hãy nhập tên tài khoản',
        CountryCode: 'ký hiệu quốc gia',
        tips7: 'hãy chọn ký hiệu quốc gia',
        tips8: 'hãy nhập Swift Code',
        tips9: 'hãy nhập số tiền',
        tips10: 'Số tiền nạp hoặc rút không được nhỏ hơn 100',
        addTxt: 'lưu'
    },
    bindMail: {
        // 綁定郵箱
        title: 'kết nối email',
        placeholder1: 'xin hãy nhập địa chỉ email',
        ptitle: 'cập nhật số điện thoại',
        placeholder2: 'xin hãy nhập số điện thoại mới',
        next: 'tiếp theo',
        zjmm: 'mật khẩu tài chính',
        placeholder3: 'xin hãy nhập mật khẩu tài chính'
    },
    setting: {
        // 設置
        title: 'cài đặt',
        settxt: 'ảnh đại diện',
        settxt1: 'ngôn ngữ',
        settxt2: 'tỉ lệ mua bán',
        settxt3: 'về chúng tôi'
    },
    withPass: {
        // 設置資金密碼
        title: 'Đặt mật khẩu tiền',
        title1: 'Mật khẩu tiền không thể giống như mật khẩu đăng nhập',
        aqyz: 'Xác minh bảo mật',
        aqyzm: 'Mã xác nhận',
        jmm: 'Mật khẩu cũ',
        xmm: 'mật khẩu mới',
        qrmm: 'mật khẩu xác nhận',
        placeholder: 'mật khẩu cũ',
        placeholder1: 'đặt mật khẩu mới',
        placeholder2: 'xin hãy nhập lại mật khẩu mới',
        qrxf: 'xác nhận',
        tips: 'xin hãy nhập mật khẩu cũ tối thiểu 6 chữ',
        tips1: 'xin hãy nhập mật khẩu mới tối thiểu 6 chữ',
        tips2: 'xin hãy nhập mật khẩu xác nhận đúng',
        tips3: 'hai lần nhập mật khẩu mới không tương tự',
        tips4: 'thiết lập mật khẩu thành công',
        confirm: 'xác nhận'
    },
    newBe: {
        title: 'Mua đồng mới',
        title1: 'Tổng số tương tác của tôi',
        scqx: 'Thời gian lưu trữ',
        day: 'ngày',
        sy: 'còn lại',
        sdsg: 'Lưu trữ',
        sysl: 'Số lượng còn lại',
        gmsl: 'Số lượng mua',
        dj: 'Giá mua',
        tips: 'Vui lòng nhập số lượng mua',
        gmzj: 'Giá tổng mua',
        qbye: 'Số tiền trong ví',
        fmzj: 'Giá tổng mua',
        gm: 'Mua',
        sgsj: 'Thời gian mua',
        Currency: 'Mỹ phẩm',
        Request: 'Số lượng yêu cầu',
        Passing: 'Số lượng qua',
        MarketTime: 'Thời gian cung cấp',
        sqz: 'Đang yêu cầu'
    },
    register: {
        title: 'Đăng ký',
        lang: 'Ngôn ngữ',
        send: 'Gửi',
        Resend: 'Gửi lại',
        Account: 'Tài khoản',
        Password: 'Mật khẩu',
        ConfirmPassword: 'Xác nhận mật khẩu',
        InvitationCode: 'Mã khuyến mãi',
        btn: 'Đăng ký',
        amount: 'Tài khoản',
        mailbox: 'Thư mục email',
        haveAccount: 'Bạn có tài khoản chưa?',
        haveLogin: 'Đăng nhập ngay',
        Login: 'Đăng nhập',
        placeholder: 'Nhập email của bạn',
        placeholder0: 'Nhập số điện thoại của bạn',
        placeholder1: 'Nhập mật khẩu của bạn',
        placeholder2: 'Nhập xác nhận mật khẩu của bạn',
        placeholder3: 'Nhập mã khuyến mãi',
        placeholder4: 'Nhập mật khẩu xác nhận',
        placeholder5: 'Nhập địa chỉ email hợp lý',
        success: 'Đăng ký thành công',
        message: 'Mật khẩu phải có ít nhất 6 ký tự',
        message1: 'Xác nhận mật khẩu phải có ít nhất 6 ký tự',
        message2: 'Hai lần mật khẩu không khớp',
        message3: 'Vui lòng đọc và chọn đồng ý với các khoản đều',
        agree: 'Tôi đồng ý',
        xy: 'Chấm dứt',
        qr: 'Đăng ký ngay',
        ftitle: 'Tìm lại mật khẩu',
        qrzh: 'Xác nhận',
        placeholder7: 'Nhập mật khẩu mới',
        placeholder6: 'Xác nhận mật khẩu mới',
        placeholder8: 'Nhập mã hội viên',
        placeholderName: 'Nhập tên người dùng'
    },
    lockming: {
        title: 'Lịch sử lưu trữ',
        wkb: 'WKB',
        wkgjd: 'WKGJD',
        qsljl: 'QSLJL',
        yjzsr: 'YJZSR',
        ck: 'CK (Trong ba ngày qua)',
        tips: 'Phải có ít nhất',
        rlx: 'Rlx (Trong một ngày)',
        day: 'Ngày',
        scqx: 'SCQX (Thời gian lưu trữ)',
        buy: 'Mua',
        zysl: 'Zysl (Số lượng đặt lưu trữ)',
        qbye: 'Qbye (Số tiền trong ví)',
        finished: 'Hoàn thành',
        doing: 'Đang xử lý...',
        sl: 'SL (Số lượng)',
        shouyilv: 'Shouyilv (Tỉ lệ lợi nhuận)',
        ks: 'KS (Thời gian bắt đầu)',
        js: 'JS (Thời gian kết thúc)'
    },
    login: {
        title: 'Đăng nhập tài khoản',
        lang: 'Ngôn ngữ',
        mobile: 'Số điện thoại',
        mailbox: 'Thư mục email',
        Account: 'Tài khoản',
        Password: 'Mật khẩu',
        btn: 'Đăng nhập',
        placeholder1: 'Nhập mật khẩu',
        placeholder: 'Nhập tài khoản',
        fail: 'Vui lòng điền đầy đủ thông tin vào mẫu',
        ForgotPassword: 'Quên mật khẩu?',
        NoAccount: 'Không có tài khoản?',
        Registration: 'Đăng ký ngay',
        server: 'Trợ giúp trực tuyến',
        jzmm: 'Ghi nhớ mật khẩu',
        success: 'Đăng nhập thành công',
        Home: 'Trang chủ',
        userbox: 'Tên người dùng'
    },
    moneyAddr: {
        title: 'Địa chỉ ví',
        Currency: 'Mỹ phẩm',
        Address: 'Địa chỉ',
        placeholder: 'Hãy chọn',
        DepositAddress: 'Địa chỉ đặt tiền',
        WalletAddress: 'Địa chỉ ví',
        Date: 'Thời gian',
        add: 'Địa chỉ ví',
        addTxt: 'Mỗi địa chỉ ví tối đa có thể thêm',
        remark: 'Ghi chú',
        Walletremark: 'Hãy thêm thông tin ghi chú cho địa chỉ ví này',
        network: 'Mạng lưới (Kỹ thuật)',
        networkXy1: 'Bạn đã chọn mạng lưới là',
        networkXy2: ', hãy xác nhận địa chỉ này không thể lỗi. Nếu bạn chọn một mạng lưới blockchain không thể tìm thấy tài sản này, bạn sẽ mất tài sản của mình.',
        ChainAddress: 'Địa chỉ blockchain',
        qrcode: 'QR code ví',
        submit: 'Xác nhận',
        tips: 'Hãy chọn loại ví',
        tips1: 'Hãy chọn loại địa chỉ blockchain',
        tips2: 'Hãy nhập địa chỉ ví',
        tips3: 'Hãy tải lên QR code ví',
        success: 'Thành công'
    },
    recharge: {
        title: 'Nạp tiền',
        title1: 'Chi tiết bổ sung',
        ls: 'Chi tiết bổ sung',
        td: 'Vui lòng chọn kênh nạp tiền bên dưới',
        bc: 'Lưu mã QR',
        address: 'Địa chỉ nạp tiền',
        copy: 'Sao chép địa chỉ',
        num: 'Số tiền nạp đầy',
        Upload: 'Tải lên ảnh chụp chi tiết thanh toán',
        Submit: 'Xác nhận',
        tip: 'Vui lòng nhập số lượng',
        tip1: 'Vui lòng upload ảnh chụp màn hình',
        copySuc: 'Sao chép thành công'
    }
}