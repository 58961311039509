module.exports = {
    pcregister: {
        p1: 'มีบัญชีอยู่แล้ว?',
        dl: 'เข้าสู่ระบบ',
        zc: 'ลงทะเบียน',
        p4: 'บัญชีผู้ใช้',
        p5: 'กรุณากรอกบัญชี',
        p6: 'กรุณาใส่รหัสผ่าน',
        p7: 'กรุณากรอกรหัสผ่านยืนยัน',
        p8: 'กรุณากรอกรหัสเชิญ',
        p9: 'ฉันเห็นด้วย',
        p10: '《ข้อตกลงผู้ใช้และนโยบายความเป็นส่วนตัว》',
        p11: 'จดหมาย',
        p12: 'กรุณาใส่อีเมลของคุณ',
        p13: 'กรุณากรอกรหัสยืนยันอีเมล์',
        p14: 'ส่ง',
        p15: 'ปี',
        p16: 'เดือน',
        p17: 'ท้องฟ้า',
        p18: 'ชั่วโมง',
        p19: 'นาที',
        p20: 'ที่สอง'
    },
    pclogin: {
        l1: 'ไม่มีบัญชี?',
        l2: 'ลงทะเบียน',
        l3: 'เข้าสู่ระบบ',
        l4: 'กรุณาใส่ชื่อผู้ใช้',
        l5: 'กรุณาใส่รหัสผ่าน',
        l6: 'ลืมรหัสผ่าน',
        l7: 'เข้าสู่ระบบ'
    },
    pccontract: {
        ss: 'ราคาตลาด',
        xj: 'ขีด จำกัด ราคา',
        gg: 'คันโยก'
    },
    pcrapidly: {
        zf: 'เพิ่ม',
        dd: 'คำสั่ง',
        bdsj: 'เวลาท้องถิ่น',
        ky: 'มีอยู่'
    },
    pchome: {
        global1: 'แพลตฟอร์มการซื้อขายสินทรัพย์ดิจิทัลชั้นนำของโลก',
        global2: 'ให้บริการธุรกรรมสินทรัพย์ดิจิทัลที่ปลอดภัยและเชื่อถือได้และบริการจัดการสินทรัพย์แก่ผู้ใช้หลายล้านคนในกว่า 100 ประเทศ',
        global3: 'ปลอดภัยและเชื่อถือได้',
        global4: 'ประสบการณ์ห้าปีในบริการทางการเงินสินทรัพย์ดิจิทัล มุ่งเน้นไปที่สถาปัตยกรรมแบบกระจายและระบบต่อต้านการโจมตี DDoS',
        global5: 'รูปแบบทางนิเวศวิทยาทั่วโลก',
        global6: 'มีการจัดตั้งศูนย์บริการธุรกรรมที่แปลเป็นภาษาท้องถิ่นในหลายประเทศเพื่อสร้างระบบนิเวศบล็อกเชนที่รวมรูปแบบธุรกิจต่างๆ',
        global7: 'ผู้ใช้ก่อน',
        global8: 'สร้างกลไกการชดเชยล่วงหน้าและจัดตั้งกองทุนคุ้มครองผู้ลงทุน',
        whever1: 'ธุรกรรมเทอร์มินัลหลายแพลตฟอร์มทุกที่ทุกเวลา',
        whever2: 'ครอบคลุม iOS, Android, Windows หลายแพลตฟอร์ม รองรับการทำงานทางธุรกิจเต็มรูปแบบ',
        register1: 'ลงทะเบียน',
        register3: 'บัญชีสากลเริ่มทำการซื้อขาย',
        register2: 'สมัครเลย',
        commodity: 'สินค้า',
        news: 'ข่าว',
        ttop1: 'ค้นพบความลึกลับของ Pinnacle',
        ttop2: 'Pinnacle เป็นการแลกเปลี่ยนระหว่า งประเทศ ที่เกี่ยวข้องในอนาคต และตัวเลือกสัญญาพลังงานเกษตรกรรมสกุลเงินโลหะ และอื่น ๆเนื่องจากการจัดตั้งการแลกเปลี่ยนได้ปรับปรุงกฎการซื้อขายอย่างต่อเนื่อง และปริมาณของผลิตภัณฑ์ต่าง ๆ ได้เพิ่มขึ้นบริษัท ประสบความสำเร็จในการขยายบริการลูกค้าปลีก และสถาบันในกว่า 30 ประเทศ และยังคงขยายตัวเรามุ่งมั่น ที่จะรักษาต้นทุนการซื้อขายให้ต่ำ ที่สุดเท่า ที่จะทำได้ และให้บริการในจิตวิญญาณร่วมมือ ที่ช่วยให้ลูกค้าของเรากลายเป็นผู้ค้า ที่ทำกำไรไม่เพียง แต่ลูกค้า ที่ทำกำไรได้หลังจากทั้งหมดถ้าคุณทำดีเราทำได้ดี',
        ttop3: 'เริ่มการซื้อขายเดี๋ยวนี้',
        product1: 'ประมวลผลคำสั่งซื้อ ที่รวดเร็ว และเชื่อถือได้',
        product2: 'เครื่องมือการวิเคราะห์ขั้นสูง',
        product3: 'คำพูดแบบเรียลไทม์',
        product4: 'เร็ว และปลอดภัยในการถอน',
        trade1: 'ฉลาดกว่า',
        trade2: 'ฝึกกลยุทธ์ของคุณ',
        trade3: 'ก่อน ที่คุณจะเปิดบัญชีการซื้อขายจริงเพื่อสำรวจแพลตฟอร์มการซื้อขาย ที่ใช้งานง่ายของเรา และเพิ่มทักษะของคุณ.',
        trade4: 'ขยายความรู้ของคุณ',
        trade5: 'เรียนรู้เกี่ยวกับการซื้อขาย CFDs ด้วย Pinnacle โดยใช้วัสดุการศึกษา ที่ครอบคลุมของเรา',
        trade6: 'จัดการความเสี่ยงของคุณ',
        trade7: 'ใช้เครื่องมือจัดการความเสี่ยงขั้นสูงของเราเพื่อ จำกัด การสูญเสียของคุณ และล็อคในผลกำไร',
        allselect1: 'ทำไมพวกเธอถึงเลือก Pinnacle ล่ะ?',
        allselect2: 'ได้รับการคุ้มครอง และปลอดภัย',
        allselect3: 'ข้อมูลของคุณมีความปลอดภัย และเงินทุนของคุณจะถูกเก็บไว้ในบัญชีธนาคาร ที่แยกออกจากกันตามข้อกำหนดด้านกฎระเบียบ',
        allselect4: 'การสนับสนุนแบบมืออาชีพ',
        allselect5: 'รับบริการลูกค้าเฉพาะตลอด 24 ชั่วโมงในหลายภาษา',
        allselect6: 'ควบคุม',
        allselect7: 'ได้รับใบอนุญาต และการควบคุมโดยความหลากหลายของหน่วยงานกำกับดูแลชั้นนำโลก',
        allselect8: 'เชื่อถือได้',
        allselect9: 'Pinnacle จำกัด เป็น บริษัท FTSE 250 ที่ระบุในตลาดหลักทรัพย์หลักของ บริษัท สาธารณะแคนาดา',
        down1: 'โทรศัพท์มือถือรุ่นใด ๆ บนสถานีโทรศัพท์มือถือสามารถรองรับการดำเนินงาน และใช้งาน',
        down2: 'แอพแพลตฟอร์ม',
    },
    pcbottom: {
        box1: 'ชั้นนำของโลก',
        box2: 'หนึ่งในทีมที่ดีที่สุด',
        box3: 'มากกว่า',
        box4: 'เกี่ยวกับเรา',
        box5: 'กระดาษสีขาว',
        box6: 'ให้บริการ',
        box7: 'ดาวน์โหลด',
        box8: 'ปัญหาที่พบบ่อย',
        box9: 'บริการออนไลน์',
        box10: 'ระเบียบข้อบังคับ',
        box11: 'ข้อตกลงการใช้',
        box12: 'นโยบายความเป็นส่วนตัว',
        box13: 'ประกาศทางกฎหมาย',
        box14: 'เชื่อมต่อ'
    },
    pcAbout_us: {
        t1: 'คอร์ส',
        t2: 'เรา 7 ปีของถนน',
        t3: 'นับตั้งแต่ก่อตั้งในปี 2558 เราได้อุทิศตนเพื่อให้บริการบล็อกเชนด้วยเทคโนโลยีเต็มเวลาและบริการสร้างช่องทาง',
        t4: 'จากปี 2014 ถึงปี 2017 ฉันพบกับช่วงขาขึ้นของ "การเติบโตที่มั่นคง" ครึ่งแรกของปี 2017-2018 เป็นช่วงเวลาที่บ้าคลั่งในการ "ซื้อเงินเพื่อรวย" ตั้งแต่ครึ่งหลังของปี 2019 จนถึงฤดูหนาวที่หนาวเย็นในวันนี้ ฉันมี พัฒนาทักษะที่แท้จริงมากมายนับไม่ถ้วนพันธมิตรที่ประสบความสำเร็จทำให้เราเป็นคนธรรมดา ๆ เรามีชื่อเสียงโด่งดังในอุตสาหกรรม ในปี 2565 เราจะมีแพลตฟอร์มใหม่ล่าสุด V3.0 และจัดตั้งบริษัทร่วมหุ้นใหม่ BitGoldEX เพื่อดำเนินการ ธุรกิจมากกว่า 100 ประเทศทั่วโลก',
        t5: 'เรากำลังมองหาใคร',
        t6: 'ตั้งแต่เริ่มต้นจนถึงวันนี้',
        t7: 'ฉันมองหาผู้ให้บริการที่ประสบปัญหาและมีรสนิยมเดียวกับฉันฉันสามารถแก้ไขปัญหาให้กับผู้ให้บริการหลายสิบรายได้สำเร็จภายในเวลาไม่กี่ปี',
        t8: 'ตามหลักเหตุผลในปัจจุบัน หากคุณต้องการหาผู้ช่วยที่ดี คู่คิดที่สามารถทำงานร่วมกันเพื่อตระหนักรู้ในตัวเองและก้าวต่อไป คุณอาจคุยกับเราได้',
        t9: 'โปรดเชื่อเถอะว่าคนที่คุณตามหากำลังตามหาคุณอยู่',
        t10: 'ให้บริการ',
        t11: 'ไม่มีพนักงานขาย แต่มีธุรกิจ',
        t12: 'ถ้าเพื่อนแนะนำธุรกิจให้คุณ แสดงว่าคุณเป็นคนดี เมื่อเพื่อนร่วมงานแนะนำธุรกิจให้คุณ แสดงว่าคุณมีชื่อเสียงที่ดี เมื่อลูกค้าแนะนำธุรกิจให้คุณ แสดงว่าคุณน่าเชื่อถือ',
        t13: 'วิญญาณ',
        t14: 'เราเป็นก้อนอิฐ ไม่ต้องไปหาที่ไหน',
        t15: 'ลูกค้าต่างมีความต้องการที่แตกต่างกัน ผลิตภัณฑ์ต่าง ๆ ลักษณะเฉพาะ โอกาสต่าง ๆ และเป้าหมายต่าง ๆ ความหมายของการดำรงอยู่ของเราคือการปรับให้เข้ากับแนวคิด ระบบ และข้อกำหนดของผู้ให้บริการต่าง ๆ และแก้ปัญหาต่าง ๆ',
        t16: 'เราเต็มใจที่จะเป็นก้อนอิฐที่มีความคิดและหน้าที่ จำเป็นแค่ไหน เราจะไปที่นั่น'
    },
    pcproblem: {
        p1: 'ปัญหาที่พบบ่อย',
        p2: 'เหตุใดจำนวนสินทรัพย์ที่แปลงจึงเปลี่ยนไป',
        p3: 'การคำนวณสินทรัพย์ที่เทียบเท่าคือมูลค่าของสกุลเงินดิจิทัลที่ถือครองอยู่ในปัจจุบันที่แปลงเป็น USD ซึ่งเปลี่ยนแปลงเนื่องจากความผันผวนของราคาของสินทรัพย์ดิจิทัล ปริมาณสินทรัพย์ดิจิทัลของคุณไม่เปลี่ยนแปลง',
        p4: 'วิธีจัดการกับการลืมรหัสผ่านกองทุนในการทำธุรกรรมบนแพลตฟอร์ม',
        p5: 'หากคุณลืมรหัสผ่านกองทุนธุรกรรมแพลตฟอร์ม โปรดไปที่ "การตั้งค่าของฉัน-คลิกเพื่อตั้งรหัสผ่านกองทุน" หรือติดต่อฝ่ายบริการลูกค้าเพื่อรีเซ็ต',
        p6: 'เงินไปที่ไหนหลังจากซื้อธุรกรรม?',
        p7: 'หลังจากการสั่งซื้อเสร็จสมบูรณ์ เงินจะเข้าสู่บัญชีสกุลเงินตามกฎหมายในสินทรัพย์ หากคุณต้องการซื้อสินทรัพย์สำหรับธุรกรรมสัญญาหรือธุรกรรมฟิวเจอร์ส โปรดไปที่โมดูลการโอนเงินเพื่อโอน',
        p8: 'ทำไมต้องจำกัดจำนวนการเปลี่ยนชื่อเล่น',
        p9: 'เพื่อความปลอดภัยในการทำธุรกรรมของคุณ ขณะนี้เราจำกัดชื่อเล่นให้แก้ไขได้เพียงครั้งเดียวเท่านั้น',
        p10: 'หมายความว่าอย่างไรเมื่อคุณโอนและทำไมคุณต้องโอน',
        p11: 'เพื่อให้แน่ใจว่าเงินทุนระหว่างบัญชีของคุณเป็นอิสระและเพื่ออำนวยความสะดวกในการควบคุมความเสี่ยงที่สมเหตุสมผลของคุณ เราขอแบ่งการโอนบัญชีของแต่ละโมดูลการซื้อขายหลัก การโอนหมายถึงกระบวนการแปลงสินทรัพย์ระหว่างบัญชีซื้อขาย',
        p12: 'วิธีคำนวณส่วนของบัญชีของสัญญาผู้ใช้',
        p13: 'มูลค่าสุทธิของบัญชีตามสัญญา = ส่วนต่างของตำแหน่ง + กำไรและขาดทุนลอยตัวของตำแหน่ง + จำนวนเงินที่มีอยู่ของบัญชีปัจจุบัน',
        p14: 'มูลค่าของการตั้งค่า Stop Loss และ Take Profit เมื่อเปิดสถานะคือเท่าใด และควรตั้งค่าอย่างไร',
        p15: 'ขีดจำกัดบนของกำไรและขาดทุนที่ตั้งไว้สำหรับคุณขึ้นอยู่กับกำไรและขาดทุน เมื่อถึงจำนวน stop profit และ stop loss ที่ตั้งไว้ ระบบจะปิดสถานะโดยอัตโนมัติ สามารถใช้สำหรับการควบคุมความเสี่ยงเมื่อซื้อสัญญาที่ตั้งไว้ครึ่งหนึ่ง ของจำนวนหยุดกำไรเป็น: เพิ่ม x ปริมาณ x เลเวอเรจทวีคูณ การตั้งค่าหยุดการขาดทุน เราขอแนะนำให้คุณตั้งค่าตามสถานการณ์สินทรัพย์จริงของคุณเพื่อควบคุมความเสี่ยงอย่างสมเหตุสมผล',
        p16: 'วิธีลดความเสี่ยงสัญญา',
        p17: 'คุณสามารถโอนสินทรัพย์ที่มีอยู่ของบัญชีที่เหลือไปยังบัญชีสัญญาได้โดยการโอนเงิน หรือลดความเสี่ยงโดยการลดตำแหน่ง',
        p18: 'ปริมาณการเปิดสัญญาหมายถึงอะไร?',
        p19: 'จำนวนตำแหน่งที่เปิดแสดงถึงจำนวนสกุลเงินที่คุณคาดว่าจะซื้อ ตัวอย่างเช่น ในหน้าเริ่มต้นของคู่การซื้อขาย BTC/USDT ให้เลือก เปิดสถานะ Long ป้อนราคาเป็น 1,000USD และป้อนปริมาณเป็น 10BTC นั่นคือ : คุณคาดว่าจะซื้อ 10 BTC ที่ราคาต่อหน่วย 1,000USD',
        p20: 'ค่าธรรมเนียมการจัดการในสัญญาคำนวณอย่างไร?',
        p21: 'ค่าธรรมเนียมการจัดการ = ราคาเปิด * ปริมาณการเปิด * อัตราค่าธรรมเนียมการจัดการ',
        p22: 'ข้อควรระวังในการบังคับชำระบัญชี',
        p23: 'ระดับความเสี่ยงเป็นตัวบ่งชี้เพื่อวัดความเสี่ยงของสินทรัพย์ของคุณ เมื่อระดับความเสี่ยงเท่ากับ 100% สถานะของคุณจะถูกพิจารณาว่าปิดแล้ว ระดับความเสี่ยง = (ส่วนต่างการถือครอง / ส่วนบัญชีสัญญา) * 100% ปรับอัตราส่วนความเสี่ยง เมื่อระดับความเสี่ยงถึงค่าความเสี่ยงที่ระบบกำหนดระบบจะบังคับชำระบัญชี ตัวอย่างเช่น อัตราส่วนการปรับปรุงที่ระบบกำหนดไว้คือ 10% จากนั้นเมื่อระดับความเสี่ยงของคุณมากกว่าหรือเท่ากับ 90% สถานะทั้งหมดของคุณ จะถูกปิดระบบบังคับชำระบัญชี',
        p24: 'กฎการซื้อขายสัญญาคืออะไร',
        p25: 'ประเภทธุรกรรม',
        p26: 'ประเภทธุรกรรมแบ่งออกเป็นทิศทางยาว (ซื้อ) และสั้น (ขาย):',
        p27: 'การซื้อระยะยาว (ขาขึ้น) หมายความว่าคุณเชื่อว่าดัชนีปัจจุบันมีแนวโน้มสูงขึ้น และคุณต้องการซื้อจำนวนหนึ่งในจำนวนที่กำหนดเพื่อทำสัญญาในราคาที่คุณกำหนดหรือราคาตลาดปัจจุบัน',
        p28: 'การขายชอร์ต (ตลาดหมี) หมายความว่าคุณเชื่อว่าดัชนีปัจจุบันอาจตกลงและต้องการขายสัญญาใหม่จำนวนหนึ่งในราคาที่คุณกำหนดหรือราคาตลาดปัจจุบัน',
        p29: 'วิธีการสั่งซื้อ',
        p30: 'คำสั่งจำกัด: คุณต้องระบุราคาและปริมาณของคำสั่ง',
        p31: 'คำสั่งซื้อขายในตลาด: คุณจะต้องกำหนดปริมาณการสั่งซื้อเท่านั้น และราคาคือราคาตลาดปัจจุบัน',
        p32: 'ตำแหน่ง',
        p33: 'เมื่อคำสั่งที่คุณส่งเพื่อเปิดตำแหน่งเสร็จสมบูรณ์ จะเรียกว่าตำแหน่ง',
        p34: 'ปัญหาการส่งมอบสัญญา',
        p35: 'สัญญาแพลตฟอร์มเป็นสัญญาถาวรและไม่มีเวลาส่งมอบ ตราบเท่าที่ระบบไม่ตรงตามเงื่อนไขบังคับชำระบัญชีหรือคุณไม่ได้ปิดตำแหน่งด้วยตนเอง คุณสามารถดำรงตำแหน่งได้อย่างถาวร ระบบจะปิดตำแหน่ง',
        p36: '1: ระบบจะปิดสถานะโดยอัตโนมัติเมื่อถึงค่าที่ตั้งไว้ของ stop profit และ stop loss',
        p37: '2: ความเสี่ยงสูงเกินไป ระบบบังคับให้ชำระบัญชี',
        p38: 'Margin สำหรับการซื้อขายสัญญาเป็นเท่าใด?',
        p39: 'ในการซื้อขายสัญญา ผู้ใช้สามารถเข้าร่วมในการซื้อขายสัญญาตามราคาและปริมาณของสัญญา รวมถึงเลเวอเรจทวีคูณที่สอดคล้องกัน เมื่อผู้ใช้เปิดตำแหน่ง พวกเขาจะมีมาร์จิ้น ยิ่งมีมาร์จิ้นสำหรับการเปิดตำแหน่งมากเท่าไหร่ ความเสี่ยงของบัญชี',
        p40: 'คำสั่งจำกัดและคำสั่งตลาดคืออะไร?',
        p41: 'คำสั่งจำกัดหมายถึงราคาที่คุณคาดว่าจะมอบความไว้วางใจให้แพลตฟอร์มในการซื้อขายและคำสั่งซื้อขายในตลาดหมายถึงการมอบความไว้วางใจโดยตรงให้กับแพลตฟอร์มในการซื้อขายในราคาปัจจุบัน ในกฎการเปิด Position คำสั่งซื้อขายในตลาดจะมีความสำคัญเหนือคำสั่งจำกัด หาก คุณเลือกคำสั่งจำกัด โปรดติดตามสกุลเงิน ราคาตลาดปัจจุบันเหมาะสมในการเปิดสถานะเพื่อหลีกเลี่ยงการขาดทุนที่เกิดจากราคาเปิดที่ไม่สมเหตุสมผล',
        p42: 'ระดับความเสี่ยงของธุรกรรมสัญญาแสดงถึงอะไร?',
        p43: 'ระดับความเสี่ยงคือดัชนีความเสี่ยงในบัญชีสัญญาของคุณ ระดับความเสี่ยงเท่ากับ 100% ซึ่งถือว่าเป็นการชำระบัญชี เราแนะนำว่าเมื่อความเสี่ยงของคุณเกิน 50% คุณต้องเปิดตำแหน่งอย่างระมัดระวังเพื่อหลีกเลี่ยงการขาดทุนที่เกิดจากการปิดตำแหน่ง คุณสามารถเสริมสินทรัพย์สัญญา เงินที่มีอยู่ หรือแบ่งเบาตำแหน่งเพื่อลดความเสี่ยง',
        p44: 'ทำไมคุณต้องการแลกเปลี่ยนสกุลเงิน?',
        p45: 'จุดประสงค์ของการแลกเปลี่ยนคือเพื่อให้การไหลเวียนของเงินทุนในสกุลเงินต่าง ๆ ในสินทรัพย์ของคุณเป็นไปอย่างราบรื่น QCC ที่ได้รับในบัญชีฟิวเจอร์สสามารถแปลงเป็น USD ได้อย่างอิสระสำหรับการซื้อขาย และ USD ในบัญชีอื่น ๆ ยังสามารถแปลงเป็น QCC ได้อย่างอิสระสำหรับ ซื้อขาย.',
        p46: 'ทำไมอีกฝ่ายไม่ได้รับเงินโอนภายในเวลาที่โอน?',
        p47: 'ขั้นแรก โปรดตรวจสอบให้แน่ใจว่าวิธีการชำระเงินที่คุณกรอกเมื่อโอนเงินตรงกับวิธีการชำระเงินที่แสดงในหน้ารายละเอียดการสั่งซื้อ จากนั้น โปรดยืนยันว่าวิธีการชำระเงินของคุณเป็นการชำระเงินทันทีหรือการชำระเงินล่าช้า สุดท้าย โปรดติดต่อธนาคารของคุณ และสถาบันการชำระเงินสำหรับการบำรุงรักษาระบบหรือเหตุผลอื่นๆ',
        p48: 'จะซื้อ USD ผ่านแพลตฟอร์มได้อย่างไร?',
        p49: 'วิธีที่ 1: เลือกคำสั่งซื้อที่คุณต้องการซื้อผ่านรายการซื้อและขายของแพลตฟอร์มเพื่อซื้อหรือขาย',
        p50: 'วิธีที่ 2: คลิกปุ่มเผยแพร่เพื่อเผยแพร่คำสั่งซื้อหรือขายตามความต้องการของคุณสำหรับการทำธุรกรรมที่รวดเร็ว ระบบจะจับคู่คุณกับผู้ซื้อและผู้ขายอย่างรวดเร็ว',
        p51: 'หมายเหตุ: หากคำสั่งซื้อไม่ได้รับการชำระเงินหลังจาก 15 นาที ระบบจะยกเลิกคำสั่งซื้อโดยอัตโนมัติ โปรดชำระเงินให้ทันเวลา หากคุณยกเลิกคำสั่งซื้อมากกว่า 3 ครั้งในวันเดียวกัน คุณจะไม่สามารถซื้อขายได้อีกในวันเดียวกัน และ สิทธิ์การซื้อขายจะได้รับการกู้คืนในวันถัดไป'
    },
    public: {
        zxkf: 'บริการลูกค้าออนไลน์',
        sure: 'ยืนยัน',
        cancel: 'ยกเลิก',
        RefreshSucceeded: 'รีเฟรชสำเร็จ',
        noMoreData: 'ไม่มีข้อมูลเพิ่มเติมให้อ่าน',
        pullingText: 'ดึงเพื่อรีเฟรช ...',
        ReleaseText: 'ปล่อยเพื่อรีเฟรช ...',
        loadingText: 'กำลังโหลด ...',
        noData: 'ไม่พบบันทึก',
        xq: 'รายละเอียด',
        submit: 'ส่ง',
        kefu: 'บริการลูกค้าออนไลน์',
        amount: 'จำนวนเงิน',
        startInvestment: 'ตำแหน่งการสร้าง',
        endInvestment: 'จุดสิ้นสุด',
        profit: 'กำไร'
    },
    ChangeType: {
        Upper: 'เงินฝากมาถึง',
        Lower: 'การถอนเงิน',
        Win: 'กำไร',
        Bet: 'เดิมพัน',
        BuyMining: 'เหมืองแร่',
        CoinWin: 'กำไรโทเค็นใหม่',
        SettleMining: 'คืนทุนในการขุด',
        GiveInterest: 'ดอกเบี้ยการขุด',
        BuyCoin: 'การซื้อโทเค็นใหม่',
        BuyCoinBack: 'คืนจากการซื้อโทเค็นใหม่',
        UsdtToBtc: 'Usd เป็น Btc',
        UsdtToEth: 'Usd เป็น Eth',
        BtcToUsdt: 'Btc เป็น Usd',
        BtcToEth: 'Btc เป็น Eth',
        EthToUsdt: 'Eth ถึง Usd',
        EthToBtc: 'Eth ถึง Btc',
        CoinThaw: 'การละลายของสกุลเงินใหม่',
        CoinFrozen: 'สกุลเงินใหม่แช่แข็ง',
        CoinCancel: 'คำสั่งสกุลเงินยกเลิก',
        CoinBuy: 'ซื้อสกุลเงิน',
        CoinBuyEntrust: 'สกุลเงิน ซื้อความไว้วางใจ',
        CoinSellEntrust: 'ความไว้วางใจในการขายสกุลเงิน',
        CoinSell: 'ขายสกุลเงิน',
        ContractBuy: 'คำสั่งซื้อตามสัญญาหักส่วนต่าง',
        ContractCharge: 'คำสั่งซื้อตามสัญญาหักค่าธรรมเนียมการจัดการ',
        ContractCancelToBond: 'คืนเงินมัดจำหลังยกเลิกสัญญา',
        ContractCancelToCharge: 'การขอคืนค่าบริการกรณียกเลิกสัญญา',
        ContractCloseProfit: 'รายได้ปิดสัญญา'
    },
    trade: {
        title: 'แลกเปลี่ยน',
        Buy: 'ซื้อ',
        Sell: 'ขาย',
        pc: 'ปิด',
        cb: 'ค่าใช้จ่าย',
        xj1: 'ราคาปัจจุบัน',
        yk: 'กำไรและขาดทุน',
        xianjia: 'ราคาคงที่',
        jiage: 'ราคา',
        shuliang: 'จำนวน',
        keyong: 'มีอยู่',
        yonghuchicang: 'ตำแหน่ง',
        fxlts: 'อัตราความเสี่ยง = ส่วนต่างของตำแหน่ง / ส่วนของลูกค้าคูณด้วย 100% โดยที่ส่วนของลูกค้าเท่ากับเงินทุนที่มีอยู่ในบัญชี + ส่วนต่างของธุรกรรมเริ่มต้น + กำไรและขาดทุนแบบลอยตัว',
        fxl: 'อัตราอันตราย',
        dqwt: 'เปิดออเดอร์',
        name: 'ชื่อ',
        leixing: 'พิมพ์',
        all: 'ทั้งหมด',
        shijian: 'เวลา',
        long: 'ยาว',
        short: 'สั้น',
        cc: 'ดำรงตำแหน่ง',
        zyjg: 'ราคาดีที่สุดในปัจจุบัน',
        caozuo: 'การดำเนินการ',
        xj: 'ราคาคงที่',
        sj: 'ราคาตลาด',
        jiaoyie: 'จำนวน',
        orderNo: 'เลขที่ใบสั่งซื้อ',
        weituojia: 'ราคา',
        chengjiaojia: 'ราคา',
        chengjiaoe: 'ราคาซื้อขาย',
        weituo: 'โหมด',
        qbwt: 'ผู้แทนทั้งหมด',
        lswt: 'ประวัติการสั่งซื้อ',
        tips: 'กรุณาใส่ราคา',
        tips1: 'กรุณาใส่ตัวเลข',
        Create: 'ความไว้วางใจ',
        Wait: 'ธุรกรรม',
        Complete: 'ปิด',
        Cancel: 'เพิกถอน',
        zsj: 'ราคาหยุดการขาดทุน',
        zyj: 'หยุดราคากำไร',
        cczsy: 'รายได้รวมจากตำแหน่ง',
        ykbl: 'อัตราส่วนกำไรขาดทุน',
        bili: 'อัตราส่วน',
        jyss: 'จำนวนการทำธุรกรรม',
        bzj: 'ระยะขอบ',
        sxf: 'ค่าธรรมเนียมการจัดการ',
        gg: 'ใช้ประโยชน์จากหลาย ๆ',
        yjpc: 'ปิดทั้งหมด'
    },
    auth: {
        title: 'การยืนยันตัว',
        title1: 'การยืนยันตัว',
        low: 'ระดับต่ำ',
        high: 'ระดับสูง',
        success: 'ยืนยันสำเร็จ',
        tips: 'กรุณายืนยันตัวตนก่อน',
        tips1: 'โปรดป้อนชื่อจริง',
        tips2: 'โปรดป้อนหมายเลขที่ประจำตัว',
        tips3: 'โปรดอัพโหลดภาพหลังรูปแบบประจำตัว',
        tips4: 'โปรดอัพโหลดภาพหลังรูปแบบบัญชี',
        tips5: 'โปรดอัพโหลดรูปถ่ายของคุณ',
        tips6: 'โปรดเลือกประเภทการยืนยันที่ต้องการ',
        qrz: 'ไปยืนยัน',
        shz: 'กำลังตรวจสอบ',
        rzsb: 'ยืนยันไม่สำเร็จ',
        userName: 'ชื่อจริง',
        IDNumber: 'หมายเลขที่ประจำตัว',
        zmz: 'ภาพหลังรูป ID',
        bmz: 'ภาพหลังบัญชี ID',
        scz: 'รูปถ่ายของผู้ใช้',
        IDCard: 'บัตรประจำตัวประชาชน',
        Passport: 'บัตรทะเบียนพาณิชย์',
        AuthenticationType: 'ประเภทการยืนยันที่ต้องการ',
        HighAuth: 'การตรวจสอบขั้นสูง',
        gj: 'สัญชาติ',
        tipsgj: 'กรุณาพิมพ์สัญชาติ',
    },
    share: {
        title: 'เปลี่ยนแปลงการเชื่อมต่อ',
        cp: 'สำหรับการเชื่อมต่อ',
        success: 'สำเร็จ'
    },
    withdrawal: {
        Wallet: 'เงินในกระเป๋า',
        Internal: 'การโอนภายใน',
        Currency: 'หน่วยเงิน',
        Main: 'เครือข่ายหลัก (พร้อม)',
        WithdrawAddress: 'ที่ออกเฉลี่ย',
        Frequently: 'ที่ออกเฉลี่ย',
        placeholder: 'USDที่ออกเฉลี่ย',
        note: 'หมายเหตุ',
        placeholder1: 'โปรดประเมินการหมายเหตุ',
        Amount: 'จำนวนที่ออก',
        placeholder2: 'ขั้นต่ำที่ออกเป็น 10',
        All: 'ทั้งหมด',
        Balance: 'ยอดเงินในกระเป๋า',
        Fee: 'ค่าธรรมเนียมการออก',
        FundPassword: 'รหัสผ่านเงิน',
        placeholder3: 'โปรดประเมินรหัสผ่านเงิน',
        forget: 'ลืมรหัสผ่านเงิน ?',
        OTP: 'หมายเหตุผล',
        placeholder4: 'โปรดประเมินหมายเหตุผล',
        send: 'ส่ง',
        Resend: 'ส่งอีกครั้ง',
        Reminder: 'คำเตือน',
        Reminder1: 'การดำเนินการออกเงินเปิดให้บริการวันจันทร์ถึงวันศุกร์ เวลา 9:00 - 22:00 (UTC+8)',
        Reminder2: 'จำนวนสามารถออกได้ทั้งหมด = ยอดเงินในกระเป๋า โดยจำนวนเงินออกรวมค่าธรรมเนียมการออก',
        Reminder3: 'ส่งข้อมูลการออกเงินไปยังเครือข่าย blockchain แล้วไม่สามารถยกเลิกได้',
        Confirm: 'ยืนยันการออกเงิน',
        noPassword: 'ยังไม่ได้ระบุรหัสผ่านเงิน',
        Fees: 'ค่าธรรมเนียม',
        Free: 'ฟรี',
        Transfer: 'จำนวนที่โอน',
        Limitation: 'ขั้นต่ำการโอนเงินวันละ',
        placeholder6: 'จำนวนเงินโอนต่ำสุด 100000',
        Available: 'ค่าธรรมเนียมการออก',
        Receiving: 'รับบัญชี',
        placeholder5: 'โปรดระบุชื่อเล่น',
        TransferBtn: 'โอนเงิน',
        Reminder4: 'หมายเตือน: โอนเงินโดยต้องการตรวจสอบข้อมูลผู้ใช้ที่ถูกต้อง',
        Reminder5: 'เวลาทำการโอนเงินภายใน 09:00 - 22:00 (UTC+8)',
        title: 'รายละเอียด',
        title1: 'บัญชีเงิน',
        title2: 'บัญชีสัญญา',
        title3: 'ทรัพย์สิน',
        title4: 'เช่า',
        title5: 'ถอดเงิน',
        Rate: 'เปลี่ยนแปลงเงินแบบ',
        allRate: 'ค่าเปลี่ยนแปลงทั้งหมด',
        PlaceOrder: 'สั่งซื้อ',
        Freeze: 'หยุด',
        Thaw: 'ละลายน้ำแข็ง',
        backgroundProgram: 'โปรแกรมที่เก็บไว้',
        AuditReject: 'ถอดเงิน ไม่ยอบรับ',
        OrderSettlement: 'การจ่ายเงินแล้ว',
        AccountBalance: 'ยอดเงินในบัญชี:  ',
        CancelOrder: 'ยกเลิกสัญญา',
        Created: 'สร้างขึ้น',
        Paid: 'ชำระเงินแล้ว',
        UnAuthed: 'รอก่อน',
        Accepted: 'ยืนยันแล้ว',
        Canceled: 'ยกเลิกสัญญา',
        Refuce: 'ปฏิเสธ',
        placeholderMC: 'ขายเสร็จสิ้น',
        read: 'อ่านแล้ว',
        unread: 'ยังไม่ได้อ่าน',
        chat: 'พูดคุย',
    },
    newRecharge: {
        Deposit: 'เงินดาวน์โหลด',
        Credit: 'บัตรเครดิต',
        Wallet: 'กระเป๋าเงินดาวน์โหลด แล้วแจกโปรโมต',
        FAQ: 'แนะนำการดาวน์โหลด',
        Currency: 'เลือกหุ้น',
        placeholder: 'โปรดเลือกหุ้น',
        Main: 'เครือข่ายหลัก (พร้อม)',
        DepositAds: 'ที่ออกเฉลี่ย',
        Copy: 'คัดลองที่ออกเฉลี่ย',
        remark: '• โปรดไม่ให้โฆษณาหรือเงินทรัพย์ที่ไม่ใช่ USD ถูกโฆษณาในที่ออกเฉลี่ย อย่างไรก็ตาม เงินทรัพย์ที่โฆษณาไปยังที่ออกเฉลี่ยจะไม่สามารถคืนได้',
        remark1: 'USD',
        remark2: '• หลังจากที่คุณโฆษณาเงินไปยังที่ออกเฉลี่ย คุณจำเป็นต้องให้ทั้งหมด 6 จุดของเครือข่ายที่ต้องการยืนยัน จุดยืนยันเหล่านี้จะทำให้เงินไปยังบัญชีของคุณได้',
        remark3: '• ไม่สามารถโฆษณาเงินไปยังที่ออกเฉลี่ยละเอียดน้อยกว่า 15 USD เพราะจะไม่สามารถโฆษณาได้',
        remark4: '• หลังจากที่คุณโฆษณาเงินไปยังที่ออกเฉลี่ย คุณจำเป็นต้องไปยังหน้านี้เพื่อคัดลองที่ออกเฉลี่ย เพื่อให้แน่ใจว่าที่ออกเฉลี่ยถูกต้องไม่ใช่',
        remark5: '• โปรดทราบว่า ทุกครั้งที่คุณต้องการดาวน์โหลดเงิน คุณจำเป็นต้องไปยังหน้านี้เพื่อคัดลองที่ออกเฉลี่ย เพื่อให้แน่ใจว่าที่ออกเฉลี่ยถูกต้องไม่ใช่ ที่ออกเฉลี่ยผิดพลาดจะทำให้การดาวน์โหลดเงินไม่สำเร็จ',
        Pay: 'ชำระเงิน',
        Buy: 'ซื้อ',
        Merchant: 'ร้านค้าที่ได้รับการยืนยันจากเครือข่าย',
        remark6: '* คลิกเพื่อดู',
        remark7: 'ข้อเรียน',
        remark8: ' หากยังไม่มีร้านค้าที่ตรงกับคุณ โปรดตรวจสอบเชื่อมต่อเนอร์ของเครือข่ายแล้วเปลี่ยนเมนูrefresh เพื่อเพิ่มโอกาสในการพบร้านค้า',
        remark9: ' หรือโทรศัพท์สนทนากับบริการสนับสนุนออนไลน์',
        ChipPay: 'ธนาคารชิปพายท์',
        Purchasebyamount: 'ซื้อโดยจำนวนเงิน',
        AccountBalance: 'ยอดเงินในบัญชี',
        Tips: 'แนะนำ'
    },
    ChipPay: {
        copySUC: 'คัดลอกสำเร็จ',
        copyERR: 'การคัดลอกล้มเหลว',
        Buy: 'ซื้อ',
        TOBECONFIRMED: 'รอยืนยัน',
        TransactionCompletion: 'การเสร็จสิ้นการซื้อ',
        OrderingInformation: 'ข้อมูลการสั่งซื้อ',
        oddNumbers: 'หมายเลขสั่งซื้อ: ',
        TransferAmount: 'จำนวนเงินที่โอน: ',
        ApproximatelyQuantity: 'จำนวนประมาณ: ',
        UnitPrice: 'ราคาต่อหน่วย: ',
        remark1: 'หากไม่ได้สั่งให้ยืนยันการโอนเงินหรือจำนวนเงินที่โอนไม่เหมาะสม จะทำให้การโอนเงินถูกยัดเยียด การโอนเงินจากบัญชีที่ไม่เป็นของคุณและข้อความเพิ่มเติม/คำแนะนำจะทำให้การซื้อล้มเหลวและจะถูกคืนเงิน',
        remark2: 'คำแนะนำที่ดี',
        remark3: 'ข้อมูลการรับชำระเงินจากการสั่งซื้อแบบนี้มีความเป็นไปได้เพียงครั้งเดียว โปรดไม่เก็บข้อมูลเพื่อใช้ในการโอนเงินอีกครั้ง',
        remark4: 'หากคุณสั่งซื้อเพียงครั้งแล้วไม่ได้ชำระเงิน คุณจะไม่สามารถสั่งซื้ออีกครั้ง โปรดประมาณการใช้งานอย่างระมัดระวัง',
        remark5: 'โปรดให้คุณได้รับการยืนยันการโอนเงินแล้ว โดยเฉพาะอย่างยิ่งในช่วงเวลาที่กำหนด เพื่อให้เงินถึงบัญชีของคุณได้อย่างเร็วที่สุด',
        remark6: 'หากมีการแจ้งข้อมูลว่า "การแจกเงินผ่านเครือข่ายไม่สำเร็จ" ขณะนี้มีความเข้าใจในการเรียกใช้งานของเครือข่ายเป็นเวลามากขึ้น โปรดเดินหน้าไปยังหน้านี้ให้ได้ทันที เพื่อยืนยันการโอนเงินของคุณอีกครั้ง',
        remark7: 'การโอนเงินของคุณจะถูกโอนไปยังบัญชีเงินของผู้ค้าร้านค้าที่ได้รับการยืนยันจากเครือข่าย ในขณะที่ผู้ค้าร้านค้าขายสินค้าคือความเป็นจริงของคุณอยู่ในการควบคุมของเครือข่าย โปรดให้ความเชื่อมั่นในการสั่งซื้อ',
        cancellation: 'ยกเลิกสัญญา',
        remittance: 'ฉันได้ทำการโอนเงินแล้ว',
        remark8: 'ข้อเรียนการซื้อ',
        remark9: 'คุณจะได้รับการเปลี่ยนแปลงเงินดาวน์โหลดUSDจากเครือข่ายChipPay ในขณะที่คุณสั่งซื้อ การเปลี่ยนแปลงจะมีการสนับสนุนจากเครือข่ายในการทำให้ความเป็นจริงของUSDของคุณ โดยในช่วงเริ่มการซื้อ โปรดอ่านและยอมรับข้อตกลงต่อไปนี้เป็นสิ่งที่สำคัญ:',
        remark10: 'โปรดทราบว่า USDไม่ได้ถูกประกอบของเครือข่ายChipPay และไม่มีความสามารถใช้เป็นเงินทรัพย์ที่มีความรักษาหรือการรับคำแนะนำ',
        remark11: 'ในขณะที่คุณกำลังจะซื้อหรือขาย USD กับผู้มี USD ที่มีอยู่ในเครือข่ายของเรา คุณจะต้องปฏิบัติตามข้อกำหนดของคำแนะนำการป้องกันการเงินเหลือในข้อความ',
        remark12: 'โปรดอ่านคำแนะนำเกี่ยวกับความเสี่ยงของการซื้อขายหุ้นตลาด และยอมรับข้อความดังกล่าว',
        remark13: 'ฉันอ่านและยอมรับข้อกำหนดการให้บริการ',
        remark14: 'หากยังไม่ได้คลิกปุ่ม "ยืนยันการโอน" หรือจำนวนเงินโอนไม่เหมาะสม จะทำให้การโอนถูกเลื่อน และการโอนจากบัญชีของผู้ใช้ไม่ถูกยืนยันและจะถูกคืนเงิน',
        remark15: '1. โปรดตรวจสอบว่าจำนวนเงินโอนของคุณเท่ากับจำนวนเงินในสัญญา หากยังไม่โอน โปรดคลิกปุ่มนี้ อย่างไรก็ตาม คุณอาจจะถูกจัดอยู่ในรายชื่อผู้ใช้ที่ดำเนินการไม่ถูกต้อง',
        ConfirmRemittance: 'ยืนยันการโอน',
        WrongPoint: 'ถูกใช้งานผิด',
        Ialreadyknow: 'ฉันอยู่ในระบบแล้ว',
        payattentionto: 'กรุณาตรวจสอบ',
        agree: 'ยอมรับ',
        Bank: 'ธนาคารรับเงิน: ',
        ReceivingAccount: 'บัญชีรับเงิน: ',
        Party: 'พาร์ทเนอร์: ',
        Authenticated: 'ได้รับการยืนยันชื่อจริง',
        Margin: 'ความรักษา',
        tips1: 'โปรดแสดงความเข้าใจกับความเสี่ยงของการซื้อขายหุ้นตลาด'
    },
    newProperty: {
        title: 'ทรัพย์สินทั้งหมด (USD)',
        Spot: 'สินค้าที่สามารถใช้งานได้',
        Contract: 'สัญญาที่สามารถใช้งานได้',
        Bonus: 'โบนัส',
        Purchase: 'ซื้อเงิน',
        Deposit: 'โอนเงิน',
        Withdraw: 'ถอดเงิน',
        Transfer: 'อนุญาติการโอน',
        Spot1: 'ทรัพย์สินที่เป็นเงิน',
        Contract1: 'ทรัพย์สินที่เป็นสัญญา',
        Commission1: 'ค่าเสียหาย',
        Financial1: 'ทรัพย์สินที่เป็นการเงินทุน',
        Balance: 'สมดุลของทรัพย์สินทั้งหมด',
        Hide: 'ซ่อน 0 ทรัพย์สิน'
    },
    property: {
        Contract: 'เงินตรา',
        Exchange: 'เปลี่ยนแปลงเงินตราทันที',
        zzc: 'จำนวนทรัพย์สินที่แปลงเป็นเงินตรา',
        mhyzh: 'บัญชีเปลี่ยนแปลงเงินตรา',
        bbzh: 'บัญชีเงินตรา',
        available: 'สามารถใช้ได้',
        OnOrders: 'ถูกลอบสร้าง',
        Estimated: 'ประมาณ',
        cw: 'บันทึกการเงิน',
        number: 'จำนวน',
        record: 'บันทึก',
        Transfer: 'โอน',
        time: 'เวลา',
        dhsl: 'จำนวนที่ต้องการเปลี่ยน',
        tips: 'โปรด 입력จำนวนที่ต้องการเปลี่ยน',
        qbdh: 'ทั้งหมดเปลี่ยน',
        dhhl: 'เปลี่ยนเงินตรา',
        ky: 'สามารถใช้ได้',
        kd: 'สามารถใช้ได้',
        sxfbl: 'สัดส่วนค่าฝ่ายเบี้ย',
        sxf: 'ค่าฝ่ายเบี้ย'
    },
    statusLang: {
        Review: 'ทบทวน',
        Success: 'ความสำเร็จ',
        Fail: 'ล้มเหลว'
    },
    with: {
        title: 'ถอดเงินออก',
        history: 'บันทึกถอดเงินออก',
        ldz: 'เลขที่เชื่อมต่อ',
        tbdz: 'ที่อยู่ที่จะถอดเงิน',
        tips: 'โปรดป้อนที่อยู่ที่จะถอดเงิน',
        tips1: 'โปรดป้อนจำนวนเงินที่ต้องการถอดออก',
        tips2: 'โปรดป้อนรหัสผ่านการชำระ',
        tbsl: 'จำนวนเงินที่ต้องการถอดออก',
        ky: 'สามารถใช้ได้',
        zjmm: 'รหัสผ่านการชำระ',
        sxf: 'ค่าฝ่ายเบี้ย',
        dz: 'จำนวนเงินที่คาดว่าจะถูกถอดออก'
    },
    tabBar: {
        home: 'ที่บ้าน',
        Quotation: 'ตลาด',
        Quotation2: 'อ้างอิงตลาด',
        Trade: 'การซื้อขาย',
        Contract: 'สัญญาเฉลี่ย',
        newContract: 'การซื้อขาย',
        assets: 'ทรัพย์สิน',
        qhyy: 'เปลี่ยนภาษา',
        zyjb: 'ล็อกแซกเบอร์',
        jsjy: 'การซื้อขายเร็ว',
        jryw: 'บริการทางการเงิน',
        hyjy: 'การซื้อขายสัญญา',
        Frequently: 'คำถามที่ถามได้',
        mine: 'ฉัน'
    },
    exchange: {
        // 闪兑换
        title: 'แฟลชไดรฟ์',
        canChange: 'จำนวนที่สามารถเปลี่ยน',
        canChange1: 'โปรด 입력จำนวนที่ต้องการเปลี่ยน',
        title1: 'รายละเอียดการเปลี่ยน',
        Available: 'ความเหลือ',
        Proposed: 'ราคาเชิงเปรียบ',
        Redeem: 'ทั้งหมด',
        Confirm: 'ยืนยัน',
        CurrencyBalance: 'ความเหลือของเงินตรา'
    },
    home: {
        // grid
        gridcz: 'เติมเงินตรา',
        gridsd: 'กำลังถอนเงิน',
        gridxs: 'คำแนะนำสำหรับผู้ใหม่',
        gridbd: 'รายชื่อบรรทัด',
        Balance: 'ความเหลือเงิน',
        UserName: 'ชื่อบัญชี',
        kjmb: 'ซื้อเงิน',
        score: 'คะแนะนำการชำระเงิน',
        zc: 'สนับสนุน BTC, USD และอื่น ๆ',
        scwk: 'ล็อกแซกเบอร์',
        sgxb: 'สมัครเงินใหม่',
        aqkx: 'ปลอดภัยและน่าเชื่อถือ',
        aqkx1: 'เป็นศูนย์ขายหุ้นที่นำเงินดังกล่าวขึ้นแล้วในโลก',

        // 榜單
        rmcjb: 'รายการขายที่มีความนิยม',
        xbb: 'บรรทัดราคาขาย',
        zfb: 'บรรทัดราคาเพิ่มขึ้น',
        dfb: 'บรรทัดราคาลดลง',

        //認定
        identify: 'ยังไม่ได้รับการยืนยัน',
        identifyYes: 'ยืนยัน',
        changeName: 'โปรดป้อนชื่อ',
        changeName1: 'ชื่อสามารถเปลี่ยนได้เพียงครั้งเดียว',
        confirmName: 'ยืนยัน',
        closeName: 'ยกเลิก',

        mc: 'ชื่อ',
        hy: 'สัญญา',
        zxj: 'ราคาล่าสุด',
        zdb: 'ปรับปรุง',
        sfyz: 'การยืนยันตัวตน',
        skfs: 'วิธีการชำระ',
        share: 'ลิงค์แชร์ของฉัน',
        kjcb: 'เติมเงิน',
        kjtb: 'ถอดเงิน',
        qbdz: 'ที่อยู่ประจำตัว',
        bps: 'บริษัท',
        xgmm: 'ปรับรหัสผ่าน',
        xgzfmm: 'ปรับรหัสการชำระ',
        gywm: 'เกี่ยวกับเรา',
        tcdl: 'ออกจาก',

        // 個人中心
        zjmx: 'รายละเอียดทุน',
        cdsz: 'ตั้งค่า',
        cdaq: 'ยืนยันชื่อจริง',
        bzzx: 'ศูนย์ช่วยเหลือ',
        ysxy: 'ข้อตกลงความเป็นส่วนตัว',
        xzyy: 'เลือกภาษา'
    },
    commonUse: {
        title: 'บริการ',
        Finance: 'การเงิน',
        Notice: 'การแจ้งเตือน',
        customerService: 'บริการลูกค้า',
        cc: 'สถานะเก็บเงิน',
        zjmx: 'รายละเอียดเงินทุน',
        cpdm: 'สินค้า',
        mm: 'ซื้อ/ขาย',
        wtj: 'ราคาของการสั่งซื้อ',
        wtsl: 'จำนวนของการสั่งซื้อ',
        Buy: 'ซื้อ',
        Sell: 'ขาย',
        Buy1: 'ซื้อ',
        Sell1: 'ขาย',
        Buy2: 'ซื้อเงินเปลี่ยน',
        Exchangequantity: 'จำนวนเงินที่ต้องการเปลี่ยน',
        Exchangeamount: 'จำนวนเงินที่ต้องการเปลี่ยน',
        Currency: 'ประเทศเงิน',
        Status: 'สถานะการสั่งซื้อ',
        Type: 'ประเภท',
        Service: 'บริการลูกค้า',
        p1: 'โปรดเลือกประเทศเงิน',
        p2: 'โปรดป้อนจำนวนเงิน',
        czlb: 'การดำเนินงาน'
    },
    product: {
        Buy: 'ซื้อ',
        Sell: 'ขาย',
        Gain: 'ปรับค่าเปลี่ยน',
        Trade: 'การทำธุรกรรม',
        Real: 'จริง',
        High: 'ต่ำสุด',
        ClosingPrice: 'ราคาปิด',
        Low: 'สูงสุด',
        Volume: 'ปริมาณการซื้อขาย 24 ชั่วโมง',
        Limit: 'สัญญาจำหน่ายค่าต่ำสุด',
        Contract: 'สัญญาซื้อขาย',
        News: 'ข่าว',
        Liquidations: 'ทั้งหมดข้อมูล',
        ContractType: 'ประเภทของสัญญา',
        OpeningPrice: 'ราคาเปิด',
        Leverage: 'การเปลี่ยนแปลงความสามารถ',
        LeverageP: 'การเปลี่ยนแปลงความสามารถไม่สามารถใช้งานได้',
        LimitPrice: 'จำนวนการซื้อขาย',
        LimitPlaceholder: 'โปรดประเภทราคาจำหน่าย',
        Overnight: 'เปลี่ยนแปลงความสามารถ',
        Direction: 'ทิศทาง',
        Operate: 'การดำเนินการ',
        Long: 'ซื้อ/ทำทุน',
        Short: 'ขาย/ทำลาย',
        Balance: 'สถานะสินเชื่อรวม',
        Floating: 'ปรับค่าเปลี่ยน',
        CloseAll: 'ปิดทั้งหมด',
        MarketOrder: 'การซื้อขายตามตลาด',
        LimitOrder: 'การซื้อขายตามราคาจำหน่าย',
        Cross: 'ทั้งหมด',
        Isolated: 'แยกตัว',
        placeholder: 'ซื้อ/ขาย โดยใช้ราคาปัจจุบัน',
        Margin: 'จำนวน',
        MarginBalance: 'สถานะสินเชื่อที่เหลือ',
        TradingFee: 'ค่าธรรมเนียมการทำธุรกรรม',
        Position: 'สถานะทำลาย',
        Success: 'สำเร็จ',
        Commission: 'ค่าเสียหาย',
        Transaction: 'การทำธุรกรรม',
        Completed: 'เสร็จสิ้น',
        Cancellation: 'ยกเลิก',
        unitPrice: 'ราคาต่ำสุด',
        LeverageRatio: 'สัญญาคุณสมบัติการเปลี่ยนแปลง',
        cancellation: 'ยกเลิก',
        ClosingPosition: 'ปิดตัว',
        productName: 'ชื่อสินค้า',
        Margin1: 'สินค้าเดิม',
        SettlementPrice: 'ราคาภายใน',
        EndTime: 'เวลาสิ้นสุด',
        CreateTime: 'เวลาที่สั่ง',
        WalletBalance: 'ยอดเงินในกระเป๋า',
        AvailableFunds: 'เงินที่สามารถใช้',
        Text1: 'ข้อมูลแจ้ง',
        Text2: 'ต้องการทำการดำเนินการนี้หรือไม่',
        qr: 'ยืนยัน',
        qx: 'ยกเลิก',
        ddbh: 'หมายเลขการสั่งซื้อ',
        wtsj: 'เวลามอบอำนาจ',
        szzyzs: 'ตั้งค่าจุดจบของการสูญเสีย',
    },
    notice: {
        title: 'ประกาศ',
        ckxq: 'ดูรายละเอียด'
    },
    helpCenter: {
        title: 'ต้องอ่านสำหรับมือใหม่'
    },
    pwdGroup: {
        title: 'การตั้งค่ารหัสผ่าน',
        pwdDL: 'รหัสผ่านเข้าสู่ระบบ',
        pwdZj: 'รหัสผ่านกองทุน'
    },
    quotation: {
        title: 'ตลาด',
        mc: 'จำนวน',
        zxj: 'ราคาล่าสุด',
        zdb: 'ปรับค่าเปลี่ยน',
        liang: 'ปริมาณ'
    },
    contract: {
        kcslfw: 'ขอบเขตขาย/ซื้อ: ',
        gao: 'สูงสุด',
        di: 'ต่ำสุด',
        liang: '24H จำนวน',
        placeholder: 'โปรดประเภทจำนวน',
        SuccessfulPurchase: 'ซื้อสำเร็จ',
        smallMin: 'นาที',
        hour: 'ชั่วโมง',
        dayMin: 'วัน',
        Trend: 'กราฟวิวชั่วโมง',
        ccjl: 'สถานะสินค้า',
        ddjl: 'บันทึกการซื้อขาย',
        sl: 'จำนวน',
        gmj: 'ราคาซื้อ',
        dqj: 'ราคาปัจจุบัน',
        yjyk: 'ความเปลี่ยนแปลงทรัพย์สิน',
        djs: 'เวลาที่เหลือ',
        gbjg: 'ราคาซื้อ',
        sc: 'ค่าธรรมเนียม',
        pal: 'ผลประโยชน์หรือความเสีย',
        mhy: 'สัญญาเฉลี่ยวันละ',
        sj: 'เวลา',
        yll: 'เปลี่ยนแปลงเปอร์เซ็นต์',
        mz: 'ซื้อ/ขาย',
        md: 'ซื้อ/ขาย',
        kcsl: 'จำนวนที่ซื้อ/ขาย',
        kcsj: 'เวลาที่ซื้อ/ขาย',
        zhye: 'ยอดเงินในบัญชี',
        qc: 'ยกเลิก'
    },
    editPassword: {
        title: 'แก้ไขรหัสผ่านเข้าสู่ระบบ',
        jmm: 'รหัสผ่านเดิม',
        xmm: 'รหัสผ่านใหม่',
        qrmm: 'รหัสผ่านยืนยัน',
        placeholder: 'รหัสผ่านเดิม',
        placeholder1: 'ตั้งรหัสผ่านใหม่',
        placeholder2: 'โปรดเลือกรหัสผ่านใหม่อีกครั้ง',
        qrxf: 'ยืนยัน'
    },
    secure: {
        // 安全認證
        title: 'ความปลอดภัย',
        title1: 'การยืนยันสมาชิกทั้งสอง',
        title2: 'เพื่อป้องกันบัญชีของคุณ ขอแนะนำให้สำเร็จการยืนยันสมาชิกอย่างน้อยเพียงหนึ่งครั้ง',
        gridTxt: 'การ 설정รหัสผ่าน',
        gridTxt1: 'ยืนยันโทรศัพท์',
        gridTxt2: 'ยืนยันอีเมล',
        gridTxt3: 'การยืนยันทัศน์วิทยา',
        listTxt: 'บัตรเงินฝาก',
        listTxt1: 'การจัดการที่ออก'
    },
    bindBank: {
        // 銀行卡
        title: 'บัตรเงินฝาก',
        addCard: 'เพิ่มบัตรเงินฝาก',
        editCard: 'แก้ไขรหัสผ่านเงินฝาก',
        ts1: 'คุณยังไม่ได้ทำการยืนยันตัวตน',
        ts2: 'โปรดทำการยืนยันตัวตนก่อน',
        addTitle: 'เพิ่มบัตรเงินฝาก',
        userName: 'ชื่อจริง',
        tips1: 'โปรดป้อนชื่อจริง',
        CollectionDetailArea: 'ที่อยู่รายละเอียดของผู้รับ',
        tips6: 'โปรดป้อนที่อยู่รายละเอียดของผู้รับ',
        userPhone: 'เบอร์โทรศัพท์ที่จัดหา',
        tips2: 'โปรดป้อนเบอร์โทรศัพท์ที่จัดหา',
        bankName: 'ชื่อธนาคาร',
        tips3: 'โปรดป้อนชื่อธนาคาร',
        bankAds: 'ที่อยู่ของธนาคาร',
        tips5: 'โปรดป้อนที่อยู่ของธนาคาร',
        bankId: 'เลขที่บัญชี',
        tips4: 'โปรดป้อนเลขที่บัญชี',
        CountryCode: 'รหัสประเทศ',
        tips7: 'โปรดเลือกรหัสประเทศ',
        tips8: 'โปรดป้อนSWIFT Code',
        tips9: 'โปรดป้อนจำนวนเงิน',
        tips10: 'จำนวนการเติมหรือถอนต้องไม่น้อยกว่า 100',
        addTxt: 'บันทึก'
    },
    bindMail: {
        // 綁定郵箱
        title: 'เชื่อมต่ออีเมล',
        placeholder1: 'โปรดป้อนที่อยู่อีเมล',
        ptitle: 'แก้ไขโทรศัพท์มือถือ',
        placeholder2: 'โปรดป้อนเบอร์โทรศัพท์ใหม่',
        next: 'ถัดไป',
        zjmm: 'รหัสผ่านเงิน',
        placeholder3: 'โปรดป้อนรหัสผ่านเงิน'
    },
    setting: {
        // 設置
        title: 'ตั้งค่า',
        setTxt: 'ภาพประกอบ',
        setTxt1: 'ภาษา',
        setTxt2: 'เปลี่ยนแปลงเงินแปลง',
        setTxt3: 'เกี่ยวกับเรา'
    },

    withPass: {
        // 設置資金密碼
        title: 'ตั้งรหัสผ่านกองทุน',
        title1: 'รหัสผ่านกองทุนต้องไม่เหมือนกับรหัสผ่านล็อกอิน',
        aqyz: 'การตรวจสอบความปลอดภัย',
        aqyzm: 'รหัสยืนยัน',
        jmm: 'รหัสผ่านเก่า',
        xmm: 'รหัสผ่านใหม่',
        qrmm: 'ยืนยันรหัสผ่าน',
        placeholder: 'รหัสผ่านเก่า',
        placeholder1: 'ตั้งรหัสผ่านใหม่',
        placeholder2: 'กรุณากรอกรหัสผ่านใหม่อีกครั้ง',
        qrxf: 'ยืนยัน',
        tips: 'กรุณาใส่รหัสผ่านเก่าอย่างน้อย 6 หลัก',
        tips1: 'กรุณาใส่รหัสผ่านใหม่อย่างน้อย 6 หลัก',
        tips2: 'กรุณากรอกรหัสผ่านยืนยันที่ถูกต้อง',
        tips3: 'รหัสผ่านใหม่ที่ป้อนสองครั้งจะไม่เป็นหนึ่งเดียว',
        tips4: 'ตั้งรหัสผ่านสำเร็จ',
        confirm: 'ยืนยัน'
    },
    newBe: {
        title: 'ซื้อเงินใหม่',
        title1: 'ฉันโหวต',
        scqx: 'วันหมดการเก็บ',
        day: 'วัน',
        sy: 'เหลือ',
        sdsg: 'บันทึก',
        sysl: 'จำนวนเหลือ',
        gmsl: 'จำนวนซื้อ',
        dj: 'ราคาต่อหนึ่ง',
        tips: 'โปรดประมาณจำนวนซื้อ',
        gmzj: 'ราคาซื้อทั้งหมด',
        qbye: 'ยอดเงินในกระเป๋า',
        fmzj: 'ราคาซื้อทั้งหมด',
        gm: 'ซื้อ',
        sgsj: 'เวลาสมัคร',
        Currency: 'หน่วยเงิน',
        Request: 'จำนวนขอ',
        Passing: 'ผ่านจำนวน',
        MarketTime: 'เวลาตลาด'
    },
    register: {
        title: 'ลงทะเบียน',
        lang: 'ภาษา',
        send: 'ส่ง',
        Resend: 'ส่ง lại',
        Account: 'บัญชี',
        Password: 'รหัสผ่าน',
        ConfirmPassword: 'ยืนยันรหัสผ่าน',
        InvitationCode: 'คำเชิงชุด',
        btn: 'ลงทะเบียน',
        amount: 'ชื่อบัญชี',
        mailbox: 'กล่องจดหมาย',
        haveAccount: 'มีบัญชีแล้วหรือไม่',
        haveLogin: 'ลงชื่อเข้าระบุได้',
        Login: 'เข้าระบุ',
        placeholder: 'กรุณากรอกกล่องจดหมาย',
        placeholder0: 'โปรดป้อนเบอร์โทรศัพท์',
        placeholder1: 'โปรดป้อนรหัสผ่าน',
        placeholder2: 'โปรดป้อนยืนยันรหัสผ่าน',
        placeholder3: 'โปรดป้อนคำเชิงชุด',
        placeholder4: 'กรุณากรอกรหัสยืนยัน',
        placeholder5: 'กรุณากรอกกล่องจดหมายที่ถูกต้อง',
        success: 'ลงทะเบียนสำเร็จ',
        message: 'รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัว',
        message1: 'ยืนยันรหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัว',
        message2: 'รหัสผ่านทั้งสองไม่ตรงกัน',
        message3: 'โปรดอ่านและตกลงกับข้อตกลง',
        agree: 'ฉันยอมรับ',
        xy: 'นโยบายและนโยบายความเป็นลับของเรา',
        qr: 'ลงทะเบียนทันที',
        // 找回密碼
        ftitle: 'หากลืมรหัสผ่าน',
        qrzh: 'ยืนยัน',
        placeholder7: 'โปรดป้อนรหัสผ่านใหม่',
        placeholder6: 'โปรดยืนยันรหัสผ่านใหม่',
        placeholder8: 'โปรดป้อนรหัสการยืนยันด้วยวงจรประดับ',
        placeholderName: 'โปรดป้อนชื่อผู้ใช้'
    },
    lockming: {
        title: 'การล็อคโทเค็น',
        wkb: 'การขุดสมบัติ',
        wkgjd: 'การขุดนั้นง่ายกว่า',
        qsljl: 'รับรางวัลได้ง่ายๆ',
        yjzsr: 'รายได้รวมโดยประมาณ',
        ck: 'อ้างอิงอัตราผลตอบแทนต่อปีในช่วงสามวันที่ผ่านมา',
        tips: 'ขั้นต่ำ',
        rlx: 'ดอกเบี้ยรายวัน',
        day: 'วัน',
        scqx: 'ช่วงปิดเทอม',
        buy: 'ซื้อ',
        zysl: 'จำนวนเงินเดิมพัน',
        qbye: 'ยอดคงเหลือในกระเป๋าเงิน',
        finished: 'สมบูรณ์',
        doing: 'อยู่ระหว่างดำเนินการ',
        sl: 'ปริมาณ',
        shouyilv: 'ประเมินค่า',
        ks: 'เวลาเริ่มต้น',
        js: 'เวลาสิ้นสุด'
    },
    login: {
        title: 'ลงชื่อเข้าระบุบัญชี',
        lang: 'ภาษา',
        mobile: 'เบอร์โทรศัพท์',
        mailbox: 'กล่องจดหมาย',
        Account: 'บัญชี',
        Password: 'รหัสผ่าน',
        btn: 'ลงชื่อเข้าระบุ',
        placeholder1: 'โปรดป้อนรหัสผ่าน',
        placeholder: 'โปรดป้อนบัญชี',
        fail: 'โปรดกรอบระบุข้อมูลเต็ม',
        ForgotPassword: 'ลืมรหัสผ่านหรือไม่',
        NoAccount: 'ยังไม่มีบัญชีของคุณ',
        Registration: 'ลงทะเบียนเพื่อเข้าระบุบัญชี',
        server: 'เฟสบอร์ตออนไลน์',
        jzmm: 'จดหมายรหัสผ่าน',
        success: 'ลงชื่อเข้าระบุสำเร็จ',
        Home: 'หน้าแรก',
        userbox: 'ชื่อผู้ใช้'
    },
    moneyAddr: {
        title: 'ที่อยู่ที่เก็บเงิน',
        Currency: 'หน่วยเงิน',
        Address: 'ที่อยู่',
        placeholder: 'โปรดเลือก',
        DepositAddress: 'ที่อยู่ที่เก็บเงิน',
        WalletAddress: 'ที่อยู่ที่เก็บเงิน',
        Date: 'วันที่',
        add: 'ที่อยู่ที่เก็บเงิน',
        addTxt: 'ที่อยู่ที่เก็บเงินที่สามารถเพิ่มได้ไม่เกิน',
        remark: 'หมายเหตุ',
        Walletremark: 'โปรดเพิ่มข้อมูลหมายเหตุที่เกี่ยวข้องกับที่อยู่ที่เก็บเงินนี้',
        network: 'เครือข่ายหลัก (คำนึงถึงเครือข่าย)',
        networkXy1: 'คุณเลือกเครือข่ายว่า',
        networkXy2: ' โปรดตรวจสอบว่าที่อยู่ที่เก็บเงินที่คุณเลือกไม่ผิดพลาด เรียกว่า "Address" ถ้าที่อยู่ที่เก็บเงินที่คุณเลือกไม่สามารถค้นหาได้ในเครือข่ายที่คุณเลือก คุณจะสูญเสียทรัพย์สินของของคุณอย่างไรก็ตาม',
        ChainAddress: 'ที่อยู่ที่เก็บเงิน',
        qrCode: 'QR Code',
        submit: 'ยืนยัน',
        tips: 'โปรดเลือกประเภทของที่อยู่ที่เก็บเงิน',
        tips1: 'โปรดเลือกประเภทของที่อยู่ที่เก็บเงิน',
        tips2: 'โปรดป้อนที่อยู่ที่เก็บเงิน',
        tips3: 'โปรดโพสต์ QR Code ของที่อยู่ที่เก็บเงิน',
        success: 'สำเร็จ'
    },
    recharge: {
        title: 'เติมเงิน',
        title1: 'รายละเอียดการเติมเงิน',
        ls: 'รายละเอียดการเติมเงิน',
        td: 'โปรดเลือกช่องทางการเติมเงินดังนี้',
        bc: 'บันทึก QR Code',
        address: 'ที่อยู่ที่เก็บเงิน',
        copy: 'คัดลองที่อยู่ที่เก็บเงิน',
        num: 'จำนวนเงินที่ต้องการเติม',
        Upload: 'อัพโหลดรูปแบบรายละเอียดการชำระเงิน',
        Submit: 'ยืนยัน',
        tip: 'โปรดป้อนจำนวนเงินที่ต้องการเติม',
        tip1: 'โปรดอัพโหลดรูปแบบรายละเอียดการชำระเงิน',
        copySuc: 'สำเร็จ'
    }
}