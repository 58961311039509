module.exports = {
    pcregister: {
        p1: 'Compte existant?',
        dl: 'Se connecter',
        zc: 's’enregistrer',
        p4: 'Compte compte',
        p5: 'Veuillez entrer le numéro de compte',
        p6: 'Veuillez entrer un mot de passe',
        p7: 'Veuillez entrer le mot de passe de confirmation',
        p8: 'Veuillez entrer le code d’invitation',
        p9: 'Je suis d’accord',
        p10: 'Accord d’utilisation et politique de confidentialité',
        p11: 'Boîte aux lettres',
        p12: 'Veuillez entrer votre adresse email',
        p13: 'Veuillez entrer le code de vérification email',
        p14: 'Envoyer à l’extérieur',
        p15: 'Année année',
        p16: 'Mois mois',
        p17: 'Jour de travail',
        p18: 'heure',
        p19: 'Compte rendu',
        p20: 'secondes',
    },
    pclogin: {
        l1: 'Pas de compte?',
        l2: 's’enregistrer',
        l3: 'Se connecter',
        l4: 'Entrez un nom d’utilisateur',
        l5: 'Veuillez entrer un mot de passe',
        l6: 'Mot de passe oublié',
        l7: 'Se connecter',
    },
    pccontract: {
        ss: 'Prix du marché',
        xj: 'Prix fixe',
        gg: 'levier',
    },
    pcrapidly: {
        zf: 'augmentation',
        dd: 'commander',
        bdsj: 'Heure locale',
        ky: 'disponible',
    },
    pchome: {
        global1: 'La plateforme de trading d’actifs numériques leader au monde',
        global2: 'Il fournit des services de trading et de gestion d’actifs numériques sécurisés et fiables à des millions d’utilisateurs dans plus de 100 pays.',
        global3: 'Sûr et fiable',
        global4: 'Cinq ans d’expérience dans les services financiers d’actifs numériques. Concentrez-vous sur l’architecture distribuée et le système d’attaque anti DDoS.',
        global5: 'Schéma écologique Global',
        global6: 'Des centres de services commerciaux localisés ont été mis en place dans de nombreux pays pour créer un écosystème blockchain intégrant plusieurs formats.',
        global7: 'Utilisateur d’abord',
        global8: 'Mettre en place un mécanisme d’avances et un fonds de protection des investisseurs.',
        whever1: 'Transaction de terminal Multi plate-forme n’importe quand n’importe où',
        whever2: 'Il couvre plusieurs plates-formes, y compris iOS Android et Windows et prend en charge toutes les fonctions d’affaires.',
        register1: 'Enregistrer un',
        register3: 'Le compte Global commence à trader',
        register2: 'Inscrivez-vous maintenant',
        commodity: 'Produits de base',
        news: 'Les nouvelles',
        ttop1: 'Découvrez le mystère de Pinnacle',
        ttop2: 'Pinnacle est une bourse internationale qui traite des contrats à terme et d’options agriculture énergie devises métaux indices et plus encore. Depuis sa création, la bourse a continuellement amélioré ses règles de négociation et le volume de divers produits a augmenté. La société s’est développée avec succès pour servir des clients particuliers et institutionnels dans plus de 30 pays et continue de croître. Nous nous efforçons de maintenir les coûts de négociation aussi bas que possible et de fournir des services dans un esprit de collaboration qui aide nos clients à devenir des traders rentables et pas seulement des clients rentables. Après tout, si vous faites bien, nous faisons bien.',
        ttop3: 'Commencez à trader maintenant',
        product1: 'Exécution rapide et fiable des ordres',
        product2: 'Outils analytiques avancés',
        product3: 'Citations en temps réel',
        product4: 'Retraits rapides et sécurisés',
        trade1: 'Plus intelligent',
        trade2: 'Pratiquez votre stratégie',
        trade3: 'Essayez notre compte démo gratuit avant d’ouvrir un compte de trading réel pour explorer notre plateforme de trading intuitive et améliorer vos compétences.',
        trade4: 'Développez vos connaissances',
        trade5: 'En savoir plus sur le trading de CFD avec Pinnacle en utilisant notre matériel éducatif complet.',
        trade6: 'Gérez vos risques',
        trade7: 'Utilisez nos outils avancés de gestion des risques pour limiter vos pertes et verrouiller vos profits.',
        allselect1: 'Pourquoi choisissez vous tous Pinnacle?',
        allselect2: 'Protégé & sécurisé',
        allselect3: 'Vos données sont en sécurité et vos fonds sont conservés dans des comptes bancaires distincts conformément aux exigences réglementaires.',
        allselect4: 'Soutien professionnel',
        allselect5: 'Bénéficiez d’un service client dédié 24 heures sur 24 en plusieurs langues.',
        allselect6: 'réglementé',
        allselect7: 'Autorisé et réglementé par une variété de régulateurs mondiaux de premier plan.',
        allselect8: 'fiable',
        allselect9: 'Pinnacle ltée est une société FTSE 250 cotée sur le marché principal des sociétés ouvertes de la bourse canadienne des valeurs mobilières.',
        down1: 'N’importe quel modèle de téléphone portable sur le terminal mobile peut soutenir l’opération et l’utilisation',
        down2: 'Application de plate-forme',
    },
    pcbottom: {
        box1: 'Leader mondial',
        box2: 'Une des meilleures équipes',
        box3: 'Plus de détails',
        box4: 'À propos de nous',
        box5: 'Livre blanc',
        box6: 'Le service',
        box7: 'Télécharger le document',
        box8: 'Problème commun',
        box9: 'Service en ligne',
        box10: 'règlements',
        box11: 'Accord de l’utilisateur',
        box12: 'Politique de confidentialité',
        box13: 'Mentions légales',
        box14: 'Contact contact',
    },
    pcAbout_us: {
        t1: 'Cours de formation',
        t2: '7 ans sur la route',
        t3: 'Depuis sa naissance en 2015, nous nous sommes consacrés aux services blockchain et aux services dédiés à la technologie et à la construction de canaux.',
        t4: 'De 2014 à 2017, il a connu une période ascendante régulière et croissant2017-2018 première moitié de l’argent pour devenir riche période de folie de la seconde moitié de 2019 à la période froide d’hiver aujourd’hui, j’ai développé une vraie compétence. D’innombrables partenaires réussis afin que discret nous avons une réputation de haut profil dans l’industrie. En 2022, nous aurons une toute nouvelle plate-forme V3.0 et mis en place une nouvelle société par actions BitGoldEX pour mener des activités dans plus de 100 pays à travers le monde.',
        t5: 'Qui recherchons-nous?',
        t6: 'De sa création à aujourd’hui',
        t7: 'J’ai cherché les fournisseurs de services qui ont des difficultés et ont le même goût que moi. J’ai résolu avec succès les problèmes de dizaines de fournisseurs de services sur plusieurs années',
        t8: 'Dans le monde rationnel d’aujourd’hui si vous cherchez un bon partenaire quelqu’un qui peut aller de l’avant avec vous - peut-être nous parler.',
        t9: 'Soyez assuré que la personne que vous recherchez a été à votre recherche.',
        t10: 'Le Service',
        t11: 'Contact Contact',
        t12: 'Quand un ami vous présente une entreprise, vous êtes une bonne personne. Quand je vous présente aux affaires, je pense que vous avez une bonne réputation. Lorsqu’un client vous présente une entreprise, cela prouve que vous êtes digne de confiance.',
        t13: 'esprit',
        t14: 'Nous serons là où nous en avons besoin',
        t15: 'Les différents clients ont des exigences différentes. Les différents produits ont des caractéristiques différentes. Le but est différent à différents moments. Le sens de notre existence est de s’adapter au système de concept et aux exigences des différents fournisseurs de services pour résoudre une variété de problèmes.',
        t16: 'Nous aimerions être une brique avec des idées et des fonctions, nous serons là où nous sommes nécessaires.',
    },
    pcproblem: {
        p1: 'Questions à poser',
        p2: 'Pourquoi le montant converti des actifs change-t-il?',
        p3: 'Le calcul équivalent dans l’actif est la valeur de la monnaie numérique détentrice actuelle convertie en USD qui change en raison de la fluctuation des prix de l’actif numérique. Le nombre de vos actifs numériques n’a pas changé.',
        p4: 'Vous avez oublié la méthode de traitement du mot de passe des fonds pour les transactions sur la plateforme',
        p5: 'Si vous oubliez le mot de passe du fonds de trading sur la plateforme, veuillez vous rendre sur «My-Settings-Click Set fund password» ou contacter le service clientèle pour réinitialiser',
        p6: 'Où est le flux de capitaux après l’achat de la transaction?',
        p7: 'Une fois le bon de commande complété, les fonds entreront dans le compte en devise française de l’actif. S’il est nécessaire d’effectuer des transactions contractuelles ou des transactions à terme pour les actifs achetés, veuillez vous rendre dans le module de transfert de fonds pour le transfert.',
        p8: 'Pourquoi limiter le nombre de modifications aux surnoms',
        p9: 'Pour des raisons de sécurité de votre transaction, nous limitons actuellement le surnom à être modifié une seule fois.',
        p10: 'Que voulez-vous dire lorsque vous transférez? Pourquoi avez-vous besoin de transférer',
        p11: 'Afin d’assurer l’indépendance des fonds entre vos comptes et de faciliter votre contrôle raisonnable des risques, nous divisons les comptes de chaque module de transaction majeure. Le transfert se réfère au processus de conversion d’actifs entre les comptes de trading.',
        p12: 'Comment calculer l’équité de compte du contrat utilisateur?',
        p13: 'Compte contractuel capitaux propres = position marge + position flottante résultat et perte + compte courant montant disponible',
        p14: 'Quelle est la valeur du réglage stop loss et profit en ouvrant une position? Comment le fixer?',
        p15: 'Take profit et stop loss comme la limite supérieure de profit et de perte définie par vous. Lorsque le montant défini de stop profit et stop loss est atteint le système fermera automatiquement la position. Il peut être utilisé pour le contrôle des risques lors de l’achat d’un contrat. La moitié du montant de prise de profit est: montant de l’augmentation x quantité x effet de levier multiple set stop loss. Nous vous recommandons de le régler en fonction de la situation réelle de votre actif et de contrôler raisonnablement le risque.',
        p16: 'Comment réduire le risque contractuel?',
        p17: 'Vous pouvez transférer les actifs disponibles des comptes restants sur le compte du contrat en transférant des fonds ou réduire le risque en réduisant les positions ouvertes.',
        p18: 'Que signifie la quantité dans l’ouverture du contrat?',
        p19: 'La quantité dans la position ouverte représente le nombre de devises que vous prévoyez acheter. Par exemple: sélectionnez sur la page d’ouverture de la paire de trading BTC/USD acheter long entrez le prix comme 1000USD et entrez le montant comme 10BTC cela signifie: vous prévoyez acheter 10BTC avec un prix unitaire de 1000USD.',
        p20: 'Comment les frais de gestion dans le contrat sont-ils calculés?',
        p21: 'Frais de manutention = prix d’ouverture * quantité d’ouverture * taux de frais de manutention',
        p22: 'Notes sur la liquidation forcée',
        p23: 'Le degré de risque est un indicateur pour mesurer le risque de vos actifs. Lorsque le degré de risque est égal à 100% votre position est considérée comme une liquidation le degré de risque = (marge de détention/capitaux propres du compte de contrat)*100% afin d’empêcher les utilisateurs de porter la position, le système définit le ratio d’ajustement du degré de risque. Lorsque le degré de risque atteint la valeur de risque définie par le système, le système forcera la position à fermer. Par exemple: le ratio d’ajustement fixé par le système est de 10% puis lorsque votre degré de risque est supérieur ou égal à 90% toutes vos positions seront forcées d’être fermées par le système.',
        p24: 'Quelles sont les règles de négociation des contrats?',
        p25: 'Type de Transaction',
        p26: 'Les types de Trading sont divisés en deux directions: positions longues (acheter) et positions courtes (vendre):',
        p27: 'Acheter long (haussier) signifie que vous pensez que l’indice actuel est susceptible d’augmenter et que vous souhaitez acheter un certain nombre de certains contrats au prix que vous avez fixé ou au prix actuel du marché.',
        p28: 'Vendre à découvert (baissier) signifie que vous pensez que l’indice actuel est susceptible de baisser et que vous souhaitez vendre un certain nombre de nouveaux contrats à un prix que vous avez fixé ou le prix actuel du marché.',
        p29: 'Méthode de commande',
        p30: 'Commande à prix limité: vous devez spécifier le prix et la quantité de la commande passée',
        p31: 'Ordre du marché: vous devez seulement définir la quantité d’ordre le prix est le prix actuel du marché',
        p32: 'Postes à pourvoir',
        p33: 'Lorsque l’ordre que vous soumettez pour ouvrir une position est terminé, il est appelé une position',
        p34: 'Questions liées à l’exécution des contrats',
        p35: 'Le contrat de plateforme est un contrat perpétuel sans délai de livraison défini. Tant que le système ne remplit pas les conditions de liquidation ou que vous ne fermez pas manuellement la position, vous pouvez maintenir la position de façon permanente.',
        p36: '1: le système fermera automatiquement la position si la valeur définie de Take Profit et Stop Loss est atteinte',
        p37: '2: le risque est trop élevé le système est forcé de fermer la position',
        p38: 'Quelle est la marge dans les transactions contractuelles?',
        p39: 'Dans les transactions contractuelles, les utilisateurs peuvent participer à la vente et à l’achat de contrats en fonction du prix et de la quantité du contrat et des multiples de levier correspondants. L’utilisateur prendra la marge lors de l’ouverture d’une position. Plus la marge d’ouverture est élevée, plus le risque de compte sera élevé.',
        p40: 'Que sont les ordres limités et les ordres au marché?',
        p41: 'L’ordre limite se réfère au prix que vous vous attendez à confier à la plate-forme pour le commerce et l’ordre du marché se réfère à confier la plate-forme pour le commerce au prix actuel directement. Dans les règles d’ouverture des positions, les ordres de marché ont la priorité sur les ordres à limite. Si vous choisissez un ordre limite, veuillez ouvrir la position raisonnablement en fonction du prix actuel du marché de la devise pour éviter les pertes dues à des prix d’ouverture déraisonnables.',
        p42: 'Quel est le niveau de risque des transactions contractuelles?',
        p43: 'Le degré de risque est un indicateur de risque dans votre compte contractuel. Un degré de risque égal à 100% est considéré comme une liquidation. Nous suggérons que lorsque votre risque dépasse 50%, vous devez ouvrir votre position avec soin pour éviter les pertes dues à la liquidation. Vous pouvez réduire votre risque en reconstituant les fonds disponibles d’actifs contractuels ou en réduisant vos positions.',
        p44: 'Pourquoi le change de devises est-il nécessaire?',
        p45: 'Le but de l’échange est de permettre la circulation fluide des fonds en différentes devises dans vos actifs et le QCC obtenu dans le compte à terme peut être librement converti en USD pour le trading. L’usdt dans d’autres comptes peut également être librement converti en QCC pour le trading.',
        p46: 'Pourquoi l’autre partie n’a-t-elle pas reçu le transfert à temps après le transfert de l’opération?',
        p47: 'Tout d’abord, assurez-vous que le mode de paiement que vous remplissez lorsque vous transférez de l’argent est exactement le même que celui affiché sur la page de détails de la commande. Veuillez ensuite confirmer si votre mode de paiement est immédiat ou retardé. Enfin, veuillez contacter votre banque et votre institution de paiement pour la maintenance du système ou pour d’autres raisons.',
        p48: 'Comment acheter USD via la plateforme?',
        p49: 'Méthode 1: sélectionnez l’ordre que vous souhaitez acheter via la liste d’achat et de vente de la plate-forme pour acheter et vendre.',
        p50: 'Méthode 2: cliquez sur le bouton publier pour publier un ordre d’achat ou de vente pour des transactions rapides en fonction de vos besoins. Le système vous mettra rapidement en relation avec l’achat et la vente d’utilisateurs.',
        p51: 'Remarque: si le bon de commande n’est pas payé après 15 minutes, le système annulera automatiquement la commande s’il vous plaît payer à temps. Si l’ordre est annulé plus de 3 fois le même jour, la transaction ne peut pas être effectuée à nouveau le même jour et l’autorité de négociation sera rétablie le lendemain.',
    },
    public: {
        zxkf: 'Service client en ligne',
        sure: 'confirmer',
        cancel: 'Annuler annuler',
        RefreshSucceeded: 'Rafraîchissement réussi',
        noMoreData: 'Plus de données disponibles à lire',
        pullingText: 'Tirer pour rafraîchir...',
        ReleaseText: 'Release à rafraîchir...',
        loadingText: 'Chargement en cours...',
        noData: 'Aucun enregistrement trouvé',
        xq: 'Détail des détails',
        submit: 'soumettre',
        kefu: 'Service client en ligne',
        amount: 'Montant total',
        startInvestment: 'débutinvestissement',
        endInvestment: 'Investissement final',
        profit: 'Profit',
    },
    ChangeType: {
        Upper: 'Dépôt arrivée',
        Lower: 'retrait',
        Win: 'Profit',
        Bet: 'miser',
        BuyMining: 'Exploitation minière',
        CoinWin: 'Nouveau profit token',
        SettleMining: 'Rendement du capital dans l’industrie minière',
        GiveInterest: 'Intérêt minier',
        BuyCoin: 'Nouvel achat de jetons',
        BuyCoinBack: 'Retour de l’achat d’un nouveau jeton',
        UsdtToBtc: 'UsdoBtc',
        UsdtToEth: 'UsdoEth',
        BtcToUsdt: 'BtcToUsd',
        BtcToEth: 'BtcToEth',
        EthToUsdt: 'EthToUsd',
        EthToBtc: 'EthToBtc',
        CoinThaw: 'Dégel de la nouvelle monnaie',
        CoinFrozen: 'Nouvelle monnaie gelée',
        CoinCancel: 'Devise ordre annuler',
        CoinBuy: 'Achat de devises',
        CoinBuyEntrust: 'Monnaie acheter confier',
        CoinSellEntrust: 'Monnaie vendre confier',
        CoinSell: 'Vente de devises',
        ContractBuy: 'Ordres de marchédéduction de la marge',
        ContractCharge: 'Commandes de contrats moins frais de manutention',
        ContractCancelToBond: 'Remboursement de l’acompte après annulation du contrat',
        ContractCancelToCharge: 'Remboursement des frais de service pour l’annulation du contrat',
        ContractCloseProfit: 'Résultat de clôture de contrat',
    },
    trade: {
        title: 'échange',
        Buy: 'Acheter acheter',
        Sell: 'vendre',
        pc: 'Fermer fermer',
        cb: 'Coût du projet',
        xj1: 'Prix actuel',
        yk: 'Profits et pertes',
        xianjia: 'Prix fixe',
        jiage: 'Prix de vente',
        shuliang: 'Nombre de personnes',
        keyong: 'disponible',
        yonghuchicang: 'Postes à pourvoir',
        fxlts: 'Taux de risque = marge de position/capitaux propres du client multiplié par 100% où les capitaux propres du client sont égaux aux fonds disponibles du compte + marge de transaction initiale + résultat et perte flottants',
        fxl: 'Taux de risque',
        dqwt: 'Ordres ouverts',
        name: 'Nom et prénom',
        leixing: 'Type de produit',
        all: 'Tous les',
        shijian: 'Le temps',
        long: 'Longue durée',
        short: 'Court terme',
        cc: 'Tenir des positions',
        zyjg: 'Meilleur prix actuel',
        caozuo: 'fonctionnement',
        xj: 'Prix fixe',
        sj: 'Prix du marché',
        jiaoyie: 'Montant total',
        orderNo: 'commandenon',
        weituojia: 'Prix de vente',
        chengjiaojia: 'Prix de vente',
        chengjiaoe: 'Deal prix',
        weituo: 'Mode mode',
        qbwt: 'Tous les délégués',
        lswt: 'Historique des commandes',
        tips: 'Veuillez entrer le prix',
        tips1: 'Pleace entrer num',
        Create: 'mandat',
        Wait: 'La transaction',
        Complete: 'fermé',
        Cancel: 'révoquée',
        zsj: 'Prix Stop loss',
        zyj: 'Prix Stop profit',
        cczsy: 'Revenu Total des positions',
        ykbl: 'Taux de profits et pertes',
        bili: 'Taux de croissance',
        jyss: 'Nombre de transactions',
        bzj: 'Marge marge',
        sxf: 'Frais de manutention',
        gg: 'Effet de levier multiple',
        yjpc: 'Fermer tout',
    },
    auth: {
        title: 'Authentification d’identité',
        title1: 'Authentification d’identité',
        low: 'Authentification de base',
        high: 'Authentification avancée',
        success: 'Vérifié avec succès',
        tips: 'Veuillez d’abord vous authentifier',
        tips1: 'Veuillez entrer votre vrai nom',
        tips2: 'Veuillez entrer votre numéro d’identification',
        tips3: 'Veuillez télécharger votre carte d’identité (au recto)',
        tips4: 'Veuillez télécharger votre carte d’identité (retour)',
        tips5: 'Veuillez télécharger une Photo de vous-même tenant l’id avec la page Photo',
        tips6: 'Veuillez sélectionner un type d’authentification de nom réel',
        qrz: 'Pour vérifier',
        shz: 'En cours d’examen',
        rzsb: 'Échec de la vérification',
        userName: 'Nom réel',
        IDNumber: 'Numéro d’identification',
        zmz: 'Photo de la carte d’identité (devant)',
        bmz: 'Photo sur carte d’identité (au dos)',
        scz: 'Photo de vous-même tenant l’id avec la page Photo',
        IDCard: 'Carte d’identité',
        Passport: 'passeport',
        AuthenticationType: 'Type d’authentification',
        HighAuth: 'Certification avancée',
        gj: 'Nationalité:',
        tipsgj: 'Veuillez entrer votre nationalité',
    },
    share: {
        title: 'Mon code de parrainage',
        cp: 'Copier l’adresse',
        success: 'Copier avec succès',
    },
    newRecharge: {
        Deposit: 'Dépôt de garantie',
        Credit: 'Carte de crédit',
        Wallet: 'Dépôt de portefeuille gagner bonus à chaque fois',
        FAQ: 'Foire aux questions',
        Currency: 'Sélectionnez la devise',
        placeholder: ' ',
        Main: 'principale',
        DepositAds: 'Adresse de dépôt',
        Copy: 'copier',
        remark: '• ne déposez aucun actif autre que',
        remark1: 'En USD',
        remark2: 'À l’adresse ci-dessus sinon les actifs ne seront pas récupérés;',
        remark3: '• après avoir déposé à l’adresse ci-dessus, vous devez attendre la confirmation de l’ensemble du nœud du réseau et seulement après 6 confirme que vous pouvez recevoir les actifs de votre compte;',
        remark4: '• dépôt unique ne doit pas être inférieur à 15 USD ce montant causera l’échec du dépôt;',
        remark5: '• avant chaque dépôt, vous devez vous rendre sur cette page et copier l’adresse pour vous assurer que l’adresse est correcte. La mauvaise adresse entraînera l’échec du dépôt.',
        Pay: 'payer',
        Buy: 'Acheter acheter',
        Merchant: 'marchand',
        remark6: '* cliquez pour vérifier',
        remark7: 'astuces',
        remark8: 'S’il y a des questions s’il vous plaît contacter',
        remark9: 'Service client',
        ChipPay: 'ChipPay coin transaction d’achat',
        Purchasebyamount: 'Achat par montant',
        AccountBalance: 'Solde du compte',
        Tips: 'astuces',
    },
    ChipPay: {
        copySUC: 'Reproduire le succès',
        copyERR: 'La copie a échoué',
        Buy: 'Acheter acheter',
        TOBECONFIRMED: 'En attente',
        TransactionCompletion: 'Achèvement des travaux',
        OrderingInformation: 'Informations de commande',
        oddNumbers: 'Nombres impairs:',
        TransferAmount: 'Montant du transfert:',
        ApproximatelyQuantity: 'Quantité approximative:',
        UnitPrice: 'Prix unitaire:',
        remark1: 'Si vous ne cliquez pas sur le bouton «j’ai rempli le versement» ou si le montant du virement est inexact, la réception sera retardée. Les transferts de compte Non personnels et les remarques/messages entraîneront un échec de la transaction et un remboursement',
        remark2: 'astuces',
        remark3: 'Les informations de paiement pour chaque commande ne sont valables que pour un seul virement. Veuillez ne pas enregistrer l’information pour un deuxième transfert.',
        remark4: 'Après avoir créé plusieurs commandes sans paiement, vous ne pourrez pas passer une autre commande. Veuillez procéder avec prudence.',
        remark5: 'Veuillez remplir la remise dans le délai spécifié et assurez-vous de cliquer sur le bouton «j’ai rempli la remise» pour assurer la réception immédiate.',
        remark6: 'Si l’invite est l’allocation de la partie de Trading a échoué et que le volume de transaction actuel est trop important ce qui fait que la partie de Trading est occupée, veuillez attendre 5-10 minutes avant de soumettre à nouveau l’ordre.',
        remark7: 'Votre versement sera directement transféré sur le compte bancaire de la contrepartie et les actifs numériques vendus par la contrepartie lors de la transaction seront gérés et protégés par la plateforme. Soyez assuré de la transaction.',
        cancellation: 'annulation',
        remittance: 'transférées',
        remark8: 'Instructions de Transaction',
        remark9: 'Vous effectuerez des transactions de devises numériques avec des détenteurs d’usd sur la plateforme ChipPay et la plateforme garantira l’authenticité de vos actifs en USD tout au long de la transaction. Veuillez lire et accepter les conditions suivantes avant de commencer la transaction:',
        remark10: 'Veuillez noter que l’usd n’est pas émis par la plateforme ChipPay et n’a pas de compensation légale',
        remark11: 'Lorsque vous effectuez des transactions avec des détenteurs d’usd sur la plateforme, vous êtes tenu de vous conformer au contenu de la déclaration de lutte contre le blanchiment d’argent pendant le processus de transaction',
        remark12: 'S’il vous plaît lire la déclaration de risque d’abord être conscient des risques du trading de devises numériques et accepter les questions ci-dessus',
        remark13: 'Je suis conscient des risques de transaction et j’accepte les conditions de service',
        remark14: 'Si vous ne cliquez pas sur le bouton «j’ai rempli le versement» ou si le montant du virement est inexact, la réception sera retardée. Les transferts de compte Non personnels et les remarques/messages entraîneront un échec de la transaction et un remboursement',
        remark15: '1. Les droits de l’homme Veuillez vous assurer que le montant de votre remise est conforme au montant de la commande. Si vous ne remettez pas cliquer sur ce bouton peut entraîner une liste noire par le système',
        ConfirmedRemittance: 'Remises confirmées',
        ConfirmRemittance: 'Confirmer le versement',
        WrongPoint: 'Mauvais Point',
        Ialreadyknow: 'Je sais déjà',
        payattentionto: 'Faites attention à',
        agree: 'd’accord',
        Bank: 'Banque réceptrice:',
        ReceivingAccount: 'Réception du compte:',
        Party: 'Partie commerciale:',
        Authenticated: 'authentifié',
        Margin: 'Marge marge',
        tips1: 'Veuillez vérifier si je suis familier avec les risques de transaction',
    },
    newProperty: {
        title: 'Total des actifs (USD)',
        Spot: 'Place disponible',
        Contract: 'Contrat disponible',
        Bonus: 'Bonus',
        Purchase: 'recharger',
        Deposit: 'Dépôt de garantie',
        Withdraw: 'retrait',
        Transfer: 'transfert',
        Spot1: 'Spot',
        Contract1: 'Contrat de travail',
        Commission1: 'Commission des communautés européennes',
        Financial1: 'financier',
        Balance: 'équilibre',
        Hide: 'Masquer les actifs de 0',
    },
    withdrawal: {
        Wallet: 'Retrait de portefeuille',
        Internal: 'Transfert interne',
        Currency: 'Monnaie nationale',
        Main: 'principale',
        WithdrawAddress: 'Retirer l’adresse',
        Frequently: 'Adresses fréquentes',
        placeholder: 'USD retirer l’adresse',
        note: 'Note de Transaction',
        placeholder1: 'Entrez une note',
        Amount: 'Retirer le montant',
        placeholder2: '10 Minimum',
        All: 'Tous les',
        Balance: 'Solde du compte',
        Fee: 'Retirer les frais',
        FundPassword: 'Mot de passe du fonds',
        placeholder3: 'Veuillez entrer le mot de passe du fonds',
        forget: 'Mot de passe oublié?',
        OTP: 'Téléphone /Email OTP',
        placeholder4: 'Entrez le Code de vérification',
        send: 'envoyer',
        Resend: 'Renvoyer à nouveau',
        Reminder: 'Rappel:',
        Reminder1: 'Le délai de rétractation est du lundi au dimanche 9:00-22:00 (UTC+8)',
        Reminder2: 'Solde Maximum disponible = solde du compte le montant du retrait comprend les frais de retrait.',
        Reminder3: 'Avant de demander un retrait, veuillez confirmer si l’adresse de retrait est correcte. Une fois la transaction envoyée au réseau blockchain, elle sera irrévocable',
        Confirm: 'confirmer',
        noPassword: 'Aucun mot de passe de fonds défini',
        Fees: 'Les frais',
        Free: 'gratuit',
        Transfer: 'Montant du transfert',
        Limitation: 'Limitation des transferts',
        placeholder6: 'Quantité de transfert 0-100000',
        Available: 'Montant disponible',
        Receiving: 'Réception du compte',
        placeholder5: 'Veuillez entrer le pseudo de l’utilisateur',
        TransferBtn: 'transfert',
        Reminder4: 'Rappel: s’il vous plaît assurez-vous de confirmer les informations utilisateur lors du transfert',
        Reminder5: 'Temps de traitement des transactions 09:00-22:00 (UTC+8)',
        title: 'déclaration',
        title1: 'Spot',
        title2: 'Contrat de travail',
        title3: 'Fonds de roulement',
        title4: 'Acheter des pièces de monnaie',
        title5: 'Vente de pièces de monnaie',
        Rate: 'Taux de change',
        allRate: 'Règlement des différends',
        PlaceOrder: 'Passer la commande',
        Freeze: 'Le gel',
        Thaw: 'Le dégel',
        backgroundProgram: 'Programme du programme',
        AuditReject: 'Vente de pièces Audit rejeter',
        OrderSettlement: 'Règlement d’ordre',
        AccountBalance: 'Solde:',
        CancelOrder: 'Annuler la commande',
        Created: 'créé',
        Paid: 'payé',
        UnAuthed: 'Non commenté',
        Accepted: 'accepté',
        Canceled: 'annulé',
        Refuce: 'réfuter',
        placeholderMC: 'Vendu avec succès',
        read: 'A lire',
        unread: 'Pas lire',
        chat: 'chat',
    },
    property: {
        Contract: 'Spot',
        Exchange: 'Deuxième contrat',
        zzc: 'Total des actifs',
        mhyzh: 'Deuxième compte contractuel',
        bbzh: 'Compte Spot',
        available: 'disponible',
        OnOrders: 'congeler',
        Estimated: 'équivalent',
        cw: 'Dossier financier',
        number: 'Numéro de téléphone',
        record: 'enregistrement',
        Transfer: 'transfert',
        time: 'Le temps',
        dhsl: 'Montant de jeton à échanger',
        tips: 'Veuillez entrer le montant de jeton à échanger',
        qbdh: 'Échanger tout',
        dhhl: 'Taux de change',
        ky: 'disponible',
        kd: 'disponible',
        sxfbl: 'Frais de manutention (taux)',
        sxf: 'Frais de manutention',
    },
    statusLang: {
        Review: 'Revue de presse',
        Success: 'Le succès',
        Fail: 'échec',
    },
    with: {
        title: 'Se retirer',
        history: 'Historique de l’histoire',
        ldz: 'Adresse Blockchain',
        tbdz: 'Adresse de retrait',
        tips: 'Veuillez entrer l’adresse de retrait',
        tips1: 'Veuillez entrer le montant des retraits',
        tips2: 'Veuillez entrer le mot de passe du fonds',
        tbsl: 'Montant des retraits',
        ky: 'disponible',
        zjmm: 'Mot de passe du fonds',
        sxf: 'Frais de manutention',
        dz: 'Montant prévu à recevoir',
    },
    tabBar: {
        home: 'Accueil',
        Quotation: 'Les marchés',
        Quotation2: 'Cotation du marché',
        Trade: 'Le commerce',
        Contract: 'Contrat de travail',
        newContract: 'Le commerce',
        assets: 'actifs',
        qhyy: 'Changer de langue',
        zyjb: 'Verrouiller dans l’exploitation minière',
        jsjy: 'Trading rapide',
        jryw: 'Affaires financières',
        hyjy: 'Transaction contractuelle',
        Frequently: 'fréquemment',
        mine: 'moi',
    },
    exchange: {
        title: 'échange',
        canChange: 'Quantité disponible',
        canChange1: 'Veuillez entrer le montant de l’échange',
        title1: 'Détails d’échange',
        Available: 'Quota disponible',
        Proposed: 'Prix proposé',
        Redeem: 'Échangez tout',
        Confirm: 'confirmer',
        CurrencyBalance: 'Solde des devises',
    },
    home: {
        gridcz: 'Recharge en fiat',
        gridsd: 'Retrait de fiat',
        gridxs: 'Foire aux questions',
        gridbd: 'Liste des projets',
        Balance: 'équilibre',
        UserName: 'Nom d’utilisateur',
        score: 'Score',
        kjmb: 'Achat rapide',
        zc: 'Soutien BTC USD etc.',
        scwk: 'Exploitation minière verrouillée',
        sgxb: 'Nouvelle inscription de Token',
        aqkx: 'Sûr et fiable',
        aqkx1: 'La plateforme de trading d’actifs numériques leader au monde',
        rmcjb: 'chaud',
        xbb: 'Nouvelles pièces',
        zfb: 'La croissance',
        dfb: 'Goutte d’eau',
        identify: 'Non vérifié',
        identifyYes: 'authentifié',
        changeName: 'Veuillez entrer votre nom d’utilisateur',
        changeName1: 'Le nom d’utilisateur ne peut modifier qu’une seule fois',
        confirmName: 'confirmer',
        closeName: 'Annuler annuler',
        mc: 'Nom et prénom',
        zxj: 'Dernier prix',
        zdb: 'Variation nette nette',
        hy: 'Effet de levier',
        btyh: 'Utilisateur Normal',
        sfyz: 'Authentification d’identité',
        skfs: 'Mode de paiement',
        share: 'Mon lien de partage',
        kjcb: 'recharger',
        kjtb: 'retrait',
        qbdz: 'Adresse du portefeuille',
        bps: 'Livre blanc',
        xgmm: 'Changer le mot de passe',
        xgzfmm: 'Changer le mot de passe du fonds',
        gywm: 'À propos',
        tcdl: 'Se déconnecter',
        zjmx: 'État du fonds',
        cdsz: 'paramètres',
        cdaq: 'authentification',
        bzzx: 'Foire aux questions',
        ysxy: 'Accord de confidentialité',
        xzyy: 'Choisir la langue',
    },
    commonUse: {
        title: 'Le Service',
        Finance: 'finances',
        Notice: 'avis',
        customerService: 'Le Service',
        cc: 'Poste de travail',
        zjmx: 'déclaration',
        cpdm: 'Nom et prénom',
        mm: 'Acheter/vendre',
        wtj: 'Prix de vente',
        wtsl: 'Numéro de téléphone',
        Buy: 'Acheter acheter',
        Sell: 'vendre',
        Buy1: 'Acheter acheter',
        Sell1: 'vendre',
        Buy2: 'Achat de pièces en un clic',
        Exchangequantity: 'La quantité',
        Exchangeamount: 'Montant total',
        Currency: 'Monnaie nationale',
        Status: 'État d’avancement',
        Type: 'Type de produit',
        Service: 'Le Service',
        p1: 'Veuillez sélectionner une devise',
        p2: 'Veuillez entrer le montant',
        czlb: 'opérer',
    },
    product: {
        Buy: 'Acheter acheter',
        Sell: 'vendre',
        Gain: 'Le Gain',
        Trade: 'Le commerce',
        Real: 'réel',
        High: 'haute',
        ClosingPrice: 'Cours de clôture',
        Low: 'faible',
        Volume: 'Volume 24h',
        Limit: 'Ordre de limite',
        Contract: 'Ordres de détention',
        News: 'Ordres de clôture',
        Liquidations: 'Les Liquidations',
        ContractType: 'Contrat /Type',
        OpeningPrice: 'Prix/prix d’ouverture',
        Leverage: 'Effet de levier',
        LeverageP: 'Le levier ne peut pas être vide',
        LimitPrice: 'Montant de la Transaction',
        LimitPlaceholder: 'S’il vous plaît entrer le montant limite de prix',
        Overnight: 'Nuit à l’hôtel',
        Direction: 'Direction générale',
        Operate: 'opérer',
        Long: 'Acheter/aller longtemps',
        Short: 'Vendre/court',
        Balance: 'équilibre',
        Floating: 'Flottant P/L',
        CloseAll: 'Fermer tout',
        MarketOrder: 'Ordre du marché',
        LimitOrder: 'Ordre de limite',
        Cross: 'La croix',
        Isolated: 'isolé',
        placeholder: 'Meilleur prix d’exécution',
        Margin: 'Numéro de téléphone',
        MarginBalance: 'Solde des marges',
        TradingFee: 'Frais de Trading',
        Position: 'Poste de travail',
        Success: 'Opération réussie',
        Commission: 'En commission',
        Transaction: 'Dans la transaction',
        Completed: 'terminé',
        Cancellation: 'annulation',
        unitPrice: 'Prix unitaire',
        LeverageRatio: 'Ratio de levier',
        cancellation: 'Annuler annuler',
        ClosingPosition: 'Position fermée',
        productName: 'Nom du produit',
        Margin1: 'Marge marge',
        SettlementPrice: 'Prix final',
        EndTime: 'Heure de fin',
        CreateTime: 'Créer du temps',
        WalletBalance: 'Équilibre du portefeuille',
        AvailableFunds: 'Fonds disponibles',
        Text1: 'astuce',
        Text2: 'Voulez-vous effectuer cette opération',
        qr: 'confirmer',
        qx: 'Annuler annuler',
        ddbh: 'Numéro de commande',
        wtsj: 'Temps de confiustment',
        szzyzs: 'Définir le bénéfice et la perte',
    },
    notice: {
        title: 'avis',
        ckxq: 'Détail des détails',
    },
    helpCenter: {
        title: 'guide des novices'
    },
    pwdGroup: {
        title: 'Définition du mot de passe',
        pwdDL: 'Login mot de passe',
        pwdZj: 'Mot de passe du fonds',
    },
    quotation: {
        title: 'Les marchés',
        mc: 'La quantité',
        zxj: 'Dernier prix',
        zdb: 'Variation nette nette',
        liang: 'Le Volume',
    },
    contract: {
        kcslfw: 'Gamme de quantité d’ouverture:',
        gao: 'haute',
        di: 'faible',
        liang: 'Volume de 24 heures',
        placeholder: 'Veuillez entrer le montant',
        SuccessfulPurchase: 'Achetez avec succès',
        smallMin: 'Min.',
        hour: 'heure',
        dayMin: 'Jour de travail',
        Trend: 'Le temps',
        ccjl: 'Liste des Holding',
        ddjl: 'Liste de commande',
        sl: 'La quantité',
        gmj: 'Prix d’achat',
        dqj: 'Prix actuel',
        yjyk: 'Résultat estimatif',
        djs: 'Compte à rebours',
        gbjg: 'Prix de grève',
        sc: 'Frais de manutention',
        pal: 'Profits et pertes',
        mhy: 'Deuxième contrat',
        sj: 'Le temps',
        yll: 'Taux de Profit',
        mz: 'Longue durée',
        md: 'Court terme',
        kcsl: 'Position ouverte quantité',
        kcsj: 'Temps de position ouvert',
        zhye: 'Solde du compte',
        qc: 'Annuler annuler',
    },
    editPassword: {
        title: 'Modifier le mot de passe de connexion',
        jmm: 'Ancien mot de passe',
        xmm: 'Nouveau mot de passe',
        qrmm: 'Confirmer le mot de passe',
        placeholder: 'Ancien mot de passe',
        placeholder1: 'Définir un nouveau mot de passe',
        placeholder2: 'Veuillez saisir un nouveau mot de passe',
        qrxf: 'OK',
    },
    secure: {
        title: 'sûr',
        title1: 'Authentification à deux facteurs',
        title2: 'Pour assurer la sécurité de votre compte, veuillez remplir le KYC.',
        gridTxt: 'Définition du mot de passe',
        gridTxt1: 'Vérification Mobile',
        gridTxt2: 'Vérification par courriel',
        gridTxt3: 'Authentification d’identité',
        listTxt: 'Carte bancaire',
        listTxt1: 'Adresse de retrait',
    },
    bindBank: {
        title: 'Carte bancaire',
        addCard: 'Ajouter une carte bancaire',
        editCard: 'Modifier le mot de passe du fonds de vérification',
        ts1: 'Vous n’avez pas encore subi d’authentification d’identité',
        ts2: 'Veuillez d’abord effectuer une authentification d’identité',
        addTitle: 'Ajouter une carte bancaire',
        userName: 'Nom réel',
        tips1: 'Veuillez entrer votre vrai nom',
        CollectionDetailArea: 'Collection zone de détail',
        tips6: 'Veuillez entrer la zone de détail de la Collection',
        userPhone: 'Numéro de téléphone réservé',
        tips2: 'Veuillez entrer un numéro de téléphone réservé',
        bankName: 'Nom de la banque',
        tips3: 'Veuillez entrer le nom de la banque d’ouverture de compte',
        bankAds: 'Adresse bancaire',
        tips5: 'Veuillez entrer l’adresse bancaire d’ouverture de compte',
        bankId: 'Numéro de compte de carte bancaire',
        tips4: 'Veuillez entrer votre numéro de compte de carte bancaire',
        CountryCode: 'Code du pays',
        tips7: 'Veuillez sélectionner un code de pays',
        tips8: 'Veuillez entrer le Code SWIFT',
        tips9: 'Veuillez entrer le montant',
        tips10: 'Le montant de la recharge ou du retrait ne peut être inférieur à 100',
        addTxt: 'économisez',
    },
    bindMail: {
        title: 'Définir l’adresse e-mail',
        placeholder1: 'Adresse courriel',
        ptitle: 'Changer de numéro de téléphone',
        placeholder2: 'Veuillez entrer votre numéro de téléphone portable',
        next: 'Suivant:',
        zjmm: 'Mot de passe du fonds',
        placeholder3: 'Veuillez entrer le mot de passe de votre fonds',
    },
    setting: {
        title: 'paramètres',
        setTxt: 'Image de profil',
        setTxt1: 'Langue de travail',
        setTxt2: 'Taux de change',
        setTxt3: 'À propos de nous',
    },
    withPass: {
        title: 'Définir le mot de passe du fonds',
        title1: 'Le mot de passe du fonds ne peut pas être le même que le mot de passe de connexion',
        aqyz: 'Vérification de sécurité',
        aqyzm: 'Téléphone /Email OTP',
        jmm: 'Ancien mot de passe',
        xmm: 'Nouveau mot de passe',
        qrmm: 'Confirmer le mot de passe',
        placeholder: 'Ancien mot de passe',
        placeholder1: 'Définir un nouveau mot de passe',
        placeholder2: 'Veuillez saisir un nouveau mot de passe',
        qrxf: 'OK OK',
        tips: 'Veuillez entrer un ancien mot de passe d’au moins 6 chiffres',
        tips1: 'Veuillez saisir un nouveau mot de passe d’au moins 6 chiffres',
        tips2: 'Veuillez entrer votre mot de passe de confirmation',
        tips3: 'L’entrée de deux nouveaux mots de passe est incohérente',
        tips4: 'Le mot de passe a été défini avec succès',
        confirm: 'confirmer',
    },
    newBe: {
        title: 'Souscription à un abonnement',
        title1: 'Mon abonnement',
        scqx: 'Période de verrouillage',
        day: 'Jour de travail',
        sy: 'restant',
        sdsg: 'Historique de l’histoire',
        sysl: 'Quantité restante',
        gmsl: 'Quantité d’achat',
        dj: 'Prix unitaire',
        tips: 'S’il vous plaît entrer la quantité d’achat',
        gmzj: 'Prix d’achat Total',
        qbye: 'Équilibre du portefeuille',
        fmzj: 'Prix d’achat Total',
        gm: 'Acheter acheter',
        sgsj: 'Temps d’abonnement',
        Currency: 'Type de jeton',
        Request: 'Montant demandé',
        Passing: 'Montant passé',
        MarketTime: 'Temps d’inscription',
        sqz: 'Dans l’application',
    },
    register: {
        title: 'inscription',
        lang: 'Langue de travail',
        send: 'envoyer',
        Resend: 'Renvoyer à nouveau',
        Account: 'Compte compte',
        Password: 'Mot de passe',
        ConfirmPassword: 'Confirmer le mot de passe',
        InvitationCode: 'Code d’invitation',
        btn: 's’enregistrer',
        mobile: 'Numéro de téléphone',
        mailbox: 'Boîte aux lettres',
        haveAccount: 'Vous avez déjà un compte?',
        haveLogin: 'Se connecter',
        Login: 'Se connecter',
        placeholder: 'S’il vous plaît entrer l’email',
        placeholder0: 'Veuillez entrer le numéro de téléphone',
        placeholder1: 'Veuillez entrer le mot de passe',
        placeholder2: 'Veuillez saisir à nouveau le mot de passe',
        placeholder3: 'Veuillez entrer le code d’invitation',
        placeholder4: 'Veuillez entrer le code de vérification',
        placeholder5: 'Veuillez entrer la bonne boîte mail',
        success: 'L’inscription a été réussie',
        message: 'Le mot de passe doit être de 6 à 16 caractères',
        message1: 'Confirmer le mot de passe saisi a au moins 6 caractères',
        message2: 'L’entrée du mot de passe est incohérente',
        message3: 'Veuillez lire et cocher l’accord',
        agree: 'Je suis d’accord',
        xy: 'Accord d’utilisation et politique de confidentialité',
        qr: 's’enregistrer',
        ftitle: 'Récupérer le mot de passe',
        qrzh: 'OK OK',
        placeholder7: 'Nouveau mot de passe',
        placeholder6: 'Confirmer le nouveau mot de passe',
        placeholder8: 'Veuillez entrer le code de vérification graphique',
        placeholderName: 'Veuillez entrer un nom d’utilisateur',
    },
    lockming: {
        title: 'Verrouillage des jetons',
        wkb: 'Trésor minier',
        wkgjd: 'L’exploitation minière est plus facile',
        qsljl: 'Gagnez une récompense facilement',
        yjzsr: 'Revenu Total estimé',
        ck: 'Taux de rendement annualisé de référence pour les trois derniers jours',
        tips: 'Minimum',
        rlx: 'Intérêt quotidien',
        day: 'Jour de travail',
        scqx: 'Période de verrouillage',
        buy: 'Acheter acheter',
        zysl: 'Quantité de jalonnement',
        qbye: 'Équilibre du portefeuille',
        finished: 'complet',
        doing: 'Dans le progrès',
        sl: 'La quantité',
        shouyilv: 'Taux de',
        ks: 'Heure de début',
        js: 'Heure de fin',
    },
    login: {
        title: 'Se connecter',
        lang: 'Langue de travail',
        mobile: 'Numéro de téléphone',
        mailbox: 'Courriel:',
        Account: 'Compte compte',
        Password: 'Mot de passe',
        btn: 'Se connecter',
        placeholder1: 'Veuillez entrer le mot de passe',
        placeholder: 'Veuillez entrer le numéro de compte',
        fail: 'Veuillez remplir le formulaire',
        ForgotPassword: 'Mot de passe oublié?',
        NoAccount: 'Pas encore de compte?',
        Registration: 'Inscrivez-vous maintenant',
        OtherLogin: 'Autre login',
        server: 'Service client en ligne',
        jzmm: 'Mémoriser le mot de passe',
        success: 'Connexion réussie',
        ageent: 'Lire et accepter les',
        ageentTitle: 'Contrat de service',
        ageentFail: 'Veuillez lire et vérifier le contrat de service',
        Home: 'La maison',
        userbox: 'Nom d’utilisateur',
    },
    moneyAddr: {
        title: 'Adresse du portefeuille',
        Currency: 'Monnaie nationale',
        Address: 'Adresse:',
        placeholder: 'Veuillez sélectionner',
        DepositAddress: 'Adresse de dépôt',
        WalletAddress: 'Adresse du portefeuille',
        Date: 'Date de naissance',
        add: 'Ajouter une adresse',
        addTxt: 'Ajoutez au plus 3 adresses à chaque blockchain',
        remark: 'Remarques remarques',
        Walletremark: 'Veuillez ajouter des remarques pour cette adresse',
        network: 'principale',
        networkXy1: 'Le réseau que vous avez choisi est',
        networkXy2: '....... Si vous avez envoyé vos biens à une adresse incorrecte par erreur, vous perdrez votre bien.',
        ChainAddress: 'Adresse de chaîne',
        qrcode: 'Portefeuille QR code',
        submit: 'soumettre',
        tips: 'Veuillez sélectionner le type de portefeuille',
        tips1: 'Veuillez sélectionner l’adresse de la chaîne',
        tips2: 'Veuillez entrer l’adresse du portefeuille',
        tips3: 'Veuillez télécharger le code QR du portefeuille',
        success: 'Le succès',
    },
    recharge: {
        title: 'recharger',
        title1: 'Détails de Recharge',
        ls: 'Historique de l’histoire',
        td: 'Veuillez sélectionner le canal suivant',
        bc: 'Enregistrer QR',
        address: 'Adresse de dépôt',
        copy: 'Copier l’adresse',
        num: 'Nombre de dépôts',
        Upload: 'Télécharger une photo',
        Submit: 'soumettre',
        tip: 'Veuillez entrer le montant',
        tip1: 'Veuillez télécharger le code QR',
        copySuc: 'Copié avec succès',
    }
}